import React, { useEffect, useState } from "react";
import Sidebar from "../Frame/Sidebar";
import { useParams } from "react-router-dom";
import { Footer } from "../../Constants/Footer";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchRequest,
  updateRequest,
  setSelectedUserId,
} from "../../Redux/slices/userSlice.js";
import Dropdown from "./VerifyUser/VerificationStatusDropdown.jsx";
import { useUser } from "../../Constants/context.js";
import toast, { Toaster } from "react-hot-toast";

export const VerificationUser = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editableUser, setEditableUser] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [
    selectedAadharVerificationStatus,
    setSelectedAadharVerificationStatus,
  ] = useState("");
  const [selectedPanVerificationStatus, setSelectedPanVerificationStatus] =
    useState("");
  const [selectedBankVerificationStatus, setSelectedBankVerificationStatus] =
    useState("");
  const userState = useSelector((state) => state.data || {});
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const { users } = userState.data || {};
  const { Admin, Sales, Telecaller, Verification, Market } = useUser();

  const verificationOptions = [
    { value: "Not-Uploaded", label: "Not Uploaded" },
    { value: "Pending", label: "Pending" },
    { value: "Not-Verified", label: "Not Verified" },
    { value: "Verified", label: "Verified" },
  ];

  useEffect(() => {
    if (userId) {
      dispatch(setSelectedUserId(userId));
      dispatch(fetchRequest());
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (users.length > 0) {
      const foundUser = users.find((user) => user._id === userId);
      setUser(foundUser);
      setEditableUser(foundUser);
      setSelectedAadharVerificationStatus(
        foundUser.verificationstatusaadharcard
      );
      setSelectedPanVerificationStatus(foundUser.verificationstatuspancard);
      setSelectedBankVerificationStatus(foundUser.verificationstatusbank);
    }
  }, [users, userId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditableUser((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdate = async () => {
    try {
      dispatch(
        updateRequest({
          id: userId,
          ...editableUser,
          verificationstatusaadharcard: selectedAadharVerificationStatus,
          verificationstatuspancard: selectedPanVerificationStatus,
          verificationstatusbank: selectedBankVerificationStatus,
        })
      );

      toast.success("User updated successfully!");
    } catch (error) {
      toast.error(`Update failed: ${error.message}`);
    } finally {
      setConfirmDialogOpen(false);
    }
  };

  const handleVerificationStatusChange = (statusType, status) => {
    if (statusType === "aadhar") {
      setSelectedAadharVerificationStatus(status);
    } else if (statusType === "bank") {
      setSelectedBankVerificationStatus(status);
    } else if (statusType === "pan") {
      setSelectedPanVerificationStatus(status);
    }
  };

  const handleConfirmDialogOpen = () => setConfirmDialogOpen(true);
  const handleCloseConfirmDialog = () => setConfirmDialogOpen(false);

  if (!user) {
    return <div>Loading...</div>;
  }
  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  return (
    <Sidebar>
      {Admin || Telecaller || Verification ? (
        <>
          <div className="xl:ml-80 text-center px-4 py-5 sm:px-6">
            <h3 className="text-2xl leading-6 font-medium text-gray-900">
              User Verification Details
            </h3>
          </div>
          <div className="p-4 xl:ml-80 flex flex-col">
            <h6 className="block mb-2 font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900 uppercase">
              <p className="top-2">
                {user.IsActive ? (
                  <span className="inline-flex items-center bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">
                    <span
                      className="w-2 h-2 me-1 bg-green-500 rounded-full"
                      style={{ animation: "blink 1s infinite" }}
                    ></span>
                    Active
                  </span>
                ) : (
                  <span className="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                    <span
                      className="w-2 h-2 me-1 bg-red-500 rounded-full"
                      style={{ animation: "blink 1s infinite" }}
                    ></span>
                    Offline
                  </span>
                )}
                <style jsx>{`
                  @keyframes blink {
                    0% {
                      opacity: 1;
                    }
                    50% {
                      opacity: 0;
                    }
                    100% {
                      opacity: 1;
                    }
                  }
                `}</style>
              </p>
              {isEditing ? (
                <input
                  type="text"
                  name="name"
                  value={editableUser.name || ""}
                  onChange={handleChange}
                  className="bg-gray-100 border border-gray-300 rounded-md"
                />
              ) : (
                user.name
              )}
              {user.verificationstatus === true ? (
                <div className="ml-5 inline-flex items-center bg-gradient-to-r from-green-400 to-green-500 text-white text-xs font-medium px-3 py-1 rounded-full shadow-md">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 h-4 mr-2 fill-current"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 1a9 9 0 110 18 9 9 0 010-18zm4.95 7.19a.75.75 0 00-1.06-1.06l-4.95 4.95-2.22-2.22a.75.75 0 00-1.06 1.06l2.75 2.75a.75.75 0 001.06 0l5.25-5.25z" />
                  </svg>
                  Verified
                </div>
              ) : (
                <div className="inline-flex items-center bg-gradient-to-r from-red-400 to-red-500 text-white text-xs font-medium px-3 py-1 rounded-full shadow-md">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 h-4 mr-2 fill-current"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm1.22-10.22a.75.75 0 011.06 1.06L11.06 10l1.22 1.22a.75.75 0 11-1.06 1.06L10 11.06l-1.22 1.22a.75.75 0 11-1.06-1.06L8.94 10 7.72 8.78a.75.75 0 111.06-1.06L10 8.94l1.22-1.22z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Not Verified
                </div>
              )}
            </h6>
            <div className="relative flex bg-clip-border rounded-xl bg-white text-gray-700 shadow-md l flex-row">
              <div className="relative flex bg-clip-border rounded-xl bg-white text-gray-700 shadow-md flex-row p-4 space-x-4">
                <div className="flex flex-col space-y-4">
                  <div className="flex-none">
                    <img
                      style={{ height: "170px", width: "400px" }}
                      className="rounded-xl"
                      src={user.aadhar}
                      alt="card-image"
                    />
                  </div>
                  <div className="flex-none">
                    <img
                      style={{ height: "170px", width: "400px" }}
                      className="rounded-xl"
                      src={user.pan}
                      alt="card-image"
                    />
                  </div>
                  <div className="flex flex-col space-y-4">
                    <div className="flex-none">
                      <img
                        style={{ height: "170px", width: "400px" }}
                        className="rounded-xl"
                        src={user.bank_details}
                        alt="card-image"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full pl-6 top-0">
                <div className="flex">
                  <div className="w-2/4">
                    <h6 className="block mb-9 mt-3 font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900 uppercase">
                      PhoneNumber:{" "}
                      {isEditing ? (
                        Admin ? (
                          <input
                            type="text"
                            name="phoneNumber"
                            disabled
                            value={editableUser.phoneNumber || ""}
                            onChange={handleChange}
                            onInput={(e) => {
                              e.target.value = e.target.value
                                .replace(/\D/g, "")
                                .slice(0, 18);
                            }}
                            maxLength={10}
                            inputMode="numeric"
                            className="outline-none border-transparent"
                          />
                        ) : (
                          user.phoneNumber
                        )
                      ) : (
                        user.phoneNumber
                      )}
                    </h6>
                  </div>
                  <div className="">
                    <h4 className="block mb-9 mt-3 text-2xl font-sans antialiased font-semibold leading-relaxed tracking-normal text-gray-600">
                      Email:{" "}
                      {isEditing ? (
                        Admin ? (
                          <input
                            type="text"
                            name="email"
                            disabled
                            value={editableUser.email || ""}
                            onChange={handleChange}
                            className=" outline-none border-transparent"
                          />
                        ) : (
                          user.email
                        )
                      ) : (
                        user.email
                      )}
                    </h4>
                  </div>
                </div>
                <p className="block text-2xl font-sans antialiased font-semibold leading-relaxed text-gray-700">
                  <div className="flex">
                    <div className="w-2/4">
                      <h4 className="mb-7">
                        Aadhar:{" "}
                        {isEditing ? (
                          <input
                            type="text"
                            name="aadhar_no"
                            disabled
                            value={editableUser.aadhar_no || ""}
                            placeholder="Enter 12 digit Aadhar"
                            onChange={(e) => {
                              const onlyDigits = e.target.value.replace(
                                /\D/g,
                                ""
                              ); // Remove non-digits
                              if (onlyDigits.length <= 12) {
                                handleChange({
                                  target: {
                                    name: "aadhar_no",
                                    value: onlyDigits,
                                  },
                                });
                              }
                            }}
                            maxLength={12}
                            inputMode="numeric"
                            className="outline-none border-transparent"
                          />
                        ) : (
                          user.aadhar_no
                        )}
                      </h4>

                      <h4 className="mb-7">
                        Pan:{" "}
                        {isEditing ? (
                          <input
                            type="text"
                            name="pan_no"
                            disabled
                            value={editableUser.pan_no || ""}
                            placeholder="Enter 10-digit PAN"
                            onChange={(e) => {
                              const panValue = e.target.value.toUpperCase();
                              // Allow only valid PAN characters (letters and numbers) up to 10 characters
                              e.target.value = panValue
                                .replace(/[^A-Z0-9]/g, "")
                                .slice(0, 10);
                              handleChange(e);
                            }}
                            onBlur={(e) => {
                              const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
                              if (!panRegex.test(e.target.value)) {
                                alert(
                                  "Invalid PAN format. Example: AAAAA1234A"
                                );
                              }
                            }}
                            maxLength={10}
                            inputMode="text"
                            className="outline-none border-transparent"
                          />
                        ) : (
                          user.pan_no
                        )}
                      </h4>
                      <h4 className="mb-7">
                        DOB:{" "}
                        {isEditing ? (
                      <input
                      type="date"
                      name="dob"
                      value={editableUser.dob || ""}
                      placeholder="Enter date of birth"
                      onChange={(e) => {
                        const dob = e.target.value;
                        handleChange(e); // Update the dob in editableUser state
                    
                        // Calculate age based on the selected DOB
                        const age = calculateAge(dob);
                        handleChange({
                          target: { name: "age", value: age },
                        }); // Update the age field
                      }}
                      max={new Date(new Date().setFullYear(new Date().getFullYear() - 18))
                        .toISOString()
                        .split("T")[0]} // Set the max date to 18 years ago
                      className="bg-gray-100 border border-gray-300 rounded-md"
                    />
                    
                        ) : (
                          user.dob
                        )}
                      </h4>

                      <h4 className="mb-7">
                        Age:{" "}
                        {isEditing ? (
                          <input
                            type="text"
                            name="age"
                            value={editableUser.age || ""}
                            placeholder="Enter Age Greater than 18 "
                            onChange={handleChange}
                            onInput={(e) => {
                              e.target.value = e.target.value
                                .replace(/\D/g, "")
                                .slice(0, 10);
                            }}
                            maxLength={2}
                            inputMode="numeric"
                            className="outline-none border-transparent"
                            disabled
                          />
                        ) : (
                          user.age
                        )}
                      </h4>

                      <h4 className="mb-7 flex ">
                        Gender:{" "}
                        {isEditing ? (
                          <div className="flex ms-5 space-x-4">
                            <label className="flex items-center">
                              <input
                                type="radio"
                                name="gender"
                                value="Male"
                                checked={editableUser.gender === "Male"}
                                onChange={handleChange}
                                className="bg-gray-100 border border-gray-300 rounded-md"
                              />
                              <span className="ml-2">Male</span>
                            </label>
                            <label className="flex items-center">
                              <input
                                type="radio"
                                name="gender"
                                value="Female"
                                checked={editableUser.gender === "Female"}
                                onChange={handleChange}
                                className="bg-gray-100 border border-gray-300 rounded-md"
                              />
                              <span className="ml-2">Female</span>
                            </label>
                            <label className="flex items-center">
                              <input
                                type="radio"
                                name="gender"
                                value="Other"
                                checked={editableUser.gender === "Other"}
                                onChange={handleChange}
                                className="bg-gray-100 border border-gray-300 rounded-md"
                              />
                              <span className="ml-2">Other</span>
                            </label>
                          </div>
                        ) : (
                          user.gender
                        )}
                      </h4>
                    </div>
                    <div className="w-2/4">
                      <h4 className="mb-7">
                        Country:{" "}
                        {isEditing ? (
                          Admin ? (
                            <input
                              type="text"
                              name="country"
                              value={editableUser.country || ""}
                              placeholder="Enter Country Name "
                              onChange={handleChange}
                              className="bg-gray-100 border border-gray-300 rounded-md"
                            />
                          ) : (
                            user.country
                          )
                        ) : (
                          user.country
                        )}
                      </h4>
                      <h4 className="mb-7">
                        State:{" "}
                        {isEditing ? (
                          <input
                            type="text"
                            name="state"
                            value={editableUser.state || ""}
                            placeholder="Enter state Name "
                            onChange={handleChange}
                            className="bg-gray-100 border border-gray-300 rounded-md"
                          />
                        ) : (
                          user.state
                        )}
                      </h4>
                      <h4 className="mb-7">
                        Coins:{" "}
                        {isEditing ? (
                          <input
                            type="text"
                            name="coins"
                            disabled
                            placeholder="Enter Bonus "
                            value={editableUser.coins || ""}
                            onChange={handleChange}
                            className="outline-none border-transparent"
                          />
                        ) : (
                          user.coins
                        )}
                      </h4>
                      <h4 className="mb-7">
                        Bank Account Number:{" "}
                        {isEditing ? (
                          Admin ? (
                            <input
                              type="text"
                              name="account_number"
                              disabled
                              value={editableUser.account_number || ""}
                              placeholder="Enter Account No "
                              onChange={handleChange}
                              onInput={(e) => {
                                e.target.value = e.target.value
                                  .replace(/\D/g, "")
                                  .slice(0, 18);
                              }}
                              maxLength={18}
                              inputMode="numeric"
                              className="outline-none border-transparent"
                            />
                          ) : (
                            user.account_number
                          )
                        ) : (
                          user.account_number
                        )}
                      </h4>
                      <h4 className="mb-7">
                        IFSC:{" "}
                        {isEditing ? (
                          Admin ? (
                            <input
                              type="text"
                              name="ifsc"
                              disabled
                              value={editableUser.ifsc || ""}
                              placeholder="Enter Ifsc  No "
                              onChange={handleChange}
                              className="outline-none border-transparent"
                            />
                          ) : (
                            user.ifsc
                          )
                        ) : (
                          user.ifsc
                        )}
                      </h4>
                    </div>
                  </div>
                  <div className="relative inline-block text-left ">
                    {isEditing ? (
                      <div className="flex">
                        <div className="mr-12">
                          Aadharcard:
                          {Number(editableUser.age) > 17 &&
                            editableUser.dob !== "" &&
                            editableUser.aadhar_no !== "" && (
                              <Dropdown
                                id="aadhar"
                                selectedStatus={
                                  selectedAadharVerificationStatus
                                }
                                onStatusChange={(status) =>
                                  handleVerificationStatusChange(
                                    "aadhar",
                                    status
                                  )
                                }
                                options={verificationOptions}
                              />
                            )}
                        </div>
                        <div className="mr-12 ml-12">
                          Pancard :
                          {editableUser.pan_no !== 0 && (
                            <Dropdown
                              id="pan"
                              selectedStatus={selectedPanVerificationStatus}
                              onStatusChange={(status) =>
                                handleVerificationStatusChange("pan", status)
                              }
                              options={verificationOptions}
                            />
                          )}
                        </div>

                        <div className="ml-12">
                          Bank Details:
                          {editableUser.account_number !== " " &&
                            editableUser.ifsc !== " " && (
                              <Dropdown
                                id="bank"
                                selectedStatus={selectedBankVerificationStatus}
                                onStatusChange={(status) =>
                                  handleVerificationStatusChange("bank", status)
                                }
                                options={verificationOptions}
                              />
                            )}
                        </div>
                      </div>
                    ) : (
                      <div className="flex ">
                        <p className="mr-12">
                          Aadharcard:{selectedAadharVerificationStatus}
                        </p>
                        <p className="mr-12 ml-12">
                          Pancard:{selectedPanVerificationStatus}{" "}
                        </p>
                        <p className="ml-12">
                          Bank Details:{selectedBankVerificationStatus}
                        </p>
                      </div>
                    )}
                  </div>
                  {isEditing ? (
                    <div className="flex">
                      {Number(editableUser.age) > 17 &&
                        editableUser.dob !== "" &&
                        editableUser.aadhar_no !== "" && (
                          <button
                            onClick={handleConfirmDialogOpen}
                            className="w-full mt-9 bg-green-500 mr-5 text-center text-white py-2 rounded"
                          >
                            Save Verified Changes
                          </button>
                        )}
                      <button
                        onClick={() => setIsEditing(false)}
                        className="w-full mt-9 bg-gray-500 mr-5 text-center text-white py-2 rounded"
                      >
                        Back To Details
                      </button>
                    </div>
                  ) : (
                    <>
                      {(Admin || Verification) && (
                        <button
                          onClick={() => setIsEditing(true)}
                          className="w-full mt-9 bg-red-500 mr-5 text-center text-white py-2 rounded"
                        >
                          <span
                            style={{ animation: "blink 1s infinite" }}
                            className=""
                          >
                            DO YOU WANT TO UPDATE DETAILS CLICK HERE
                          </span>
                          <style jsx>{`
                            @keyframes blink {
                              0% {
                                opacity: 1;
                              }
                              50% {
                                opacity: 0;
                              }
                              100% {
                                opacity: 1;
                              }
                            }
                          `}</style>
                        </button>
                      )}
                    </>
                  )}
                </p>
              </div>
              {confirmDialogOpen && (
                <div className=" w-screen fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50 z-50">
                  <div className="bg-white xl:ml-80 text-center px-2 mr-2 py-5 sm:px-6  rounded-lg shadow-lg">
                    <h2 className="text-lg  text-red-500 text-center text-3xl font-bold">
                      Confirm Before Clicking Yes
                    </h2>
                    <p className="mt-2 text-2xl font-bold">
                      Are you sure you want to update the statusfor {user.name}?
                      <br />
                      <div className="flex">
                        <div className="px-5">
                          <img
                            width="300px"
                            height="200px"
                            src={user.aadhar}
                            alt="Aadhar"
                            className="mt-2 mb-2"
                          />
                          <p className="text-red-500 font-bold">
                            {" "}
                            {selectedAadharVerificationStatus}
                          </p>
                          <p className="text-2xl font-bold">
                            AADHAR NO:{user.aadhar_no}
                          </p>
                        </div>
                        <div className="px-5">
                          <img
                            width="300px"
                            height="200px"
                            src={user.pan}
                            alt="Aadhar"
                            className="mt-2 mb-2"
                          />
                          <p className="text-red-500 font-bold">
                            {" "}
                            {selectedPanVerificationStatus}
                          </p>
                          <p className="text-2xl font-bold">
                            PAN NO:{user.pan_no}
                          </p>
                        </div>
                        <div className="px-5">
                          <img
                            width="300px"
                            height="200px"
                            src={user.bank_details}
                            alt="Aadhar"
                            className="mt-2 mb-2"
                          />
                          <p className="text-red-500 font-bold">
                            {selectedBankVerificationStatus}
                          </p>
                          <p className="text-2xl font-bold">
                            A/c No:{user.account_number}{" "}
                          </p>
                          <p className="text-2xl font-bold">IFSC:{user.ifsc}</p>
                        </div>
                      </div>
                    </p>
                    <div className="mt-4 flex justify-end">
                      <button
                        onClick={handleUpdate}
                        className="bg-green-500 text-white px-4 py-2 rounded-lg mr-2"
                      >
                        Yes
                      </button>
                      <button
                        onClick={handleCloseConfirmDialog}
                        className="bg-red-500 text-white px-4 py-2 rounded-lg"
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <div>Unauthorized access</div>
      )}
      <Footer />
      <Toaster />
    </Sidebar>
  );
};
