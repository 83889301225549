import axios from 'axios';
import { BASE_URL } from '../../Constants/constant.js';
import Cookies from 'js-cookie';

export const getApi = async (url, config = {}) => {
  try {
    const token = Cookies.get('token');
    if (!token) {
      throw new Error('Authentication token is missing.');
    }

    // Merge headers with Authorization
    const mergedConfig = {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.get(`${BASE_URL}/${url}`, mergedConfig);
    return response;
  } catch (error) {
    return error.response || error;
  }
};
