import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { BASE_URL } from "../../Constants/constant";
import Cookies from 'js-cookie';
export const PayBonus = ({ userId, roomId, onClose }) => {
    const [error, setError] = useState(null);
    const [response, setResponse] = useState(null);
    const [isConfirmVisible, setIsConfirmVisible] = useState(false);
    const [formData, setFormData] = useState({
        userId: userId,
        payed_amount: "",
        pay_amount_as: "",
        roomId: roomId,
        reason: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async () => {
        try {
            const token = Cookies.get("token");
            const res = await axios.post(
                `${BASE_URL}/api/v-1/pay_bonus/AddpayBonus`,
                { ...formData },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            setResponse(res.data);
            setError(null);
            toast.success("Bonus added successfully!");
            setIsConfirmVisible(false);
            onClose();
        } catch (err) {
            setError(err.message);
            setResponse(null);
            toast.error("Failed to add bonus.");
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-70 z-50">
            <div className="relative bg-white rounded-lg shadow-lg w-96 p-6 space-y-4">
                {/* Close Button */}
                <button
                    onClick={onClose}
                    className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                >
                    ✖
                </button>

                <h2 className="text-xl font-bold text-center text-gray-700">Pay Amount </h2>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        setIsConfirmVisible(true);
                    }}
                    className="space-y-4"
                >
                    <div>
                        <label className="block text-sm font-medium text-gray-600">Pay Amount:</label>
                        <input
                            type="number"
                            name="payed_amount"
                            value={formData.payed_amount}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (!value || parseFloat(value) >= 0) {
                                    setFormData({
                                        ...formData,
                                        payed_amount: value,
                                    });
                                }
                            }}
                            min="0"
                            max="50000"
                            className="w-full px-3 py-2 border rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            required
                            step="any"
                        />

                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-600">Pay Amount As:</label>
                        <select
                            name="pay_amount_as"
                            value={formData.pay_amount_as}
                            onChange={handleChange}
                            className="w-full px-3 py-2 font-medium border rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            required
                        >
                            <option value="">Select an option</option>
                            <option value="bonus_balance">Bonus Balance</option>
                            <option value="game_balance">Game Balance</option>
                        </select>
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-600">Reason:</label>
                        <textarea
                            name="reason"
                            value={formData.reason}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            rows="4"
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        className="px-4 py-2 mt-4 bg-gradient-to-r from-green-400 to-green-600 text-white font-semibold rounded-lg shadow-lg hover:from-green-500 hover:to-green-700 transform hover:scale-105 transition-all duration-300 w-full"
                    >
                        Pay
                    </button>
                </form>

                {isConfirmVisible && (
                    <div className="mt-4 p-4 bg-gray-100 rounded-md shadow-inner">
                        <p className="text-gray-600">! Warning you are Adding amount to user, Please confirm Again?</p>
                        <div className="flex justify-end space-x-4 mt-3">
                            <button
                                onClick={handleSubmit}
                                className="bg-green-500 text-white py-1 px-3 rounded-md hover:bg-green-600"
                            >
                                Yes
                            </button>
                            <button
                                onClick={() => setIsConfirmVisible(false)}
                                className="bg-green-500 text-white py-1 px-3 rounded-md hover:bg-green-600"
                            >
                                No
                            </button>
                        </div>
                    </div>
                )}

                {error && <p className="text-sm text-red-500 mt-2">{error}</p>}
                {response && <p className="text-sm text-green-500 mt-2">Success: {JSON.stringify(response)}</p>}
            </div>
        </div>
    );
};
