import React, { useEffect, useRef, useState } from 'react';

import { MdArrowDropDown } from "react-icons/md";
import { useFormik } from 'formik';
import { employeeValidationSchema } from '../Validation/EmployeeValidationSchema ';
import { useUser } from '../../Constants/context';
import { addRequest } from '../../Redux/slices/employeeSlice';
import { useDispatch } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';

const dropdownOptions = [
  { id: 1, planname: 'Admin' },
  { id: 2, planname: 'Market Team' },
  { id: 3, planname: 'Telecaller Team' },
  { id: 4, planname: 'Verification Team' },
];

export const AddEmployee = ({ onClose }) => {
  const dispatch = useDispatch();
  const [access, setAccess] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [isDropdownOpenfilter, setIsDropdownOpenfilter] = useState(false);
  const [checkboxStates, setCheckboxStates] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
  });

  const dropdownRef = useRef(null);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      repeatPassword: '',
      name: '',
      aadharno: '',
      employeeId: '',
      phone: '',
      role: '',
      access: [],
    },
    validationSchema: employeeValidationSchema,
    onSubmit: async (values) => {
      try {
        onClose();
        const resultAction = dispatch(addRequest(values));
        const { payload } = resultAction;
        if (payload.status === 200) {
          console.log(payload.status, "payload.status ")
          toast.success("Employee registered successfully");

          formik.resetForm();


        } else if (resultAction.type === 'employee/addFailure') {
          console.log("else if")
          onClose();
          toast.success("An error occurred");
        }
      } catch (error) {
        toast.error(error.message || "An error occurred");
        onClose();
      }
    },

    validateOnChange: false,
    validateOnBlur: false,
  });

  useEffect(() => {
    if (formik.errors && Object.keys(formik.errors).length > 0) {
      const firstError = Object.values(formik.errors)[0];
      toast.error(firstError);
    }
  }, [formik.errors]);

  useEffect(() => {
    const updatedAccess = Object.keys(checkboxStates)
      .filter(key => checkboxStates[key])
      .map(key => dropdownOptions.find(option => option.id === parseInt(key))?.planname || '')
      .filter(name => name);

    setAccess(updatedAccess);
    formik.setFieldValue('access', updatedAccess);
  }, [checkboxStates]);

  const handleCheckboxChanged = (id) => {
    setCheckboxStates(prev => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const handleSelectAllChanged = () => {
    const allChecked = !selectAllChecked;
    const newCheckboxStates = Object.keys(checkboxStates).reduce((acc, key) => {
      acc[key] = allChecked;
      return acc;
    }, {});
    setCheckboxStates(newCheckboxStates);
    setSelectAllChecked(allChecked);
  };

  const toggleDropdownfilter = () => {
    setIsDropdownOpenfilter(prev => !prev);
  };


  const { Admin, Sales, Telecaller, Verification, Market } = useUser();
  return (
    <>
      {Admin ?
        (


          <form className="mx-auto px-8   mb-4" onSubmit={formik.handleSubmit}>

            <div className=" text-center mb-4">
              <div>
                <h1 className="text-xl font-bold">Enter Employee Details</h1>

              </div>
            </div>
            <div className="grid md:grid-cols-2 md:gap-6">
              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="text"
                  name="name"
                  id="floating_first_name"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
                <label
                  htmlFor="floating_first_name"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Employee name
                </label>
              </div>
              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="text"
                  name="employeeId"
                  id="floating_last_name"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  value={formik.values.employeeId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
                <label
                  htmlFor="floating_last_name"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Employee Id
                </label>
              </div>
            </div>

            <div className="grid md:grid-cols-2 md:gap-6">
              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="text"
                  name="role"
                  id="floating_first_name"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  value={formik.values.role}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
                <label
                  htmlFor="floating_first_name"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Employee Job Role
                </label>
              </div>
              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="number"
                  name="aadharno"
                  id="floating_last_name"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  value={formik.values.aadharno}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Validate if the input is exactly 12 digits
                    if (/^\d{0,12}$/.test(value)) {
                      formik.setFieldValue('aadharno', value);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  required
                />
                <label
                  htmlFor="floating_last_name"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Aadhar Number
                </label>
              </div>
            </div>

            <div className="relative z-0 w-full mb-5 group">
              <input
                type="email"
                name="email"
                id="floating_email"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required
              />
              <label
                htmlFor="floating_email"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Email address
              </label>
            </div>
            <div className="grid md:grid-cols-2 md:gap-6">
              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="password"
                  name="password"
                  id="floating_password"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
                <label
                  htmlFor="floating_password"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Password
                </label>
              </div>
              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="password"
                  name="repeatPassword"
                  id="floating_repeat_password"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  value={formik.values.repeatPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
                <label
                  htmlFor="floating_repeat_password"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Confirm password
                </label>
              </div>
            </div>
            <div className="grid md:grid-cols-2 md:gap-6">
              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="number"
                  name="phone"
                  id="floating_phone"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  value={formik.values.phone}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d{0,10}$/.test(value)) {
                      formik.setFieldValue('phone', value);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  required
                />


                <label
                  htmlFor="floating_phone"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Phone number
                </label>
              </div>
              <div className="relative z-0 w-full mb-5 group">
                <div className="flex justify-end">
                  <div
                    ref={dropdownRef}
                    className="relative flex items-center justify-start py-3 cursor-pointer mb-8"
                    onMouseEnter={() => setIsDropdownOpenfilter(true)}
                    onMouseLeave={() => setIsDropdownOpenfilter(false)}

                  >
                    <label htmlFor="yourInput" className="flex items-end px-3">
                      Access :
                      <span className="text-red-500 ml-1">*</span>
                    </label>
                    <div className="flex pb-1 border-b border-gray-500 outline-none ">
                      <input
                        type="text"
                        placeholder="Select category"
                        value={access.join(', ') || 'Select'}
                        readOnly
                        style={{ border: "none", outline: "none" }}
                        required
                      />
                      <MdArrowDropDown
                        onClick={toggleDropdownfilter}
                        className="text-xl"
                      />
                      {/* Dropdown Content */}
                      {isDropdownOpenfilter && (
                        <div
                          style={{
                            boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.25)',
                            borderRadius: '0 0 5px 5px',
                            zIndex: '100',
                          }}

                          className="absolute top-12 left-0 w-full bg-white border border-gray-300"
                        >
                          <div className="p-2">
                            <div className="flex items-center mb-2">
                              <input
                                type="checkbox"
                                id="selectAll"
                                checked={selectAllChecked}
                                onChange={handleSelectAllChanged}
                                className="mr-2"
                              />
                              <label htmlFor="selectAll">Select All</label>
                            </div>
                            {dropdownOptions.map(option => (
                              <div key={option.id} className="flex items-center mb-2">
                                <input
                                  type="checkbox"
                                  id={`access-${option.id}`}
                                  checked={checkboxStates[option.id]}
                                  onChange={() => handleCheckboxChanged(option.id)}
                                  className="mr-2"
                                />
                                <label htmlFor={`access-${option.id}`}>{option.planname}</label>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='flex justify-center'>
              <div className='mr-4'>
                <button
                  type="submit"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Register
                </button>
              </div>

              <div>
                <button
                  onClick={onClose}
                  className="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Close
                </button>
              </div>
            </div>
          </form>

        ) : (
          <div className='text-center'>
            <p className='mb-5 text-3xl text-red-500'>Access Not Permitted...!</p>
            <button
              onClick={onClose}
              className="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Close
            </button>
          </div>
        )}
      <Toaster />
    </>
  );
};



