import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { MdArrowDropDown } from "react-icons/md";
import { BASE_URL } from "../../Constants/constant";
import toast from "react-hot-toast";
import { useUser } from "../../Constants/context";
import { updateRequest } from "../../Redux/slices/employeeSlice.js";
import { useDispatch } from "react-redux";

const dropdownOptions = [
  { id: 1, planname: "Admin" },
  { id: 2, planname: "Market Team" },
  { id: 3, planname: "Telecaller Team" },
  { id: 4, planname: "Verification Team" },
];

export const EditEmployee = ({ employee, onClose }) => {
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const dropdownRef1 = useRef(null);

  const [formData, setFormData] = useState({
    email: "",
    name: "",
    aadharno: "",
    employeeId: "",
    phone: "",
    role: "",
    access: [],
    block: false,
  });
  const [accessOptions, setAccessOptions] = useState(dropdownOptions);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [checkboxStates, setCheckboxStates] = useState({});
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [blockDropdownOpen, setBlockDropdownOpen] = useState(false);
  const [blockStatus, setBlockStatus] = useState("Unblock");
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [pendingBlockStatus, setPendingBlockStatus] = useState(null);

  useEffect(() => {
    if (employee) {
      setFormData({
        email: employee.email || "",
        name: employee.name || "",
        aadharno: employee.aadharno || "",
        employeeId: employee.employeeId || "",
        phone: employee.phone || "",
        role: employee.role || "",
        access: employee.access || [],
        block: employee.block || false,
      });

      const initialCheckboxStates = {};
      const employeeAccessSet = new Set(employee.access);

      dropdownOptions.forEach((option) => {
        initialCheckboxStates[option.id] = employeeAccessSet.has(
          option.planname
        );
      });

      setCheckboxStates(initialCheckboxStates);
      const allChecked = dropdownOptions.every(
        (option) => initialCheckboxStates[option.id]
      );
      setSelectAllChecked(allChecked);
      setBlockStatus(employee.block ? "Block" : "Unblock");
    }
  }, [employee]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChanged = (id) => {
    setCheckboxStates((prevState) => {
      const newState = { ...prevState, [id]: !prevState[id] };
      const updatedAccess = accessOptions
        .filter((option) => newState[option.id])
        .map((option) => option.planname);
      setFormData({
        ...formData,
        access: updatedAccess,
      });

      const allChecked = accessOptions.every((option) => newState[option.id]);
      setSelectAllChecked(allChecked);

      return newState;
    });
  };

  const handleSelectAllChanged = () => {
    const allChecked = !selectAllChecked;
    const newCheckboxStates = {};
    accessOptions.forEach((option) => {
      newCheckboxStates[option.id] = allChecked;
    });
    setCheckboxStates(newCheckboxStates);
    setFormData({
      ...formData,
      access: allChecked ? accessOptions.map((option) => option.planname) : [],
    });
    setSelectAllChecked(allChecked);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  const toggleBlockDropdown = () => {
    setBlockDropdownOpen((prevState) => !prevState);
  };

  const handleBlockStatusChange = (status) => {
    setPendingBlockStatus(status);
    setConfirmDialogOpen(true);
  };

  const confirmBlockStatusChange = (confirm) => {
    if (confirm) {
      setFormData((prevState) => ({
        ...prevState,
        block: pendingBlockStatus === "Block",
      }));
      setBlockStatus(pendingBlockStatus);
      setBlockDropdownOpen(false);
    }
    setConfirmDialogOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch(updateRequest({ id: employee._id, ...formData }));
      toast.success("Employee registered successfully");
      onClose();
    } catch (error) {
      toast.error("An error occurred");
    }
  };

  const { Admin } = useUser();
  return (
    <>
      {Admin ? (
        <form className="mb-4" onSubmit={handleSubmit}>
          <div className="text-center mb-4">
            <h1 className="text-xl font-bold">Edit Employee Details</h1>
          </div>
          <div className="grid md:grid-cols-2 md:gap-8">
            {["name", "employeeId", "role", "aadharno", "email", "phone"].map(
              (field) => (
                <div key={field} className="relative z-0 w-full mb-5 group">
                  <input
                    type="text" 
                    name={field}
                    maxLength={
                      field === "employeeId"
                        ? 5
                        : field === "phone"
                        ? 10
                        : field === "aadharno"
                        ? 12
                        : undefined
                    }
                    inputMode={
                      field === "phone" || field === "aadharno"
                        ? "numeric"
                        : "text"
                    }
                    disabled={field === 'email'}
                    pattern={
                      field === "phone" || field === "aadharno"
                        ? "[0-9]*"
                        : undefined
                    }
                    id={`floating_${field}`}
                    className={`block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer ${
                      field === "email" ? "w-full" : ""
                    }`}
                    placeholder=" "
                    value={field === 'employeeId' ? formData[field]?.toUpperCase() : formData[field]}
                    onChange={(e) => {
                      let { value } = e.target;

                      if (field === "phone" || field === "aadharno") {
                        value = value.replace(/\D/g, ""); 
                        if (value.length > (field === "phone" ? 10 : 12))
                          return;
                      }

                      handleInputChange({ target: { name: field, value } });
                    }}
                  />

                  <label
                    htmlFor={`floating_${field}`}
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    {field?.charAt(0)?.toUpperCase() +
                      field.slice(1)?.replace(/([A-Z])/g, " $1")}
                  </label>
                </div>
              )
            )}
          </div>
          <div
            ref={dropdownRef1}
            onMouseEnter={() => setBlockDropdownOpen(true)}
            onMouseLeave={() => setBlockDropdownOpen(false)}
            className=" flex relative z-0 w-full mb-5 group"
          >
            <div className="relative pt-2 z-0 w-full mb-5 group">
              <div className="flex items-center">
                <label htmlFor="block" className="flex items-end px-3">
                  Block:
                </label>
                <div className="relative flex items-center">
                  <input
                    type="text"
                    placeholder="Select status"
                    value={blockStatus}
                    readOnly
                    style={{ border: "none", outline: "none" }}
                    onClick={toggleBlockDropdown}
                    className={`block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
                  />
                  <MdArrowDropDown
                    onClick={toggleBlockDropdown}
                    className="text-xl"
                  />
                  {blockDropdownOpen && (
                    <div className="absolute top-8 left-0 bg-white border border-gray-300 shadow-lg rounded-lg z-10">
                      <ul className="overflow-y-auto max-h-40">
                        <li className="border-t px-2 py-1 cursor-pointer text-black flex justify-between items-center">
                          <span className="text-sm">Block</span>
                          <div className="flex items-center">
                            <label className="inline-flex items-center">
                              <input
                                type="radio"
                                checked={blockStatus === "Block"}
                                onChange={() =>
                                  handleBlockStatusChange("Block")
                                }
                                className="form-radio h-4 w-4 text-blue-600"
                              />
                            </label>
                          </div>
                        </li>
                        <li className="border-t px-2 py-1 cursor-pointer text-black flex justify-between items-center">
                          <span className="text-sm">Unblock</span>
                          <div className="flex items-center">
                            <label className="inline-flex  ms-5 items-center">
                              <input
                                type="radio"
                                checked={blockStatus === "Unblock"}
                                onChange={() =>
                                  handleBlockStatusChange("Unblock")
                                }
                                className="form-radio h-4 w-4 text-blue-600"
                              />
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div
              ref={dropdownRef}
              onMouseEnter={() => setIsDropdownOpen(true)}
              onMouseLeave={() => setIsDropdownOpen(false)}
              className="flex justify-end"
            >
              <div className="relative flex items-center justify-start py-3 cursor-pointer mb-8">
                <label htmlFor="access" className="flex  items-end px-3">
                  Access:
                </label>
                <div className="flex pb-1 border-b border-gray-500 outline-none">
                  <input
                    type="text"
                    placeholder="Select category"
                    value={formData.access.join(", ")}
                    readOnly
                    style={{ border: "none", outline: "none" }}
                  />
                  <MdArrowDropDown
                    onClick={toggleDropdown}
                    className="text-xl"
                  />
                  {isDropdownOpen && (
                    <div className="absolute top-12 right-0 bg-white border border-gray-300 shadow-lg rounded-lg z-10">
                      <ul className="overflow-y-auto max-h-40">
                        <li className="border-t px-2 py-1 cursor-pointer text-black flex justify-between items-center">
                          <span className="text-sm">All</span>
                          <div className="flex items-center">
                            <label className="inline-flex items-center">
                              <input
                                type="checkbox"
                                checked={selectAllChecked}
                                onChange={handleSelectAllChanged}
                                className="form-checkbox h-3 w-4 text-blue-600"
                              />
                            </label>
                          </div>
                        </li>
                        {accessOptions.map((option) => (
                          <li
                            key={option.id}
                            className="border-t px-2 py-1 cursor-pointer text-black flex justify-between items-center"
                          >
                            <span className="text-sm">{option.planname}</span>
                            <div className="flex items-center">
                              <label className="inline-flex items-center">
                                <input
                                  type="checkbox"
                                  checked={checkboxStates[option.id]}
                                  onChange={() =>
                                    handleCheckboxChanged(option.id)
                                  }
                                  className="form-checkbox h-3 w-4 text-blue-600"
                                />
                              </label>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {confirmDialogOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50 z-50">
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h2 className="text-lg font-semibold">Confirm</h2>
                <p className="mt-2">
                  Are you sure you want to {pendingBlockStatus.toLowerCase()}{" "}
                  this {employee.name}?
                </p>
                <div className="mt-4 flex justify-end">
                  <button
                    onClick={() => confirmBlockStatusChange(true)}
                    className="bg-geen-500 text-white px-4 py-2 rounded-lg mr-2"
                  >
                    Yes
                  </button>
                  <button
                    onClick={() => confirmBlockStatusChange(false)}
                    className="bg-red-500 text-white px-4 py-2 rounded-lg"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          )}

          <div className="flex justify-center">
            <div className="mr-4">
              <button
                type="submit"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
              >
                Update
              </button>
            </div>
            <div>
              <button
                type="button"
                onClick={onClose}
                className="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      ) : (
        <div className="text-center">
          <p className="mb-5 text-3xl text-red-500">Access Not Permitted...!</p>
          <button
            onClick={onClose}
            className="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Close
          </button>
        </div>
      )}
    </>
  );
};
