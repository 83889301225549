import { useEffect, useState } from 'react';
import Sidebar from '../Frame/Sidebar';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRequest, setSelectedUserId } from '../../Redux/slices/userSlice.js';
import { useUser } from '../../Constants/context.js';
import { selectCurrentPage, selectNonverifiedusers, selectpagecount, selectPageSize, selectTotalPages, selectTotalUserCount, selectUsers, selectVerifiedusers } from '../../Redux/Selector/userSelector.js';

import { BASE_URL } from '../../Constants/constant.js';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { selectSearchLoading, selectSearchResults } from '../../Redux/slices/SearchSlice.js';

export const SearchResult = () => {
    const dispatch = useDispatch();

    const users = useSelector(selectUsers);
    const status = useSelector(state => state.data.status);
    const error = useSelector(state => state.data.error);
    const [selectedUserId, setSelectedUserIdLocal] = useState(null);
    const [activeFilter, setActiveFilter] = useState('all');
    const [filteredUsers, setFilteredUsers] = useState([]);
    const { Admin, Sales, Telecaller, Verification, Market } = useUser();
    const pagecount = useSelector(selectpagecount);
    const pageSize = useSelector(selectPageSize);
    const totalPages = useSelector(selectTotalPages);
    const currentPage = useSelector(selectCurrentPage);
    const totalCount = useSelector(selectTotalUserCount);
    const nonverifieduser = useSelector(selectNonverifiedusers);
    const verifieduser = useSelector(selectVerifiedusers)

    useEffect(() => {
        dispatch(fetchRequest(1));
    }, [dispatch]);

    useEffect(() => {
        setFilteredUsers(users);
    }, [users]);





    const handleViewClick = (userId) => {
        setSelectedUserIdLocal(userId);
        dispatch(setSelectedUserId(userId));
    };


    const calculateSerialNumber = (index) => {
        return (currentPage - 1) * pageSize + index + 1;
    };
    const results = useSelector(selectSearchResults);
    const loading = useSelector(selectSearchLoading);

    return (

        <div >

            <div className="-my-2  sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <div className="table-container-button table-container overflow-x-auto">
                            <table id="userTable" className="table table-bordered w-full">
                                <thead className="bg-gray-100  sticky top-0 ">
                                    <tr>
                                        <th className="px-6 py-3  text-left">Sl.No</th>
                                        <th className="px-6 py-3 text-left">Profile</th>
                                        <th className="px-6 py-3 text-left">Name</th>
                                        <th className="px-6 py-3 text-left">Phone Number</th>
                                        {Verification && (
                                            <>
                                                <th className="px-6 py-3 text-left">Aadhar</th>
                                                <th className="px-6 py-3 text-left">Pan</th>
                                                <th className="px-6 py-3 text-left">Bankdetails</th>
                                                <th className="px-6 py-3 text-left">Final</th>
                                            </>
                                        )}
                                        <th className="px-6 py-3 text-left">Registered Date</th>
                                        {(Admin || Telecaller) && (
                                            <th className="px-6 py-3 text-left">Action</th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                    {status === 'loading' ? (
                                        <tr>
                                            <td colSpan="12" className="w-screen text-center">Loading...</td>
                                        </tr>
                                    ) : (
                                        results.length > 0 && results.map((user, index) => (
                                            <tr key={user._id} className={index % 2 === 0 ? "bg-white" : "bg-gray-100"}>
                                                <td className="px-6 py-4">{calculateSerialNumber(index)}</td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="flex items-center">
                                                        <div className="flex-shrink-0 h-10 w-10">
                                                            <img className="h-10 w-10 rounded-full" src={user.image || '/default-image.png'} alt="" />
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="text-sm font-medium text-gray-900">{user.name || 'No Name'}</div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm text-gray-500">{user.phoneNumber || 'No phoneNumber'}</div>
                                                </td>
                                                {Verification && (
                                                    <>
                                                        <td className="px-6 py-4 whitespace-nowrap ">
                                                            <span className={`px-2 mr-2 inline-flex text-xs leading-5 font-semibold rounded-full `}>
                                                                {user.verificationstatusaadharcard === "Not-Uploaded" ? (
                                                                    <span className="inline-flex items-center bg-stone-100 text-stone-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-stone-900 dark:text-stone-300">
                                                                        <span className="w-2 h-2 me-1 bg-stone-500 rounded-full" style={{ animation: 'blink 1s infinite' }}></span>
                                                                        Not-Uploaded
                                                                    </span>
                                                                ) : user.verificationstatusaadharcard === "Pending" ? (
                                                                    <span className="inline-flex items-center bg-yellow-100 text-yellow-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-yellow-900 dark:text-yellow-300">
                                                                        <span className="w-2 h-2 me-1 bg-yellow-500 rounded-full" style={{ animation: 'blink 1s infinite' }}></span>
                                                                        Pending
                                                                    </span>
                                                                ) : user.verificationstatusaadharcard === "Not-Verified" ? (
                                                                    <span className="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                                                                        <span className="w-2 h-2 me-1 bg-red-500 rounded-full" style={{ animation: 'blink 1s infinite' }}></span>
                                                                        Not-Verified
                                                                    </span>
                                                                ) : user.verificationstatusaadharcard === "Verified" ? (
                                                                    <span className="inline-flex items-center bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">
                                                                        <span className="w-2 h-2 me-1 bg-green-500 rounded-full" style={{ animation: 'blink 1s infinite' }}></span>
                                                                        Verified
                                                                    </span>
                                                                ) : null}
                                                            </span>
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap ">
                                                            <span className={`px-2 mr-2 inline-flex text-xs leading-5 font-semibold rounded-full `}>
                                                                {user.verificationstatuspancard === "Not-Uploaded" ? (
                                                                    <span className="inline-flex items-center bg-stone-100 text-stone-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-stone-900 dark:text-stone-300">
                                                                        <span className="w-2 h-2 me-1 bg-stone-500 rounded-full" style={{ animation: 'blink 1s infinite' }}></span>
                                                                        Not-Uploaded
                                                                    </span>
                                                                ) : user.verificationstatuspancard === "Pending" ? (
                                                                    <span className="inline-flex items-center bg-yellow-100 text-yellow-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-yellow-900 dark:text-yellow-300">
                                                                        <span className="w-2 h-2 me-1 bg-yellow-500 rounded-full" style={{ animation: 'blink 1s infinite' }}></span>
                                                                        Pending
                                                                    </span>
                                                                ) : user.verificationstatuspancard === "Not-Verified" ? (
                                                                    <span className="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                                                                        <span className="w-2 h-2 me-1 bg-red-500 rounded-full" style={{ animation: 'blink 1s infinite' }}></span>
                                                                        Not-Verified
                                                                    </span>
                                                                ) : user.verificationstatuspancard === "Verified" ? (
                                                                    <span className="inline-flex items-center bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">
                                                                        <span className="w-2 h-2 me-1 bg-green-500 rounded-full" style={{ animation: 'blink 1s infinite' }}></span>
                                                                        Verified
                                                                    </span>
                                                                ) : null}
                                                            </span>
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap ">
                                                            <span className={`px-2 mr-2 inline-flex text-xs leading-5 font-semibold rounded-full `}>
                                                                {user.verificationstatusbank === "Not-Uploaded" ? (
                                                                    <span className="inline-flex items-center bg-stone-100 text-stone-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-stone-900 dark:text-stone-300">
                                                                        <span className="w-2 h-2 me-1 bg-stone-500 rounded-full" style={{ animation: 'blink 1s infinite' }}></span>
                                                                        Not-Uploaded
                                                                    </span>
                                                                ) : user.verificationstatusbank === "Pending" ? (
                                                                    <span className="inline-flex items-center bg-yellow-100 text-yellow-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-yellow-900 dark:text-yellow-300">
                                                                        <span className="w-2 h-2 me-1 bg-yellow-500 rounded-full" style={{ animation: 'blink 1s infinite' }}></span>
                                                                        Pending
                                                                    </span>
                                                                ) : user.verificationstatusbank === "Not-Verified" ? (
                                                                    <span className="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                                                                        <span className="w-2 h-2 me-1 bg-red-500 rounded-full" style={{ animation: 'blink 1s infinite' }}></span>
                                                                        Not-Verified
                                                                    </span>
                                                                ) : user.verificationstatusbank === "Verified" ? (
                                                                    <span className="inline-flex items-center bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">
                                                                        <span className="w-2 h-2 me-1 bg-green-500 rounded-full" style={{ animation: 'blink 1s infinite' }}></span>
                                                                        Verified
                                                                    </span>
                                                                ) : null}
                                                            </span>
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap ">
                                                            <span className={`px-2 mr-2 inline-flex text-xs leading-5 font-semibold rounded-full ${user.verificationstatus ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                                                                {user.verificationstatus ? 'Verified' : 'Not Verified'}
                                                            </span>
                                                        </td>
                                                    </>
                                                )}
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm text-gray-500">{user.createdAt.split('T')[0]}</div>
                                                </td>
                                                {(Admin || Telecaller || Verification) && (
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                                                        {(Admin || Telecaller) && (
                                                            <Link
                                                                to={`/view_user_details/${user._id}`}
                                                                onClick={() => handleViewClick(user._id)}
                                                                className="cursor-pointer text-green-600 hover:text-green-900"
                                                            >
                                                                View
                                                            </Link>
                                                        )}
                                                        {Verification && (
                                                            <Link
                                                                to={`/verification_details/${user._id}`}

                                                                className="cursor-pointer text-blue-600 hover:text-blue-900 ms-4"
                                                            >
                                                                Verify
                                                            </Link>
                                                        )}
                                                    </td>
                                                )}
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};


