import React, { useState } from 'react';
import Addfaq from './Addfaq';
import axios from 'axios';
import { BASE_URL } from '../../Constants/constant';
import Updatefaq from './Updatemainfaq';
import Cookies from 'js-cookie';
const Viewfaq = ({ fetchAdds, selectedFaq, onClose }) => {
    const [popupaddfaq, setPopupaddfaq] = useState(false)
    const [popupupdatefaq, setPopupupdatefaq] = useState(false)
    const [deletefaqId, setDeletefaqId] = useState(null)
    const [deletePopupVisible, setDeletePopupVisible] = useState(false)
    const handleClosePopup = () => {
        setPopupaddfaq(false);
        setPopupupdatefaq(false)
    };
    const token = Cookies.get('token');
    const handleDeleteConfirmation = (id) => {
        setDeletefaqId(id);
        setDeletePopupVisible(true);
    };

    const handleDeleteCategory = async () => {
        try {
            await axios.delete(`${BASE_URL}/api/v-1/faq/deletefaq/${selectedFaq._id}`, {
                data: { faqId: deletefaqId },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            fetchAdds();
        } catch (error) {
            console.error('Error deleting:', error);
        } finally {
            setDeletePopupVisible(false);
        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
            <div className="bg-white rounded-lg shadow-2xl p-8 max-w-6xl relative overflow-hidden flex flex-col">
                {/* Static header with close button */}
                <h2 className="text-2xl font-extrabold mb-6 text-gray-800 flex justify-center items-center">
                    <span>FAQ Details for: {selectedFaq?.name || "Category"}</span>
                    <button
                        className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
                        onClick={onClose}
                        aria-label="Close"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </h2>
                <div>
                    <button
                        type="button"
                        onClick={() => setPopupaddfaq(true)}
                        className=" text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                    >
                        Add FAQ
                    </button>
                </div>



                {/* Scrollable content section */}
                <div className="flex-1 table-container overflow-auto mb-16">
                    {selectedFaq ? (
                        <div className="overflow-x-auto">
                            {selectedFaq.faqs && selectedFaq.faqs.length > 0 ? (
                                <table className="table-auto w-full ">
                                    <thead>
                                        <tr className="bg-gray-100">
                                            <th className="px-4 py-2 border border-gray-300 text-left text-gray-700 font-semibold">SlNo.</th>
                                            <th className="px-4 py-2 border border-gray-300 text-left text-gray-700 font-semibold">Question & Answer </th>
                                            <th className="px-4 py-2 border border-gray-300 text-center text-gray-700 font-semibold">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedFaq.faqs.map((faq, index) => (
                                            <tr
                                                key={faq.id || index}
                                                className="hover:bg-gray-50 transition-colors"
                                            >
                                                <td className="px-4 py-3 border border-gray-200 text-gray-700 text-sm">
                                                    {index + 1}
                                                </td>
                                                <td className="px-4 py-3 border border-gray-200 text-gray-700 text-sm">
                                                    <div className="flex flex-col space-y-2">
                                                        <div className="flex items-start text-red-600">
                                                            <span className="font-semibold mr-2">Question&ensp;:</span>
                                                            <p className="text-gray-800">{faq.question}</p>
                                                        </div>
                                                        <div className="flex items-start text-green-600">
                                                            <span className="font-semibold mr-2">Answer&ensp;&ensp;:</span>
                                                            <p className="text-gray-800">{faq.answer}</p>
                                                        </div>
                                                        <div className="flex items-start text-blue-800">
                                                            <span className="font-semibold mr-2">URL&ensp;&ensp;&ensp;&ensp;&ensp;:</span>
                                                            {faq.url ? (
                                                                <a
                                                                    href={faq.url}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    className="hover:underline"
                                                                >
                                                                    {faq.url}
                                                                </a>
                                                            ) : (
                                                                "N/A"
                                                            )}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="px-4 py-3 border border-gray-200 text-center">
                                                    <div className="flex justify-center space-x-4">
                                                        <button onClick={()=>{setDeletefaqId(faq);setPopupupdatefaq(true)}} className="text-sm px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition">
                                                            Edit
                                                        </button>
                                                        <button onClick={() => handleDeleteConfirmation(faq._id)} className="text-sm px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition">
                                                            Delete
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <p className="text-gray-500 text-center">No FAQs available.</p>
                            )}
                        </div>
                    ) : (
                        <p className="text-center text-gray-500">No details available.</p>
                    )}
                </div>

                {/* Fixed Close button at the bottom */}
                <button
                    className="absolute bottom-4 left-0 right-0 mx-auto px-6 py-3 bg-red-500 text-white text-lg font-semibold rounded-lg hover:bg-red-600 transition w-full"
                    onClick={onClose}
                >
                    Close
                </button>
            </div>
            {popupaddfaq && (
                <Addfaq fetchAdds={() => fetchAdds()} selectedFaq={selectedFaq} onClose={handleClosePopup} />
            )}

            {popupupdatefaq && (
                <Updatefaq faq={deletefaqId} fetchAdds={() => fetchAdds()} selectedFaq={selectedFaq} onClose={handleClosePopup} />
            )}




            {deletePopupVisible && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50 z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <h2 className="text-lg font-semibold">Confirm</h2>
                        <p className="mt-2">
                            Are you sure you want to delete faq permanently?
                        </p>
                        <div className="mt-4 flex justify-end">
                            <button
                                onClick={handleDeleteCategory}
                                className="bg-blue-500 text-white px-4 py-2 rounded-lg mr-2"
                            >
                                Yes
                            </button>
                            <button
                                onClick={handleClosePopup}
                                className="bg-gray-500 text-white px-4 py-2 rounded-lg"
                            >
                                No
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Viewfaq;
