// import { useState, useEffect } from 'react';
// import axios from 'axios';
// import { load } from '@cashfreepayments/cashfree-js';
// import { BASE_URL } from '../../Constants/constant';

// const PaymentForm = () => {
//     const [cashfree, setCashfree] = useState(null);
//     const [orderId, setOrderId] = useState('');
//     const [customerId, setCustomerId] = useState('');
//     const [customerPhone, setCustomerPhone] = useState('');
//     const [customerName, setCustomerName] = useState('');
//     const [customerEmail, setCustomerEmail] = useState('');
//     const [orderAmount, setOrderAmount] = useState(1.00);
//     const [orderCurrency] = useState('INR');

//     useEffect(() => {
//         const initializeSDK = async () => {
//             const sdk = await load({ mode: 'sandbox' });
//             setCashfree(sdk);
//             console.log('Cashfree SDK initialized');
//         };

//         initializeSDK();
//     }, []);

//     const getSessionId = async () => {
//         try {
//             const res = await axios.post(`${BASE_URL}/api/v-1/payments/payment`, {
//                 customer_id: customerId,
//                 customer_phone: customerPhone,
//                 customer_name: customerName,
//                 customer_email: customerEmail,
//                 order_amount: orderAmount,
//                 order_currency: orderCurrency,
//             });

//             if (res.data && res.data.payment_session_id) {
//                 setOrderId(res.data.order_id);
//                 console.log('Order ID:', res.data.order_id); // Log the order ID
//                 return { sessionId: res.data.payment_session_id, orderId: res.data.order_id };
//             }
//         } catch (error) {
//             console.error('Error in getSessionId:', error);
//         }
//         return null;
//     };

//     const verifyPayment = async (orderId) => {
//         try {
//             console.log('Verifying payment for Order ID:', orderId); 
//             const res = await axios.post(`${BASE_URL}/api/v-1/payments/verify/${orderId}`);
//             console.log('Verification response:', res); 
//             if (res.data && res.data[0].payment_status === 'SUCCESS') {
//                 alert('Payment verified successfully');
//             } else {
//                 alert('Payment verification failed');
//             }
//         } catch (error) {
//             console.error('Error in verifyPayment:', error);
//         }
//     };

//     const handlePaymentClick = async (e) => {
//         e.preventDefault();
//         if (!customerId || !customerPhone || !customerName || !customerEmail) {
//             alert("Please fill in all fields.");
//             return;
//         }

//         try {
//             const sessionInfo = await getSessionId();

//             // Check if sessionInfo is retrieved successfully
//             if (!sessionInfo) {
//                 alert('Failed to retrieve payment session ID.');
//                 return;
//             }

//             const { sessionId, orderId } = sessionInfo;

//             const checkoutOptions = {
//                 paymentSessionId: sessionId,
//                 redirectTarget: '_modal',
//             };

//             if (cashfree) {
//                 cashfree.checkout(checkoutOptions)
//                     .then((response) => {
//                         console.log('Payment initialized', response);

//                         // Call verifyPayment with the orderId
//                         verifyPayment(orderId);
//                     })
//                     .catch((error) => {
//                         console.error('Payment failed', error);
//                     });
//             }
//         } catch (error) {
//             console.error('Error in handlePaymentClick:', error);
//         }
//     };

//     return (
//         <form onSubmit={handlePaymentClick}>
//             <input
//                 type="text"
//                 placeholder="Customer ID"
//                 value={customerId}
//                 onChange={(e) => setCustomerId(e.target.value)}
//                 required
//             />
//             <input
//                 type="text"
//                 placeholder="Customer Phone"
//                 value={customerPhone}
//                 onChange={(e) => setCustomerPhone(e.target.value)}
//                 required
//             />
//             <input
//                 type="text"
//                 placeholder="Customer Name"
//                 value={customerName}
//                 onChange={(e) => setCustomerName(e.target.value)}
//                 required
//             />
//             <input
//                 type="email"
//                 placeholder="Customer Email"
//                 value={customerEmail}
//                 onChange={(e) => setCustomerEmail(e.target.value)}
//                 required
//             />
//             <input
//                 type="number"
//                 placeholder="Order Amount"
//                 value={orderAmount}
//                 onChange={(e) => setOrderAmount(e.target.value)}
//                 required
//             />
//             <button type="submit">Pay</button>
//         </form>
//     );
// };

// export default PaymentForm;

import { useState, useEffect } from 'react';
import axios from 'axios';
import { load } from '@cashfreepayments/cashfree-js';
import { BASE_URL } from '../../Constants/constant';

const PaymentForm = () => {
    const [cashfree, setCashfree] = useState(null);
    const [orderId, setOrderId] = useState('');
    const [customerId, setCustomerId] = useState('');
    const [customerPhone, setCustomerPhone] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [customerEmail, setCustomerEmail] = useState('');
    const [orderAmount, setOrderAmount] = useState(1.00);
    const [orderCurrency] = useState('INR');
    const [paymentStatus, setPaymentStatus] = useState('');
    const token = localStorage.getItem('token');
    useEffect(() => {
        const initializeSDK = async () => {
            const sdk = await load({ mode: 'sandbox' });
            setCashfree(sdk);
            console.log('Cashfree SDK initialized');
        };

        initializeSDK();
    }, []);

    const getSessionId = async () => {
        try {
            const res = await axios.post(`${BASE_URL}/api/v-1/payments/payment`, {
                customer_id: customerId,
                customer_phone: customerPhone,
                customer_name: customerName,
                customer_email: customerEmail,
                order_amount: orderAmount,
                order_currency: orderCurrency,
            }, {

                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            );

            if (res.data && res.data.payment_session_id) {
                setOrderId(res.data.order_id);
                console.log('Order ID:', res.data.order_id);
                return { sessionId: res.data.payment_session_id, orderId: res.data.order_id };
            }
        } catch (error) {
            console.error('Error in getSessionId:', error);
        }
        return null;
    };

    const handlePaymentClick = async (e) => {
        e.preventDefault();
        if (!customerId || !customerPhone || !customerName || !customerEmail) {
            alert("Please fill in all fields.");
            return;
        }

        try {
            const sessionInfo = await getSessionId();

            if (!sessionInfo) {
                alert('Failed to retrieve payment session ID.');
                return;
            }

            const { sessionId } = sessionInfo;

            const checkoutOptions = {
                paymentSessionId: sessionId,
                redirectTarget: '_modal',
            };

            if (cashfree) {
                cashfree.checkout(checkoutOptions)
                    .then((response) => {
                        console.log('Payment initialized', response);
                    })
                    .catch((error) => {
                        console.error('Payment failed', error);
                    });
            }
        } catch (error) {
            console.error('Error in handlePaymentClick:', error);
        }
    };

    // Optionally, you can poll for payment status
    const pollPaymentStatus = async () => {
        if (orderId) {
            try {
                const res = await axios.post(`${BASE_URL}/api/v-1/payments/verify/${orderId}`,{
                    headers: {
                        'Authorization': `Bearer ${token}`,
                      },
                });
                setPaymentStatus(res.completeOrderDetails.payment[0].payment_status);
            } catch (error) {
                console.error('Error polling payment status:', error);
            }
        }
    };

    // Call this function at intervals to check the payment status
    useEffect(() => {
        const intervalId = setInterval(() => {
            pollPaymentStatus();
        }, 5000); // Check every 5 seconds

        return () => clearInterval(intervalId); // Cleanup interval on unmount
    }, [orderId]);

    return (
        <form onSubmit={handlePaymentClick}>
            <input
                type="text"
                placeholder="Customer ID"
                value={customerId}
                onChange={(e) => setCustomerId(e.target.value)}
                required
            />
            <input
                type="text"
                placeholder="Customer Phone"
                value={customerPhone}
                onChange={(e) => setCustomerPhone(e.target.value)}
                required
            />
            <input
                type="text"
                placeholder="Customer Name"
                value={customerName}
                onChange={(e) => setCustomerName(e.target.value)}
                required
            />
            <input
                type="email"
                placeholder="Customer Email"
                value={customerEmail}
                onChange={(e) => setCustomerEmail(e.target.value)}
                required
            />
            <input
                type="number"
                placeholder="Order Amount"
                value={orderAmount}
                onChange={(e) => setOrderAmount(e.target.value)}
                required
                min="1.00"
            />
            <button type="submit">Pay</button>
            {paymentStatus && <p>Payment Status: {paymentStatus}</p>}
        </form>
    );
};

export default PaymentForm;
