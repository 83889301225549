import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { MAIN_URL } from "../../Constants/constant.js";


const initialState = {
	isLoading:true,
    termsAndCondition:null,
    privacyPolicy:null,
	heroScreenCarousal:[],
    Partners:[],
    OurGames:[],
    AllJobs:[],
    AllJobsApplication:[],
    AllContactQuery:[],
    faqsWebsite:[],
    ErrorMessage:null,
}
const websiteReducer = createSlice({
	name:'website',
    initialState,
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(getHeroCarousal.pending,(state)=>{
            state.isLoading = true;
        }).addCase(getHeroCarousal.fulfilled,(state,action)=>{
            state.isLoading = false;
            state.heroScreenCarousal = action?.payload?.result || [];
        }).addCase(getHeroCarousal.rejected,(state,action)=>{
            state.isLoading = false;
            state.heroScreenCarousal = []
            state.ErrorMessage = action?.payload?.error?.message || "Failed to fetch Hero Carousal";
        }).addCase(fetchPartners.pending,(state)=>{
            state.isLoading = true;
        }).addCase(fetchPartners.fulfilled,(state,action)=>{
            state.isLoading = false;
            state.Partners = action?.payload?.result || [];
        }).addCase(fetchPartners.rejected,(state,action)=>{
            state.isLoading = false;
            state.Partners = []
            state.ErrorMessage = action?.payload?.error?.message || "Failed to fetch Partners";
        }).addCase(fetchOurGamesData.pending,(state)=>{
            state.isLoading = true;
        }).addCase(fetchOurGamesData.fulfilled,(state,action)=>{
            state.isLoading = false;
            state.OurGames = action?.payload?.result || [];
        }).addCase(fetchOurGamesData.rejected,(state,action)=>{
            state.isLoading = false;
            state.OurGames = []
            state.ErrorMessage = action?.payload?.error?.message || "Failed to fetch Our Games";
        }).addCase(fetchAllJobs.pending,(state)=>{
            state.isLoading = true;
        }).addCase(fetchAllJobs.fulfilled,(state,action)=>{
            state.isLoading = false;
            state.AllJobs = action?.payload?.result || [];
        }).addCase(fetchAllJobs.rejected,(state,action)=>{
            state.isLoading = false;
            state.AllJobs = []
            state.ErrorMessage = action?.payload?.error?.message || "Failed to fetch All Jobs";
        }).addCase(fetchAllJobsApplication.pending,(state)=>{
            state.isLoading = true;
        }).addCase(fetchAllJobsApplication.fulfilled,(state,action)=>{
            state.isLoading = false;
            state.AllJobsApplication = action?.payload?.result || [];
        }).addCase(fetchAllJobsApplication.rejected,(state,action)=>{
            state.isLoading = false;
            state.AllJobsApplication = []
            state.ErrorMessage = action?.payload?.error?.message || "Failed to fetch All Jobs";
        }).addCase(getAllContactQuery.pending,(state)=>{
            state.isLoading = true;
        }).addCase(getAllContactQuery.fulfilled,(state,action)=>{
            state.isLoading = false;
            state.AllContactQuery = action?.payload?.result || [];
        }).addCase(getAllContactQuery.rejected,(state,action)=>{
            state.isLoading = false;
            state.AllContactQuery = []
            state.ErrorMessage = action?.payload?.error?.message || "Failed to fetch All Jobs";
        }).addCase(getTermsAndConditions.pending,(state)=>{
            state.isLoading = true;
        }).addCase(getTermsAndConditions.fulfilled,(state,action)=>{
            state.isLoading = false;
            state.termsAndCondition = action?.payload?.result || null;
        }).addCase(getTermsAndConditions.rejected,(state,action)=>{
            state.isLoading = false;
            state.termsAndCondition = null;
            state.ErrorMessage = action?.payload?.error?.message || "Failed to fetch Terms and Conditions";
        }).addCase(getPrivacyAndPolicy.pending,(state)=>{
            state.isLoading = true;
        }).addCase(getPrivacyAndPolicy.fulfilled,(state,action)=>{
            state.isLoading = false;
            state.privacyPolicy = action?.payload?.result || null;
        }).addCase(getPrivacyAndPolicy.rejected,(state,action)=>{
            state.isLoading = false;
            state.privacyPolicy = null;
            state.ErrorMessage = action?.payload?.error?.message || "Failed to fetch Privacy and Policy";
        }).addCase(fetchAllFAQSWebsite.pending,(state)=>{
            state.isLoading = true;
        }).addCase(fetchAllFAQSWebsite.fulfilled,(state,action)=>{
            state.isLoading = false;
            state.faqsWebsite = action?.payload?.result || [];
        }).addCase(fetchAllFAQSWebsite.rejected,(state,action)=>{
            state.isLoading = false;
            state.faqsWebsite = []
            state.ErrorMessage = action?.payload?.error?.message || "Failed to fetch FAQs";
        })
    }
})


export const setCarousalImage = createAsyncThunk('/myPrideWebsite/setCarousalImage',async(data)=>{
    try {
        const response = await axios.post(`${MAIN_URL}/api/myPrideWebsite/heroCarousal/add`,data)
        // console.log("Set Carousal Image",response.data);
        return response.data;
    } catch (error) {
        console.error("Error setting carousal image: ",error);
        return error?.response?.data || { message: "Unknown error occurred" };
    }
})
export const deleteCarousalImage = createAsyncThunk('/myPrideWebsite/deleteCarousalImage',async(data)=>{
    try {
        const response = await axios.patch(`${MAIN_URL}/api/myPrideWebsite/heroCarousal/delete`,data);
        // console.log("Delete Carousal Image",response.data);
        return response.data;
    } catch (error) {
        console.error("Error deleting carousal image: ",error);
        return error?.response?.data || { message: "Unknown error occurred" };
    }
})

export const getHeroCarousal = createAsyncThunk('/myPrideWebsite/getHeroCarousal',async()=>{
    try {
        const response = await axios.get(`${MAIN_URL}/api/myPrideWebsite/heroCarousal/all`);
        console.log("Get All Games",response.data);
        return response.data;
    } catch (error) {
        console.error(`Error Admin fetching orders: `,error);
        return error?.response?.data || { message: "Unknown error occurred" };
    }
})
export const setPartner = createAsyncThunk('/myPrideWebsite/setPart',async(data)=>{
    try {
        const response = await axios.post(`${MAIN_URL}/api/myPrideWebsite/ourPartners/add`,data);
        // console.log("Set Partner",response.data);
        return response.data;
    } catch (error) {
        console.error("Error setting partner: ",error);
        return error?.response?.data || { message: "Unknown error occurred" };
    }
})
export const removePartnerId = createAsyncThunk('/myPrideWebsite/removePartnerId',async({id})=>{
    try {
        const response = await axios.delete(`${MAIN_URL}/api/myPrideWebsite/ourPartners/del/${id}`);
        // console.log("Set Partner",response.data);
        return response.data;
    } catch (error) {
        console.error("Error Delete partner: ",error);
        return error?.response?.data || { message: "Unknown error occurred" };
    }
})
export const updatePartners = createAsyncThunk('/myPrideWebsite/ourPartners/update',async({partnerId,data})=>{
    try {
        const response = await axios.put(`${MAIN_URL}/api/myPrideWebsite/ourPartners/update/${partnerId}`,data);
        console.log("Update Partner",response.data);
        return response.data;
    } catch (error) {
        console.error("Error updating partner: ",error);
        return error?.response?.data || { message: "Unknown error occurred" };
    }

})
export const fetchPartners = createAsyncThunk('/myPrideWebsite/fetchPartners',async()=>{
    try {
        const response = await axios.get(`${MAIN_URL}/api/myPrideWebsite/ourPartners/all`);
        // console.log("Get All Our Partners:",response.data);
        return response.data;
    } catch (error) {
        console.error(`Error Admin fetching orders: `,error);
        return error?.response?.data || { message: "Unknown error occurred" };
    }
})

export const setAboutData = createAsyncThunk('/myPrideWebsite/aboutData',async(data)=>{
    try {
        const response = await axios.post(`${MAIN_URL}/api/myPrideWebsite/about/add`,data);
        // console.log("Set About Data",response.data);
        return response.data;
    } catch (error) {
        console.error("Error setting about data: ",error);
        return error?.response?.data || { message: "Unknown error occurred" };
    }
})
export const fetchAboutData = createAsyncThunk('/myPrideWebsite/aboutData',async(data)=>{
    try {
        const response = await axios.get(`${MAIN_URL}/api/myPrideWebsite/about/all`);
        // console.log("Get All About Data:",response.data);
        return response.data;
    } catch (error) {
        console.error(`Error Admin fetching orders: `,error);
        return error?.response?.data || { message: "Unknown error occurred" };
    }
})

export const setOurGamesData = createAsyncThunk('/myPrideWebsite/ourGames/add',async(data)=>{
    try {
        const response = await axios.post(`${MAIN_URL}/api/myPrideWebsite/ourGames/add`,data);
        // console.log("Set Our Games Data",response.data);
        return response.data;
    } catch (error) {
        console.error("Error setting our games data: ",error);
        return error?.response?.data || { message: "Unknown error occurred" };
    }
})
export const UpdateOurGamesById = createAsyncThunk('/api/myPrideWebsite/ourGames/update',async({gameId,data})=>{
    try {
        const response = await axios.put(`${MAIN_URL}/api/myPrideWebsite/ourGames/update/${gameId}`,data);
        // console.log("Update Our Games By ID",response.data);
        return response.data;
    } catch (error) {
        console.error("Error updating our games by ID: ",error);
        return error?.response?.data || { message: "Unknown error occurred" };
    }
})
export const deleteOurGame = createAsyncThunk('/myPrideWebsite/ourGames/delete',async({id})=>{
    try {
        const response = await axios.delete(`${MAIN_URL}/api/myPrideWebsite/ourGames/delete/${id}`);
        // console.log("Delete Our Games By ID",response.data);
        return response.data;
    } catch (error) {
        console.error("Error deleting our games by ID: ",error);
        return error?.response?.data || { message: "Unknown error occurred" };
    }
})
export const fetchOurGamesData = createAsyncThunk('/myPrideWebsite/ourGames/all',async()=>{
    try {
        const response = await axios.get(`${MAIN_URL}/api/myPrideWebsite/ourGames/all`);
        // console.log("Get All Our Games:",response.data);
        return response.data;
    } catch (error) {
        console.error(`Error Admin fetching orders: `,error);
        return error?.response?.data || { message: "Unknown error occurred" };
    }
})

export const fetchOurGamesById = createAsyncThunk('/myPrideWebsite/ourGamesById',async({id})=>{
    try {
        const response = await axios.get(`${MAIN_URL}/api/myPrideWebsite/ourGames/id/${id}`);
        // console.log("Get Our Games By ID:",response.data);
        return response.data;
    } catch (error) {
        console.error(`Error Admin fetching orders: `,error);
        return error?.response?.data || { message: "Unknown error occurred" };
    }
})
export const createJob = createAsyncThunk('/myPrideWebsite/jobs/posting',async(jobData)=>{
    try {
        const response = await axios.post(`${MAIN_URL}/api/myPrideWebsite/jobs/posting`,jobData);
        // console.log("Create Job",response.data);
        return response.data;
    } catch (error) {
        console.error("Error creating job: ",error);
        return error?.response?.data || { message: "Unknown error occurred" };
    }
})
export const fetchAllJobs = createAsyncThunk('/api/myPrideWebsite/jobs/all',async()=>{
    try {
        const response = await axios.get(`${MAIN_URL}/api/myPrideWebsite/jobs/all`);
        // console.log("Get All Jobs:",response.data);
        return response.data;
    } catch (error) {
        console.error(`Error Admin fetching orders: `,error);
        return error?.response?.data || { message: "Unknown error occurred" };
    }
})
export const updateCurrentJob = createAsyncThunk('/api/myPrideWebsite/jobs/update',async({jobId,data})=>{
    try {
        const response = await axios.put(`${MAIN_URL}/api/myPrideWebsite/jobs/update/${jobId}`,data);
        // console.log("Update Current Job",response.data);
        return response.data;
    } catch (error) {
        console.error("Error updating current job: ",error);
        return error?.response?.data || { message: "Unknown error occurred" };
    }
})
export const removeJob = createAsyncThunk('/api/myPrideWebsite/jobs/remove',async({jobId})=>{
    try {
        const response = await axios.delete(`${MAIN_URL}/api/myPrideWebsite/jobs/remove/${jobId}`);
        // console.log("Remove Job",response.data);
        return response.data;
    } catch (error){
        console.error("Error removing job: ",error);
        return error?.response?.data || { message: "Unknown error occurred" };
    }
});

export const fetchAllJobsApplication = createAsyncThunk('/myPrideWebsite/jobs/applications',async({s,e,period})=>{
    try {
        const response = await axios.get(`${MAIN_URL}/api/myPrideWebsite/jobs/applications/all?startDate=${s}&endDate=${e}&period=${period}`);
        // console.log("Get All Jobs Application:",response.data);
        return response.data;
    } catch (error) {
        console.error(`Error Admin fetching orders: `,error);
        return error?.response?.data || { message: "Unknown error occurred" };
    }
})
export const deleteJobApplicationById = createAsyncThunk('/myPrideWebsite/jobs/applications/delete',async({appId})=>{
    try {
        const response = await axios.patch(`${MAIN_URL}/api/myPrideWebsite/jobs/applications/delete/${appId}`);
        // console.log("Delete Job Application By Id",response.data);
        return response.data;
    } catch (error) {
        console.error("Error deleting job application by ID: ",error);
        return error?.response?.data || { message: "Unknown error occurred" };
    }
})

export const getAllContactQuery = createAsyncThunk('/contactquery/getAll',async()=>{
    try {
        const response = await axios.get(`${MAIN_URL}/api/myPrideWebsite/contactquery/all`);
        console.log("Get All Games",response.data);
        return response.data;
    } catch (error) {
        console.error(`Error Admin fetching orders: `,error);
        return error.response.data;
    }
})

export const getContactQueryById = createAsyncThunk('/contactquery/byId',async({queryId})=>{
    try {
        const response = await axios.get(`${MAIN_URL}/api/myPrideWebsite/contactquery/${queryId}`);
        console.log("Get Game by Id",response.data);
        return response.data;
    } catch (error) {
        console.error(`Error Admin fetching orders: `,error);
        return error.response.data;
    }
})
export const removeContactQuery = createAsyncThunk('/contactquery/remove',async({queryId})=>{
    try {
        const response = await axios.delete(`${MAIN_URL}/api/myPrideWebsite/admin/contact/remove/${queryId}`);
        console.log("Remove Contact Query",response.data);
        return response.data;
    }
    catch (error) {
        console.error(`Error Admin fetching orders: `,error);
        return error.response.data;
    }
});

export const sendAdminMail = createAsyncThunk('/sendAdminMail',async(mailData)=>{
    try {
        const response = await axios.post(`${MAIN_URL}/api/myPrideWebsite/admin/contact/sendMail`,mailData);
        console.log("Send Admin Mail",response.data);
        return response.data;
    } catch (error) {
        console.error(`Error Admin fetching orders: `,error);
        return error.response.data;
    }
})

export const updateTermsAndConditions = createAsyncThunk('/updateTermsAndConditions',async(termsAndConditions)=>{
    try {
        const response = await axios.post(`${MAIN_URL}/api/myPrideWebsite/admin/termsAndConditions`,termsAndConditions);
        console.log("Update Terms And Conditions",response.data);
        return response.data;
    } catch (error) {
        console.error(`Error Admin fetching orders: `,error);
        return error.response.data;
    }
})
export const getTermsAndConditions = createAsyncThunk('/getTermsAndConditions',async()=>{
    try {
        const response = await axios.get(`${MAIN_URL}/api/myPrideWebsite/admin/termsAndConditions`);
        console.log("Get Terms And Conditions",response.data);
        return response.data;
    } catch (error) {
        console.error(`Error Admin fetching orders: `,error);
        return error.response.data;
    }
})

export const updatePrivacyPolicy = createAsyncThunk('/updatePrivacyPolicy',async(privacyPolicy)=>{
    try {
        const response = await axios.post(`${MAIN_URL}/api/myPrideWebsite/admin/privacyPolicy`,privacyPolicy);
        console.log("Update Privacy Policy",response.data);
        return response.data;
    } catch (error) {
        console.error(`Error Admin fetching orders: `,error);
        return error.response.data;
    }
})
export const getPrivacyAndPolicy = createAsyncThunk('/getPrivacyAndPolicy',async()=>{
    try {
        const response = await axios.get(`${MAIN_URL}/api/myPrideWebsite/admin/privacyPolicy`);
        console.log("Get Privacy Policy",response.data);
        return response.data;
    } catch (error) {
        console.error(`Error Admin fetching orders: `,error);
        return error.response.data;
    }
})


export const setFAQSWebsite = createAsyncThunk ('admin/faq/set',async(faqData)=>{
    try {
        const response = await axios.post(`${MAIN_URL}/api/myPrideWebsite/admin/faq/set`,faqData);
        console.log("Set FAQS",response.data);
        return response.data;
    } catch (error) {
        console.error(`Error Admin fetching orders: `,error);
        return error.response.data;
    }
})
export const fetchAllFAQSWebsite = createAsyncThunk ('admin/faq/get/all',async()=>{
    try {
        const response = await axios.get(`${MAIN_URL}/api/myPrideWebsite/admin/faq/get/all`);
        console.log("Get FAQS",response.data);
        return response.data;
    } catch(error){
        console.error(`Error Admin fetching orders: `,error);
        return error.response.data;
    }
})

export const updateFAQSWebsite = createAsyncThunk ('admin/faq/update',async(faqData)=>{
    try {
        const response = await axios.post(`${MAIN_URL}/api/myPrideWebsite/admin/faq/update`,faqData);
        console.log("Update FAQS",response.data);
        return response.data;
    } catch (error) {
        console.error(`Error Admin fetching orders: `,error);
        return error.response.data;
    }
})
export const removeFAQSWebsite = createAsyncThunk ('admin/faq/remove',async({faqId})=>{
    try {
        const response = await axios.patch(`${MAIN_URL}/api/myPrideWebsite/admin/faq/remove/${faqId}`);
        console.log("Remove FAQS",response.data);
        return response.data;
    } catch (error) {
        console.error(`Error Admin fetching orders: `,error);
        return error.response.data;
    }
})

export default websiteReducer.reducer;