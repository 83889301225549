import React, { useState } from 'react'
import Sidebar from '../Frame/Sidebar'
import HomeDataUpload from './LudoWebsiteComponents/HomeDataUpload';
import BenefitsDataUpload from './LudoWebsiteComponents/BenefitsDataUpload';
const tabs = [
    {id:'home-data',name:'Home Data'},
    {id:'benefits',name:'Benefits'},,
];

const LudoWebsiteTabView = () => {
    const [activeTab, setActiveTab] = useState('home-data');
  
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    return (
        <Sidebar>
            <div className='w-[80%] max-h-screen justify-self-end py-5 flex flex-col overflow-y-auto  justify-start items-center overflow-x-hidden scrollbar scrollbar-track-gray-800 scrollbar-thumb-gray-300'>
                <div className='w-full justify-start items-center flex flex-col pb-11'>
                    <div className="w-full justify-start space-x-2 items-center flex pb-11 px-3 border-b border-gray-300">
                        {
                            tabs.map((tab,index)=>(
                                <button
                                    key={index}
                                    onClick={() => handleTabClick(tab.id)}
                                    className={`tab-button bg-blue-700 hover:scale-105 duration-300 transition-transform ease-ease-out-expo rounded-md px-3 py-2 text-white font-bold ${activeTab === tab.id ? 'bg-blue-300' : ''}`}
                                >
                                    {tab.name} 
                                </button>
                            ))
                        }
                        
                    </div>
                    <div className="tab-content w-full flex justify-center items-center">
                        {activeTab === 'home-data' && <HomeDataUpload/>}
                        {activeTab === 'benefits' && <BenefitsDataUpload/>}
                    </div>
                </div>
            </div>
        </Sidebar>
    )
}

export default LudoWebsiteTabView