import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import dataReducer from './slices/userSlice.js';
import logger from 'redux-logger';
import rootSaga from './sagas/RootSaga.js';
import employeeSlice from './slices/employeeSlice.js';
import SearchSlice from './slices/SearchSlice.js';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    data: dataReducer,
    employee: employeeSlice,
    search:SearchSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false, 
    }).concat(
      sagaMiddleware,
      // process.env.NODE_ENV !== 'production' ? logger : () => {} 
    ),
});

sagaMiddleware.run(rootSaga);

export default store;
