import { createSelector } from 'reselect';

const selectEmployeeState = (state) => state.employee;

// Memoized selector to get employees
export const selectEmployees = createSelector(
  [selectEmployeeState],
  (employeeState) => employeeState.employees.employees || []
);

// Memoized selector to get total employee count
export const selectTotalEmployeeCount = createSelector(
  [selectEmployeeState],
  (employeeState) => employeeState.employees.totalEmployeeCount || 0
);

// Memoized selector to get current page
export const selectCurrentPage = createSelector(
  [selectEmployeeState],
  (employeeState) => employeeState.employees.currentPage || 0
);

// Memoized selector to get total pages
export const selectTotalPages = createSelector(
  [selectEmployeeState],
  (employeeState) => employeeState.employees.totalPages || 0
);

// Memoized selector to get selected employee ID
export const selectSelectedEmployeeId = createSelector(
  [selectEmployeeState],
  (employeeState) => employeeState.selectedEmployeeId
);

export const selectPageSize = createSelector(
  [selectEmployeeState],
  (employeeState) => employeeState.employees.pageSize || 0
);

export const selectpagecount = createSelector(
  [selectEmployeeState],
  (employeeState) => employeeState.employees.pagecount
);

export const selectTotalUserCount = createSelector(
  [selectEmployeeState],
  (employeeState) => employeeState.employees.totalEmployeesCount || 0
);
