
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  fetchRequest, fetchSuccess, fetchFailure,
  updateRequest, updateSuccess, updateFailure,
} from '../slices/userSlice.js';
import { getApi, postApi, putApi } from '../Apirequest/userApiRequest.js';
import Cookies from 'js-cookie';
function* fetchDataSaga(action) {
  try {
    const token = Cookies.get('token');
    const headers = {
      'Authorization': `Bearer ${token}`
    };

    const response = yield call(getApi, `api/v-1/user/getallusers?page=${action.payload || 0}}`, { headers });

    if (response.status === 200) {
      yield put(fetchSuccess(response.data));
    } else {
      yield put(fetchFailure(response.data || 'An error occurred'));
    }
  } catch (error) {
    yield put(fetchFailure(error.message || 'An error occurred'));
  }
}

function* updateDataSaga(action) {
  try {
    const token = Cookies.get('token');
    const headers = {
      'Authorization': `Bearer ${token}`
    };

    // Perform the API call for the update
    const response = yield call(putApi, `api/v-1/user/updateuseremployee/${action.payload.id}`, action.payload, { headers });

    if (response.status === 200) {
      yield put(fetchRequest(action.payload.pageSize));
      yield put(updateSuccess(response.data));
    } else {
      throw new Error(response.data || 'An error occurred');
    }
  } catch (error) {
    // If the update fails, dispatch updateFailure to revert optimistic changes
    yield put(updateFailure(error.message || 'An error occurred'));
  }
}

function* watchUserSaga() {
  yield takeLatest(fetchRequest.type, fetchDataSaga);
  yield takeLatest(updateRequest.type, updateDataSaga);
}

export default watchUserSaga;

