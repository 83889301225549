import React from 'react';
import Sidebar from '../Frame/Sidebar';
import { Footer } from '../../Constants/Footer';

export const JoinedLegue = () => {
    return (
        <Sidebar>
            <div class=" xl:ml-80 text-center px-4 py-5 sm:px-6">
                    <h3 class=" text-2xl leading-6 font-medium text-gray-900">
                        User Joined Game Details
                    </h3>
                </div>
            <div className="xl:ml-80 flex flex-col table-container overflow-x-auto">
                
                <table className="table table-bordered">
                    <thead className="bg-gray-100 sticky top-0">
                        <tr>
                            <th>Serial No</th>
                            <th> RommId</th>
                            <th>Amount</th>
                            <th>Time </th>

                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                        {/* {adds.map((add, index) => ( */}
                        {/* key={index} className={index % 2 === 0 ? "bg-white" : "bg-gray-100"} */}
                        <tr >
                            <td>1</td>
                            <td>RoomId</td>

                            <td>
                                Amount
                            </td>
                            <td>
                                Time
                            </td>
                        </tr>
                        <tr >
                            <td>Serial No</td>
                            <td>50</td>

                            <td>
                                Deposit
                            </td>
                            <td>
                                Time
                            </td>

                        </tr>
                        {/* ))} */}
                    </tbody>
                </table>
            </div>
            <Footer/>
        </Sidebar>
    );
}


