import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";
import { BASE_URL } from "../../Constants/constant";
import Cookies from "js-cookie";

const WithdrawallimitTable = () => {
  const [bonus, setBonus] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    FirstWithdrawal: 0,
    DepositeWithdrawal: 0,
  });

  useEffect(() => {
    fetchWithdrawalData();
  }, []);

  const token = Cookies.get("token");

  const fetchWithdrawalData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/v-1/withdrawallimit/getlimit`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data) {
        setBonus(response.data);
        setFormData(response.data);
      } else {
        toast.error("Invalid withdrawal limit data received.");
      }
    } catch (error) {
      toast.error("Failed to load withdrawal limit data.");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdate = async () => {
    if (!token) return toast.error("User authentication required.");

    try {
      const response = await axios.post(
        `${BASE_URL}/api/v-1/withdrawallimit/createlimit`,
        formData,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.data) {
        toast.success("Withdrawal limits updated successfully.");
        fetchWithdrawalData(); // Refresh data
        setIsEditing(false);
      }
    } catch (error) {
      toast.error("Error updating withdrawal limits.");
    }
  };

  if (!bonus) return <p>Loading...</p>;

  return (
    <div className="table-container overflow-x-auto p-4">
      <p className="text-center pt-2 pb-2 text-2xl font-bold">Bonus Percentage Data</p>
      
      <div className="mb-4">
        {isEditing ? (
          <div>
            <button
              onClick={handleUpdate}
              className="bg-green-500 text-white px-4 py-2 rounded-lg mr-5"
            >
              Save Changes
            </button>
            <button
              onClick={() => setIsEditing(false)}
              className="bg-red-500 text-white px-4 py-2 rounded-lg"
            >
              Cancel
            </button>
          </div>
        ) : (
          <button
            onClick={() => setIsEditing(true)}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg"
          >
            Update Details
          </button>
        )}
      </div>

      <table className="table table-bordered w-full border border-gray-300">
        <thead className="bg-gray-100 sticky top-0 border border-gray-300 rounded-lg font-bold">
          <tr>
            <th>Sl.No</th>
            <th>Withdraw Limit Name</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 text-lg">
          {[
            { name: "First Withdrawal", key: "FirstWithdrawal" },
            { name: "Deposite Withdrawal", key: "DepositeWithdrawal" },
          ].map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{item.name}</td>
              <td>
                {isEditing ? (
                  <input
                    type="number"
                    name={item.key}
                    value={formData[item.key]}
                    onChange={handleInputChange}
                    className="border p-1 w-20"
                  />
                ) : (
                  `₹ ${bonus[item.key] || 0}.00`
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default WithdrawallimitTable;
