import React, { useState } from 'react'
import Sidebar from '../Frame/Sidebar'
import HomeCarousal from './WebComponents/HomeCarousal';
import PartnersDataUpload from './WebComponents/PartnersDataUpload';
import GameForm from './WebComponents/GameForm';
import JobPostingForm from './WebComponents/JobPostingForm';
import JobApplications from './WebComponents/JobApplications';
import ContactQuery from './WebComponents/ContactQuery';
import TermsAndCondition from './WebComponents/TermsAndCondition';
import PrivacyAndPolicy from './WebComponents/PrivacyAndPolicy';
import FaqManagementComponents from './WebComponents/FaqManagementComponents';

const MainWebsiteTabView = () => {
    const [activeTab, setActiveTab] = useState('home-carousel');
  
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    return (
        <Sidebar>
            <div className='w-[80%] max-h-screen justify-self-end py-5 flex flex-col overflow-y-auto  justify-start items-center overflow-x-hidden scrollbar scrollbar-track-gray-800 scrollbar-thumb-gray-300'>
                <div className='w-full justify-start items-center flex flex-col pb-11'>
                    <div className="w-full justify-start space-x-2 items-center flex pb-10 px-2 border-b border-gray-300 max-w-[90vw] overflow-x-auto">
                        <button 
                            onClick={() => handleTabClick('home-carousel')}
                            className={`tab-button hover:scale-105 w-auto whitespace-nowrap duration-300 border-red-600 transition-transform ease-ease-out-expo rounded-md px-3 py-2 text-white font-bold ${activeTab === 'home-carousel' ? 'border bg-red-400' : 'bg-red-600'}`}
                        >
                            Home 
                        </button>
                        <button 
                            onClick={() => handleTabClick('partners-data')}
                            className={`tab-button w-auto whitespace-nowrap hover:scale-105 duration-300 transition-all ease-ease-out-expo rounded-md px-3 py-2 text-white font-bold ${activeTab === 'partners-data' ? 'border bg-red-400' : 'bg-red-600'}`}
                        >
                            Partners Data
                        </button>
                        <button 
                            onClick={() => handleTabClick('our-games')}
                            className={`tab-button w-auto whitespace-nowrap hover:scale-105 duration-300 transition-all ease-ease-out-expo rounded-md px-3 py-2 text-white font-bold ${activeTab === 'our-games' ? 'border bg-red-400' : 'bg-red-600'}`}
                        >
                            Our Games
                        </button>
                        <button 
                            onClick={() => handleTabClick('job-posting')}
                            className={`tab-button  hover:scale-105 duration-300 transition-all ease-ease-out-expo rounded-md px-3 py-2 text-white font-bold ${activeTab === 'job-posting' ? 'border bg-red-400' : 'bg-red-600'}`}
                        >
                            Job Posting
                        </button>
                        <button 
                            onClick={() => handleTabClick('job-application')}
                            className={`tab-button w-auto whitespace-nowrap hover:scale-105 duration-300 transition-all ease-ease-out-expo rounded-md px-3 py-2 text-white font-bold ${activeTab === 'job-application' ? 'border bg-red-400' : 'bg-red-600'}`}
                        >
                            Job Application
                        </button>
                        <button 
                            onClick={() => handleTabClick('contact-query')}
                            className={`tab-button w-auto whitespace-nowrap rounded-md px-3 py-2 text-white font-bold ${activeTab === 'contact-query' ? 'border bg-red-400' : 'bg-red-600'}`}
                        >
                            Contact Query
                        </button>
                        <button 
                            onClick={() => handleTabClick('faq')}
                            className={`tab-button w-auto whitespace-nowrap rounded-md px-3 py-2 text-white font-bold ${activeTab === 'faq' ? 'border bg-red-400' : 'bg-red-600'}`}
                        >
                            Faqs
                        </button>
                        <button 
                            onClick={() => handleTabClick('terms-and-conditions')}
                            className={`tab-button w-auto whitespace-nowrap rounded-md px-3 py-2 text-white font-bold ${activeTab === 'terms-and-conditions' ? 'border bg-red-400' : 'bg-red-600'}`}
                        >
                            Terms & Conditions
                        </button>
                        <button 
                            onClick={() => handleTabClick('privacy-and-policy')}
                            className={`tab-button w-auto whitespace-nowrap rounded-md px-3 py-2 text-white font-bold ${activeTab === 'privacy-and-policy' ? 'border bg-red-400' : 'bg-red-600'}`}
                        >
                            Privacy & Policy
                        </button>
                    </div>
                    <div className="tab-content w-full flex justify-center items-center">
                        {activeTab === 'home-carousel' && <HomeCarousal />}
                        {activeTab === 'partners-data' && <PartnersDataUpload/>}
                        {activeTab === 'our-games' && <GameForm />}
                        {activeTab === 'job-posting' && <JobPostingForm />}
                        {activeTab === 'job-application' && <JobApplications />}
                        {activeTab === 'contact-query' && <ContactQuery />}
                        {activeTab === 'terms-and-conditions' && <TermsAndCondition />}
                        {activeTab === 'privacy-and-policy' && <PrivacyAndPolicy />}
                        {activeTab === 'faq' && <FaqManagementComponents />}
                    </div>
            
                </div>
            </div>
        </Sidebar>
    )
}

export default MainWebsiteTabView