import React, { useState, useEffect } from 'react';
import { BASE_URL } from '../../Constants/constant';
import toast from 'react-hot-toast';

const AddappDetails = ({ add, onClose, contact }) => {
  const [formData, setFormData] = useState({
    app_name: contact.app_name || '',
    version: contact.version || '',
  });

  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const token = localStorage.getItem('token');
  // Handler to update form data
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await fetch(`${BASE_URL}/api/v-1/app_details/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', 
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(formData)
      });

      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }

      const result = await res.json();
      setResponse(result);
      setError(null);  
      add();
      toast.success('Added successfully...');
      onClose();
    } catch (err) {
      setError(err.message);
      setResponse(null);  
    }
  };

  return (
    <div style={{ width: '50vw' }}>
      {response && (
        <div className="mt-6 p-4 bg-green-50 border border-green-200 text-green-800 rounded-lg shadow-md">
          <h3 className="text-lg text-center font-semibold">Uploaded Successfully!</h3>
        </div>
      )}
      {error && (
        <div className="mt-6 p-4 bg-red-50 border border-red-200 text-red-800 rounded-lg shadow-md">
          <h3 className="text-lg font-semibold">Error in Uploading</h3>
        </div>
      )}
      <h2 className="text-2xl font-semibold text-gray-800 mb-6 text-center">Add Fixed Game Data</h2>
      <form onSubmit={handleSubmit} className="space-y-6">
        {[
          { label: 'App_Name', type: 'text', name: 'app_name' },
          { label: 'Version', type: 'text', name: 'version' },
        ].map(({ label, type, name }) => (
          <div key={name} className="flex flex-col-2">
            <label className="block text-sm font-medium text-gray-700 py-2.5 mr-5">{label}:</label>
            <input
              type={type}
              name={name}
              value={formData[name]} 
              onChange={handleChange}
              className="block py-2.5 px-0 text-center w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            />
          </div>
        ))}
        <div className='flex space-between'>
          <button
            type="submit"
            className="w-full bg-indigo-600 text-white py-3 px-1 rounded-lg shadow-md hover:bg-indigo-700 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Submit
          </button>
          <button
            onClick={onClose}
            className="w-full bg-red-600 text-white py-3 px-1 ml-5 rounded-lg shadow-md hover:bg-red-700 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Close
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddappDetails;
