import { useEffect, useState } from 'react';
import Sidebar from '../Frame/Sidebar';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRequest, setSelectedUserId } from '../../Redux/slices/userSlice.js';
import { useUser } from '../../Constants/context.js';
import { selectCurrentPage, selectNonverifiedusers, selectpagecount, selectPageSize, selectTotalPages, selectTotalUserCount, selectUsers, selectVerifiedusers } from '../../Redux/Selector/userSelector.js';
import { exportToExcel, exportToPDF, PDFIcon, ExcelIcon } from '../Utils/Exportutils.jsx';
import DynamicFilterForm from '../Report/Filter.jsx';
import { BASE_URL } from '../../Constants/constant.js';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { selectSearchLoading, selectSearchResults } from '../../Redux/slices/SearchSlice.js';
import { SearchResult } from '../Search/SearchResult.jsx';
import Cookies from 'js-cookie';

export const UserMain = () => {
    const dispatch = useDispatch();

    const users = useSelector(selectUsers);
    const status = useSelector(state => state.data.status);
    const error = useSelector(state => state.data.error);
    const [selectedUserId, setSelectedUserIdLocal] = useState(null);
    const [activeFilter, setActiveFilter] = useState('all');
    const [filteredUsers, setFilteredUsers] = useState([]);
    const { Admin, Sales, Telecaller, Verification, Market } = useUser();
    const pagecount = useSelector(selectpagecount);
    const pageSize = useSelector(selectPageSize);
    const totalPages = useSelector(selectTotalPages);
    const currentPage = useSelector(selectCurrentPage);
    const totalCount = useSelector(selectTotalUserCount);
    const nonverifieduser = useSelector(selectNonverifiedusers);
    const verifieduser = useSelector(selectVerifiedusers)
    const token = Cookies.get("token");
    console.log(token,"tokencookies");
    useEffect(() => {
        dispatch(fetchRequest(1));
    }, [dispatch]);

    useEffect(() => {
        if(Array.isArray(users)){
            setFilteredUsers(users);
        }
     
    }, [users]);

    useEffect(() => {
        const filterUsers = () => {
            switch (activeFilter) {
                case 'verified':
                    return verifieduser;
                case 'notVerified':
                    return nonverifieduser;
                case 'all':
                default:
                    return users;
            }
        };
        setFilteredUsers(filterUsers());

    }, [activeFilter, users]);

    const handlePageClick = ({ selected }) => {
        const pageSize = selected + 1;
        dispatch(fetchRequest(pageSize));
    };


    const handleViewClick = (userId) => {
        setSelectedUserIdLocal(userId);
        dispatch(setSelectedUserId(userId));
    };

    const handleFiltersApplied = async (filters) => {
        try {
            const response = await axios.post(`${BASE_URL}/api/v-1/user/filter`, filters,{
                headers:{
                    'Authorization': `Bearer ${token}`,
                }
            });
            setFilteredUsers(response.data);
        } catch (error) {
            console.error('Error fetching filtered users:', error);
        }
    };
    const calculateSerialNumber = (index) => {
        return (currentPage - 1) * pageSize + index + 1;
    };
    const results = useSelector(selectSearchResults);
    const loading = useSelector(selectSearchLoading);

    return (
        <Sidebar>
            <div className={`p-4 xl:ml-80 flex flex-col`}>
                {Verification && (
                    <div className='flex'>
                        <div className='me-3'>
                            <DynamicFilterForm onFiltersApplied={handleFiltersApplied} />
                        </div>
                        <div>
                            <button
                                type="button"
                                className={`text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 ${activeFilter === 'all' ? 'bg-blue-500' : ''}`}
                                onClick={() => setActiveFilter('all')}
                            >
                                All Users
                            </button>
                            <button
                                type="button"
                                className={`text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 ${activeFilter === 'verified' ? 'bg-blue-500' : ''}`}
                                onClick={() => setActiveFilter('verified')}
                            >
                                Verified Users
                            </button>
                            <button
                                type="button"
                                className={`relative text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 ${activeFilter === 'notVerified' ? 'bg-blue-500' : ''}`}
                                onClick={() => setActiveFilter('notVerified')}
                            >
                                Not Verified Users
                                {pagecount > 0 && (
                                    <span
                                        className="absolute top-0 right-0 -translate-x-1/2 translate-y-1/2 bg-red-500 text-white text-xs font-bold px-2 py-1 rounded-full animate-blink"
                                        style={{ transform: 'translate(50%, -50%)' }}
                                    >
                                        {pagecount}
                                    </span>
                                )}
                            </button>
                        </div>

                    </div>
                )}

                <div className="mt-4 flex justify-end space-x-4">
                    <span
                        onClick={() => exportToPDF('userTable', 'userTableDetails.pdf')}
                        className="cursor-pointer"
                        title="Export to PDF"
                    >
                        <PDFIcon width={50} height={50} />
                    </span>
                    <span
                        onClick={() => exportToExcel('userTable', 'userTable.xlsx')}
                        className="cursor-pointer"
                        title="Export to Excel"
                    >
                        <ExcelIcon width={50} height={50} />
                    </span>
                </div>

                {results.length > 0 ? (
                    results.map((result, index) => (
                        <SearchResult key={result.id} data={result} />
                    ))
                ) : (


                    <div className="-my-2  sm:-mx-6 lg:-mx-8">
                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                <div className="table-container-button table-container overflow-x-auto">
                                    <table id="userTable" className="table table-bordered w-full">
                                        <thead className="bg-gray-100  sticky top-0 ">
                                            <tr>
                                                <th className="px-6 py-3  text-left">Sl.No</th>
                                                <th className="px-6 py-3 text-left">Profile</th>
                                                <th className="px-6 py-3 text-left">Name</th>
                                                <th className="px-6 py-3 text-left">Phone Number</th>
                                                {Verification && (
                                                    <>
                                                        <th className="px-6 py-3 text-left">Aadhar</th>
                                                        <th className="px-6 py-3 text-left">Pan</th>
                                                        <th className="px-6 py-3 text-left">Bankdetails</th>
                                                        <th className="px-6 py-3 text-left">Final</th>
                                                    </>
                                                )}
                                                <th className="px-6 py-3 text-left">Registered Date</th>
                                                {(Admin || Telecaller) && (
                                                    <th className="px-6 py-3 text-left">Action</th>
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody className=" divide-y divide-gray-200">
                                            {status === 'loading' ? (
                                                <tr>
                                                    <td colSpan="12" className="w-screen text-center">Loading...</td>
                                                </tr>
                                            ) :  filteredUsers.length  === 0 ? (
                                            
                                                    <td colSpan="12" className=" w-screen px-6 text-red-500">Your User Portal is Empty</td>
                                               
                                            ) : (
                                             
                                                Array.isArray(filteredUsers) && filteredUsers.map((user, index) => (
                                                        <tr key={user._id} className={index % 2 === 0 ? "bg-white" : "bg-gray-100"}>
                                                            <td className="px-6 py-4">{calculateSerialNumber(index)}</td>
                                                            <td className="px-6 py-4 whitespace-nowrap">
                                                                <div className="flex items-center">
                                                                    <div className="flex-shrink-0 h-10 w-10">
                                                                        <img className="h-10 w-10 rounded-full" src={user.image || '/default-image.png'} alt="" />
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="text-sm font-medium text-gray-900">{user.name || 'No Name'}</div>
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap">
                                                                <div className="text-sm text-gray-500">{user.phoneNumber || 'No phoneNumber'}</div>
                                                            </td>
                                                            {Verification && (
                                                                <>
                                                                    <td className="px-6 py-4 whitespace-nowrap ">
                                                                        <span className={`px-2 mr-2 inline-flex text-xs leading-5 font-semibold rounded-full `}>
                                                                            {user.verificationstatusaadharcard === "Not-Uploaded" ? (
                                                                                <span className="inline-flex items-center bg-stone-100 text-stone-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-stone-900 dark:text-stone-300">
                                                                                    <span className="w-2 h-2 me-1 bg-stone-500 rounded-full" style={{ animation: 'blink 1s infinite' }}></span>
                                                                                    Not-Uploaded
                                                                                </span>
                                                                            ) : user.verificationstatusaadharcard === "Pending" ? (
                                                                                <span className="inline-flex items-center bg-yellow-100 text-yellow-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-yellow-900 dark:text-yellow-300">
                                                                                    <span className="w-2 h-2 me-1 bg-yellow-500 rounded-full" style={{ animation: 'blink 1s infinite' }}></span>
                                                                                    Pending
                                                                                </span>
                                                                            ) : user.verificationstatusaadharcard === "Not-Verified" ? (
                                                                                <span className="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                                                                                    <span className="w-2 h-2 me-1 bg-red-500 rounded-full" style={{ animation: 'blink 1s infinite' }}></span>
                                                                                    Not-Verified
                                                                                </span>
                                                                            ) : user.verificationstatusaadharcard === "Verified" ? (
                                                                                <span className="inline-flex items-center bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">
                                                                                    <span className="w-2 h-2 me-1 bg-green-500 rounded-full" style={{ animation: 'blink 1s infinite' }}></span>
                                                                                    Verified
                                                                                </span>
                                                                            ) : null}
                                                                        </span>
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap ">
                                                                        <span className={`px-2 mr-2 inline-flex text-xs leading-5 font-semibold rounded-full `}>
                                                                            {user.verificationstatuspancard === "Not-Uploaded" ? (
                                                                                <span className="inline-flex items-center bg-stone-100 text-stone-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-stone-900 dark:text-stone-300">
                                                                                    <span className="w-2 h-2 me-1 bg-stone-500 rounded-full" style={{ animation: 'blink 1s infinite' }}></span>
                                                                                    Not-Uploaded
                                                                                </span>
                                                                            ) : user.verificationstatuspancard === "Pending" ? (
                                                                                <span className="inline-flex items-center bg-yellow-100 text-yellow-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-yellow-900 dark:text-yellow-300">
                                                                                    <span className="w-2 h-2 me-1 bg-yellow-500 rounded-full" style={{ animation: 'blink 1s infinite' }}></span>
                                                                                    Pending
                                                                                </span>
                                                                            ) : user.verificationstatuspancard === "Not-Verified" ? (
                                                                                <span className="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                                                                                    <span className="w-2 h-2 me-1 bg-red-500 rounded-full" style={{ animation: 'blink 1s infinite' }}></span>
                                                                                    Not-Verified
                                                                                </span>
                                                                            ) : user.verificationstatuspancard === "Verified" ? (
                                                                                <span className="inline-flex items-center bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">
                                                                                    <span className="w-2 h-2 me-1 bg-green-500 rounded-full" style={{ animation: 'blink 1s infinite' }}></span>
                                                                                    Verified
                                                                                </span>
                                                                            ) : null}
                                                                        </span>
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap ">
                                                                        <span className={`px-2 mr-2 inline-flex text-xs leading-5 font-semibold rounded-full `}>
                                                                            {user.verificationstatusbank === "Not-Uploaded" ? (
                                                                                <span className="inline-flex items-center bg-stone-100 text-stone-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-stone-900 dark:text-stone-300">
                                                                                    <span className="w-2 h-2 me-1 bg-stone-500 rounded-full" style={{ animation: 'blink 1s infinite' }}></span>
                                                                                    Not-Uploaded
                                                                                </span>
                                                                            ) : user.verificationstatusbank === "Pending" ? (
                                                                                <span className="inline-flex items-center bg-yellow-100 text-yellow-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-yellow-900 dark:text-yellow-300">
                                                                                    <span className="w-2 h-2 me-1 bg-yellow-500 rounded-full" style={{ animation: 'blink 1s infinite' }}></span>
                                                                                    Pending
                                                                                </span>
                                                                            ) : user.verificationstatusbank === "Not-Verified" ? (
                                                                                <span className="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                                                                                    <span className="w-2 h-2 me-1 bg-red-500 rounded-full" style={{ animation: 'blink 1s infinite' }}></span>
                                                                                    Not-Verified
                                                                                </span>
                                                                            ) : user.verificationstatusbank === "Verified" ? (
                                                                                <span className="inline-flex items-center bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">
                                                                                    <span className="w-2 h-2 me-1 bg-green-500 rounded-full" style={{ animation: 'blink 1s infinite' }}></span>
                                                                                    Verified
                                                                                </span>
                                                                            ) : null}
                                                                        </span>
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap ">
                                                                        <span className={`px-2 mr-2 inline-flex text-xs leading-5 font-semibold rounded-full ${user.verificationstatus ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                                                                            {user.verificationstatus ? 'Verified' : 'Not Verified'}
                                                                        </span>
                                                                    </td>
                                                                </>
                                                            )}
                                                            <td className="px-6 py-4 whitespace-nowrap">
                                                                <div className="text-sm text-gray-500">{user.createdAt.split('T')[0]}</div>
                                                            </td>
                                                            {(Admin || Telecaller || Verification) && (
                                                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                                                                    {(Admin || Telecaller) && (
                                                                        <Link
                                                                            to={`/view_user_details/${user._id}`}
                                                                            onClick={() => handleViewClick(user._id)}
                                                                            className="cursor-pointer text-green-600 hover:text-green-900"
                                                                        >
                                                                            View
                                                                        </Link>
                                                                    )}
                                                                    {Verification && (
                                                                        <Link
                                                                            to={`/verification_details/${user._id}`}
                                                                            // onClick={() => handleViewClick(user._id)}
                                                                            className="cursor-pointer text-blue-600 hover:text-blue-900 ms-4"
                                                                        >
                                                                            Verify
                                                                        </Link>
                                                                    )}
                                                                </td>
                                                            )}
                                                        </tr>
                                                    ))
                                                )}
                                        </tbody>
                                    </table>
                                    {(activeFilter === "all" &&
                                        <div className="fixed bottom-12 right-0 text-blue-500 mr-12  text-xl text-md  ">
                                            <div className="left-0 flex items-center justify-between ">
                                                <p>
                                                    Showing page {currentPage} of {totalPages} &ensp;
                                                    {currentPage * pageSize - pageSize} to {Math.min(currentPage * pageSize, totalCount)} of {totalCount} entries
                                                </p>
                                                <div className="text-blue-500  `{active? text-red-500 }` pagination">
                                                    <ReactPaginate
                                                        previousLabel={""}
                                                        nextLabel={"."}
                                                        breakLabel={"...."}
                                                        pageCount={totalPages}
                                                        marginPagesDisplayed={1}
                                                        pageRangeDisplayed={5}
                                                        onPageChange={handlePageClick}
                                                        containerClassName={"pagination"}
                                                        activeClassName={"active"}
                                                        className="flex"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Sidebar>
    );
};


