import * as Yup from 'yup';

export const employeeValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email format')
    .matches(/@mypridegames\.com$/, 'Email must end with @mypridegames.com')
    .required('Required'),
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .required('Required'),
  repeatPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Required'),
  name: Yup.string().required('Required'),
  aadharno: Yup.string()
    .matches(/^\d{12}$/, 'Enter a valid 12-digit Aadhaar Number')
    .required('Required'),
  employeeId: Yup.string().required('Required'),
  phone: Yup.string()
    .matches(/^[0-9]{10}$/, 'Enter a valid 10-digit Phone Number')
    .required('Required'),
  role: Yup.string().required('Required'),
  access: Yup.array()
    .min(1, 'At least one access level must be selected')
    .required('Required'),
});
