import { useEffect } from "react";
import useConversation from "../zustand/useConversation";
import MessageInput from "./MessageInput";
import Messages from "./Messages";
import { TiMessages } from "react-icons/ti";
import { useAuthContext } from "../../../Constants/context";
import { Link } from "react-router-dom";

const MessageContainer = () => {
	const { selectedConversation, setSelectedConversation } = useConversation();

	useEffect(() => {
		return () => setSelectedConversation(null);
	}, [setSelectedConversation]);

	return (
		<div className='w-full h-full flex flex-col bg-white rounded-lg shadow-lg'>
			{!selectedConversation ? (
				<NoChatSelected />
			) : (
				<>
					<div className='bg-gray-100 px-6 py-3 mb-4 flex items-center justify-between  text-gray-400'style={{marginLeft:"280px"}}>
						<div className="flex items-center space-x-4">
							<div className='w-20 h-20 flex items-center justify-center rounded-full bg-gradient-to-r from-blue-400 to-pink-500 shadow-lg border-4 border-200'>
								<span className='text-white text-4xl font-extrabold shadow-md'>{selectedConversation?.name?.charAt(0)?.toUpperCase()}</span>
							</div>

							<div className='flex flex-col'>
								<span className='text-lg font-bold'>{selectedConversation.name}</span>
								<span className='text-sm text-gray-400'>
									View:{" "}
									<Link
										to={`/view_user_details/${selectedConversation._id}`}
										className='text-blue-300 hover:text-blue-400 transition-colors duration-300'
									>
										{selectedConversation._id}
									</Link>
								</span>
							</div>
						</div>
					</div>
					<Messages />
					<MessageInput />
				</>
			)}
		</div>
	);
};

const NoChatSelected = () => {
	const { authUser } = useAuthContext();
	return (
		<div className='flex items-center justify-center w-full h-full'>
			<div className='px-4 text-center sm:text-lg md:text-xl text-gray-200 font-semibold flex flex-col items-center gap-2'>
				<TiMessages className='text-3xl md:text-6xl text-center' />
				<p>Welcome 👋 {authUser.fullName}</p>
				<p>Select a chat to start messaging</p>
			</div>
		</div>
	);
};

export default MessageContainer;
