import { useEffect, useState } from 'react';
import Sidebar from '../Frame/Sidebar';
import { BASE_URL } from '../../Constants/constant';
import axios from 'axios';

export const AllWithdrawals = () => {
    const [withdrawal, setWithdrawal] = useState([]);
    const [activeFilter, setActiveFilter] = useState('Pending');
    const [showUpdateForm, setShowUpdateForm] = useState(false);
    const [selectedWithdrawal, setSelectedWithdrawal] = useState(null);
    const token = localStorage.getItem('token');
    const fetchWithdrawal = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/v-1/withdrawal/get`,{
                headers:{
                    'Authorization': `Bearer ${token}`,
                }
        });
            setWithdrawal(response.data.withdrawals);
        } catch (error) {
            console.error('Error fetching withdrawals:', error);
        }
    };

    useEffect(() => {
        fetchWithdrawal();
    }, []);

    const handleUpdate = async () => {
        if (!selectedWithdrawal) return;

        const { clientId, withdrawalId, refferenceId, transactionId } = selectedWithdrawal;
        try {
            await axios.post(`${BASE_URL}/api/v-1/withdrawal/withdrawal_success`, {
                clientId,
                withdrawalId,
                refferenceId,
                transactionId
            },{
                    headers:{
                        'Authorization': `Bearer ${token}`,
                    }
            });

            setShowUpdateForm(false);
            fetchWithdrawal();
        } catch (error) {
            console.error('Error updating withdrawal:', error);
        }
    };

    const filteredWithdrawals = () => {
        switch (activeFilter) {
            case 'Success':
                return withdrawal.filter(w => w.status === "success");
            case 'Pending':
                return withdrawal.filter(w => w.status === "pending");
            case 'all':
            default:
                return withdrawal;
        }
    };

    return (
        <Sidebar>
            <div className="p-4 xl:ml-80 flex flex-col">
                <div>
                    <button
                        type="button"
                        className={`text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 ${activeFilter === 'Pending' ? 'bg-blue-500' : ''}`}
                        onClick={() => setActiveFilter('Pending')}
                    >
                        Withdrawal Pending
                    </button>
                    <button
                        type="button"
                        className={`text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 ${activeFilter === 'Success' ? 'bg-blue-500' : ''}`}
                        onClick={() => setActiveFilter('Success')}
                    >
                        Withdrawal Success
                    </button>
                    <button
                        type="button"
                        className={`text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 ${activeFilter === 'all' ? 'bg-blue-500' : ''}`}
                        onClick={() => setActiveFilter('all')}
                    >
                        All Withdrawals
                    </button>
                </div>
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <div className="table-container-button overflow-x-auto">
                                <table className="table table-bordered w-full">
                                    <thead className="bg-gray-100 sticky top-0">
                                        <tr>
                                            <th className="px-6 py-3 text-left">Sl.No</th>
                                            <th className="px-6 py-3 text-left">Payable Amount</th>
                                            <th className="px-6 py-3 text-left">Amount Requested</th>
                                            <th className="px-6 py-3 text-left">Status</th>
                                            <th className="px-6 py-3 text-left">A/c Holder Name</th>
                                            <th className="px-6 py-3 text-left">Account No</th>
                                            <th className="px-6 py-3 text-left">IFSC</th>
                                            <th className="px-6 py-3 text-left">Reference ID</th>
                                            <th className="px-6 py-3 text-left">Transaction ID</th>
                                            <th className="px-6 py-3 text-left">Withdrawal ID</th>
                                            <th className="px-6 py-3 text-left">Created At</th>
                                            <th className="px-6 py-3 text-left">Updated At</th>
                                            <th className="px-6 py-3 text-left">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                        {filteredWithdrawals().length === 0 ? (
                                            <tr>
                                                <td colSpan="13" className="px-6 py-4 text-center">No Withdrawal Requests</td>
                                            </tr>
                                        ) : (

                                            filteredWithdrawals().map((user, index) => (
                                                <tr key={user._id} className={index % 2 === 0 ? "bg-white" : "bg-gray-100"}>
                                                    <td className="px-6 py-4">{index + 1}</td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="text-xl font-medium text-green-500">{user.payable_amount || "No payable amount"}</div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="text-sm font-xs text-gray-900">{user.amount || 'No amount'}</div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <span className={`px-2 py-1 mr-2 inline-flex text-xs leading-5 font-semibold rounded-full ${user.status === "success" ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                                                            {user.status === "success" ? 'Success' : 'Pending'}
                                                        </span>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="text-sm text-gray-500">{user.accountholdername || "No accountholder name"}</div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="text-sm text-gray-500">{user.account_no || "No account number"}</div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="text-sm text-gray-500">{user.ifsc || "No IFSC"}</div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="text-sm text-gray-500">{user.refferenceId || "No reference ID"}</div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="text-sm text-gray-500">{user.transactionId || "No transaction ID"}</div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="text-sm text-gray-500">{user.withdrawalId || "No withdrawal ID"}</div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="text-sm text-gray-500">
                                                            {user.createdAt
                                                                ? `${user.createdAt.split("T")[0]} Time: ${user.createdAt.split("T")[1].split(".")[0]}`
                                                                : "No createdAt"}
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        {user.updatedAt
                                                            ? `${user.updatedAt.split("T")[0]} Time: ${user.updatedAt.split("T")[1].split(".")[0]}`
                                                            : "No updatedAt"}
                                                    </td>

                                                    <td className="px-6 py-4 whitespace-nowrap text-xl font-bold">
                                                        {user.status === "pending" ? (
                                                            <a
                                                                className="cursor-pointer text-xl text-indigo-600 hover:text-green-700"
                                                                onClick={() => {
                                                                    setSelectedWithdrawal(user);
                                                                    setShowUpdateForm(true);
                                                                }}
                                                            >
                                                                Pay
                                                            </a>
                                                        ) : (
                                                            <span className="text-xl text-red-600 hover:text-red-700">Done</span>
                                                        )}
                                                    </td>

                                                </tr>
                                         ))
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            {showUpdateForm && selectedWithdrawal && (
                                <div className="fixed inset-0 flex items-center justify-center z-50">
                                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                                    <div className="bg-white rounded-lg p-8 z-10">
                                        <h2 className="text-lg font-bold">Confirm Payment</h2>
                                        <p>Are you sure you want to process the payment for withdrawal ID: {selectedWithdrawal.withdrawalId}?</p>

                                        <div className="mt-4">
                                            <label className="block text-sm font-medium text-gray-700">Client ID</label>
                                            <input
                                                type="text"
                                                value={selectedWithdrawal.clientId || ''}
                                                onChange={(e) => setSelectedWithdrawal({ ...selectedWithdrawal, clientId: e.target.value })}
                                                className="mt-1 p-2 border border-gray-300 rounded w-full"
                                                placeholder="Enter Client ID"
                                            />
                                        </div>

                                        <div className="mt-4">
                                            <label className="block text-sm font-medium text-gray-700">Reference ID</label>
                                            <input
                                                type="text"
                                                value={selectedWithdrawal.refferenceId || ''}
                                                onChange={(e) => setSelectedWithdrawal({ ...selectedWithdrawal, refferenceId: e.target.value })}
                                                className="mt-1 p-2 border border-gray-300 rounded w-full"
                                                placeholder="Enter Reference ID"
                                            />
                                        </div>

                                        <div className="mt-4">
                                            <label className="block text-sm font-medium text-gray-700">Transaction ID</label>
                                            <input
                                                type="text"
                                                value={selectedWithdrawal.transactionId || ''}
                                                onChange={(e) => setSelectedWithdrawal({ ...selectedWithdrawal, transactionId: e.target.value })}
                                                className="mt-1 p-2 border border-gray-300 rounded w-full"
                                                placeholder="Enter Transaction ID"
                                            />
                                        </div>

                                        <div className="mt-6">
                                            <button
                                                className="bg-green-500 text-white px-4 py-2 rounded mr-2"
                                                onClick={() => {
                                                    handleUpdate();
                                                    setShowUpdateForm(false);
                                                  }}
                                                  
                                            >
                                                Confirm
                                            </button>
                                            <button
                                                className="bg-red-500 text-white px-4 py-2 rounded"
                                                onClick={() => setShowUpdateForm(false)}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Sidebar>
    );
};
