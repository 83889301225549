// import { useEffect, useState } from "react";
// import useConversation from "../zustand/useConversation.js";
// import toast from "react-hot-toast";
// import { BASE_URL } from "../../../Constants/constant.js";

// const useGetMessages = () => {
//     const [loading, setLoading] = useState(false);
//     const { messages, setMessages, selectedConversation } = useConversation();

//     useEffect(() => {
//         const getMessages = async () => {
//             setLoading(true);

//             try {
//                 const token = localStorage.getItem("token");
//                 const res = await fetch(`${BASE_URL}/api/v-1/chat/${selectedConversation._id}`, {
//                     headers: {
//                         'Authorization': `Bearer ${token}`,
//                     },
//                 });

//                 const data = await res.json();
//                 if (data.error) throw new Error(data.error);
//                 setMessages(data);
//             } catch (error) {
//                 toast.error(error.message);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         if (selectedConversation?._id) getMessages();
//     }, [selectedConversation?._id, setMessages]);

//     return { messages, loading };
// };
// export default useGetMessages;


// import { useEffect, useState } from "react";
// import useConversation from "../zustand/useConversation.js";
// import toast from "react-hot-toast";
// import { BASE_URL } from "../../../Constants/constant.js";
// import { useSocketContext } from "../../../Constants/SocketContext.jsx";

// const useGetMessages = () => {
//     const [loading, setLoading] = useState(false);
//     const { messages, setMessages, selectedConversation } = useConversation();
//     const { socket,fetchdata } = useSocketContext(); 

//     const getMessages = async () => {
//         setLoading(true);
//         try {
//             const token = localStorage.getItem("token");
//             const res = await fetch(`${BASE_URL}/api/v-1/chat/${selectedConversation._id}`, {
//                 headers: {
//                     'Authorization': `Bearer ${token}`,
//                 },
//             });

//             const responseData = await res.json();
//             if (responseData.error) throw new Error(responseData.error);

//             // Update messages only if there are changes
//             if (JSON.stringify(messages) !== JSON.stringify(responseData)) {
//                 setMessages(responseData);
//             }
//         } catch (error) {
//             toast.error(error.message);
//         } finally {
//             setLoading(false);
//         }
//     };

//     useEffect(() => {
//         // Fetch messages when a conversation is selected
//         if (selectedConversation?._id) {
//             getMessages();
//         }

//         // Listen for 'Fetchmessage' events
//         socket.on("Fetchmessage", () => {
//             if (selectedConversation?._id === fetchdata) {
//                 getMessages();
//             }
//         });
//         // return () => {
//         //     socket.off("Fetchmessage");
//         // };
//     }, [selectedConversation?._id]);

//     return { messages, loading };
// };

// export default useGetMessages;

import { useEffect, useState } from "react";
import useConversation from "../zustand/useConversation.js";
import toast from "react-hot-toast";
import { BASE_URL } from "../../../Constants/constant.js";
import { useSocketContext } from "../../../Constants/SocketContext.jsx";

const useGetMessages = () => {
    const [loading, setLoading] = useState(false);
    const { messages, setMessages, selectedConversation } = useConversation();
    const { socket } = useSocketContext();

    const getMessages = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem("token");
            const res = await fetch(`${BASE_URL}/api/v-1/chat/${selectedConversation._id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            const responseData = await res.json();
            if (responseData.error) throw new Error(responseData.error);

            // Update messages only if there are changes
            if (JSON.stringify(messages) !== JSON.stringify(responseData)) {
                setMessages(responseData);
            }
        } catch (error) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        // Fetch messages when a conversation is selected
        if (selectedConversation?._id ) {
            getMessages();
        }

        // Listen for 'Fetchmessage' events
        const handleFetchMessage = () => {
            if (selectedConversation?._id ) {
                getMessages();
            }
        };

        socket.on("Fetchmessage", handleFetchMessage);

        // Cleanup listener on component unmount
        return () => {
            socket.off("Fetchmessage", handleFetchMessage);
        };
    }, [selectedConversation?._id]); // Ensure fetchdata is included in the dependencies

    return { messages, loading };
};

export default useGetMessages;

