import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchTerm, fetchRequest, selectSearchTerm, selectSearchStatus, selectSearchLoading, selectSearchError, clearResults } from '../../Redux/slices/SearchSlice.js';
import toast, { Toaster } from 'react-hot-toast';

const UserSearch = () => {
    const dispatch = useDispatch();
    const searchTerm = useSelector(selectSearchTerm);
    const error = useSelector(selectSearchError);
    const [isSearching, setIsSearching] = useState(false);

    useEffect(() => {
        if (searchTerm.length < 3) {
            if (searchTerm === '') {
                dispatch(clearResults());
                return;
            }
            if (searchTerm.length > 0) {
                toast.error("Enter at least 3 characters");
            }
            return;
        }

        const debounceTimeout = setTimeout(() => {
            if (searchTerm.trim().length >= 3) {
                setIsSearching(true);
                dispatch(fetchRequest({ searchTerm }));
            } else {
                dispatch(clearResults());
            }
        }, 100);

        return () => {
            clearTimeout(debounceTimeout);
            setIsSearching(false); // Reset the searching state when input changes
        };
    }, [searchTerm, dispatch]);

    useEffect(() => {
        if (error && isSearching) {
            toast.error(error);
        }
    }, [error, isSearching]);

    return (
        <div className='text-blue-500'>
            <div className="flex mr-auto md:mr-4 md:w-56">
                <div className="relative w-full min-w-[200px] h-10">
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => dispatch(setSearchTerm(e.target.value))}
                        className="peer w-full h-full bg-transparent text-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-blue-500"
                    />
                    <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal peer-placeholder-shown:text-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-blue-gray-400 peer-focus:text-blue-500 before:border-blue-gray-200 peer-focus:before:border-blue-500 after:border-blue-gray-200 peer-focus:after:border-blue-500">
                        Type here
                    </label>
                </div>
            </div>

            <Toaster />
        </div>
    );
};

export default UserSearch;
