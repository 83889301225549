import React, { useEffect } from 'react';
import Sidebar from '../Frame/Sidebar';
import MoneyCard from '../Utils/Moneycard';
import { useUser } from '../../Constants/context';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRequest } from '../../Redux/slices/userSlice.js';
import { selectReportpendingcount, selectTotalUserCount, selectUsers, selectunverifiedusers, selecttotalActiveLength, selectTopPlayed, selecttotalbonusdistributed } from '../../Redux/Selector/userSelector.js';
import UserGraph from './userGraph.jsx';
import DynamicFilterForm from '../Report/Filter.jsx';
import { useSocketContext } from '../../Constants/SocketContext.jsx';
import {FRONTEND_URL } from '../../Constants/constant.js';
const AdminDashboard = () => {
  const { userRoles } = useUser();
  const dispatch = useDispatch();
  const TotalUserCount = useSelector(selectTotalUserCount)
  const { data } = useSocketContext();



  const TopPlayed = useSelector(selectTopPlayed)
  console.log(TopPlayed, 'TopPlayed')
  const users = useSelector(selectUsers);
  const activeuser = useSelector(selecttotalActiveLength)
  const unverifiedUserCount = useSelector(selectunverifiedusers);
  console.log(unverifiedUserCount, "unverifieduser")
  const TotalBonus = useSelector(selecttotalbonusdistributed)
  useEffect(() => {
    dispatch(fetchRequest());
  }, [dispatch]);



  console.log(data,"data")
  return (
    <Sidebar>
      <div className="p-4 xl:ml-80  " >
        <div className="min-h-screen  bg-gray-50/50">
          <div className="mt-12 ">
            <div className="mb-8 grid gap-y-10 gap-x-6 md:grid-cols-2 xl:grid-cols-5">
              <MoneyCard
                title="All Users"
                amount={data?.totalUser || 0}
                onClick="/user_details"
                percentage={`${(
                  (data?.currentWeek?.currentweektotaluser || 0) - (data?.previousWeek?.previouseweektotaluser || 0) > 0
                    ? '+' : '-'
                )}${(
                  Math.abs(
                    ((data?.currentWeek?.currentweektotaluser || 0) - (data?.previousWeek?.previouseweektotaluser || 0)) /
                    (data?.previousWeek?.previouseweektotaluser || 1) * 100
                  ).toFixed(2)
                )}% than yesterday`}
                percentageClass={(
                  ((data?.currentWeek?.currentweektotaluser || 0) - (data?.previousWeek?.previouseweektotaluser || 0)) /
                    (data?.previousWeek?.previouseweektotaluser || 1) * 100 > 0
                    ? 'text-green-500'  // Positive change
                    : 'text-red-500'    // Negative change
                )}
                bg="bg-gradient-to-tr from-blue-600 to-blue-400"
                img="./Images/active users_icon.png"
              />

              <MoneyCard
                title="Total Deposits"
                 onClick="/deposites"
                 amount={(data?.TotalDeposit !== undefined ? data.TotalDeposit : 0).toFixed(3)}
                percentage={`${(
                  ((data?.currentWeek?.currentWeektotalDeposits || 0) - (data?.previousWeek?.previousWeektotalDeposits || 0)) /
                  (data?.previousWeek?.previousWeektotalDeposits || 1) * 100
                ).toFixed(2)}% than yesterday`}
                percentageClass={(
                  ((data?.currentWeek?.currentWeektotalDeposits || 0) - (data?.previousWeek?.previousWeektotalDeposits || 0)) /
                    (data?.previousWeek?.previousWeektotalDeposits || 1) * 100 > 0
                    ? 'text-green-500'  // Positive change
                    : 'text-red-500'    // Negative change
                )}
                bg="bg-gradient-to-tr from-red-600 to-red-400"
                img="./Images/Total Deposit_icon.png"
              />

              <MoneyCard
                title="Today's Deposit"
                 onClick="/user_details"
                 amount={(data?.todayDeposits !== undefined ? data.todayDeposits : 0).toFixed(3)}
                percentage={`${(
                  (data?.todayDeposits || 0) - (data?.yesterdayDeposits || 0) > 0 ? '+' : '-'
                )}${(
                  Math.abs(
                    ((data?.todayDeposits || 0) - (data?.yesterdayDeposits || 0)) /
                    (data?.yesterdayDeposits || 1) * 100
                  ).toFixed(2)
                )}% than yesterday`}
                percentageClass={(
                  ((data?.todayDeposits || 0) - (data?.yesterdayDeposits || 0)) /
                    (data?.yesterdayDeposits || 1) * 100 > 0
                    ? 'text-green-500'
                    : 'text-red-500'
                )}
                bg="bg-gradient-to-tr from-yellow-600 to-yellow-400"
                img="./Images/Today Deposit_icon.png"
              />

              <MoneyCard
                title="Total withdrawal"
                 onClick="/Withdraw"
                 amount={(data?.TotalWithdrawal !== undefined ? data.TotalWithdrawal : 0).toFixed(3)}
                percentage={`${(
                  (data?.currentWeek?.currentWeektotalWithdrawals || 0) - (data?.previousWeek?.previousWeektotalWithdrawals || 0) > 0 ? '+' : '-'
                )}${(
                  Math.abs(
                    ((data?.currentWeek?.currentWeektotalWithdrawals || 0) - (data?.previousWeek?.previousWeektotalWithdrawals || 0)) /
                    (data?.previousWeek?.previousWeektotalWithdrawals || 1) * 100
                  ).toFixed(2)
                )}% than last week`}
                percentageClass={(
                  ((data?.currentWeek?.currentWeektotalWithdrawals || 0) - (data?.previousWeek?.previousWeektotalWithdrawals || 0)) /
                    (data?.previousWeek?.previousWeektotalWithdrawals || 1) * 100 > 0
                    ? 'text-green-500' : 'text-red-500'
                )}
                bg='bg-gradient-to-tr from-blue-600 to-blue-400'

                img="./Images/Total withdraw_icon.png"
              />
              <MoneyCard
                title="Today's withdrawal"
                 onClick="/user_details"
                 amount={(data?.todayWithdrawal !== undefined ? data.todayWithdrawal : 0).toFixed(3)}
                percentage={`${(
                  (data?.todayWithdrawal || 0) - (data?.yesterdayWithdrawal || 0) > 0 ? '+' : '-'
                )}${(
                  Math.abs(
                    ((data?.todayWithdrawal || 0) - (data?.yesterdayWithdrawal || 0)) /
                    (data?.yesterdayWithdrawal || 1) * 100
                  ).toFixed(2)
                )}% than yesterday`}
                percentageClass={(
                  ((data?.todayWithdrawal || 0) - (data?.yesterdayWithdrawal || 0)) /
                    (data?.yesterdayWithdrawal || 1) * 100 > 0
                    ? 'text-green-500'
                    : 'text-red-500'
                )}
                bg='bg-gradient-to-tr from-red-600 to-red-400'
                img="./Images/Today withdraw_icon.png"
              />
              <MoneyCard
                title="Total GST"
                 onClick="/user_details"
                 amount={(data?.TotalGstAmountAmount !== undefined ? data.TotalGstAmountAmount : 0).toFixed(3)}
                percentage={`${(
                  (data?.currentWeek?.currentWeekGst || 0) - (data?.previousWeek?.previousWeekGst || 0) > 0
                    ? '+' : '-'
                )}${(
                  Math.abs(
                    ((data?.currentWeek?.currentWeekGst || 0) - (data?.previousWeek?.previousWeekGst || 0)) /
                    (data?.previousWeek?.previousWeekGst || 1) * 100
                  ).toFixed(2)
                )}% than last week`}
                percentageClass={(
                  ((data?.currentWeek?.currentWeekGst || 0) - (data?.previousWeek?.previousWeekGst || 0)) /
                    (data?.previousWeek?.previousWeekGst || 1) * 100 > 0
                    ? 'text-green-500'  // Positive change
                    : 'text-red-500'    // Negative change
                )}
                bg="bg-gradient-to-tr from-blue-600 to-blue-400"
                img="./Images/active users_icon.png"
              />


              <MoneyCard
                title="Total Taxable"
                 onClick="/user_details"
                 amount={(data?.TotalTaxableAmount !== undefined ? data.TotalTaxableAmount : 0).toFixed(3)}
                percentage={`${(
                  (data?.currentWeek?.previousWeekTaxable || 0) - (data?.previousWeek?.previousWeekTaxable || 0) > 0
                    ? '+' : '-'
                )}${(
                  Math.abs(
                    ((data?.currentWeek?.previousWeekTaxable || 0) - (data?.previousWeek?.previousWeekTaxable || 0)) /
                    (data?.previousWeek?.previousWeekTaxable || 1) * 100
                  ).toFixed(2)
                )}% than last week`}
                percentageClass={(
                  ((data?.currentWeek?.previousWeekTaxable || 0) - (data?.previousWeek?.previousWeekTaxable || 0)) /
                    (data?.previousWeek?.previousWeekTaxable || 1) * 100 > 0
                    ? 'text-green-500'  // Positive change
                    : 'text-red-500'    // Negative change
                )}
                bg="bg-gradient-to-tr from-blue-600 to-blue-400"
                img="./Images/active users_icon.png"
              />
              <MoneyCard
                title="Total TDS"
                 onClick="/user_details"
                 amount={(data?.TotalTdsAmount !== undefined ? data.TotalTdsAmount : 0).toFixed(3)}
                percentage={`${(
                  (data?.currentWeek?.currentWeekTds || 0) - (data?.previousWeek?.previousWeekTds || 0) > 0
                    ? '+' : '-'
                )}${(
                  Math.abs(
                    ((data?.currentWeek?.currentWeekTds || 0) - (data?.previousWeek?.previousWeekTds || 0)) /
                    (data?.previousWeek?.previousWeekTds || 1) * 100
                  ).toFixed(2)
                )}% than last week`}
                percentageClass={(
                  ((data?.currentWeek?.currentWeekTds || 0) - (data?.previousWeek?.previousWeekTds || 0)) /
                    (data?.previousWeek?.previousWeekTds || 1) * 100 > 0
                    ? 'text-green-500'  // Positive change
                    : 'text-red-500'    // Negative change
                )}
                bg="bg-gradient-to-tr from-blue-600 to-blue-400"
                img="./Images/active users_icon.png"
              />





              <MoneyCard
                title="Active Users"
                amount={(data?.totalActiveLength !== undefined ? data.totalActiveLength : 0).toFixed(3)}
                percentage=""
                percentageClass=""
                bg='bg-gradient-to-tr from-yellow-600 to-yellow-400'
                img="./Images/active users_icon.png"

              />
              {/* <MoneyCard
                title="Un-Verified Users"
                amount={data?.totalInactiveLength || 0}
                percentage=""
                percentageClass=""
                bg='bg-gradient-to-tr from-blue-600 to-blue-400'
                img="./Images/unverified user_icon.png"

              /> */}

              <MoneyCard
                title="Total Bonus Distributed"
                amount={(data?.TotalBonusDistributed !== undefined ? data.TotalBonusDistributed : 0).toFixed(3)}
                percentage=""
                percentageClass=""
                bg='bg-gradient-to-tr from-red-600 to-red-400'
                img="./Images/bonus d_icon.png"
              />
            </div>
            <h2 className='font-bold text-3xl bg-gradient-to-tr from-blue-600 to-blue-400 ps-5 text-gray-100 '>Top Game Played Users </h2>
            <div className="flex mt-3 h-96 overflow-x-auto space-x-4">

              <div className="flex-shrink-0 w-6/12 bg-clip-border rounded-xl bg-white text-gray-700 shadow-md overflow-auto">
                <table className="min-w-full divide-y divide-blue-gray-200">
                  <thead>
                    <tr className="text-start bg-blue-gray-100">
                      <th className="py-3 px-5 border-b border-blue-gray-300">Sl no</th>
                      <th className="py-3 px-5 border-b border-blue-gray-300">Name</th>
                      <th className="py-3 px-5 border-b border-blue-gray-300">Game</th>
                      <th className="py-3 px-5 border-b border-blue-gray-300">Phone Number</th>
                      <th className="py-3 px-5 border-b border-blue-gray-300">Total Games</th>
                    </tr>
                  </thead>
                  <tbody>
                    {TopPlayed && TopPlayed.length > 0 ? (
                      TopPlayed.map((player, index) => (
                        <tr key={index} className="text-center">
                          <td className="py-3 px-5 border-b border-blue-gray-200">

                            <p className="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-bold">{index + 1}</p>

                          </td>
                          <td className="py-3 px-5 border-b border-blue-gray-200">

                            <p className="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-bold">{player.name}</p>

                          </td>
                          <td className="py-3 px-5 border-b border-blue-gray-200">
                            <p className="block antialiased font-sans text-xs font-medium text-blue-gray-600">{player.game_name || "-"}</p>
                          </td>
                          <td className="py-3 px-5 border-b border-blue-gray-200">
                            <p className="block antialiased font-sans text-xs font-medium text-blue-gray-600">{player.phoneNumber || "-"}</p>
                          </td>
                          <td className="py-3 px-5 border-b border-blue-gray-200">
                            <p className="block antialiased font-sans text-xs font-medium text-blue-gray-600">{player.totalGames}</p>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4" className="py-3 px-5 text-center text-blue-gray-600">No data available</td>
                      </tr>
                    )}
                  </tbody>
                </table>

              </div>

              <div className="flex-shrink-0 w-5/12 ">
                <UserGraph />
              </div>

            </div>



          </div>
        </div>
      </div>

    </Sidebar>
  );
}

export default AdminDashboard;









