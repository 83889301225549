import Conversations from "./Conversations.jsx";
import SearchInput from "./SearchInput.jsx";

const Sidebar1 = () => {
    return (
        <div className='absolute pt-20 bg-gray-100 text-white border-r text-center border-gray-200 p-4 flex flex-col'>
            <h2 className='text-lg  text-gray-400 font-semibold mb-4'>ALL USERS</h2>
            <SearchInput className='mb-4' />
            <div className='border-t border-gray-600 my-4 ' ></div>
            <Conversations />
        </div>
    );
};

export default Sidebar1;
