import { FileUpIcon, X } from "lucide-react";
import FileUploadComponent from "./FileUploadComponent";
import { FaDesktop, FaMobile } from "react-icons/fa";
import { Fragment, useState } from "react";
import ConfirmDeletePopup from "../ConfirmDeletePopup";

const FileUploadWithButton = ({ 
    title, 
    tag, 
    type, 
    uploadingImages, 
    setUploadingImages, 
    isLoading, 
    setIsLoading, 
    uploadFunction, 
    resetImageUpload,
    onClose
}) => {
    const isDisabled = !uploadingImages || uploadingImages.type !== type || uploadingImages.imageArray.length <= 0;
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg w-1/2 max-h-[700px] overflow-y-auto relative">
                <button onClick={onClose} className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
                    <X/>
                </button>
                <div className="w-full justify-center flex flex-col items-center space-y-3">
                    <h1>{title}</h1>
                    <FileUploadComponent
                        maxFiles={12}
                        tag={tag}
                        onSetImageUrls={(files) => {
                            console.log(`${title} Files Uploaded`, files);
                            setUploadingImages({ type, imageArray: files });
                        }}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        onReset={resetImageUpload}
                    />
                    <button 
                        disabled={isDisabled} 
                        onClick={uploadFunction}
                        className={`py-2 px-4 justify-center space-x-3 items-center flex mt-2 rounded-md ${isDisabled ? 'bg-gray-300' : 'bg-blue-500 text-white'} `}
                    >
                        <FileUpIcon/>
                        <span>
                            Upload

                        </span>
                    </button>
                </div>
            </div>
        </div>
    );
};

const CarousalView = ({ 
    title, 
    images, 
    carousalType,
    deleteCarousalImageByIndex,
}) => {
    const[deletingCarousalImageId, setDeletingCarousalImageId] = useState(null);
    return (
        <Fragment>
            <div className="mt-6">
                <h2 className="text-xl font-bold mb-4">{title}</h2>
                <div className="flex flex-wrap">
                    {images && images.length > 0 && images.map((src, index) => (
                        <div key={index} className="w-fit h-32 m-2 relative">
                            <img src={src} alt={`${carousalType}-${index}`} className="w-full h-full object-cover rounded-md"/>
                            <button className="absolute top-0 right-0 bg-red-500 text-white p-1 rounded-md" onClick={() => setDeletingCarousalImageId(index)}>
                                <X/>
                            </button>
                        </div>
                    ))}
                </div>
            </div>
            <ConfirmDeletePopup
                isOpen={deletingCarousalImageId !== null}
                onCancel={() => setDeletingCarousalImageId(null)}
                onConfirm={() => {
                    deleteCarousalImageByIndex(carousalType, deletingCarousalImageId);
                    setDeletingCarousalImageId(null);
                }}
            />
        </Fragment>
    )
}

const ImageUploadSection = ({ 
    isLoading, 
    setIsLoading, 
    uploadingImages, 
    setUploadingImages, 
    uploadWideImageToCarousal, 
    uploadSmallImageToCarousal, 
    wideScreenCarousal, 
    smallScreenCarousal, 
    resetImageUpload,
    deleteCarousalImageByIndex,
}) => {
    const [isWideCarousalOpen, setIsWideCarousalOpen] = useState(false);
    const [isSmallCarousalOpen, setIsSmallCarousalOpen] = useState(false);
    return (
        <div className="p-6 w-full space-y-5 mb-7">
            <div className="w-full justify-between items-center flex">
                <button onClick={()=> {
                    setIsWideCarousalOpen(!isWideCarousalOpen);
                    setIsSmallCarousalOpen(false);
                }} className="bg-red-500 flex flex-row justify-center items-center space-x-2 text-white py-2 px-4 rounded-md">
                    <FaDesktop/>
                    <span>
                        Add Desktop Carousal
                    </span>
                </button>
                <button onClick={()=>{
                    setIsSmallCarousalOpen(!isSmallCarousalOpen);
                    setIsWideCarousalOpen(false);
                }} className="bg-red-500 flex flex-row justify-center items-center space-x-2 text-white py-2 px-4 rounded-md">
                    <FaMobile/>
                    <span>
                        Add Mobile Carousal
                    </span>
                </button>
            </div>
            {
                isWideCarousalOpen && (
                    <FileUploadWithButton 
                        title="Desktop View Carousal" 
                        tag="wide_sizeImage" 
                        type="Wide" 
                        uploadingImages={uploadingImages} 
                        setUploadingImages={setUploadingImages} 
                        isLoading={isLoading} 
                        setIsLoading={setIsLoading} 
                        uploadFunction={uploadWideImageToCarousal} 
                        resetImageUpload={resetImageUpload}
                        onClose={() => setIsWideCarousalOpen(false)}
                    />
                )
            }
            {
                isSmallCarousalOpen && <FileUploadWithButton 
                    title="Mobile View Carousal" 
                    tag="small_sizeImage" 
                    type="Small" 
                    uploadingImages={uploadingImages} 
                    setUploadingImages={setUploadingImages} 
                    isLoading={isLoading} 
                    setIsLoading={setIsLoading} 
                    uploadFunction={uploadSmallImageToCarousal} 
                    resetImageUpload={resetImageUpload}
                    onClose={() => setIsSmallCarousalOpen(false)}
                />
            }
            
            
            <CarousalView 
                title="Desktop View Images" 
                images={wideScreenCarousal} 
                carousalType="Wide"
                deleteCarousalImageByIndex={deleteCarousalImageByIndex}
            />
            
            <CarousalView 
                title="Mobile View Images" 
                images={smallScreenCarousal} 
                carousalType="Small"
                deleteCarousalImageByIndex={deleteCarousalImageByIndex}
            />
        </div>
    )
}

export default ImageUploadSection;
