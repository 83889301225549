
import React, { createContext, useState, useContext, useEffect } from 'react';

// UserContext
const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [userRoles, setUserRoles] = useState(() => {
        return JSON.parse(localStorage.getItem('userRoles')) || [];
    });

    useEffect(() => {
        localStorage.setItem('userRoles', JSON.stringify(userRoles));
    }, [userRoles]);

    // Define role-based flags
    const Admin = userRoles.includes("Admin") || userRoles.includes("Telecaller Team") || userRoles.includes("Verification Team") || userRoles.includes("Market Team");
    const Telecaller = userRoles.includes("Telecaller Team");
    const Verification = userRoles.includes("Verification Team");
    const Market = userRoles.includes("Market Team");

    return (
        <UserContext.Provider value={{ userRoles, setUserRoles, Admin, Telecaller, Verification, Market }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => {
    return useContext(UserContext);
};

// AuthContext
const AuthContext = createContext();

export const useAuthContext = () => {
    return useContext(AuthContext);
};

export const AuthContextProvider = ({ children }) => {
    const [authUser, setAuthUser] = useState(() => {
        const token = localStorage.getItem("token");
        if (token) { 
            return { token }; 
        }
        return null;
    });
    useEffect(() => {
        if (authUser) {
            localStorage.setItem("token", authUser.token);
        } else {
            localStorage.removeItem("token");
        }
    }, [authUser]);
    return (
        <AuthContext.Provider value={{ authUser, setAuthUser }}>
            {children}
        </AuthContext.Provider>
    );
};

export const CombinedProvider = ({ children }) => {
    return (
        <AuthContextProvider>
            <UserProvider>
                {children}
            </UserProvider>
        </AuthContextProvider>
    );
};
