import React, { useEffect, useState } from 'react';
import { fetchPartners, removePartnerId, setPartner, updatePartners } from '../../../Redux/slices/websiteSlice';
import toast from 'react-hot-toast';
import FileUploadComponent from './FileUploadComponent';
import { useDispatch, useSelector } from 'react-redux';
import { Handshake, X } from 'lucide-react';
import ConfirmDeletePopup from '../ConfirmDeletePopup';

const initialForm = {
    name: "",
    logo: "",
};

const PartnersDataUpload = () => {
    const { Partners, ErrorMessage } = useSelector(state => state.website);
    const dispatch = useDispatch();
    const [formData, setFormData] = useState(initialForm);
    const [isLoading, setIsLoading] = useState(false);
    const [reset, setIsReset] = useState(false);
    const [errors, setErrors] = useState({});
    const [showModal, setShowModal] = useState(false);  // State to control modal visibility

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (file) => {
        if (file) {
            setFormData({ ...formData, logo: file });
        }
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = "Name is required";
        if (!formData.name) newErrors.details = "Details is required";
        if (!formData.logo) newErrors.logo = "Logo is required";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            await dispatch(setPartner(formData));
            setFormData(initialForm);
            setIsReset(true);
            setTimeout(() => {
                setIsReset(false);
            }, 400);
            dispatch(fetchPartners());
            setShowModal(false);  // Close the modal after submission
        }else{
            toast.error("Form invalid");
        }
    };

    useEffect(() => {
        if(!Partners || Partners.length === 0){
            dispatch(fetchPartners())
            toast.success("Partners fetched successfully");
        }
        // dispatch(fetchPartners());
    }, [dispatch]);

    useEffect(() => {
        if (ErrorMessage) {
            toast.error(ErrorMessage);
        }
    }, [ErrorMessage]);

    return (
        <div className="w-full p-6 bg-white rounded-md shadow-lg">
            <button 
                onClick={() => setShowModal(true)} 
                className="px-4 py-2 bg-red-600 flex flex-row justify-center items-center font-semibold space-x-3 text-white rounded-md shadow-md hover:bg-red-700"
            >
                <Handshake />
                <span>Add New Payment Partner</span>
            </button>

            {/* Modal Structure */}
            {showModal && (
                <div className="fixed inset-0 z-50 flex justify-center items-center bg-gray-500 bg-opacity-50">
                    <div className="w-full max-w-lg bg-white p-6 rounded-md relative shadow-lg">
                        <h2 className="text-xl font-semibold mb-4">Add New Partner</h2>
                        <form onSubmit={handleSubmit}>
                            {/* Name */}
                            <div className="mb-4">
                                <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500"
                                />
                                {errors.name && <p className="text-red-500 text-sm mt-2">{errors.name}</p>}
                            </div>

                            {/* Logo */}
                            <div className="mb-4">
                                <label htmlFor="logo" className="block text-sm font-medium text-gray-700">Partners Logo</label>
                                <FileUploadComponent
                                    maxFiles={1}
                                    tag={"partners_ImageLogo"}
                                    onSetImageUrls={(files) => {
                                        if(files.length > 0){
                                            handleFileChange(files[0]?.url);
                                        }
                                    }}
                                    isLoading={isLoading}
                                    setIsLoading={setIsLoading}
                                    onReset={reset}
                                />
                                {errors.logo && <p className="text-red-500 text-sm mt-2">{errors.logo}</p>}
                            </div>

                            <button
                                type="submit"
                                className="w-full px-4 py-2 bg-red-600 text-white font-medium rounded-md shadow-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
                            >
                                Submit
                            </button>
                        </form>

                        <button 
                            onClick={() => setShowModal(false)} 
                            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                        >
                            <X/>
                        </button>
                    </div>
                </div>
            )}

            {/* Partners Table View */}
            <PartnersTableView Partners={Partners} OnChange={()=>{

                dispatch(fetchPartners())
            }}/>
        </div>
    );
};

const PartnersTableView = ({ Partners,OnChange }) => {
    const dispatch = useDispatch();
    const[isLoading,setIsLoading] = useState(false);
    const [deletingPartnerId, setDeletingPartnerId] = useState(null);
    const[rest,setRest] = useState(false);
    const [selectedPartner, setSelectedPartner] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editedPartner, setEditedPartner] = useState({_id:'', name: '', logo: '',details:'' });

    // Open the modal and set the partner data
    const handleEditPartner = (partner) => {
        setEditedPartner({_id:partner._id, name: partner.name, logo: partner.logo });
        setSelectedPartner(partner);
        setIsEditing(true);
    };

    // Close the modal
    const handleCloseModal = () => {
        setIsEditing(false);
        setSelectedPartner(null);
        if(OnChange){
            OnChange();
        }
    };
    const handleDeletePartner = async (partnerId) => {
        const response = await dispatch(removePartnerId({id:partnerId}))
        if(response?.payload?.success){
            if(OnChange){
                OnChange();
            }
        }
    };

    // Handle changes to editable fields
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedPartner({
            ...editedPartner,
            [name]: value,
        });
    };
    const HandleLogoFileChange = (file)=>{
        setEditedPartner({...editedPartner, logo: file });
    }

    // Save the changes to the partner (You can dispatch this to the backend or handle it)
    const handleSaveChanges = async () => {
        // console.log("Updated Partner: ", editedPartner);
        const response = await dispatch(updatePartners({partnerId:editedPartner._id,data:editedPartner}))
        console.log("Updated Partner: ", response)
        if(response?.payload?.success){
            setRest(true);
            setTimeout(() => {
                setRest(false);
                handleCloseModal();
            }, 100);
        }
        // Here you can update the Partners array or make an API call to save the data.

    };


    return (
        <div className="overflow-x-auto mt-6">
            <div className="w-full">
                {/* Table Header */}
                <div className="grid grid-cols-4 justify-center items-center text-center bg-gray-200 py-2 font-semibold">
                    <span>Name</span>
                    <span>Details</span>
                    <span>Logo</span>
                    <span>Action</span>
                </div>

                {/* Partner List */}
                {Partners && Partners.length > 0 && Partners.map((partner, index) => (
                    <div key={index} className="grid grid-cols-4 py-2 justify-center items-center text-center border-b cursor-pointer hover:bg-gray-50" onClick={() => handleEditPartner(partner)}>
                        <span className="text-sm font-medium">{partner.name}</span>
                        <span className="text-sm font-medium">{partner.details}</span>
                        <div className='w-full justify-center items-center flex'>
                            <img src={partner.logo} alt="Logo" className="w-16 h-16 rounded-md object-contain" />
                        </div>
                        <div className='w-full justify-center items-center flex'>
                            <button className='bg-red-400 p-3 text-white rounded-md' onClick={(e) => {
                                e.stopPropagation();
                                setDeletingPartnerId(partner._id);
                            }}>
                                <X/>
                            </button>
                        </div>
                    </div>
                ))}
            </div>

            {/* Modal for Editing Partner */}
            {isEditing && selectedPartner && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg w-full sm:w-3/4 md:w-1/2 lg:w-1/3 max-h-[90vh] overflow-y-auto">
                        <div className="w-full flex justify-between items-center">
                            <h3 className="text-2xl font-bold mb-4">Edit Partner</h3>
                            <button
                                onClick={handleCloseModal}
                                className="cursor-pointer text-gray-500 hover:text-gray-700"
                            >
                                <X className='bg-black text-white' size={20}/>
                            </button>
                        </div>

                        {/* Editable Fields */}
                        <div className="mb-4">
                            <label className="block text-gray-700">Partner Name</label>
                            <input
                                type="text"
                                name="name"
                                value={editedPartner.name}
                                onChange={handleInputChange}
                                className="w-full px-4 py-2 mt-1 border rounded-md focus:ring-2 focus:ring-red-400"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700">Partner Details</label>
                            <input
                                type="text"
                                name="details"
                                value={editedPartner.details}
                                onChange={handleInputChange}
                                className="w-full px-4 py-2 mt-1 border rounded-md focus:ring-2 focus:ring-red-400"
                            />
                        </div>
                        <div className="mb-4 flex justify-center">
                            <img src={editedPartner.logo} alt="Logo Preview" className="w-24 h-24 object-cover rounded-full" />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700">Set Logo Url</label>
                            <FileUploadComponent
                                maxFiles={1}
                                tag={"partners_ImageLogo"}
                                onSetImageUrls={(files) => {
                                    HandleLogoFileChange(files[0]?.url);
                                }}
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                                onReset={rest}
                            />
                        </div>


                        {/* Save Button */}
                        <button
                            onClick={handleSaveChanges}
                            className="w-full py-2 bg-red-500 text-white rounded-md hover:bg-red-600 focus:outline-none"
                        >
                            Save Changes
                        </button>
                    </div>
                </div>
            )}
            <ConfirmDeletePopup
                isOpen={deletingPartnerId !== null}
                onClose={() => setDeletingPartnerId(null)}
                onConfirm={()=> {
                    handleDeletePartner(deletingPartnerId)
                    setDeletingPartnerId(null);
                }}
            />
        </div>
    );
};

export default PartnersDataUpload;
