
import { useState } from "react";
import useConversation from "../zustand/useConversation.js";
import toast from "react-hot-toast";
import { BASE_URL } from "../../../Constants/constant.js";

const useSendMessage = () => {
  const [loading, setLoading] = useState(false);
  const { messages, setMessages, selectedConversation } = useConversation();

  const sendMessage = async (message, files) => {
    setLoading(true);
    const formData = new FormData();

    // Append message and files to FormData
    formData.append("message", message);

    if (files && files.length > 0) {
      for (const file of files) {
        formData.append("files", file);
      }
    }

    // Log FormData content for debugging (if necessary)
    // Comment this out in production
    console.log("Sending FormData:");
    for (const [key, value] of formData.entries()) {
      if (value instanceof File) {
        console.log(`${key}: ${value.name}`);
      } else {
        console.log(`${key}: ${value}`);
      }
    }

    try {
      const token = localStorage.getItem("token")
      const res = await fetch(`${BASE_URL}/api/v-1/chat/send/${selectedConversation._id}`, {
					headers: {
						'Authorization': `Bearer ${token}`,
					},
        method: "POST",
        body: formData, 
      });

      if (!res.ok) {
        const errorData = await res.json();
        throw new Error(errorData.error || "An error occurred");
      }

      const data = await res.json();
      setMessages([...messages, data]);
    } catch (error) {
      toast.error(error.message || "Failed to send message");
    } finally {
      setLoading(false);
    }
  };

  return { sendMessage, loading };
};

export default useSendMessage;
