import React, { useEffect, useState } from 'react';
import FileUploadComponent from '../WebComponents/FileUploadComponent';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLudoHomeData, setLudoHomeData } from '../../../Redux/slices/ludoWebsiteSlice';
import { Plus, PlusCircle, X } from 'lucide-react';
import toast from 'react-hot-toast';

const defaultData = {
    wideScreenBannerBg: '',
    mobileScreenBannerBg: '',
    mainBanner: '',
    scannerCodeImage: '',
    header: '',
    activeUserCount: { header: '', count: 0 },
    totalDownloads: { header: '', count: 0 },
    increaseUsersDownload: { header: '', count: 0 },
    rating: { header: '', count: 0 },
    achievements: [{ icon: '', header: '', subHeader: '' }],
    bonusText: '',
    howToPlayVideo: '',
};

const HomeDataUpload = () => {
    const { homeData } = useSelector((state) => state.ludoWebsite);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [reset, setIsReset] = useState(false);
    const [formData, setFormData] = useState(defaultData);

    const homeActions = async (actions, params) => {
        if (params) {
            await dispatch(actions(params));
        } else {
            await dispatch(actions());
        }
        dispatch(fetchLudoHomeData());
        setIsReset(true);
        setFormData(defaultData);
        setTimeout(() => setIsReset(false), 100);
    };

    const handleChangeCounts = (e, type) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: { ...formData[name], [type]: value } });
    };

    const UploadHomeData = () => {
        homeActions(setLudoHomeData, formData);
    };

    useEffect(() => {
        if (homeData) setFormData(homeData);
    }, [homeData]);

    useEffect(() => {
        dispatch(fetchLudoHomeData());
    }, [dispatch]);

    const renderFileUploadSection = (label, fieldName, fileUrl) => {
        const isVideoFile = (file) => {
            return (
                file?.includes("video") ||
                file?.endsWith(".mp4") ||
                file?.endsWith(".mov") ||
                file?.endsWith(".avi")
            );
        };
    
        return (
            <div className="overflow-x-auto py-4">
               {/* Label Column */}
                <h1>{label}</h1>
                <table className="w-full border-collapse overflow-hidden">
                    <thead>
                        <tr>
                            <th className="border border-gray-800 p-2">File</th>
                            <th className="border border-gray-800 p-2">Preview Upload</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {/* File Upload Column */}
                            <td className="border border-gray-600 p-2">
                                <FileUploadComponent
                                    maxFiles={1}
                                    tag={fieldName}
                                    onSetImageUrls={(files) => {
                                        setFormData({ ...formData, [fieldName]: files[0]?.url });
                                    }}
                                    isLoading={isLoading}
                                    setIsLoading={setIsLoading}
                                    onReset={reset}
                                />
                            </td>    
                            <td className="border border-gray-900 p-2">
                                {
                                    isVideoFile(fileUrl) ? (
                                        <video
                                            src={fileUrl}
                                            autoPlay={false}
                                            controls
                                            muted
                                            style={{ maxWidth: '100%', maxHeight: '300px' }}
                                        />
                                    ) : (
                                        <img
                                            src={fileUrl}
                                            alt={fieldName}
                                            className="w-full h-auto"
                                            style={{ maxHeight: '300px', objectFit: 'contain' }}
                                        />
                                    )
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };
    

    const renderInputField = (name, value, placeholder, isNumber = false) => (
        <input
            type={isNumber ? 'number' : 'text'}
            name={name}
            value={value}
            onChange={(e) => setFormData({ ...formData, [name]: e.target.value })}
            placeholder={placeholder}
            className="w-full border border-gray-700 placeholder-gray-700 font-normal text-sm rounded-lg p-2"
        />
    );
    const renderInputHeaderAndCount = (name,value,pos,isNumber,placeholder)=>(
        <input
            type= {isNumber ? "number":"text"}
            name={name}
            value={value}
            onChange={(e) => handleChangeCounts(e, pos)}
            placeholder={placeholder}
            className="w-full border border-gray-700 font-normal text-sm placeholder-gray-800 bg-gray-50 rounded-lg p-2"
        />
    )

    const renderAchievementSection = (achievement, index) => (
        <div key={index} className="overflow-x-auto py-4">
            <table className="w-full border-collapse">
                <thead>
                    <tr>
                        <th className="border border-gray-800 p-2">Image</th>
                        <th className="border border-gray-800 p-2">Header</th>
                        <th className="border border-gray-800 p-2">Subheader</th>
                        <th className="border border-gray-800 p-2">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className='w-full'>
                        {/* Image Column */}
                        <td className="border border-gray-600 p-2">
                            <div className="w-20 h-20 border border-gray-600 bg-black p-2">
                                <img
                                    src={achievement?.icon}
                                    alt="achievement"
                                    className="w-full h-auto"
                                    style={{ maxHeight: '100px', objectFit: 'contain' }}
                                />
                            </div>
                            <FileUploadComponent
                                maxFiles={1}
                                tag={`achievement-${index}`}
                                onSetImageUrls={(files) => {
                                    if(files.length > 0){
                                        setFormData({
                                            ...formData,
                                            achievements: formData.achievements.map((item, i) => i === index ? { ...item, icon: files.pop().url } : item)
                                        });
                                    }else{
                                        toast.error("Please upload an image");
                                    }
                                }}
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                                onReset={reset}
                                className={"rounded-lg"}
                            />
                        </td>
    
                        {/* Achievement Name Column */}
                        <td className="border border-gray-600 p-2">
                            <input
                                type="text"
                                placeholder="Enter Achievement Name"
                                className="w-full p-2 border border-gray-300 rounded-md"
                                value={achievement?.header}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        achievements: formData.achievements.map((item, i) =>
                                            i === index ? { ...item, header: e.target.value } : item
                                        ),
                                    });
                                }}
                            />
                        </td>
    
                        {/* Achievement Subheader Column */}
                        <td className="border border-gray-600 p-2">
                            <input
                                type="text"
                                placeholder="Enter Achievement Subheader"
                                className="w-full p-2 border border-gray-300 rounded-md"
                                value={achievement?.subHeader}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        achievements: formData.achievements.map((item, i) =>
                                            i === index ? { ...item, subHeader: e.target.value } : item
                                        ),
                                    });
                                }}
                            />
                        </td>    
                        {/* Actions Column */}
                        <td className="border border-gray-600 p-2">
                            <button
                                onClick={() => {
                                    setFormData({
                                        ...formData,
                                        achievements: formData.achievements.filter((_, i) => i !== index),
                                    });
                                }}
                                className="text-white bg-red-500 rounded-md p-2"
                            >
                                <X />
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
    
    

    return (
        <div className="p-6 w-full space-y-5 mb-7 shadow-md rounded-md">
            <div className="w-full bg-neutral-50 shadow-lg px-4 py-6 border rounded-lg grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {renderFileUploadSection('Upload Wide Screen Background Banner', 'wideScreenBannerBg', formData?.wideScreenBannerBg)}
                {renderFileUploadSection('Upload How To Play Video File', 'howToPlayVideo', formData?.howToPlayVideo)}
                {renderFileUploadSection('Upload Mobile Screen Background Banner', 'mobileScreenBannerBg', formData?.mobileScreenBannerBg)}
                {renderFileUploadSection('Upload Main Banner', 'mainBanner', formData?.mainBanner)}
                {renderFileUploadSection('Upload Scanner Code Image', 'scannerCodeImage', formData?.scannerCodeImage)}
            </div>


            {/* Add Achievements Section */}
            <div className="w-full justify-center flex flex-col items-center space-y-3">
                <button
                    onClick={() => {
                        setFormData({
                            ...formData,
                            achievements: [...formData.achievements, { icon: '', header: '', subHeader: '' }],
                        });
                    }}
                    className="bg-red-600 justify-center flex items-center space-x-1 hover:bg-red-500 text-white font-bold py-2 px-4 rounded"
                >
                    <span>
                        Add Achievements
                    </span>
                    <Plus strokeWidth={3}/>
                </button>
                {formData.achievements.map((achievement, index) => renderAchievementSection(achievement, index))}
            </div>

            {/* Header Text */}
            {/* <div className='grid grid-cols-1 md:grid-cols-2 gap-3'>

            </div> */}

            {/* Counts */}
            <div className="w-full grid shadow-md font-bold text-lg bg-neutral-50 p-2 grid-cols-1 md:grid-cols-2 gap-3 items-center justify-center">
                <div className="w-full font-bold text-lg flex flex-col items-center space-y-3">
                    <label className="text-black">Add Header Text</label>
                    {renderInputField('header', formData.header, 'Add Header Text')}
                </div>

                {/* Bonus Text */}
                <div className="w-full flex flex-col font-bold text-lg items-center space-y-3">
                    <h1 className="text-black">Add Bonus Amount Text</h1>
                    {renderInputField('bonusText', formData.bonusText, 'Add Bonus Amount', true)}
                </div>
                {/* Active User Count */}
                <div className="w-full flex flex-col items-center space-y-3">
                    <h1 className="text-black">Add Active User/Count Text</h1>
                    {/* {renderInputField('activeUserCount.header', formData?.activeUserCount?.header, 'Add Active User Subtext')} */}
                    {renderInputHeaderAndCount('activeUserCount',formData?.activeUserCount?.header,'header',false,'Add Active User Subtext')}
                    {renderInputHeaderAndCount('activeUserCount',formData?.activeUserCount?.count,'count',true,'Add Active User Count')}

                </div>

                {/* Total Downloads */}
                <div className="w-full flex flex-col items-center space-y-3">
                    <h1 className="text-black">Add Total Downloads Text</h1>
                    {renderInputHeaderAndCount('totalDownloads',formData?.totalDownloads?.header,'header',false,'Add Total Downloads Subtext')}
                    {renderInputHeaderAndCount('totalDownloads',formData?.totalDownloads?.count,'count',true,'Add Total Downloads Count')}
                </div>

                {/* Increased User Downloads */}
                <div className="w-full flex flex-col items-center space-y-3">
                    <h1 className="text-black">Add Increased User Download Text</h1>
                    {renderInputHeaderAndCount('increaseUsersDownload',formData?.increaseUsersDownload?.header,'header',false,'Add Increased Users Download Subtext')}
                    {renderInputHeaderAndCount('increaseUsersDownload',formData?.increaseUsersDownload?.count,'count',true,'Add Increased Users Download Count')}
                </div>

                {/* Rating */}
                <div className="w-full flex flex-col items-center space-y-3">
                    <h1 className="text-black">Add Rating Text</h1>
                    {renderInputHeaderAndCount('rating',formData?.rating?.header,'header',false,'Add Rating Subtext')}
                    {renderInputHeaderAndCount('rating',formData?.rating?.count,'count',true,'Add Rating Count')}
                </div>
            </div>

            <div className="mt-6 flex justify-center">
                <button
                    onClick={UploadHomeData}
                    className="bg-gray-900 text-white p-3 rounded-md"
                >
                    Save Data
                </button>
            </div>
        </div>
    );
};

export default HomeDataUpload;
