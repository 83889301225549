import { useEffect, useState } from "react";
import { BASE_URL } from "../../Constants/constant";
import axios from "axios";
import Sidebar from "../Frame/Sidebar";
import Resolveticket from "./Resolveticket";
import { useSocketContext } from "../../Constants/SocketContext";
import ReactPaginate from "react-paginate";
import Cookies from 'js-cookie';

const Ticketsview = () => {
  const [ticket, setTicket] = useState([]);
  const [filteredTickets, setFilteredTickets] = useState([]);
  const [popup, setPopup] = useState(false);
  const [selectId, setSelectedId] = useState(null);
  const [imagePopup, setImagePopup] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [selectedImage, setSelectedImage] = useState("");
  const [statusFilter, setStatusFilter] = useState("Pending"); // Default to 'Pending'
  const { ticketCount } = useSocketContext();
  const token = Cookies.get("token");

  const handleview = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/v-1/ticket/getall?page=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTotalPages(response.data.totalPages);
      setCurrentPage(response.data.currentPage);
      setTotalCount(response.data.toatalTicketcount);
      setTicket(response.data.allticket);
      setFilteredTickets(
        response.data.allticket.filter((tic) => tic.status === "Pending")
      ); // Initialize filtered tickets
    } catch (error) {
      console.log(error);
    }
  };

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  const calculateSerialNumber = (index) => {
    return (currentPage - 1) * 5 + index + 1; 
  };

  useEffect(() => {
    handleview();
  }, [currentPage]);

  useEffect(() => {
    setFilteredTickets(ticket.filter((tic) => tic.status === statusFilter));
  }, [statusFilter, ticket]);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setImagePopup(true);
  };

  const closeImagePopup = () => {
    setImagePopup(false);
    setSelectedImage("");
  };

  const handleclose = () => {
    setPopup(false);
  };

  return (
    <Sidebar>
      <div className="xl:ml-80 text-center px-4 py-5 sm:px-6 relative">
        <h3 className="text-2xl leading-6 font-medium text-gray-900">
          User Ticket Details
        </h3>
      </div>

      <div className="xl:ml-80 px-4 mb-4 flex  gap-4">
        <div className="relative inline-block">
          <button
            onClick={() => setStatusFilter("Pending")}
            className={`px-6 py-3 rounded-lg transition-colors duration-300 ${
              statusFilter === "Pending"
                ? "bg-yellow-600 text-white shadow-lg shadow-yellow-300/50"
                : "bg-gray-200 text-gray-700 hover:bg-gray-300"
            }`}
          >
            Pending
          </button>
          {ticketCount > 0 && (
            <span className="absolute top-0 right-0 -translate-y-1/2 translate-x-1/2 bg-red-500 text-white text-xs font-bold px-2 py-1 rounded-full shadow animate-blink">
              {ticketCount}
            </span>
          )}
        </div>

        <button
          onClick={() => setStatusFilter("Resolved")}
          className={`px-4 py-2 rounded ${
            statusFilter === "Resolved"
              ? "bg-green-600 text-white"
              : "bg-gray-200 text-gray-700"
          }`}
        >
          Resolved
        </button>
      </div>

      <div className="xl:ml-80 flex flex-col table-container overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-100 sticky top-0">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Serial No
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Issue Image
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                TicketId
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                UserId
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                createdAt
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredTickets.length === 0 ? (
              <tr>
                <td colSpan={12} className="px-6 py-4 text-center">
                  <p className="text-sm text-red-500">No Tickets Details...</p>
                </td>
              </tr>
            ) : (
              filteredTickets.map((tic, index) => (
                <tr
                  key={tic._id}
                  className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
                >
                  <td className="px-6 py-4 whitespace-nowrap">
                    {calculateSerialNumber(index)}
                  </td>
                  <td>
                    <img
                      src={tic.ticketimageUrl}
                      height={"100px"}
                      width={"100px"}
                      alt="Issue"
                      className="cursor-pointer"
                      onClick={() => handleImageClick(tic.ticketimageUrl)}
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {tic.ticketId}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">{tic.userId}</td>
                  <td
                    className={`px-6 py-4 whitespace-nowrap ${
                      tic.status === "Pending"
                        ? "text-yellow-700"
                        : tic.status === "Resolved"
                        ? "text-green-500"
                        : ""
                    }`}
                  >
                    {tic.status}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {tic.createdAt.split("T")[0]} Time:
                    {tic.createdAt.split("T")[1].split(".")[0]}
                  </td>
                  <td
                    onClick={() => {
                      setPopup(true);
                      setSelectedId(tic);
                    }}
                    className="text-blue-700 cursor-pointer"
                  >
                    {tic.status === "Resolved" ? "Resolved" : "Resolve"}
                  </td>
                </tr>
              ))
            )}
          </tbody>
          <div className="fixed bottom-12 right-0 text-blue-500 mr-12  text-xl text-md  ">
            <div className="left-0 flex items-center justify-between ">
              <p>
                Showing page {currentPage} of {totalPages} &ensp;
                {currentPage * pageSize - pageSize} to{" "}
                {Math.min(currentPage * pageSize, totalCount)} of {totalCount}{" "}
                entries
              </p>
              <div className="text-blue-500  `{active? text-red-500 }` pagination">
                <ReactPaginate
                  previousLabel={""}
                  nextLabel={"."}
                  breakLabel={"...."}
                  pageCount={totalPages}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  className="flex"
                />
              </div>
            </div>
          </div>
        </table>
        {popup && (
          <Resolveticket
            handleview={handleview}
            selectId={selectId}
            onClose={handleclose}
          />
        )}
      </div>

      {imagePopup && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
          <div className="relative">
            <img
              src={selectedImage}
              alt="Popup"
              className="max-w-[1200px] max-h-[700px]"
            />
            <button
              onClick={closeImagePopup}
              className="absolute top-2 right-2 bg-red-500 text-white px-3 py-1 rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </Sidebar>
  );
};

export default Ticketsview;
