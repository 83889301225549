import { useState } from 'react';
import { useAuthContext } from "../../../Constants/context.js";
import { useSocketContext  } from "../../../Constants/SocketContext.jsx";
import { extractTime } from "../utils/extractTime.js";
import useConversation from "../zustand/useConversation.js";
import { FaCheck, FaCheckDouble } from 'react-icons/fa';

const Message = ({ message }) => {
    const { authUser } = useAuthContext();
    const { selectedConversation } = useConversation();
    const id = localStorage.getItem("id");
    const [selectedImage, setSelectedImage] = useState(null);

    if (!message) {
        return null;
    }

    const fromMe = message.senderId === id;
    const formattedTime = extractTime(message.createdAt);
    const chatClassName = fromMe ? "justify-end" : "justify-start";
    const profilePic = fromMe ? authUser.profilePic : selectedConversation?.profilePic;
    const profileName = fromMe ? authUser.name : selectedConversation?.name;
    const bubbleBgColor = fromMe ? "bg-blue-500" : "bg-gray-300";
    const textColor = fromMe ? "text-white" : "text-gray-800";
    const shakeClass = message.shouldShake ? "animate-shake" : "";

    const validImages = (message.images || []).filter(url => url && url.trim() !== '');
    const hasImages = validImages.length > 0;
    const imageCount = validImages.length;

    const getInitials = (name) => {
        if (!name) return '';
        const nameParts = name.split(' ');
        const initials = nameParts.map(part => part?.charAt(0)?.toUpperCase())?.join('');
        return initials;
    };

    const renderStatus = () => {
        if (message.status === 'delivered') {
            return <FaCheck className='text-gray-400 text-xs' />;
        } else if (message.status === 'read') {
            return <FaCheckDouble className='text-blue-400 text-xs' />;
        }
        return null;
    };

    return (
        <div className={`flex ${chatClassName} mb-4 p-2`} style={{marginLeft:"280px"}} > 
            {!fromMe && (
                <div className='flex-shrink-0 mr-2'>
                    <div className='w-10 h-10 rounded-full overflow-hidden shadow-md flex items-center justify-center bg-gray-300'>
                        {profilePic ? (
                            <img alt='User Avatar' src={profilePic} className='w-full h-full object-cover' />
                        ) : (
                            <span className='text-white text-lg font-bold'>
                                {getInitials(profileName)}
                            </span>
                        )}
                    </div>
                </div>
            )}
            <div className={`flex flex-col ${fromMe ? 'items-end' : 'items-start'} w-full`}>
                {hasImages && (
                    <div className='relative flex flex-wrap gap-2 mb-2'>
                        {validImages.slice(0, 3).map((imageUrl, index) => (
                            <div key={index} className='relative'>
                                <img
                                    src={imageUrl}
                                    alt={`Image ${index}`}
                                    onClick={() => setSelectedImage(imageUrl)}
                                    className={`max-w-[100px] max-h-[100px] rounded-lg object-cover cursor-pointer hover:opacity-80 transition-opacity ${index === 0 ? 'z-10' : 'z-0'}`}
                                    style={{ zIndex: validImages.length - index }}
                                />
                                {index === 2 && imageCount > 3 && (
                                    <div className='absolute top-0 right-0 bg-gray-800 text-white text-xs rounded-full px-2 py-1'>
                                        +{imageCount - 3}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                )}
                <div className={`p-3 rounded-lg shadow-md ${bubbleBgColor} ${textColor} ${shakeClass}  max-w-[60%] break-words`}>
                    <p className='m-0'>{message.message || "No message content"}</p>
                    <div className='absolute bottom-1 right-2'>
                        {renderStatus()}
                    </div>
                </div>
                <div className={`text-xs ${fromMe ? 'text-gray-400' : 'text-gray-600'} mt-1`}>
                    {formattedTime}
                </div>
            </div>
            {fromMe && (
                <div className='flex-shrink-0 ml-2'>
                    <div className='w-10 h-10 rounded-full text-xl  text-white overflow-hidden shadow-md flex items-center justify-center bg-blue-400'  style={{ fontWeight: '700' }}>
                      A
                    </div>
                </div>
            )}
            {selectedImage && (
                <div className='fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50' onClick={() => setSelectedImage(null)}>
                    <img src={selectedImage} alt='Full Screen' className='max-w-full max-h-full object-contain cursor-pointer' />
                </div>
            )}
        </div>
    );
};

export default Message;
