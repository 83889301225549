import { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { File, Loader, X } from 'lucide-react';
import toast from 'react-hot-toast';
import { MAIN_URL } from '../../../Constants/constant';

const FileUploadComponent = ({
    maxFiles = 5,
    tag,
    onSetImageUrls,
    isLoading,
    setIsLoading,
    onReset,
    className
}) => {
    const [files, setFiles] = useState([]); // Array of selected files
    const [loadingStates, setLoadingStates] = useState([]); // Loading state per file
    // const inputRef = useRef(null);
    const dropzoneRef = useRef(null);

    const handleFileChange = async (e) => {
        const selectedFiles = e.target.files;
        console.log("Selected Files: ", selectedFiles);

        if (!selectedFiles || selectedFiles.length === 0) return;

        const newFiles = [...files];
        const newLoadingStates = [...loadingStates];

        // Limit the number of files to the maxFiles count
        for (let i = 0; i < selectedFiles.length; i++) {
            if (newFiles.length < maxFiles) {
                newFiles.push(selectedFiles[i]);
                newLoadingStates.push(true); // Set loading state to true when uploading
            }
        }

        // Update state
        setFiles(newFiles);
        setLoadingStates(newLoadingStates);

        // Start uploading files
        handleUploadFiles(newFiles);
    };

    // Function to handle the upload of multiple files (both image and video)
    const handleUploadFiles = async (files) => {
        setIsLoading(true);
        const formData = new FormData();

        // Append each file to the FormData object
        files.forEach((file) => {
            formData.append('my_files[]', file);
        });

        try {
            const res = await axios.post(`${MAIN_URL}/api/myPrideWebsite/utils/uploadImages`, formData);
            console.log('Files uploaded successfully:', res.data);
            const urls = res.data?.results || [];
            updateUploadedFileUrls(urls);
            toast.success("Files uploaded successfully");
        } catch (error) {
            if (error.response) {
                toast.error("Error uploading files: " + error.response.data.message);
            } else if (error.request) {
                toast.error("No response received while uploading files");
            } else {
                toast.error("Error uploading files: " + error.message);
            }
            updateUploadedFileUrls([]);
        } finally {
            setIsLoading(false);
            setLoadingStates(Array(files.length).fill(false)); // Reset loading states
        }
    };

    const updateUploadedFileUrls = (urls) => {
        const newFiles = [...files];
        urls.forEach((url, index) => {
            newFiles[index] = { ...newFiles[index], url }; // Attach URL to file
        });
        setFiles(newFiles);
        onSetImageUrls(newFiles); // Notify parent about the updated file URLs
    };

    const handleRemoveFile = (index) => {
        const newFiles = [...files];
        newFiles.splice(index, 1); // Remove file from array
        setFiles(newFiles);

        const newLoadingStates = [...loadingStates];
        newLoadingStates.splice(index, 1); // Remove loading state for the file
        setLoadingStates(newLoadingStates);
    };

    // Drag and drop handlers
    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (dropzoneRef.current) {
            dropzoneRef.current.classList.add('bg-gray-100');
        }
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (dropzoneRef.current) {
            dropzoneRef.current.classList.remove('bg-gray-100');
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (dropzoneRef.current) {
            dropzoneRef.current.classList.remove('bg-gray-100');
        }

        const droppedFiles = e.dataTransfer.files;
        if (droppedFiles.length > 0) {
            handleFileChange({ target: { files: droppedFiles } });
        }
    };

    useEffect(() => {
        if (onReset) {
            setFiles([]);
            setLoadingStates([]);
        }
    }, [onReset]);

    return (
        <div className={`${className || 'flex flex-col items-center bg-white p-1 w-full'}`}>
            <h1 className="mb-4 text-lg font-medium flex justify-center items-center space-x-2">
                <File className="w-10 h-10 text-gray-500" /> : <span>{files.filter((file) => file !== '').length} / {maxFiles}</span>
            </h1>

            {/* File Upload Area */}
            <div
                ref={dropzoneRef}
                className="w-full h-32 border-2 justify-center items-center flex flex-col border-dashed border-gray-400 rounded-md p-4 text-center cursor-pointer transition-colors duration-300 hover:bg-gray-100"
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                onClick={(e) => {
                    e.stopPropagation(); // Prevent the click from propagating to parent elements
                    document.getElementById(`file-upload-${tag}`).click(); // Trigger file input click
                }}
            >
                <input
                    type="file"
                    id={`file-upload-${tag}`}
                    className='pointer-events-none hidden' // Disable interaction with the input directly
                    accept=".jpg, .jpeg, .png, .gif, .webp, .mp4, .mov, .avi, .mkv"
                    style={{ display: 'none' }}
                    multiple
                    onChange={handleFileChange}
                    disabled={files.length >= maxFiles}
                    onClick={(e) => {
                        e.stopPropagation(); // Prevent input click from propagating and triggering the div's onClick
                    }}
                />
                <label
                    // htmlFor={`file-upload-${tag}`}
                    className="flex flex-col justify-center cursor-pointer hover:scale-105 transition-all duration-500 ease-ease-in-out-expo items-center"
                >
                    <span className="mb-2 text-lg font-semibold">Drag & Drop or Click to Upload</span>
                </label>
            </div>


            {isLoading && <span className="mt-4 text-gray-500">Please wait while the files are uploading...</span>}

            <div className="mt-4 w-full">
                {/* File List */}
                {files.map((file, index) => (
                    <div key={index} className="flex justify-between gap-4 items-center p-3 border-b border-gray-200">
                        <div className="flex items-center">
                            <h2 className="text-sm font-medium">File {index + 1}</h2>
                            {file?.type?.startsWith("video/") ? (
                                <video width="100" controls>
                                    <source src={file.url} type={file.type} />
                                </video>
                            ) : (
                                <img src={file.url || URL.createObjectURL(file)} alt="file-preview" className="w-16 h-16 object-cover rounded-md" />
                            )}
                        </div>
                        {loadingStates[index] ? (
                            <span className="text-sm text-gray-500">Uploading...</span>
                        ) : (
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleRemoveFile(index);
                                }}
                                className="text-red-500 hover:text-red-700"
                            >
                                <X />
                            </button>
                        )}
                    </div>
                ))}
            </div>
            <UploadOverlay isUploading={isLoading} progress={20} />
        </div>
    );
};

// Overlay for Uploading State
const UploadOverlay = ({ isUploading }) => {
    if (!isUploading) return null; // Only render the overlay if uploading is true
  
    return (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
			<div className="bg-white p-6 rounded-md shadow-lg text-center">
				<div className="animate-pulse w-full justify-cente flex flex-col items-center text-xl text-gray-700 font-semibold">
					<p className="w-full text-center">Uploading images, Hold your Breath</p>
					<div className="mt-4">
						<Loader size={60} className="text-gray-600 animate-spin" />
					</div>
				</div>
				<div className="mt-6 relative w-full h-2 bg-gray-300 rounded-full overflow-hidden">
					{/* Shimmering loading bar */}
					<div className="animate-loading-bar absolute top-0 left-0 h-full bg-blue-600"></div>
					
					{/* Shine effect */}
					<div className="shine-effect absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent to-white opacity-30 animate-shine"></div>
				</div>
			</div>
		</div>
    );
};

export default FileUploadComponent;
