import React, { useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../Constants/constant";

const SubpaisaPaymentForm = () => {
  const [formData, setFormData] = useState({
    userId: "",
    payerName: "",
    payerEmail: "",
    payerMobile: "",
    amount: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Making the API request to initiate payment
      const response = await axios.post(`${BASE_URL}/api/v-1/subpaisapayment/initPgReq`, formData);
      const { spURL, encData, clientCode } = response.data;
     console.log(response.data,"response.data")
      if (!encData) {
        alert("Encryption data missing. Please try again.");
        return;
      }

      // Create and submit form with encData and clientCode
      const form = document.createElement("form");
      form.method = "POST";
      form.action = spURL;

      // Create hidden inputs for encData and clientCode
      const encDataInput = document.createElement("input");
      encDataInput.type = "hidden";
      encDataInput.name = "encData";
      encDataInput.value = encData;
      console.log(encData,"encData")

      const clientCodeInput = document.createElement("input");
      clientCodeInput.type = "hidden";
      clientCodeInput.name = "clientCode";
      clientCodeInput.value = clientCode;

      form.appendChild(encDataInput);
      form.appendChild(clientCodeInput);

      // Append the form and submit it
      document.body.appendChild(form);
      form.submit();
    } catch (error) {
      console.error("Payment initiation failed:", error.message);
      alert("Payment initiation failed. Please try again.");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
          <input name="userId" placeholder="userId" onChange={handleChange} />
      <input name="payerName" placeholder="Name" onChange={handleChange} />
      <input name="payerEmail" placeholder="Email" onChange={handleChange} />
      <input name="payerMobile" placeholder="Mobile" onChange={handleChange} />
      <input name="amount" placeholder="Amount" onChange={handleChange} />
    
      <button type="submit">Pay Now</button>
    </form>
  );
};

export default SubpaisaPaymentForm;
