
import React, { useState } from 'react';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useAuthContext,useUser } from '../../Constants/context'; 
import { BASE_URL } from '../../Constants/constant';
import Cookies from 'js-cookie';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { setAuthUser } = useAuthContext(); 
	const { setUserRoles } = useUser();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post(`${BASE_URL}/api/v-1/employee/login`, { email, password });
            if (response.status === 200) {
                const { id,token, name, access,refreshToken } = response.data;
                console.log(id,"login")
                setUserRoles(access,{ secure: true, httpOnly: true, sameSite: 'Strict' });
                
                   Cookies.set('refreshToken', refreshToken,{ secure: true, httpOnly: true, sameSite: 'Strict' });
                   Cookies.set('token', token,{ secure: true, httpOnly: true, sameSite: 'Strict' });
                   Cookies.set('id', id);
                   localStorage.setItem('id', id,{ secure: true, httpOnly: true, sameSite: 'Strict' });
                   Cookies.set('name', name,{ secure: true, httpOnly: true, sameSite: 'Strict' });
                   Cookies.set('userRoles', JSON.stringify(access),{ secure: true, httpOnly: true, sameSite: 'Strict' });
                
                setAuthUser({ token, name, access });
            
                navigate('/dashboard');
                toast.success("Login Successful");
            }
        } catch (error) {
            if (error.response) {
                toast.error(error.response.data.message || 'Authentication failed');
            } else {
                toast.error('An unexpected error occurred.');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleClick = () => {
        toast.error('Please contact Admin, You are not authorized to change credentials.');
      };

    return (
        <div

        className="Login  flex flex-col items-center justify-center min-w-96 mx-auto py-12 px-10 lg:px-10">
            <div className="  bg-#f9cdcd py-8 px-4  sm:px-10 rounded-3xl mt-40 mb-40" style={{ width: '620px'}}>
                <form className="space-y-12" onSubmit={handleSubmit} style={{ width: '570px' }}>
                    <div className="text">
                        <h4 className="text-4xl font-extrabold text-red-600 ">
                            WELCOME TO MY PRIDE  GAMES
                        </h4>
                    </div>
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                            Email address
                        </label>
                        <div className="mt-1">
                            <input
                                id="email"
                                name="email"
                                type="email"
                                autoComplete="email"
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="appearance-none rounded-md block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="Enter your email"
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                            Password
                        </label>
                        <div className="mt-1">
                            <input
                                id="password"
                                name="password"
                                type="password"
                                autoComplete="current-password"
                                required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="appearance-none rounded-md block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="Enter your password"
                            />
                        </div>
                    </div>
                    <div className="flex items-center justify-between">
                        <div className="flex items-center">
    
                        </div>
                        <div className="text-sm">
                            <a onClick={handleClick} className=" cursor-pointer font-medium text-blue-600 hover:text-blue-500">
                                Forgot your password?
                            </a>
                        </div>
                    </div>
                    <div className='text-3xl '>
                        <button
                            type="submit"
                            className="w-full  flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"

                            disabled={loading}
                        >
                            {loading ? <span className="loading  loading-spinner"></span> : "Login"}
                        </button>
                    </div>
          
                 

                </form>
                <Toaster />
            </div>
        </div>
    );
};

export default Login;


// import React, { useState } from 'react';
// import axios from 'axios';
// import toast, { Toaster } from 'react-hot-toast';
// import { useNavigate } from 'react-router-dom';
// import { useAuthContext, useUser } from '../../Constants/context'; 
// import { BASE_URL } from '../../Constants/constant';
// import Cookies from 'js-cookie';

// const Login = () => {
//     const [email, setEmail] = useState('');
//     const [password, setPassword] = useState('');
//     const [loading, setLoading] = useState(false);
//     const navigate = useNavigate();
//     const { setAuthUser } = useAuthContext(); 
//     const { setUserRoles } = useUser();

//     const handleSubmit = async (event) => {
//         event.preventDefault();
//         setLoading(true);
//         try {
//             const response = await axios.post(
//                 `${BASE_URL}/api/v-1/employee/login`,
//                 { email, password },
//                 { withCredentials: true }
//             );

//             if (response.status === 200) {
//                 toast.success("Login Successful");

//                 // Correctly parse userInfo from Cookies
//                 const userInfoString = Cookies.get("userInfo");
//                 if (userInfoString) {
//                     const userInfo = JSON.parse(userInfoString);

//                     // Set authentication and user roles
//                     setAuthUser({ token:userInfo.token, name:userInfo.name, access:userInfo.access });
//                     setUserRoles(userInfo.access);

//                     navigate("/dashboard");
//                 } else {
//                     toast.error("Failed to retrieve user info");
//                 }
//             }
//         } catch (error) {
//             if (error.response) {
//                 toast.error(error.response.data.message || 'Authentication failed');
//             } else {
//                 toast.error('An unexpected error occurred.');
//             }
//         } finally {
//             setLoading(false);
//         }
//     };

//     const handleClick = () => {
//         toast.error('Please contact Admin, You are not authorized to change credentials.');
//     };

//     return (
//         <div className="Login flex flex-col items-center justify-center min-w-96 mx-auto py-12 px-10 lg:px-10">
//             <div className="bg-#f9cdcd py-8 px-4 sm:px-10 rounded-3xl mt-40 mb-40" style={{ width: '620px' }}>
//                 <form className="space-y-12" onSubmit={handleSubmit} style={{ width: '570px' }}>
//                     <div className="text">
//                         <h4 className="text-4xl font-extrabold text-red-600">
//                             WELCOME TO MY PRIDE GAMES
//                         </h4>
//                     </div>
//                     <div>
//                         <label htmlFor="email" className="block text-sm font-medium text-gray-700">
//                             Email address
//                         </label>
//                         <div className="mt-1">
//                             <input
//                                 id="email"
//                                 name="email"
//                                 type="email"
//                                 autoComplete="email"
//                                 required
//                                 value={email}
//                                 onChange={(e) => setEmail(e.target.value)}
//                                 className="appearance-none rounded-md block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//                                 placeholder="Enter your email"
//                             />
//                         </div>
//                     </div>
//                     <div>
//                         <label htmlFor="password" className="block text-sm font-medium text-gray-700">
//                             Password
//                         </label>
//                         <div className="mt-1">
//                             <input
//                                 id="password"
//                                 name="password"
//                                 type="password"
//                                 autoComplete="current-password"
//                                 required
//                                 value={password}
//                                 onChange={(e) => setPassword(e.target.value)}
//                                 className="appearance-none rounded-md block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//                                 placeholder="Enter your password"
//                             />
//                         </div>
//                     </div>
//                     <div className="flex items-center justify-between">
//                         <div className="text-sm">
//                             <a onClick={handleClick} className="cursor-pointer font-medium text-blue-600 hover:text-blue-500">
//                                 Forgot your password?
//                             </a>
//                         </div>
//                     </div>
//                     <div className='text-3xl'>
//                         <button
//                             type="submit"
//                             className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//                             disabled={loading}
//                         >
//                             {loading ? <span className="loading loading-spinner"></span> : "Login"}
//                         </button>
//                     </div>
//                 </form>
//                 <Toaster />
//             </div>
//         </div>
//     );
// };

// export default Login;




