import { createContext, useState, useEffect, useContext } from "react";
import io from "socket.io-client";
import { useAuthContext } from "./context";

const SocketContext = createContext();

export const useSocketContext = () => {
    return useContext(SocketContext);
};

export const SocketContextProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);
    const [messages, setMessages] = useState([]);
    const [onlineUsers, setOnlineUsers] = useState([]);
    // const [user, setUser] = useState([]);
    const [data, setData] = useState([]);
    const [fetchdata, setFetchdata] = useState([]);

    const { authUser } = useAuthContext();

    useEffect(() => {
        let socketInstance;

        if (authUser && authUser.token) {
            const id = localStorage.getItem('id');
            if (!id) {
                console.error("No user ID found in localStorage");
                return;
            }

            socketInstance = io("http://localhost:4000", {
                query: { userId: id },
                transports: ['websocket'],
            });

            socketInstance.on("connect", () => {
                console.log("Socket connected with id:", socketInstance.id);
                socketInstance.emit("MessageFromReact",{data:"Hello"})
            });

            socketInstance.on("connect_error", (error) => {
                console.error("Socket connection error:", error);
            });

            socketInstance.on("disconnect", () => {
                console.log("Socket disconnected");
            });

            socketInstance.on("getOnlineUsers", (users) => {
                console.log("Received online users1:", users);
                setOnlineUsers(users);
            });

            // socketInstance.on("AllUsers", (users) => {
            //     console.log("Received all users1:", users);
            //     setUser(users);
            // });

            socketInstance.on("Dashboard", (data) => {
                console.log("Received Dashboard Data:", data);
                setData(data);
            });

            socketInstance.on("Fetchmessage", (fetchdata) => {
                console.log("Fetchmessage:", fetchdata);
                setFetchdata(fetchdata);
            });
            
            
            socketInstance.on("newMessage", (message) => {
                console.log("newMessage111:", message); 
                setMessages((prevMessages) => [...prevMessages, message]);
            });

            setSocket(socketInstance);
        } else {
            if (socket) {
                socket.disconnect();
                setSocket(null);
                console.log("Socket disconnected due to lack of authUser");
            }
        }

        return () => {
            if (socketInstance) {
                socketInstance.disconnect();
                console.log("Socket disconnected on cleanup");
            }
        };
    }, [authUser]);

    const sendMessage = (message) => {
        if (socket) {
            socket.emit("sendMessage", message);
        }
    };

    return (
        <SocketContext.Provider value={{fetchdata,data,socket, onlineUsers,messages, sendMessage }}>
            {children}
        </SocketContext.Provider>
    );
};


