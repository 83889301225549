import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import AdminDashboard from "./Components/Dashboard/AdminDashboard.jsx";
import Login from "./Components/Login/Login.jsx";
import { Employee } from "./Components/Employee/Employees.jsx";
import './App.css';
import { Toaster } from "react-hot-toast";
import Banner from "./Components/Banner/Banner.jsx";
import { UserMain } from "./Components/Users/UserMain.jsx";
import { ViewDetails } from "./Components/Users/ViewDetails.jsx";
import { DepositDetils } from "./Components/Users/DepositDetils.jsx";
import { WalletTtransaction } from "./Components/Users/Wallettransaction.jsx";
import { JoinedLegue } from "./Components/Users/JoinedLegue.jsx";
import { ResultData } from "./Components/Users/ResultData.jsx";
import { WithdrawalStatus } from "./Components/Users/Withdrawal.jsx";
import { VerificationUser } from "./Components/Users/VerifyUser.jsx";
import { RefferalDetails } from "./Components/Users/RefferalDetails.jsx";
import { ReportDetails } from "./Components/Users/ReportDetails.jsx";
import { Report } from "./Components/Report/Report.jsx";
import Winner_Timer from "./Components/Gametemplates/Winnercash_timerdata.jsx";
import { PushNotification } from "./Components/PushNotification/PushNotification.jsx";
import { useAuthContext } from "./Constants/context.js";
// import PaymentForm from "./Components/Cashfree/payments.jsx";
import { AllWithdrawals } from "./Components/Cashfree/Withdrawal.jsx";
import Tickets from "./Components/FAQ/Getalltickets.jsx";
import Ticketsview from "./Components/Tickets/Ticketsview.jsx";
import { AllDeposites } from "./Components/Deposite/Totaldeposit.jsx";
import { Getpoolsdata } from "./Components/PoolsData/Getpoolsdata.jsx";
import MainWebsiteTabView from "./Components/Websites/MainWebsiteTabView.jsx";
import LudoWebsiteTabView from "./Components/Websites/LudoWebsiteTabView.jsx";
import SpinWheelTable from "./Components/Spinwheels/SpinwheelDetails.jsx";
import SpinData from "./Components/Spinwheels/SpinsData.jsx";
import SpinMain from "./Components/Spinwheels/SpinMain.jsx";
import FreeSpin from "./Components/Spinwheels/FreeSpin.jsx";

function App() {
  const { authUser } = useAuthContext(); 
  return (
    <div className="App">
    <Toaster />
        <BrowserRouter>
            <Routes>
        
    {/* 
            <Route path="/Dashboard/response" element={authUser ? <PaymentResponse /> : <Navigate to={"/"} />} />   
                <Route path="/Dashboard" element={authUser ? <SubpaisaPaymentForm /> : <Navigate to={"/"} />} />      */}
            
          
            <Route path="/Dashboard" element={authUser ? <AdminDashboard /> : <Navigate to={"/"} />} /> 
            <Route path="/Spinwheel" element={authUser ? <SpinMain /> : <Navigate to={"/"} />} />      
            <Route path="/" element={authUser ? <Navigate to='/Dashboard' /> : <Login />}  />    
            <Route path="/user_details" element={authUser ?<UserMain/>: <Navigate to={"/"} />} />
            <Route path="/report" element={authUser ?<Report/>: <Navigate to={"/"} />} />
            <Route path="/Employee" element={authUser ?<Employee/>: <Navigate to={"/"} />} />
            <Route path="/Banner" element={authUser ?<Banner/>: <Navigate to={"/"} />} />
            <Route path="/VerificationList" element={authUser ?<UserMain/>: <Navigate to={"/"} />} />
        
            <Route path="/deposite_details/:userId" element={authUser ?<DepositDetils/>: <Navigate to={"/"} />} />
            <Route path="/wallet_transaction/:userId" element={authUser ?<WalletTtransaction/>: <Navigate to={"/"} />} />
            <Route path="/joined_games/:userId" element={authUser ?<JoinedLegue/>: <Navigate to={"/"} />} />
            <Route path="/result_data/:userId" element={authUser ?<ResultData/>: <Navigate to={"/"} />} />
            <Route path="/withdrawal_details/:userId" element={authUser ?<WithdrawalStatus/>: <Navigate to={"/"} />} />
            <Route path="/verification_details/:userId" element={authUser ?<VerificationUser/>: <Navigate to={"/"} />} />
            <Route path="/refferal_details/:userId" element={authUser ?<RefferalDetails/>: <Navigate to={"/"} />} />
            <Route path="/report_details/:userId" element={authUser ?<ReportDetails/>: <Navigate to={"/"} />} />
            <Route path="/game_template" element={authUser ?<Winner_Timer/>: <Navigate to={"/"} />} />
            <Route path="/dashboard/notification" element={authUser ?<PushNotification/>: <Navigate to={"/"} />} />
            <Route path="/view_user_details/:userId" element={authUser ?<ViewDetails/>: <Navigate to={"/"} />} />
              <Route path="/Withdraw" element={authUser ?<AllWithdrawals/>: <Navigate to={"/"} />} /> 
            <Route path="/faq" element={authUser ?<Tickets/>: <Navigate to={"/"} />} /> 
            <Route path="/tickets" element={authUser ?<Ticketsview/>: <Navigate to={"/"} />} /> 
            <Route path="/deposites" element={authUser ? <AllDeposites /> : <Navigate to={"/"} />} />  
            <Route path="/pools_details" element={authUser ? <Getpoolsdata /> : <Navigate to={"/"} />} />

            <Route path="/mainWebsite" element={authUser ? <MainWebsiteTabView /> : <Navigate to={"/"} />} />
            
            <Route path="/ludoWebsite" element={authUser ? <LudoWebsiteTabView /> : <Navigate to={"/"} />} />
            <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </BrowserRouter>
        </div>
    );
}

export default App;
