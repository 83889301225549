
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../Constants/constant';

const RoomDetailsPopup = ({ room, onClose, user }) => {
    const [showConfirmPopup, setShowConfirmPopup] = useState(false);
    const [selectedReport, setSelectedReport] = useState(null);
    const [reportList, setReportList] = useState([]);
    const [resolveMessage, setResolveMessage] = useState('');
    const token = localStorage.getItem('token');
    // Fetch reports on component mount
    useEffect(() => {
        const fetchReports = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/api/v-1/report/getallreport`,{
                    headers: {
                        'Authorization': `Bearer ${token}`,
                      },  
                });
                setReportList(response.data.report || []);
            } catch (error) {
                console.error('Error fetching reports:', error);
            }
        };

        fetchReports();
    }, []);

    const handleUpdateStatus = async () => {
        if (!selectedReport) return;

        try {
            await axios.put(`${BASE_URL}/api/v-1/report/updatereportbyid/${selectedReport._id}`, {
                status: 'resolved',
                resolvemessage: resolveMessage
            },{
                headers:{
                    'Authorization': `Bearer ${token}`,   
                }
            });
            setShowConfirmPopup(false);
            alert('Report updated successfully.');
            onClose();
        } catch (error) {
            console.error('Error updating report:', error);
            alert('Failed to update the report.');
        }
    };

    const openConfirmPopup = (report) => {
        setSelectedReport(report);
        setShowConfirmPopup(true);
    };

    if (!room) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-70 z-50">
            <div className="bg-white xl:ml-40 mr-16 p-6 rounded-xl shadow-xl w-full">
                <h2 className="text-2xl font-bold text-gray-800 mb-6">
                    Room Details: <span className="text-blue-600">#{room.roomId}</span>
                </h2>
                <table className="w-full border border-gray-300">
                    <thead className="bg-gray-100">
                        <tr>
                            <th className="border px-4 py-2 text-left text-gray-700">Amount</th>
                            <th className="border px-4 py-2 text-left text-gray-700">Number of Players</th>
                            <th className="border px-4 py-2 text-left text-gray-700">Mode Type</th>
                            <th className="border px-4 py-2 text-left text-gray-700">Players</th>
                            <th className="border px-4 py-2 text-left text-gray-700">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="border-t">
                            <td className="border px-4 py-3 text-gray-800">{room.joining_amount}</td>
                            <td className="border px-4 py-3 text-gray-800">{room.no_of_players}</td>
                            <td className="border px-4 py-3 text-gray-800">{room.mode_type}</td>
                            <td className="border px-4 py-3 text-gray-800">
                                {room.players.map((player, index) => (
                                    <div key={index} className="mb-4">
                                        <table className="w-full border border-gray-300 mb-4">
                                            <thead className="bg-gray-200">
                                                <tr>
                                                    <th className="border px-2 py-1 text-left text-gray-700">Game Name</th>
                                                    <th className="border px-2 py-1 text-left text-gray-700">Client ID</th>
                                                    <th className="border px-2 py-1 text-left text-gray-700">Win/Loss Status</th>
                                                    <th className="border px-2 py-1 text-left text-gray-700">Win/Loss Amount</th>
                                                    <th className="border px-2 py-1 text-left text-gray-700">Pieces</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="border px-2 py-1 font-semibold">{player.game_name}</td>
                                                    <td className="border px-2 py-1 font-semibold">{player.clientId}</td>
                                                    <td className="border px-2 py-1 font-semibold">{player.winorloss}</td>
                                                    <td className="border px-2 py-1 font-semibold">{player.winamount}</td>
                                                    <td className="border px-2 py-1 font-semibold">
                                                        {player.pieces.map((piece, pieceIndex) => (
                                                            <div key={pieceIndex} className="text-sm text-gray-600 mb-2">
                                                                P1: {piece.p1}  |
                                                                P2: {piece.p2}   |
                                                                P3: {piece.p3}   |
                                                                P4: {piece.p4}  |
                                                                <hr />
                                                                <div className="font-semibold">Total Points: {piece.totalpoints}</div>
                                                            </div>
                                                        ))}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                ))}
                            </td>
                            <td className="border px-4 py-3 text-gray-800">
                                {user?.Report?.map((usergame, index) => (
                                    usergame.roomId === room.roomId && (
                                    <div key={usergame._id}>
                                        <span className={`px-6 py-4 ${usergame.status === 'pending' ? 'text-red-500' :
                                            usergame.status === 'resolved' ? 'text-green-500' : ''
                                            }`}>
                                            {usergame.status}
                                        </span>
                                        {usergame.status === 'pending' && (
                                            <button
                                                onClick={() => openConfirmPopup(usergame)}
                                                className="ml-2 px-2 py-1 bg-blue-500 text-white rounded"
                                            >
                                                Resolve
                                            </button>
                                        )}
                                    </div>
                                )))}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <h1 className='text-2xl font-bold text-gray-800 mb-6'>Reported Details</h1>
                <div className='flex'>
                    {user?.Report?.map((usergame) => (
                        usergame.roomId === room.roomId && (
                            <div className='flex text-gray-800 font-semibold mr-10' key={usergame._id}>
                                <div className='mr-10'>
                                    Reported Message:
                                    <br />
                                    <textarea
                                        className='text-red-500 text-justify'
                                        cols={100}
                                        rows={6}
                                        value={usergame.message}
                                        readOnly
                                    />
                                </div>

                                <div className='text-1xl text-gray-800 mb-2 font-semibold'>
                                    Resolve Message:
                                    <br />
                                    <textarea
                                        className='text-green-500 text-justify border'
                                        placeholder={usergame.resolvemessage ? `${usergame.resolvemessage}` : 'Please Enter Proper response'}
                                        cols={100}
                                        rows={6}
                                        value={resolveMessage}
                                        onChange={(e) => setResolveMessage(e.target.value)}
                                    />
                                </div>
                            </div>
                        )
                    ))}
                </div>

                <div className="mt-6 text-right">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-200"
                    >
                        Close
                    </button>
                </div>
            </div>

            {showConfirmPopup && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-70 z-50">
                    <div className="bg-white p-6 rounded-xl shadow-xl">
                        <h2 className="text-xl font-bold text-gray-800 mb-4">
                            Confirm Status Update
                        </h2>
                        <p className="text-gray-600 mb-6">
                            Are you sure you want to resolve this report?
                        </p>
                        <div className="flex justify-end">
                            <button
                                onClick={() => setShowConfirmPopup(false)}
                                className="px-4 py-2 bg-gray-300 text-gray-800 rounded-lg mr-4 hover:bg-gray-400 transition duration-200"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleUpdateStatus}
                                className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition duration-200"
                            >
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default RoomDetailsPopup;
