import React, { useState } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../Constants/constant';
import Cookies from 'js-cookie';
const Updatesubfaq = ({ faq, fetchAdds, selectedsubFaq, selectedFaq1, onClose }) => {
  const [question, setQuestion] = useState(faq?.question || '');
  const [answer, setAnswer] = useState(faq?.answer || '');
  const [url, setUrl] = useState(faq?.url || '');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    try {
      const token = Cookies.get('token');
      const response = await axios.put(
        `${BASE_URL}/api/v-1/faq/updatesubFaq/${selectedFaq1?._id}`,
        {
        subCategoryId: selectedsubFaq?._id,
          faqId: faq?._id,
          question,
          answer,
          url,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setSuccess(response.data.message);
        onClose();
        fetchAdds();
      }
    } catch (error) {
      setError(error.response?.data?.message || 'Something went wrong');
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-4">
      <div className="bg-white rounded-xl shadow-2xl p-6 w-full max-w-lg relative transform transition-all duration-300 hover:scale-105">
        <h2 className="text-3xl font-semibold mb-6 text-center text-gray-800">
          Update FAQ
          <button
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 transition-colors"
            onClick={onClose}
            aria-label="Close"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </h2>

        <form onSubmit={handleSubmit} className="space-y-4">
          {error && (
            <div className="text-red-500 bg-red-100 p-2 rounded-md text-sm text-center">
              {error}
            </div>
          )}
          {success && (
            <div className="text-green-500 bg-green-100 p-2 rounded-md text-sm text-center">
              {success}
            </div>
          )}
          <div>
            <label className="block text-gray-700 text-sm font-medium mb-2">
              Question
            </label>
            <input
              type="text"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              placeholder="Enter Question"
              className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700 text-sm font-medium mb-2">
              Answer
            </label>
            <textarea
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
              placeholder="Enter Answer"
              className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              rows="4"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700 text-sm font-medium mb-2">
              URL
            </label>
            <input
              type="text"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              placeholder="Enter URL"
              className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="px-6 py-3 bg-yellow-600 text-white font-medium rounded-lg shadow-md hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Update FAQ
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Updatesubfaq;
