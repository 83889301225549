import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import toast from 'react-hot-toast';
import { deleteCarousalImage, getHeroCarousal, setCarousalImage } from '../../../Redux/slices/websiteSlice';
import ImageUploadSection from './ImageUploadSection';

const HomeCarousal = () => {
    const dispatch = useDispatch();

    const{ heroScreenCarousal } = useSelector(state => state.website);



    const[resetImageUpload,setResetImageUpload] = useState(false);
    const[wideScreenCarousal,setWideScreenCarousal] = useState([]);
    const[smallScreenCarousal,setSmallScreenCarousal] = useState([]);
    const[uploadingImages,setUploadingImages] = useState({type:'',imageArray:[]});
    const[isLoading,setIsLoading] = useState(false);



    const uploadWideImageToCarousal = async (e)=>{
        e.preventDefault();
        try {
            await dispatch(setCarousalImage(uploadingImages));
            toast.success("Uploading Success");
            setUploadingImages(null);
            
            setResetImageUpload(true);
            setTimeout(() => {
                setResetImageUpload(false);
            }, 400);
        } catch (error) {
            console.error("Error uploading: " , error);
            toast.error("Error uploading: " + error.message);
        }finally{
            dispatch(getHeroCarousal())
        }
    }
    const deleteCarousalImageByIndex = async (type,index) => {
        // console.log("Deleting Image: ",type,index);
        try {
            await dispatch(deleteCarousalImage({type,index}));
            toast.success("Successfully deleted Image");
        } catch (error) {
            console.error("Error deleting: " , error);
            toast.error("Error deleting: " + error.message);
        }finally{
            dispatch(getHeroCarousal())
        }
    };
    const uploadSmallImageToCarousal = async (e)=>{
        e.preventDefault();
        try {
            await dispatch(setCarousalImage(uploadingImages));
            toast.success("Uploading Success ");
            setUploadingImages(null);
            setResetImageUpload(true);
            setTimeout(() => {
                setResetImageUpload(false);
            }, 400);
        } catch (error) {
            console.error("Error uploading: " , error);
            toast.error("Error uploading: " + error.message);
        }finally{
            dispatch(getHeroCarousal())
        }
    }
    useEffect(()=>{
        dispatch(getHeroCarousal())
    },[dispatch])
    useEffect(()=>{
        if(heroScreenCarousal){
            setWideScreenCarousal(heroScreenCarousal.find(s => s.tag === 'Wide')?.imageUrl || [])
            setSmallScreenCarousal(heroScreenCarousal.find(s => s.tag === 'Small')?.imageUrl || [])
            toast.success("Successfully fetched Carousal!");
        }
    },[heroScreenCarousal])
    console.log("Screen Carousal ",wideScreenCarousal,smallScreenCarousal)
    return <ImageUploadSection
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        uploadingImages={uploadingImages}
        setUploadingImages={setUploadingImages}
        uploadWideImageToCarousal={uploadWideImageToCarousal}
        uploadSmallImageToCarousal={uploadSmallImageToCarousal}
        resetImageUpload={resetImageUpload}
        wideScreenCarousal={wideScreenCarousal}
        smallScreenCarousal={smallScreenCarousal}
        deleteCarousalImageByIndex = {(type,index)=>{
            console.log("Deleting Image: ",type,index);
            deleteCarousalImageByIndex(type,index);
        }}
    />
};
  

export default HomeCarousal