import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchRequest, fetchSuccess, fetchFailure, clearResults } from '../slices/SearchSlice.js';
import { getApi } from '../Apirequest/searchApiRequest.js';

function* fetchDataSaga(action) {
  const { searchTerm } = action.payload;

  if (!searchTerm.trim()) {
    yield put(clearResults());
    return;
  }

  try {
    const token = localStorage.getItem('token');
    if (!token) {
      yield put(fetchFailure('Authentication token is missing.'));
      return;
    }

    const response = yield call(getApi, `api/v-1/search/get`, {
      params: { q: searchTerm },
    
    });

    if (response.status === 200 && response.data && response.data.results) {
      yield put(fetchSuccess({ results: response.data.results.user || [] }));
    } else {
      const errorMessage =
        response.data?.message || 'An unexpected error occurred while fetching data.';
      yield put(fetchFailure(errorMessage));
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'An error occurred';
    yield put(fetchFailure(errorMessage));
  }
}

function* watchSearchSaga() {
  yield takeLatest(fetchRequest.type, fetchDataSaga);
}

export default watchSearchSaga;
