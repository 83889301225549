import { Edit, X } from 'lucide-react';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAllContactQuery, removeContactQuery, sendAdminMail } from '../../../Redux/slices/websiteSlice';
import ConfirmDeletePopup from '../ConfirmDeletePopup';

const ContactQuery = () => {
    const dispatch = useDispatch();
    const { AllContactQuery } = useSelector(state => state.website);

    const [deletingContactId,setDeletingContactId] = useState(null);

    const [isModalOpen, setIsModalOpen] = useState(false); // State to handle modal visibility
    const [selectedContactQuery, setSelectedContactQuery] = useState(null);



    const openModal = (query) => {
        setSelectedContactQuery(query);
        setIsModalOpen(true);
    };
    const handleAction = async (action, params) => {
        // setIsActionComplete(false); // Indicate that an action is in progress
        await dispatch(action(params)); // Dispatch the provided action with its parameters
        // setIsActionComplete(true); // Action is complete
        dispatch(getAllContactQuery());
    };
    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedContactQuery(null);
    };
    const handleSave = async (mailData) => {
        await handleAction(sendAdminMail,mailData);
    };
    const delContactQuery = async (id) => {
        await handleAction(removeContactQuery,{queryId:id});
        setDeletingContactId(null);
    };
    useEffect(()=>{
        dispatch(getAllContactQuery());
    },[])
    return (
        <div className="overflow-x-auto w-full bg-white mt-3 ">
            <TableHeader />
            {/* Table Rows */}
            {AllContactQuery.map((query, index) => (
                <div key={query._id || index} className="hover:bg-gray-100">
                    <TableRow query={query}
                        editJobApplication={()=>{
                            openModal(query);
                        }}
                        removeJobApplication={()=>{
                            setDeletingContactId(query._id);
                        }} 
                    />
                </div>
            ))}
            {/* Modal for editing job */}
            {
                isModalOpen && <EditJobModal
                    query={selectedContactQuery}
                    onClose={closeModal}
                    onSave={handleSave}
                />
            }
            <ConfirmDeletePopup
                isOpen={deletingContactId !== null}
                onClose={() => setDeletingContactId(null)}
                onCancel={() => setDeletingContactId(null)}
                onConfirm={() => {
                    if (deletingContactId) {
                        delContactQuery(deletingContactId);
                    }
                }}
            
            />
            
        </div>
    )
}
const TableHeader = () => {
    return (
        <div className={`grid grid-cols-6 gap-5 p-4 border font-semibold text-black shadow-md bg-gray-200 rounded-t-lg`}>
            <div className="px-4 py-2 text-base text-center">Full Name</div>
            <div className="px-4 py-2 text-base text-center">Email</div>
            <div className="px-4 py-2 text-base text-center">Phone Number</div>
            <div className="px-4 py-2 text-base text-center">Contacted From</div>
            <div className="px-4 py-2 text-base text-center">Query Message</div>
            <div className="px-4 py-2 text-base text-center">Action</div>
        </div>
    );
};

const TableRow = ({ query,editJobApplication ,removeJobApplication}) => {
    console.log("Query: ",query);
    return (
        <div className={`grid grid-cols-6 gap-5 p-4 border-b hover:bg-gray-50 border justify-center items-center`}>
            <div className="px-4 py-2 text-center text-sm">{query?.fullName}</div>
            <div className="px-4 py-2 text-center truncate text-sm">{query?.email}</div>
            <div className="px-4 py-2 text-center text-sm">{query?.phone}</div>
            <div className="px-4 py-2 text-center truncate text-sm">{query?.website}</div>
            <div className="px-4 break-words py-2 text-center text-sm">
                <p>{query?.message}</p>
            </div>
            <div className="px-4 py-2 text-center text-sm space-x-2 justify-center items-center flex">
                <button onClick={editJobApplication} className='bg-green-600 p-2 rounded-md text-white transition-all duration-300 hover:bg-green-700 ease-ease-in-out-expo hover:scale-105'>
                    <Edit/>
                </button>
                <button onClick={removeJobApplication} className='bg-red-600 p-2 rounded-md text-white transition-all duration-300 hover:bg-red-700 ease-ease-in-out-expo hover:scale-105'>
                    <X/>
                </button>
            </div>
        </div>
    );
};
const EditJobModal = ({ query, onClose, onSave }) => {
    const optionsValues = [
        "Pending",
        "Resolved",
    ]
    const [formData, setFormData] = useState({queryId:query?._id,email:query?.email,subject:'',message:'',status:''});

    const handleChange = (e) => {
        const { name, value} = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value}));
    };

    const handleSave = () => {

        onSave(formData); // Save the updated data
        onClose(); // Close the modal
    };
    // console.log("Form Data: ",formData);
    if (!query) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg w-1/2 space-y-3 max-h-[700px] overflow-y-auto relative">
                <button
                    onClick={onClose}
                    className="ml-2 top-2 right-5 p-4 rounded-lg"
                >
                    <X/>
                </button>
                <div className='w-full justify-center items-center p-3 flex bg-gray-500 mb-4'><h2 className="text-xl text-white text-center font-semibold">Query Details</h2></div>
                <div className="space-y-4">
                    <div>
                        <label className="block text-sm font-semibold mb-2">Full Name</label>
                        <p className='border p-2 w-full h-fit'>{query?.fullName}</p>
                    </div>
                    <div>
                        <label className="block text-sm font-semibold mb-2">Phone Number</label>
                        <p className='border p-2 w-full h-fit'>{query?.phone}</p>
                    </div>
                    <div>
                        <label className="block text-sm font-semibold mb-2">Contact From</label>
                        <p className='border p-2 w-full h-fit'>{query?.website}</p>
                    </div>
                    <div>
                        <label className="block font-semibold mb-2">Email</label>
                        <p className='border p-2 w-full h-fit'>{query?.email}</p>
                    </div>
                    <div>
                        <label className="block text-sm font-semibold mb-2">Message</label>
                        <p className='border p-2 w-full h-fit'>{query?.message}</p>
                    </div>
                    <div className='w-full justify-start space-y-5 items-center p-3 flex flex-col mb-4'>
                        <h1 className="text-xl text-black text-center font-semibold">Send Mails To Resolved</h1>
                        <h2>Status</h2>
                        <select
                            className='h-10 border border-gray-800 rounded-md p-2'
                            onChange={(e)=> setFormData({...formData,status:e.target.value})}
                        >
                            {
                                optionsValues.map((opt, index) => (
                                    <option key={index} value={opt}>
                                        {opt}
                                    </option>
                                ))
                            }
                        </select>
                        <h2>Subject</h2>
                        <input
                            type='text'
                            id='subject'
                            name='subject'
                            value={formData.subject}
                            onChange={handleChange}
                            placeholder='Add your Subject for the Reply'
                            className='w-full border p-2 placeholder-gray-500 bg-gray-50 border-gray-900 rounded-md'
                        />
                        <h2>Message</h2>
                        <textarea
                            id='message'
                            name='message'
                            placeholder='Add your Message for the Reply'
                            onChange={handleChange}
                            className='w-full border p-2 placeholder-gray-500 bg-gray-50 border-gray-900 rounded-md'
                            rows={"6"}
                            value={formData.message}
                        />
                        
                    </div>
                    
                    <div className="mt-4 flex justify-center items-center w-full">
                        <button
                            onClick={handleSave}
                            className="bg-red-600 text-white py-2 px-4 rounded-lg"
                        >
                            Send Message
                        </button>
                        {/*  */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactQuery