import axios from "axios";
import React, { useEffect, useState } from "react";
import Sidebar from "../Frame/Sidebar";
import { BASE_URL } from "../../Constants/constant";
import AddWinner_Timer from "./Add_Winnercash_timerdata.jsx";
import toast, { Toaster } from "react-hot-toast";
import AddFixed_TimerData from "./Add_FixedTimerData.jsx";
import AddContactdetails from "./AddContactdetaisl.jsx";
import AddappDetails from "./AppDetails.jsx";
import Updatemaintainance from "./Update_maintainance.jsx";
import Cookies from 'js-cookie';
import BonusTable from "./Add_Bonus.jsx";
import WithdrawallimitTable from "./Withdrawallimit.jsx";

const Winner_Timer = () => {
  const [points, setPoints] = useState([]);
  const [list, setList] = useState([]);
  const [timer, setTimer] = useState([]);
  const [timerfixed, setTimerfixed] = useState([]);
  const [percentage, setPercentage] = useState([]);
  const [movepoint, setMovepoint] = useState([]);
  const [unlock, setUnlock] = useState([]);
  const [contact, setContact] = useState([]);
  const [showBannerForm, setShowBannerForm] = useState(false);
  const [showBannerForm1, setShowBannerForm1] = useState(false);
  const [showBannerForm2, setShowBannerForm2] = useState(false);
  const [showBannerForm3, setShowBannerForm3] = useState(false);
  const [showBannerForm4, setShowBannerForm4] = useState(false);
  const [activeTable, setActiveTable] = useState("");
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmlockOpen, setConfirmlockOpen] = useState(false);
  const [appdetails, setAppdetails] = useState([]);
  const [maintainance, setMaintainance] = useState([]);

  const [selectedPoint, setSelectedPoint] = useState(null);

  const [selectedMode, setSelectedMode] = useState({
    mode: "",
    currentStatus: "",
  });
  const token = Cookies.get("token");

  const fetchPoints = async () => {
    try {
      const endpoints = [
        "/api/v-1/RankingPoints/FindAllRankingPoints",
        "/api/v-1/RoomCash/GetRoomCash",
        "/api/v-1/WinningCashOut/gettimer",
        "/api/v-1/WinningCashOut/getWiningCash",
        "/api/v-1/LudoPoints/getLudoPoints",
        "/api/v-1/fixedData/get",
        "/api/v-1/contactdetail/get",
        "/api/v-1/unlock/get",
        "/api/v-1/app_details/get",
        "/api/v-1/serverMaintenace/get",
    
      ];
  
      const headers = {
        Authorization: `Bearer ${token}`,
      };
  
      // Fetch all requests in parallel
      const responses = await Promise.all(
        endpoints.map((endpoint) => axios.get(`${BASE_URL}${endpoint}`, { headers }))
      );
  
      // Extracting data from responses
      const [
        rankingPoints,
        roomCash,
        timerData,
        winningCash,
        ludoPoints,
        fixedData,
        contactDetails,
        unlockData,
        appDetails,
        maintenance,
      ] = responses.map((response) => response.data);
  
      // Updating state
      setPoints(rankingPoints?.message?.[0]?.RankPoints || []);
      setList(roomCash?.[0]?.ROOM_TYPE || []);
      setTimer(timerData?.[0]?.TimerD || []);
      setPercentage(winningCash || []);
      setMovepoint(ludoPoints?.Ludopoints || []);
      setTimerfixed(fixedData?.fixedData?.[0]?.TimerD || []);
      setContact(contactDetails?.contactdetails || []);
      setUnlock(unlockData?.unlockData || []);
      setAppdetails(appDetails?.details || []);
      setMaintainance(maintenance || []);
    } catch (error) {
      console.error("Error fetching Points:", error);
  
      // Resetting state on error
      setPoints([]);
      setList([]);
      setTimer([]);
      setPercentage([]);
      setMovepoint([]);
      setTimerfixed([]);
      setUnlock([]);
      setAppdetails([]);
      setMaintainance([]);
 
    }
  };




  const handleUnlock = async (mode, currentStatus) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/v-1/unlock/create`,
        {
          [mode]: currentStatus === "Locked" ? "UnLocked" : "Locked",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      fetchPoints();
    } catch (error) {}
  };

  useEffect(() => {
    const storedActiveTable = Cookies.get("activeTable");
    if (storedActiveTable) {
      setActiveTable(storedActiveTable);
    } else {
      setActiveTable("points");
    }
    fetchPoints();
  }, []);

  useEffect(() => {
    Cookies.set("activeTable", activeTable);
  }, [activeTable]);

  const handleDeleteClick = (point) => {
    setSelectedPoint(point); // Store the full point object
    setConfirmDialogOpen(true);
  };

  const handleDelete = async () => {
    if (!selectedPoint) return;

    try {
      await axios.delete(`${BASE_URL}/api/v-1/template/delete`, {
        data: { amount: selectedPoint.AMOUNT },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      fetchPoints();
      toast.success("Deleted successfully");
      setPoints((prevPoints) =>
        prevPoints.filter((point) => point.AMOUNT !== selectedPoint.AMOUNT)
      );

      setConfirmDialogOpen(false);
      setSelectedPoint(null); // Reset after deletion
    } catch (error) {
      toast.error("Error deleting template");
    }
  };

  const handleDeleteFixedData = async (UniqueId) => {
    try {
      await axios.delete(
        `${BASE_URL}/api/v-1/fixedData/delete`,
        {
          data: { UniqueId },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      fetchPoints();
      toast.success("Delete successfully");
      setConfirmDialogOpen(false);
      setTimerfixed((prevPoints) =>
        prevPoints.filter((point) => point.UniqueId !== UniqueId)
      );
    } catch (error) {
      toast.error("Error deleting template");
    }
  };

  return (
    <Sidebar>
      <div className="p-4 xl:ml-80 flex flex-col">
        <div className="mb-4">
          <button
            type="button"
            onClick={() => setShowBannerForm(true)}
            className="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
          >
            Add Template
          </button>
          {showBannerForm && (
            <div className="fixed inset-0 flex items-center justify-center z-50">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
              <div className="bg-white rounded-lg p-8 z-10 relative">
                <button
                  className="absolute top-0 right-0 p-3"
                  onClick={() => setShowBannerForm(false)}
                >
                  Close
                </button>
                <AddWinner_Timer
                  add={() => fetchPoints()}
                  onClose={() => setShowBannerForm(false)}
                />
              </div>
            </div>
          )}

          {[
            "points",
            "list",
            "timer",
            "percentage",
            "gamepoints",
            "fixedtimerdata",
            "contact Details",
            "unlock Details",
            "AppDetails",
            "Mentainance",
            "Bonus",
            "Withdrawallimit"
          ]?.map((table) => (
            <button
              key={table}
              type="button"
              onClick={() => setActiveTable(table)}
              className={`text-white ${
                activeTable === table ? "bg-blue-500" : "bg-gray-500"
              } hover:bg-blue-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2`}
            >
              {table?.charAt(0)?.toUpperCase() + table?.slice(1)}
            </button>
          ))}
        </div>
        {/* Conditionally render tables based on `activeTable` state */}
        {activeTable === "points" && (
          <div className="table-container overflow-x-auto">
            <p className="text-center pt-2 pb-2 text-2xl font-bold">
              Joining amount template
            </p>
            <table className="table table-bordered">
              <thead className="bg-gray-100 sticky top-0">
                <tr>
                  <th>Sl.No</th>
                  <th>Amount</th>
                  <th>Points</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {points.map((point, index) => (
                  <tr
                    key={point._id}
                    className={index % 2 === 0 ? "bg-white" : "bg-gray-100"}
                  >
                    <td>{index + 1}</td>
                    <td>{point.AMOUNT}</td>
                    <td>{point.POINTS}</td>
                    <td>
                      <button
                        className="bg-red-500 hover:bg-red-600 px-4 py-2 rounded-lg text-white font-semibold shadow-lg transition duration-200 ease-in-out"
                        onClick={() => handleDeleteClick(point)} 
                      >
                        Delete
                      </button>
                      {confirmDialogOpen && selectedPoint && (
                        <div className="fixed inset-0 flex items-center justify-center z-50">
                          <div className="bg-white p-6 rounded-lg shadow-lg">
                            <h2 className="text-2xl font-semibold">Confirm</h2>
                            <p className="mt-2 text-xl">
                              Are you sure you want to delete amount {selectedPoint.AMOUNT} permanently..?{" "}
                              <br />
                              Because it may affect gameplay..{" "}
                             
                            </p>
                            <div className="mt-4 flex justify-end">
                              <button
                                onClick={handleDelete}
                                className="bg-green-500 hover:bg-green-600 text-white px-10 py-3 rounded-lg mr-2"
                              >
                                Yes
                              </button>
                              <button
                                onClick={() => setConfirmDialogOpen(false)}
                                className="bg-red-500 hover:bg-red-600 text-white px-10 py-2 rounded-lg"
                              >
                                No
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {activeTable === "list" && (
          <div className="table-container overflow-x-auto">
            <p className="text-center pt-2 pb-2 text-2xl font-bold">
              Joining amount template
            </p>
            <table className="table table-bordered">
              <thead className="bg-gray-100 sticky top-0">
                <tr>
                  <th>Sl.No</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {list.map((lists, index) => (
                  <tr
                    key={lists._id}
                    className={index % 2 === 0 ? "bg-white" : "bg-gray-100"}
                  >
                    <td>{index + 1}</td>
                    <td>{lists}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {activeTable === "timer" && (
          <div className="table-container overflow-x-auto">
            <p className="text-center pt-2 pb-2 text-2xl font-bold">
              Timer mode timer data
            </p>
            <table className="table table-bordered">
              <thead className="bg-gray-100 sticky top-0">
                <tr>
                  <th>Sl.No</th>
                  <th>UniqueId</th>
                  <th>Time</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {timer.map((timers, index) => (
                  <tr
                    key={timers._id}
                    className={index % 2 === 0 ? "bg-white" : "bg-gray-100"}
                  >
                    <td>{index + 1}</td>
                    <td>{timers.UniqueId}</td>
                    <td>{timers.Time}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {activeTable === "percentage" && (
          <div className="table-container overflow-x-auto">
            <p className="text-center pt-2 pb-2 text-2xl font-bold">
              Company Percentage
            </p>
            <table className="table table-bordered">
              <thead className="bg-gray-100 sticky top-0">
                <tr>
                  <th>Sl.No</th>
                  <th>UniqueName</th>
                  <th>CompanyPercentage</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {percentage.map((percentages, index) => (
                  <tr
                    key={percentages._id}
                    className={index % 2 === 0 ? "bg-white" : "bg-gray-100"}
                  >
                    <td>{index + 1}</td>
                    <td>{percentages.UniqueName}</td>
                    <td>{percentages.CompanyPercentage}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {activeTable === "gamepoints" && (
          <div className="table-container overflow-x-auto">
            <p className="text-center pt-2 pb-2 text-2xl font-bold">
              WINING_COINS
            </p>
            <table className="table table-bordered">
              <thead className="bg-gray-100 sticky top-0">
                <tr>
                  <th>Sl.No</th>
                  <th>EveryMove</th>
                  <th>EveryKill</th>
                  <th>EveryDestination</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {movepoint.map((coins, index) => (
                  <tr
                    key={coins._id}
                    className={index % 2 === 0 ? "bg-white" : "bg-gray-100"}
                  >
                    <td>{index + 1}</td>
                    <td>{coins.PerMove}</td>
                    <td>{coins.PerKill}</td>
                    <td>{coins.PerDestination}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {activeTable === "fixedtimerdata" && (
          <div>
            <button
              type="button"
              onClick={() => setShowBannerForm1(true)}
              className="text-white bg-gradient-to-br from-blue-800 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
            >
              Add FIxed Data
            </button>
            {showBannerForm1 && (
              <div className="fixed inset-0 flex items-center justify-center z-50">
                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                <div className="bg-white rounded-lg p-8 z-10 relative">
                  <button
                    className="absolute top-0 right-0 p-3"
                    onClick={() => setShowBannerForm1(false)}
                  >
                    Close
                  </button>
                  <AddFixed_TimerData
                    add={() => fetchPoints()}
                    onClose={() => setShowBannerForm1(false)}
                  />
                </div>
              </div>
            )}
            <div className="table-container overflow-x-auto">
              <p className="text-center pt-2 pb-2 text-2xl font-bold">
                Fixed Timer Data{" "}
              </p>
              <table className="table table-bordered">
                <thead className="bg-gray-100 sticky top-0">
                  <tr>
                    <th>Sl.No</th>
                    <th>UniqueId</th>
                    <th>Timer</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {timerfixed.map((timer, index) => (
                    <tr
                      key={timer._id}
                      className={index % 2 === 0 ? "bg-white" : "bg-gray-100"}
                    >
                      <td>{index + 1}</td>
                      <td>{timer.UniqueId}</td>
                      <td>{timer.Time}</td>
                      <td>
                        <button
                          className="bg-red-500 hover:bg-red-600 px-4 py-2 rounded-lg text-white font-semibold shadow-lg transition duration-200 ease-in-out"
                          onClick={() => setConfirmDialogOpen(true)}
                        >
                          Delete
                        </button>
                        {confirmDialogOpen && (
                          <div className="fixed inset-0 flex items-center justify-center z-50">
                            <div className="bg-white p-6 rounded-lg shadow-lg">
                              <h2 className="text-2xl font-semibold">
                                Confirm
                              </h2>
                              <p className="mt-2 text-xl">
                                Are you sure you want to delete permanently..?{" "}
                                <br /> Because it may cause Effect in Game
                                Play..
                              </p>
                              <div className="mt-4 flex justify-end">
                                <button
                                  onClick={() =>
                                    handleDeleteFixedData(timer.UniqueId)
                                  }
                                  className="bg-green-500 hover:bg-green-600 text-white px-10 py-3 rounded-lg mr-2"
                                >
                                  Yes
                                </button>
                                <button
                                  onClick={() => setConfirmDialogOpen(false)}
                                  className="bg-red-500 hover:bg-red-600 text-white px-10 py-2 rounded-lg"
                                >
                                  No
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {activeTable === "contact Details" && (
          <div className="p-6">
            <div className="overflow-x-auto">
              <p className="text-center pt-2 pb-4 text-2xl font-bold">
                Contact Details
              </p>
              <div className="bg-white border border-gray-300 rounded-lg shadow-md p-6">
                {contact.length > 0 ? (
                  contact.map((timer) => (
                    <div key={timer._id} className="mb-6 border-b pb-4 text-xl">
                      <div className="flex justify-between border-b pb-2 mb-2">
                        <strong>LinkedIn:</strong>
                        <a
                          href={timer.LINKED_IN}
                          className="text-blue-600 hover:underline"
                        >
                          {timer.LINKED_IN}
                        </a>
                      </div>
                      <div className="flex justify-between border-b pb-2 mb-2">
                        <strong>WhatsApp:</strong>
                        <span>{timer.WHATS_UP}</span>
                      </div>
                      <div className="flex justify-between border-b pb-2 mb-2">
                        <strong>Twitter:</strong>
                        <a
                          href={timer.TWEETER}
                          className="text-blue-600 hover:underline"
                        >
                          {timer.TWEETER}
                        </a>
                      </div>
                      <div className="flex justify-between border-b pb-2 mb-2">
                        <strong>Instagram:</strong>
                        <a
                          href={timer.INSTAGRAM}
                          className="text-blue-600 hover:underline"
                        >
                          {timer.INSTAGRAM}
                        </a>
                      </div>
                      <div className="flex justify-between border-b pb-2 mb-2">
                        <strong>Telegram:</strong>
                        <a
                          href={timer.TELEGRAM}
                          className="text-blue-600 hover:underline"
                        >
                          {timer.TELEGRAM}
                        </a>
                      </div>
                      <div className="flex justify-between border-b pb-2 mb-2">
                        <strong>Facebook:</strong>
                        <a
                          href={timer.FACE_BOOK}
                          className="text-blue-600 hover:underline"
                        >
                          {timer.FACE_BOOK}
                        </a>
                      </div>
                      <div className="flex justify-between border-b pb-2 mb-2">
                        <strong>Phone Number:</strong>
                        <span>{timer.PHONE_NUMBER}</span>
                      </div>
                      <div className="flex justify-between border-b pb-2 mb-2">
                        <strong>Email:</strong>
                        <span>{timer.EMAIL}</span>
                      </div>
                      <div className="flex justify-between border-b pb-2 mb-2">
                        <strong>APP_LINK:</strong>
                        <a
                          href={timer.APP_LINK}
                          className="text-blue-600 hover:underline"
                        >
                          {" "}
                          {timer.APP_LINK}{" "}
                        </a>
                      </div>
                      <div className="flex justify-end">
                        <button
                          type="button"
                          onClick={() => setShowBannerForm2(true)}
                          className="text-white bg-yellow-600 hover:bg-yellow-800 px-4 py-2 rounded-md end-0  justify-end"
                        >
                          Update Details
                        </button>
                      </div>
                      {showBannerForm2 && (
                        <div className="fixed inset-0 flex items-center justify-center z-50">
                          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                          <div className="bg-white rounded-lg p-8 z-10 relative">
                            <button
                              className="absolute top-0 right-0 p-3"
                              onClick={() => setShowBannerForm2(false)}
                            >
                              Close
                            </button>
                            <AddContactdetails
                              add={() => fetchPoints()}
                              onClose={() => setShowBannerForm2(false)}
                              contact={timer}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <p className="text-center text-gray-500">
                    No contact details available.
                  </p>
                )}
              </div>
            </div>
          </div>
        )}

        {activeTable === "unlock Details" && (
          <div className="p-6">
            <div className="overflow-x-auto">
              <p className="text-center pt-2 pb-4 text-2xl font-bold">
                Unlock Mode Details
              </p>
              <div className="bg-white border border-gray-300 rounded-lg shadow-md p-6">
                {unlock.length > 0 ? (
                  unlock.map((timer, index) => (
                    <div key={timer._id} className="mb-6 border-b pb-4 text-xl">
                      <div className="flex justify-around border-b pb-2 mb-2">
                        <span>1</span>
                        <strong>Quick Mode:</strong>
                        <strong
                          onClick={() => {
                            setSelectedMode({
                              mode: "quick",
                              currentStatus: timer.quick,
                            });
                            setConfirmlockOpen(true);
                          }}
                          className={`inline-block px-4 py-2 cursor-pointer transition ${
                            timer.quick === "UnLocked"
                              ? "bg-green-600 text-white rounded-xl hover:bg-green-700"
                              : "bg-red-600 text-white rounded-xl hover:bg-red-700"
                          }`}
                        >
                          {timer.quick} &ensp;{" "}
                          {timer.quick === "UnLocked" ? "🔓" : "🔒"}
                        </strong>
                      </div>
                      <div className="flex justify-around border-b pb-2 mb-2">
                        <span>2</span>
                        <strong>Timer Mode:</strong>
                        <strong
                          onClick={() => {
                            setSelectedMode({
                              mode: "timer",
                              currentStatus: timer.timer,
                            });
                            setConfirmlockOpen(true);
                          }}
                          className={`inline-block px-4 py-2 cursor-pointer transition ${
                            timer.timer === "UnLocked"
                              ? "bg-green-600 text-white rounded-xl hover:bg-green-700"
                              : "bg-red-600 text-white rounded-xl hover:bg-red-700"
                          }`}
                        >
                          {timer.timer} &ensp;{" "}
                          {timer.timer === "UnLocked" ? "🔓" : "🔒"}
                        </strong>
                      </div>
                      <div className="flex justify-around border-b pb-2 mb-2">
                        <span>3</span>
                        <strong>Classic Mode:</strong>
                        <strong
                          onClick={() => {
                            setSelectedMode({
                              mode: "classic",
                              currentStatus: timer.classic,
                            });
                            setConfirmlockOpen(true);
                          }}
                          className={`inline-block px-4 py-2 cursor-pointer transition ${
                            timer.classic === "UnLocked"
                              ? "bg-green-600 text-white rounded-xl hover:bg-green-700"
                              : "bg-red-600 text-white rounded-xl hover:bg-red-700"
                          }`}
                        >
                          {timer.classic} &ensp;{" "}
                          {timer.classic === "UnLocked" ? "🔓" : "🔒"}
                        </strong>
                      </div>

                      {confirmlockOpen && (
                        <div className="fixed inset-0 flex items-center justify-center z-50">
                          <div className="bg-white p-6 rounded-lg shadow-lg">
                            <h2 className="text-2xl font-semibold text-center">
                              Confirm
                            </h2>
                            <p className="mt-2 font-semibold">
                              You are locking the mode. Please be careful before
                              locking it...
                            </p>
                            <div className="mt-4 flex justify-center">
                              <button
                                onClick={() => setConfirmlockOpen(false)}
                                className="bg-red-500 hover:bg-red-600  font-semibold text-white px-10 py-1 rounded-lg mr-2"
                              >
                                No
                              </button>
                              <button
                                onClick={() => {
                                  handleUnlock(
                                    selectedMode.mode,
                                    selectedMode.currentStatus
                                  );
                                  setConfirmlockOpen(false);
                                }}
                                className="bg-green-500 hover:bg-green-600 font-semibold text-white px-10 py-1 rounded-lg "
                              >
                                Yes
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <p className="text-center text-gray-500">
                    No unlock details available.
                  </p>
                )}
              </div>
            </div>
          </div>
        )}

        {activeTable === "AppDetails" && (
          <div className="">
            <p className="text-center pt-2 pb-2 text-2xl font-bold">
              App Detials
            </p>
            <table className="table table-bordered">
              <thead className="bg-gray-100 sticky top-0 bg-white border border-gray-300 rounded-lg  p-6">
                <tr>
                  <th>Sl.No</th>
                  <th>App Name</th>
                  <th>App Version</th>
                  <th>Updated Time</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {appdetails.map((coins, index) => (
                  <tr
                    key={coins._id}
                    className={index % 2 === 0 ? "bg-white" : "bg-gray-100"}
                  >
                    <td>{index + 1}</td>
                    <td>{coins.app_name}</td>
                    <td>{coins.version}</td>
                    <td>
                      {coins.updatedAt
                        ? `${coins.updatedAt.split("T")[0]} Time ${
                            coins.updatedAt.split("T")[1].split(".")[0]
                          }`
                        : ""}
                    </td>
                    <td>
                      <div className="flex justify-center">
                        <button
                          type="button"
                          onClick={() => setShowBannerForm3(true)}
                          className="text-white bg-yellow-600 hover:bg-yellow-800 px-4 py-2 rounded-md "
                        >
                          Update Details
                        </button>
                      </div>
                      {showBannerForm3 && (
                        <div className="fixed inset-0 flex items-center justify-center z-50">
                          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                          <div className="bg-white rounded-lg p-8 z-10 relative">
                            <button
                              className="absolute top-0 right-0 p-3"
                              onClick={() => setShowBannerForm3(false)}
                            >
                              Close
                            </button>
                            <AddappDetails
                              add={() => fetchPoints()}
                              onClose={() => setShowBannerForm3(false)}
                              contact={coins}
                            />
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {activeTable === "Mentainance" && (
          <div className="">
            <p className="text-center pt-2 pb-2 text-2xl font-bold">
              Mentainance Detials
            </p>
            <table className="table table-bordered">
              <thead className="bg-gray-100 sticky top-0 bg-white border border-gray-300 rounded-lg  p-6">
                <tr>
                  <th>Sl.No</th>
                  <th>Server Name</th>
                  <th>Status</th>
                  <th>Last Maintained</th>
                  <th>Next Maintenance</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {maintainance.map((coins, index) => (
                  <tr
                    key={coins._id}
                    className={index % 2 === 0 ? "bg-white" : "bg-gray-100"}
                  >
                    <td>{index + 1}</td>
                    <td>{coins.serverName}</td>
                    <td
                      className={`${
                        coins.status === "active"
                          ? "text-green-500"
                          : "text-red-500"
                      }`}
                    >
                      {coins.status}
                    </td>
                    <td>
                      {coins.lastMaintained
                        ? `${coins.lastMaintained.split("T")[0]} Time ${
                            coins.lastMaintained.split("T")[1].split(".")[0]
                          }`
                        : ""}
                    </td>
                    <td>
                      {coins.nextMaintenance
                        ? `${coins.nextMaintenance.split("T")[0]} Time ${
                            coins.nextMaintenance.split("T")[1].split(".")[0]
                          }`
                        : ""}
                    </td>
                    <td>
                      <div className="flex justify-center">
                        <button
                          type="button"
                          onClick={() => setShowBannerForm4(true)}
                          className="text-white bg-yellow-600 hover:bg-yellow-800 px-4 py-2 rounded-md "
                        >
                          Update Mentainance
                        </button>
                      </div>
                      {showBannerForm4 && (
                        <div className="fixed inset-0 flex items-center justify-center z-50">
                          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                          <div className="bg-white rounded-lg p-8 z-10 relative">
                            <button
                              className="absolute top-0 right-0 p-3"
                              onClick={() => setShowBannerForm4(false)}
                            >
                              Close
                            </button>
                            <Updatemaintainance
                              add={() => fetchPoints()}
                              onClose={() => setShowBannerForm4(false)}
                              contact={coins}
                            />
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

{activeTable === "Bonus" && (
<div>

    <BonusTable/>
    </div>
        )}

{activeTable === "Withdrawallimit" && (
<div>

    <WithdrawallimitTable/>
    </div>
        )}



      </div>
      <Toaster />
    </Sidebar>
  );
};

export default Winner_Timer;



{/* <td>
<div className="flex justify-center">
  <button
    type="button"
    onClick={() => setShowBannerForm4(true)}
    className="text-white bg-yellow-600 hover:bg-yellow-800 px-4 py-2 rounded-md "
  >
    Update Mentainance
  </button>
</div>
{showBannerForm4 && (
  <div className="fixed inset-0 flex items-center justify-center z-50">
    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
    <div className="bg-white rounded-lg p-8 z-10 relative">
      <button
        className="absolute top-0 right-0 p-3"
        onClick={() => setShowBannerForm4(false)}
      >
        Close
      </button>
      <Updatemaintainance
        add={() => fetchPoints()}
        onClose={() => setShowBannerForm4(false)}
        contact={coins}
      />
    </div>
  </div>
)}
</td> */}