import { useState } from "react";
import { IoSearchSharp } from "react-icons/io5";
import useConversation from "../zustand/useConversation.js";
import useGetConversations from "../hooks/useGetConversations";
import toast from "react-hot-toast";

const SearchInput = () => {
  const [search, setSearch] = useState("");
  const { setSelectedConversation } = useConversation();
  const { conversations } = useGetConversations();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!search) return;
    if (search.length < 3) {
      return toast.error("Search term must be at least 3 characters long");
    }

    const conversation = conversations.find((c) => c.name.toLowerCase().includes(search.toLowerCase()));

    if (conversation) {
      setSelectedConversation(conversation);
      setSearch("");
    } else toast.error("No such user found!");
  };

  return (
    <form onSubmit={handleSubmit} className="relative flex items-center w-full max-w-md mx-auto bg-white shadow-md rounded-full overflow-hidden">
      <input
        type="text"
        placeholder="Search…"
        className="w-full py-2 px-4 pr-12 text-gray-700 placeholder-gray-400 border-none outline-none focus:ring-2 focus:ring-sky-500 rounded-full"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        aria-label="Search conversations"
      />
      <button
        type="submit"
        className="absolute right-0 top-0 bottom-0 flex items-center justify-center w-12 bg-sky-500 text-white hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-sky-500 rounded-full"
      >
        <IoSearchSharp className="w-6 h-6" />
      </button>
    </form>
  );
};

export default SearchInput;
