import Sidebar from "../../../Frame/Sidebar.jsx";
import MessageContainer from "../../messages/MessageContainer.jsx";
import Sidebar1 from "../../sidebar/Sidebar.jsx";

const Home = () => {
    return (
        <Sidebar>
            <div className="p-4 xl:ml-80   flex flex-col h-screen">
                <div className='flex flex-1 pt overflow-hidden bg-gray-400 bg-clip-padding backdrop-filter backdrop-blur-lg bg-opacity-0'>
                    <Sidebar1 className='overflow-auto h-full w-64' />
                    <MessageContainer className='overflow-auto flex-1' />
                </div>
            </div>
        </Sidebar>
    );
};

export default Home;
