import { createSelector } from 'reselect';

// Selector to get the user slice of state
const selectUserState = (state) => state.data;

// Memoized selector to get users
export const selectUsers = createSelector(
  [selectUserState],
  (userState) => userState.data.users || []
);

// Memoized selector to get total user count
export const selectTotalUserCount = createSelector(
  [selectUserState],
  (userState) => userState.data.totalUserCount || 0
);

// Memoized selector to get current page
export const selectCurrentPage = createSelector(
  [selectUserState],
  (userState) => userState.data.currentPage || 0
);

// Memoized selector to get total pages
export const selectTotalPages = createSelector(
  [selectUserState],
  (userState) => userState.data.totalPages || 0
);

export const selecttotalActiveLength = createSelector(
  [selectUserState],
  (userState) => userState.data.totalActiveLength || 0
);

export const selecttotalInactiveLength = createSelector(
  [selectUserState],
  (userState) => userState.data.totalInactiveLength || 0
);

export const selectunverifiedusers = createSelector(
  [selectUserState],
  (userState) => userState.data.unverifiedusers || 0
);


// Memoized selector to get selected user ID
export const selectSelectedUserId = createSelector(
  [selectUserState],
  (userState) => userState.selectedUserId
);

export const selectpagecount = createSelector(
  [selectUserState],
  (userState) => userState.pagecount
);

export const selectReportpendingcount = createSelector(
  [selectUserState],
  (userState) => userState.data.reportpendingcount || 0,
);

export const selectChatcount = createSelector(
  [selectUserState],
  (userState) => userState.data.chatcount || 0
);

export const selectPageSize = createSelector(
  [selectUserState],
  (userState) => userState.data.pageSize || 0
);


export const selectNonverifiedusers = createSelector(
  [selectUserState],
  (userState) => userState.data.nonverifiedusers || 0
);


export const selectVerifiedusers = createSelector(
  [selectUserState],
  (userState) => userState.data.verifiedusers || 0
);

export const selectTopPlayed = createSelector(
  [selectUserState],
  (userState) => userState.data.topPlayed || 0
);

export const selecttotalbonusdistributed= createSelector(
  [selectUserState],
  (userState) => userState.data.totalbonusdistributed
);




