// import React from 'react';
// import { Link } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import { useUser } from './context.js';

// export const Footer = () => {
//     const userId = useSelector(state => state.data.selectedUserId)
//     const { Admin, Sales, Telecaller, Verification, Market } = useUser();
//     console.log(userId,"userIdFooter")
//     console.log(Admin,Telecaller,"userIdFooterTelecaller")
//     return (
//         <div>
//             {(Admin || Telecaller)  && (

//             <div className='fixed bottom-3 left-0 w-full py-4 xl:ml-80'>
//             {userId !== undefined && userId !== null && (
                
                     
//                 <div className=''>
                
//                     <Link
//                         to="/user_details"
//                         type="button"
//                         className={`text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2`}
//                     >
//                         All User
//                     </Link>
                    
//                         <Link
//                             to={`/view_user_details/${userId}`}
//                             type="button"
//                             className={`text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2`}
//                         >
//                             User Details
//                         </Link>
                  
//                     {/* <Link
//                         to={`/deposite_details/${userId}`}
//                         type="button"
//                         className={`text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 `}
//                     >
//                         Deposit Details
//                     </Link> */}
//                     <Link
//                         to={`/wallet_transaction/${userId}`}
//                         type="button"
//                         className={`text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 `}
//                     >
//                         Wallet Transaction
//                     </Link>
//                     {/* <Link
//                         to={`/joined_games/${userId}`}
//                         type="button"
//                         className={`text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2`}
//                     >
//                         Joined Games
//                     </Link> */}
//                     <Link
//                         to={`/result_data/${userId}`}
//                         type="button"
//                         className={`text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2`}
//                     >
//                         User Result Data
//                     </Link>
//                     {/* <Link
//                         to={`/withdrawal_details/${userId}`}
//                         type="button"
//                         className={`text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2`}
//                     >
//                         Withdrawal Details
//                     </Link> */}
//                     <Link
//                         to={`/verification_details/${userId}`}
//                         type="button"
//                         className={`text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2`}
//                     >
//                         Verification Details
//                     </Link>
//                     <Link
//                         to={`/refferal_details/${userId}`}
//                         type="button"
//                         className={`text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2`}
//                     >
//                         Refferal Details
//                     </Link>
//                     <Link
//                         to={`/report_details/${userId}`}
//                         type="button"
//                         className={`text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2`}
//                     >
//                         Report Details
//                     </Link>
                    
//                 </div>
//             )}
            
//             </div>
//                  )}
//         </div>
//     );
// }


import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useUser } from './context.js';

export const Footer = () => {
    const location = useLocation();
    const userId = useSelector(state => state.data.selectedUserId);
    const { Admin, Telecaller } = useUser();
    
    // Define the active link styles
    const activeStyle = 'text-white bg-gray-500'; // Active button color
    const inactiveStyle = 'text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl';

    // Determine if the link is active
    const isActive = (path) => location.pathname === path;

    return (
        <div>
            {(Admin || Telecaller) && (
                <div className='fixed bottom-3 left-0 w-full py-4 xl:ml-80'>
                    {userId !== undefined && userId !== null && (
                        <div className=''>
                            <Link
                                to="/user_details"
                                type="button"
                                className={`font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 ${isActive('/user_details') ? activeStyle : inactiveStyle}`}
                            >
                                All User
                            </Link>
                            <Link
                                to={`/view_user_details/${userId}`}
                                type="button"
                                className={`font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 ${isActive(`/view_user_details/${userId}`) ? activeStyle : inactiveStyle}`}
                            >
                                User Details
                            </Link>
                            <Link
                                to={`/wallet_transaction/${userId}`}
                                type="button"
                                className={`font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 ${isActive(`/wallet_transaction/${userId}`) ? activeStyle : inactiveStyle}`}
                            >
                                Wallet Transaction
                            </Link>
                            <Link
                                to={`/result_data/${userId}`}
                                type="button"
                                className={`font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 ${isActive(`/result_data/${userId}`) ? activeStyle : inactiveStyle}`}
                            >
                                User Result Data
                            </Link>
                            <Link
                                to={`/verification_details/${userId}`}
                                type="button"
                                className={`font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 ${isActive(`/verification_details/${userId}`) ? activeStyle : inactiveStyle}`}
                            >
                                Verification Details
                            </Link>
                            <Link
                                to={`/refferal_details/${userId}`}
                                type="button"
                                className={`font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 ${isActive(`/refferal_details/${userId}`) ? activeStyle : inactiveStyle}`}
                            >
                                Refferal Details
                            </Link>
                            <Link
                                to={`/report_details/${userId}`}
                                type="button"
                                className={`font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 ${isActive(`/report_details/${userId}`) ? activeStyle : inactiveStyle}`}
                            >
                                Report Details
                            </Link>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
