import { call, put, takeLatest } from 'redux-saga/effects';
import {
  loginRequest, loginSuccess, loginFailure,
  fetchRequest, fetchSuccess, fetchFailure,
  updateRequest, updateSuccess, updateFailure,
  updatePasswordRequest, updatePasswordSuccess, updatePasswordFailure,
  addRequest, addSuccess, addFailure,
  deleteRequest, deleteSuccess, deleteFailure,
} from '../slices/employeeSlice.js';
import { login,updatePassword,getApi, postApi, putApi, deleteApi } from '../Apirequest/employeeApiRequest.js';




export function* updateDataSaga(action) {
  try {
    const token = localStorage.getItem('token');
    const headers = {
      'Authorization': `Bearer ${token}`,
    };

    const response = yield call(putApi, `api/v-1/employee/updatebyid/${action.payload.id}`, action.payload, { headers });

    if (response.status === 200) {
      yield put(fetchRequest());
      yield put(updateSuccess(response.data));
    } else {
      yield put(updateFailure(response.data || 'An error occurred'));
    }
  } catch (error) {
    yield put(updateFailure(error.message || 'An error occurred'));
  }
}

function* addDataSaga(action) {
  try {
    const token = localStorage.getItem('token');
    const headers = {
      'Authorization': `Bearer ${token}`
    };
    const response = yield call(postApi, 'api/v-1/employee/create_employee', action.payload, { headers });
    if (response.status === 201) {
      yield put(fetchRequest());
      yield put(addSuccess(response.data.message ));
    } else {
      yield put(addFailure(response.data.message || 'An error occurred'));
    }
  } catch (error) {
    yield put(addFailure(error.message || 'An error occurred'));
  }
}


// Update Password Saga
function* updatePasswordSaga(action) {
  try {
    const token = localStorage.getItem('token');
    const headers = {
      'Authorization': `Bearer ${token}`
    };
    const response = yield call(updatePassword, 'api/v-1/employee/updatepassword', action.payload, { headers });
    if (response.status === 200) {
      yield put(fetchRequest());
      yield put(updatePasswordSuccess(response.data.message));
    } else {
      yield put(updatePasswordFailure(response.data.message || 'An error occurred'));
    }
  } catch (error) {
    yield put(updatePasswordFailure(error.message || 'An error occurred'));
  }
}

// Fetch Data Saga
function* fetchDataSaga() {
  try {
    const token = localStorage.getItem('token');
    const headers = {
      'Authorization': `Bearer ${token}`
    };
    const response = yield call(getApi, 'api/v-1/employee/getallemployee', { headers});
    console.log(response)
    if (response.status === 200) {
      yield put(fetchSuccess(response.data));
    } else {
      yield put(fetchFailure(response.data || 'An error occurred'));
    }
  } catch (error) {
    yield put(fetchFailure(error.message || 'An error occurred'));
  }
}



const getAuthHeaders = () => {
  const token = localStorage.getItem('token');
  return {
    'Authorization': `Bearer ${token}`
  };
};

// Login Saga
function* loginSaga(action) {
  try {
    const response = yield call(login, 'api/v-1/employee/login', action.payload);
    if (response.status === 200) {
      localStorage.setItem('token', response.data.token);
      yield put(loginSuccess(response.data.message));
    } else {
      yield put(loginFailure(response.data.message || 'An error occurred'));
    }
  } catch (error) {
    yield put(loginFailure(error.message || 'An error occurred'));
  }
}


function* deleteDataSaga(action) {
  try {
    const response = yield call(deleteApi, `api/v-1/employee/delete/${action.payload}`, { headers: getAuthHeaders() });
    if (response.status === 200) {
      yield put(fetchRequest());
      yield put(deleteSuccess(response.data.message));
    } else {
      yield put(deleteFailure(response.data.message || 'An error occurred'));
    }
  } catch (error) {
    yield put(deleteFailure(error.message || 'An error occurred'));
  }
}

// Watcher Saga
function* watchEmployeeSaga() {
  yield takeLatest(loginRequest.type, loginSaga);
  yield takeLatest(fetchRequest.type, fetchDataSaga);
  yield takeLatest(updateRequest.type, updateDataSaga);
  yield takeLatest(updatePasswordRequest.type, updatePasswordSaga);
  yield takeLatest(addRequest.type, addDataSaga);
  yield takeLatest(deleteRequest.type, deleteDataSaga);
}

export default watchEmployeeSaga;
