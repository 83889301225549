import React, { useState } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../Constants/constant';
import Cookies from 'js-cookie';

const Resolveticket = ({ handleview, selectId, onClose }) => {
    const [resolvemessage, setResolvemessage] = useState(selectId.resolvemessage);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false); 

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isSubmitting) return; 
        setIsSubmitting(true);

        try {
            const token = Cookies.get('token');
            const response = await axios.put(
                `${BASE_URL}/api/v-1/ticket/update/${selectId._id}`,
                {
                    resolvemessage: resolvemessage,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200) {
                setSuccess(response.data.message);
                handleview()
                setTimeout(() => {
                    onClose();
                }, 1500);
            }
        } catch (error) {
            setError(error.response?.data?.message || 'Something went wrong');
        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-4">
            <div className="bg-white rounded-lg shadow-lg p-8 max-w-3xl w-full relative space-y-6">
                <h2 className="text-2xl font-bold text-gray-800 text-center relative">
                    Resolve Ticket
                    <button
                        className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
                        onClick={() => {
                            onClose();
                            setError(null);
                            setSuccess(null);
                        }}
                        aria-label="Close"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke="currentColor"
                            className="w-6 h-6"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </h2>

                <form onSubmit={handleSubmit} className="space-y-4">
                    {error && <div className="text-red-500 text-sm text-center">{error}</div>}
                    {success && <div className="text-green-500 text-sm text-center">{success}</div>}

                    <div className="space-y-2">
                        <label className="block text-gray-700 font-medium">Question</label>
                        <input
                            type="text"
                            value={selectId.question}
                            disabled
                            className="w-full p-3 text-lg border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-100"
                        />
                    </div>

                    <div className="space-y-2">
                        <label className="block text-gray-700 font-medium">Answer</label>
                        <textarea
                            type="textarea"
                            value={selectId.answer}
                            disabled
                            rows={4}
                            className="w-full p-3 text-lg border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-100"
                        ></textarea>
                    </div>

                    <div className="space-y-2">
                        <label className="block text-gray-700 font-medium">Message</label>
                        <textarea
                            type="text"
                            value={selectId.message}
                            disabled
                            rows={4}
                            className="w-full p-3 text-lg border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-100"
                        ></textarea>
                    </div>

                    <div className="space-y-2">
                        <label className="block text-gray-700 font-medium">Resolve Message</label>
                        <textarea
                            type="text"
                            value={resolvemessage}
                            disabled={selectId.status === "Resolved"}
                            onChange={(e) => setResolvemessage(e.target.value)}
                            placeholder="Enter resolution message"
                            rows={4}
                            className="w-full p-3 text-lg border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            required
                        ></textarea>
                    </div>

                    <div className="flex justify-end">
                        {selectId.status === "Resolved" ? (
                            <p onClick={onClose} className="px-6 py-3 bg-red-600 text-white rounded-lg shadow-lg hover:bg-red-700 transition duration-200">
                          Close
                            </p>
                        ) : (
                            <button
                            type="submit"
                            className={`px-6 py-3 rounded-lg shadow-lg transition duration-200 ${
                                isSubmitting
                                    ? "bg-gray-400 cursor-not-allowed"
                                    : "bg-blue-600 text-white hover:bg-blue-700"
                            }`}
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? "Submitting..." : "Resolve"}
                        </button>
                        )}
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Resolveticket;
