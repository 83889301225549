import React, { useState, useEffect, useCallback } from 'react';

const DynamicFilterForm = ({ onFiltersApplied }) => {
  const [filters, setFilters] = useState([]);
  const [newFilter, setNewFilter] = useState({ field: '', min: '', max: '', value: '' });
  const [error, setError] = useState('');
  const [editingIndex, setEditingIndex] = useState(null);

  const applyFilters = useCallback(() => {
    try {
      const formattedFilters = filters.reduce((acc, filter) => {
        if (filter.field) {
          if (filter.field === 'createdAt') {
            acc[filter.field] = {
              startDate: filter.min ? new Date(filter.min).toISOString() : undefined,
              endDate: filter.max ? new Date(filter.max).toISOString() : undefined
            };
          } else if (filter.min || filter.max) {
            acc[filter.field] = { min: filter.min, max: filter.max };
          } else {
            acc[filter.field] = filter.value;
          }
        }
        return acc;
      }, {});

      onFiltersApplied(formattedFilters);
    } catch (error) {
      setError('Error applying filters: ' + error.message);
    }
  }, [filters]);

  useEffect(() => {
    applyFilters();
  }, [filters, applyFilters]);

  const handleFilterChange = (key, value) => {
    setNewFilter(prev => ({ ...prev, [key]: value }));
  };

  const handleAddFilter = () => {
    if (newFilter.field) {
      const existingFilterIndex = filters.findIndex(filter => filter.field === newFilter.field);

      if (existingFilterIndex !== -1 && editingIndex === null) {
        alert(`Filter for ${newFilter.field} already exists. Please edit it instead of adding a new one.`);
        setNewFilter(" ")
        return;
      }

      if (editingIndex !== null) {
        const updatedFilters = filters.map((filter, index) =>
          index === editingIndex ? newFilter : filter
        );
        setFilters(updatedFilters);
        setEditingIndex(null);
      } else {
        setFilters([...filters, newFilter]);
      }

      setNewFilter({ field: '', min: '', max: '', value: '' });
      setError('');
    }
  };

  const handleRemoveFilter = (index) => {
    const newFilters = filters.filter((_, i) => i !== index);
    setFilters(newFilters);
  };

  const handleEditFilter = (index) => {
    setNewFilter(filters[index]);
    setEditingIndex(index);
  };

  return (
    <div className="">
      <form className="">
        <div className="relative items-center gap-4 ">
          <select
            value={newFilter.field}
            onChange={(e) => handleFilterChange('field', e.target.value)}
            className="text-white mb-3 bg-gradient-to-br from-purple-600 to-blue-500 hover:from-purple-700 hover:to-blue-600 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm  text-center py-2.5 transition-colors duration-300"
          >
       
            <option className='text-gray-800 bg-gray-100 ' value="">Add Filter</option>
            <option className='text-gray-800 bg-gray-100' value="bonus_balance">Bonus Balance</option>
            <option className='text-gray-800 bg-gray-100' value="withdrawal_balance">Withdrawal Balance</option>
            <option className='text-gray-800 bg-gray-100' value="game_balance">Game Balance</option>
            <option className='text-gray-800 bg-gray-100' value="coins">Coins</option>
            <option className='text-gray-800 bg-gray-100' value="totalDeposits">Total Deposits</option>
            <option className='text-gray-800 bg-gray-100' value="totalWithdrawals">Total Withdrawals</option>
            <option className='text-gray-800 bg-gray-100' value="createdAt">Created At</option>
          </select>

          {newFilter.field === 'createdAt' ? (
            <div className="flex gap-2">
              <input
                type="date"
                value={newFilter.min}
                onChange={(e) => handleFilterChange('min', e.target.value)}
                placeholder="Start Date"
                className="border border-gray-300 rounded-md p-2"
              />
              <input
                type="date"
                value={newFilter.max}
                onChange={(e) => handleFilterChange('max', e.target.value)}
                placeholder="End Date"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleAddFilter();
                  }
                }}
                className="border border-gray-300 rounded-md p-2"
              />
                      <button
  onClick={handleAddFilter} 
 className='text-white px-5 py-2 rounded-md bg-gradient-to-br from-blue-600 to-blue-500 hover:from-blue-700 hover:to-blue-600'
>
  Add
</button>
            </div>
          ) : ['bonus_balance', 'withdrawal_balance', 'game_balance', 'totalDeposits', 'totalWithdrawals'].includes(newFilter.field) ? (
            <div className="flex gap-2">
              <input
                type="number"
                value={newFilter.min}
                onChange={(e) => handleFilterChange('min', e.target.value)}
                placeholder="Min"
                className="border border-gray-300 rounded-md p-2"
              />
              <input
                type="number"
                value={newFilter.max}
                onChange={(e) => handleFilterChange('max', e.target.value)}
                placeholder="Max"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleAddFilter();
                  }
                }}
                className="border border-gray-300 rounded-md p-2"
              />
        <button
  onClick={handleAddFilter} 
  className='text-white px-5 py-2 rounded-md bg-gradient-to-br from-blue-600 to-blue-500 hover:from-blue-700 hover:to-blue-600'
>
  Add
</button>
            </div>
                
          ) : null}
        </div>
        <div className=" absolute flex flex-wrap gap-2">
          {filters.map((filter, index) => (
            <div key={index} className="flex items-center bg-gray-200 text-gray-800 rounded-full py-1 px-3 text-sm">
              <span className="mr-2">
                {filter.field}: {filter.field === 'createdAt'
                  ? `${filter.min} - ${filter.max}`
                  : (filter.min || filter.max ? `${filter.min} - ${filter.max}` : filter.value)}
              </span>
              <button
                type="button"
                onClick={() => handleEditFilter(index)}
                className="text-blue-500 hover:text-blue-700"
              >
                Edit
              </button>
              <button
                type="button"
                onClick={() => handleRemoveFilter(index)}
                className="ml-2 text-red-500 hover:text-red-700"
              >
                &times;
              </button>
            </div>
          ))}
        </div>
      </form>
    </div>
  );
};

export default DynamicFilterForm;
