import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";
import { BASE_URL } from "../../Constants/constant";
import Cookies from "js-cookie";

const BonusTable = () => {
  const [bonus, setBonus] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    joining_bonus: "",
    refferal_bonus: "",
    cash_bonus_percentage: "",
    reward_bonus_percentage: "",
    deposite_reward_percentage: "",
    deposite_cash_percentage: "",
    joining_reward_percentage: "",
    joining_cash_percentage: "",
    refferal_reward_percentage: "",
    refferal_cash_percentage: "",
  });

  const [range, setRange] = useState([]);

  useEffect(() => {
    fetchBonusData();
  }, []);

  const token = Cookies.get("token");

  const fetchBonusData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/v-1/bonus/getbonus`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data && response.data.data) {
        setBonus(response.data.data);
        setFormData(response.data.data);
        setRange(response.data.data.range || []);
      } else {
        toast.error("Invalid bonus data received.");
      }
    } catch (error) {
      toast.error("Failed to load bonus data.");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleRangeChange = (index, field, value) => {
    const updatedRange = [...range];

    updatedRange[index][field] = value;

    const fromValue = updatedRange[index].from || "";
    const toValue = updatedRange[index].to || "";

    updatedRange[index]["range_name"] = `range_${fromValue}_${toValue}`;

    setRange(updatedRange);
  };

  const addNewRange1 = async () => {
    if (!token) return toast.error("User authentication required.");

    const newRange = range.find((r) => !r.range_name || !r.value);
    if (newRange)
      return toast.error("Please fill all range fields before saving.");

    try {
      for (const r of range) {
        await axios.post(
          `${BASE_URL}/api/v-1/bonus/Addrange`,
          { range_name: r.range_name, value: r.value },
          { headers: { Authorization: `Bearer ${token}` } }
        );
      }

      toast.success("Range Added/Updated successfully.");
      setIsEditing(false);
      fetchBonusData();
    } catch (error) {
      toast.error("Failed to add new range.");
    }
  };

  const addNewRange = () => {
    setRange([...range, { range_name: "", value: "" }]);
  };

  const handleUpdate = async () => {
    try {
      const response = await axios.post(`${BASE_URL}/api/v-1/bonus/addbonus`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      toast.success("Bonus data updated successfully.");
      setIsEditing(false);
      fetchBonusData();
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to update bonus data.");
    }
  };
  

  if (!bonus) return <p>Loading...</p>;

  return (
    <div className="table-container overflow-x-auto p-4">
      <div>
        <p className="text-center pt-2 pb-2 text-2xl font-bold">
          Bonus Percentage Data
        </p>
        <div className="mb-4">
          {isEditing ? (
            <div>
              <button
                onClick={() => {
                  handleUpdate();
                  addNewRange1();
                }}
                className="bg-green-500 text-white px-4 py-2 rounded-lg mr-5"
              >
                Save Changes
              </button>
              <button
                onClick={() => {
                  setIsEditing(false);
                }}
                className="bg-red-500 text-white px-4 py-2 rounded-lg"
              >
                Cancel
              </button>
            </div>
          ) : (
            <button
              onClick={() => setIsEditing(true)}
              className="bg-blue-500 text-white px-4 py-2 rounded-lg"
            >
              Update Details
            </button>
          )}
        </div>
        <table className="table table-bordered w-full border border-gray-300">
          <thead className="bg-gray-100 sticky top-0 border border-gray-300 rounded-lg font-bold">
            <tr>
              <th>Sl.No</th>
              <th>Bonus Name</th>
              <th>Amount</th>
              <th>Percentage</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 text-lg">
            {[
              {
                name: "Joining Bonus",
                key: "joining_bonus",
                rewardKey: "joining_reward_percentage",
                cashbackKey: "joining_cash_percentage",
              },
              {
                name: "Referral Bonus",
                key: "refferal_bonus",
                rewardKey: "refferal_reward_percentage",
                cashbackKey: "refferal_cash_percentage",
              },
              {
                name: "Deposite Bonus",
                key: "deposite_bonus",
                rewardKey: "deposite_reward_percentage",
                cashbackKey: "deposite_cash_percentage",
              },
              {
                name: "Game Joining Amount Deduction",
                key: "bonus",
                rewardKey: "reward_bonus_percentage",
                cashbackKey: "cash_bonus_percentage",
              },
            ].map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.name}</td>
                <td>
                  {isEditing ? (
                    <input
                      type="number"
                      name={item.key}
                      value={formData[item.key]}
                      onChange={handleInputChange}
                      className="border p-1 w-20 "
                      disabled={item.key === "bonus" || "deposite_bonus"}
                    />
                  ): item.key === "bonus" ? (
                    "If user joins game, how much % he can use from each wallet"
                  ) : item.key === "deposite_bonus" ? (
                    "Value based on amount deposited by user"
                  ) : (
                    `₹ ${bonus[item.key] || 0}.00`
                  )}
                </td>
                <td>
                  Reward Bonus ={" "}
                  {isEditing ? (
                    <input
                      type="number"
                      name={item.rewardKey}
                      value={formData[item.rewardKey]}
                      onChange={handleInputChange}
                      className="border p-1 w-20"
                    />
                  ) : (
                    `${bonus[item.rewardKey]}%`
                  )}
                  <br />
                  Cashback Bonus ={" "}
                  {isEditing ? (
                    <input
                      type="number"
                      name={item.cashbackKey}
                      value={formData[item.cashbackKey]}
                      onChange={handleInputChange}
                      className="border p-1 w-20"
                    />
                  ) : (
                    `${bonus[item.cashbackKey]}%`
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Deposit Bonus Section */}
      <div>
        <p className="text-center pt-2 pb-2 text-2xl font-bold">
          Deposit Amount List and Bonus Distribution
        </p>
        <table className="table table-bordered w-full border border-gray-300">
          <thead className="bg-gray-100 sticky top-0 border border-gray-300 rounded-lg">
            <tr>
              <th>Sl.No</th>
              <th>Amount Range</th>
              <th>Return Bonus Amount</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 text-lg">
            {range.map((amount, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>
                  {isEditing ? (
                    <div>
                      <div>
                        From Range:
                        <input
                          type="text"
                          value={amount.range_name.split("_")[1] || ""}
                          onChange={(e) =>
                            handleRangeChange(index, "from", e.target.value)
                          }
                          className="border p-1 w-40"
                          required
                        />
                        &ensp;&ensp;To Value:
                        <input
                          type="text"
                          value={amount.range_name.split("_")[2]}
                          onChange={(e) =>
                            handleRangeChange(index, "to", e.target.value)
                          }
                          className="border p-1 w-40"
                          required
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="">
                      From Ranve:{amount.range_name.split("_")[1]}&ensp;&ensp;
                      To Value:{amount.range_name.split("_")[2]}
                    </div>
                  )}
                </td>
                <td>
                  {isEditing ? (
                    <input
                      type="number"
                      required
                      value={amount.value}
                      onChange={(e) =>
                        handleRangeChange(index, "value", e.target.value)
                      }
                      className="border p-1 w-20"
                    />
                  ) : (
                    `${amount.value}%`
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="mb-4">
          {isEditing ? (
            <div>
              <button
                onClick={() => {
                  handleUpdate();
                  addNewRange1();
                }}
                className="bg-green-500 text-white px-4 py-2 rounded-lg mr-5"
              >
                Save Range
              </button>

              <button
                onClick={() => {
                  setIsEditing(false);
                }}
                className="bg-red-500 text-white px-4 py-2 rounded-lg"
              >
                Cancel
              </button>
            </div>
          ) : (
            <button
              onClick={() => {
                setIsEditing(true);
                addNewRange();
              }}
              className="bg-blue-500 text-white px-4 py-2 rounded-lg"
            >
              + Add New Range
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default BonusTable;
