
import axios from 'axios';
import { BASE_URL } from '../../Constants/constant.js';

const handleError = (error) => {
  if (error.response) {
    return error.response;
  } else if (error.request) {
    return { status: 500, data: { message: 'No response received from server' } };
  } else {
    return { status: 500, data: { message: error.message } };
  }
};

export const postApi = async (url, data, config = {}) => {
  try {
    const response = await axios.post(`${BASE_URL}/${url}`, data, config);
    return response;
  } catch (error) {
    return handleError(error);
    console.log("123")
  }
};

export const putApi = async (url, data, config = {}) => {
  try {
    const response = await axios.put(`${BASE_URL}/${url}`, data, config);
    return response;
  } catch (error) {
    return handleError(error);
  }
};

// Update Password API request function
export const updatePassword = async (url,data, config = {}) => {
  try {
    const response = await putApi(`${BASE_URL}/${url}`, data, config);
    return response;
  } catch (error) {
    return handleError(error);
  }
};


export const getApi = async (url, config = {}) => {
  try {
    const response = await axios.get(`${BASE_URL}/${url}`, config);
    return response;
  } catch (error) {
    return handleError(error);
  }
};


export const deleteApi = async (url, config = {}) => {
  try {
    const response = await axios.delete(`${BASE_URL}/${url}`, config);
    return response;
  } catch (error) {
    return handleError(error);
  }
};

// Login API request function
export const login = async (credentials) => {
  try {
    const response = await postApi('api/v-1/employee/login', credentials);
    return response;
  } catch (error) {
    return handleError(error);
  }
};


