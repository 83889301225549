// src/PayoutForm.js
import React, { useState } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../Constants/constant';

const PayoutForm = () => {
    const [receiverId, setReceiverId] = useState('');
    const [receiverPhone, setReceiverPhone] = useState('');
    const [receiverEmail, setReceiverEmail] = useState('');
    const [payoutAmount, setPayoutAmount] = useState('');
    const [payoutCurrency, setPayoutCurrency] = useState('USD'); // Default currency
    const [message, setMessage] = useState('');
    const [payoutId, setPayoutId] = useState('');
    const [status, setStatus] = useState('');
    const token = localStorage.getItem('token');
    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage('');

        try {
            const response = await axios.post(`${BASE_URL}/api/v-1/payouts/create`, {
                receiver_id: receiverId,
                receiver_phone: receiverPhone,
                receiver_email: receiverEmail,
                payout_amount: payoutAmount,
                payout_currency: payoutCurrency,
            },{
                headers:{
                    'Authorization': `Bearer ${token}`,
                }
            });

            setMessage(`Payout created! Payout ID: ${response.data.payout_id}`);
            setPayoutId(response.data.payout_id);
            setStatus(response.data.payout_status);
        } catch (error) {
            setMessage('Error creating payout: ' + error.response.data.message);
        }
    };

    const verifyPayout = async () => {
        setMessage('');
        try {
            const response = await axios.get(`http://localhost:5000/api/payouts/verify/${payoutId}`);
            setStatus(response.data.payout_status);
        } catch (error) {
            setMessage('Error verifying payout: ' + error.response.data.message);
        }
    };

    return (
        <div>
            <h2>Request a Payout</h2>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    placeholder="Receiver ID"
                    value={receiverId}
                    onChange={(e) => setReceiverId(e.target.value)}
                    required
                />
                <input
                    type="text"
                    placeholder="Receiver Phone"
                    value={receiverPhone}
                    onChange={(e) => setReceiverPhone(e.target.value)}
                    required
                />
                <input
                    type="email"
                    placeholder="Receiver Email"
                    value={receiverEmail}
                    onChange={(e) => setReceiverEmail(e.target.value)}
                    required
                />
                <input
                    type="number"
                    placeholder="Payout Amount"
                    value={payoutAmount}
                    onChange={(e) => setPayoutAmount(e.target.value)}
                    required
                />
                <select
                    value={payoutCurrency}
                    onChange={(e) => setPayoutCurrency(e.target.value)}
                >
                    <option value="USD">USD</option>
                    <option value="INR">INR</option>
                    {/* Add more currencies as needed */}
                </select>
                <button type="submit">Request Payout</button>
            </form>

            {message && <p>{message}</p>}

            <h3>Verify Payout Status</h3>
            <input
                type="text"
                placeholder="Payout ID"
                value={payoutId}
                onChange={(e) => setPayoutId(e.target.value)}
            />
            <button onClick={verifyPayout}>Verify Payout</button>

            {status && <p>Payout Status: {status}</p>}
        </div>
    );
};

export default PayoutForm;
