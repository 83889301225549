import { all } from 'redux-saga/effects';
import watchUserSaga from './userSaga.js';
import watchEmployeeSaga from './employeeSaga.js';
import watchSearchSaga from './Searchsaga.js';

export default function* rootSaga() {
  yield all([
    watchEmployeeSaga(),
    watchUserSaga(),
    watchSearchSaga()
  
  ]);
}
