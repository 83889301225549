import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteJobApplicationById, fetchAllJobsApplication } from '../../../Redux/slices/websiteSlice';
import { Download, File, X } from 'lucide-react';
import DatePicker from 'react-datepicker'; // Import DatePicker
import "react-datepicker/dist/react-datepicker.css"; // Import the CSS for DatePicker
import ConfirmDeletePopup from '../ConfirmDeletePopup';



const periods = [{id:"Monthly",name:'This Month'},{id:"Weekly",name:"Last 7 days"},{id:"Daily",name:"Today"},{id:'Yearly',name:"This Year"},{id:'Custom',name:"Custom"}]

const JobApplications = () => {
    const { AllJobsApplication } = useSelector(state => state.website);
    const [activePeriods,setPeriods] = useState('Monthly')

    const dispatch = useDispatch();
    
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedJob, setSelectedJob] = useState(null);
    const HandleDateChanged = (start,end)=>{
        setStartDate(start)
        setEndDate(end)
    }
    const handlePeriodChange = (e) => {
        setPeriods(e.target.value);
        const getPeriodDates = () => {
            const today = new Date();
            let start, end;
    
            switch (e.target.value) {
                case 'Daily':
                    start = new Date(today.setHours(0, 0, 0, 0)); // Start of today
                    end = new Date(today.setHours(23, 59, 59, 999)); // End of today
                    break;
    
                case 'Weekly':
                    // Calculate the start and end of the last 7 days
                        start = new Date(today);
                        start.setDate(today.getDate() - 7); // Set start to 7 days ago
                        start.setHours(0, 0, 0, 0); // Start of the day (midnight)

                        end = new Date(today);
                        end.setHours(23, 59, 59, 999); // End of the current day (just before midnight)
                    break;
    
                case 'Monthly':
                    start = new Date(today); // Start of the current month
                    start.setDate(today.getDate() - 30);
                    start.setHours(0, 0, 0, 0); // Set start time to midnight (start of the day)
                    end = new Date(today); // End of the current month
                    end.setDate(end.getDate() + 30);
                    end.setHours(23, 59, 59, 999); // End of the day (just before midnight)
                    break;
                case 'Yearly':
                        start = new Date(today);
                        start.setDate(today.getDate() - 365); // Set start to 365 days ago
                        start.setHours(0, 0, 0, 0); // Set start time to midnight (start of the day)
                    
                        // Set end to today, at the end of the day
                        end = new Date(today);
                        end.setHours(23, 59, 59, 999); // End of the day (just before midnight)
                        break;
    
                default:
                    // const today = new Date();
                    start = new Date(today);
                    start.setDate(today.getDate() - 1); // Set start as yesterday
                    start.setHours(0, 0, 0, 0); // Set start time to midnight (start of the day)
                    end = new Date(today);
                    end.setDate(today.getDate() + 1); // Set end as tomorrow
                    end.setHours(23, 59, 59, 999); // End of the day (just before midnight)
            }
    
            return { start, end };
        };
        const { start, end } = getPeriodDates();
        setStartDate(start);
        setEndDate(end);
    };

    const openModal = (job) => {
        setSelectedJob(job);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedJob(null);
    };

    const handleSave = async (updatedJobApplication) => {
        // Add save functionality here
    };
    const deleteJobApplication = async (jobApplicationId)=>{
        await dispatch(deleteJobApplicationById({appId:jobApplicationId}))
        const s = new Date(startDate).toISOString().split("T")[0];
        const e = new Date(endDate).toISOString().split("T")[0];
        dispatch(fetchAllJobsApplication({ s, e, period: activePeriods}));
        setIsModalOpen(false);
    }

    useEffect(() => {
        const s = new Date(startDate).toISOString().split("T")[0];
        const e = new Date(endDate).toISOString().split("T")[0];
        dispatch(fetchAllJobsApplication({ s, e, period: activePeriods}));
    }, [dispatch, startDate, endDate,activePeriods]); // Ensure dependency array includes startDate and endDate

    console.log('JobApplications', AllJobsApplication);

    return (
        <div className="overflow-x-auto bg-white p-6 min-h-screen border-b border-gray-700">
            <div className="flex space-x-3 mb-4">
                {/* Start Date Picker */}
                <div>
                    <label className="block text-sm font-medium">Start Date</label>
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => {
                            HandleDateChanged(date,endDate)
                            // setStartDate(date)
                        }} // Set start date on change
                        dateFormat="yyyy-MM-dd"
                        className="p-2 border rounded-md"
                    />
                </div>

                {/* End Date Picker */}
                <div>
                    <label className="block text-sm font-medium">End Date</label>
                    <DatePicker
                        selected={endDate}
                        onChange={(date) => {
                            HandleDateChanged(startDate,date)
                            // setEndDate(date)
                        }} // Set end date on change
                        dateFormat="yyyy-MM-dd"
                        className="p-2 border rounded-md"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium">Period</label>
                    <select
                        value={activePeriods}
                        onChange={(e) => {
                            handlePeriodChange(e);
                        }}
                        className="block border p-2 w-full pl-3 pr-10 py-2 text-sm border-gray-300 rounded-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm"
                    >
                        <option value="">Select Period</option>
                        {periods.map((period) => (
                            <option key={period.id} value={period.id}>{period.name}</option>
                        ))}
                    </select>
                </div>
            </div>

            <TableHeader />
            {/* Table Rows */}
            {AllJobsApplication && AllJobsApplication.length > 0 && AllJobsApplication.map((jobApplication, index) => (
                <div key={jobApplication._id || index} onClick={() => openModal(jobApplication)} className="cursor-pointer hover:bg-gray-100">
                    <TableRow jobApplication={jobApplication} />
                </div>
            ))}

            {/* Modal for editing jobApplication */}
            {
                isModalOpen && <ApplicationPreview
                    jobApplication={selectedJob}
                    onClose={closeModal}
                    onSave={handleSave}
                    onJobApplicationDelete = {deleteJobApplication}
                />
            }
        </div>
    );
};
const TableHeader = () => {
    return (
        <div className={`grid grid-cols-8 gap-2 text-center p-4 font-semibold justify-center items-center text-black bg-gray-200 rounded-t-lg`}>
            <div className="px-4 py-2 text-base text-center">First Name</div>
            <div className="px-4 py-2 text-base text-center">Last Name</div>
            <div className="px-4 py-2 text-base text-center">Email</div>
            <div className="px-4 py-2 text-base text-center">Phone Number</div>
            <div className="px-4 py-2 text-base text-center">Resume</div>
            <div className="px-4 py-2 text-base text-center">Portfolio</div>
            <div className="px-4 py-2 text-base text-center">Posting Date</div>
            <div className="px-4 py-2 text-base text-center">Job Applied</div>
            
        </div>
    );
};

const TableRow = ({ jobApplication }) => {
    return (
        <div className="grid grid-cols-8 text-center gap-4 p-4 border-b justify-center items-center cursor-pointer hover:bg-gray-50 transition ease-in-out duration-150">
            <div className="text-center text-sm">{jobApplication?.firstName}</div>
            <div className="text-center text-sm">{jobApplication?.lastName}</div>
            <div className="text-center text-sm max-w-xs truncate">{jobApplication?.email}</div>
            <div className="text-center text-sm">{jobApplication?.phone}</div>
            <div className="text-center text-sm">
                <FileView jobApplication={jobApplication}/>
            </div>
            <div className="text-center text-sm">{jobApplication?.portfolio}</div>
            <div className="text-center text-sm">
                {jobApplication?.createdAt
                    ? new Date(jobApplication.createdAt).toLocaleDateString()
                    : "N/A"}
            </div>
            <div className="text-center text-sm">{jobApplication?.jobId?.title}</div>
        </div>
    );
};
const FileView = ({ jobApplication }) => {
    // This function will render the file view or download link
    const renderFile = () => {
        const file = jobApplication?.resume; // The file URL (assumed to be a URL to the resume file)

        if (!file) {
            return <p>No resume available</p>;
        }

        // Check file extension to render different types of file previews or links
        const fileExtension = file.split('.').pop().toLowerCase();

        switch (fileExtension) {
            case 'pdf':
                return (
                    <div className="flex justify-center items-center">
                        <embed
                            src={file}
                            type="application/pdf"
                            width="600px"
                            height="800px"
                            alt="Resume PDF"
                        />
                    </div>
                );

            case 'doc':
            case 'docx':
                return (
                    <div>
                        <a href={file} target="_blank" rel="noopener noreferrer">
                            <button className="bg-red-500 text-white p-2 rounded">
                                View Resume (Word)
                            </button>
                        </a>
                    </div>
                );

            case 'jpg':
            case 'jpeg':
            case 'png':
                return (
                    <div className="flex justify-center items-center">
                        <img
                            src={file}
                            alt="Resume Image"
                            className="max-w-full h-auto border border-gray-300"
                        />
                    </div>
                );

            default:
                return (
                    <div>
                        <a href={file} target="_blank" rel="noopener noreferrer">
                            <button className="bg-red-500 text-white p-2 rounded">
                                Download Resume
                            </button>
                        </a>
                    </div>
                );
        }
    };

    return (
        <div className="text-center text-sm">
            {jobApplication?.resume && renderFile()}
        </div>
    );
};

const ApplicationPreview = ({ jobApplication,onJobApplicationDelete, onClose, onSave }) => {
    const[deletingJobAppId,setDeletingJobAppId] = useState(null);
    const [formData, setFormData] = useState({ ...jobApplication });

    const handleChange = (e) => {
        const { name, value,type,checked } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: type  === 'checkbox' ? checked:value}));
    };

    const handleSave = () => {
        onSave(formData); // Save the updated data
        onClose(); // Close the modal
    };
    const deleteJobApplication = async () => {
        // Add delete functionality here
        onJobApplicationDelete(jobApplication?._id);
        setDeletingJobAppId(null);
    }
    if (!jobApplication) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg w-3/4 sm:w-1/2 max-h-[700px] overflow-y-auto relative">
                <h2 className="text-2xl font-semibold mb-4 text-center">Job Application Preview</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                    {/* First Name */}
                    <div className="flex flex-col space-y-2">
                        <label className="text-sm font-semibold">First Name</label>
                        <div className="border p-2">{formData?.firstName || "N/A"}</div>
                    </div>

                    {/* Last Name */}
                    <div className="flex flex-col space-y-2">
                        <label className="text-sm font-semibold">Last Name</label>
                        <div className="border p-2">{formData?.lastName || "N/A"}</div>
                    </div>

                    {/* Email */}
                    <div className="flex flex-col space-y-2">
                        <label className="text-sm font-semibold">Email</label>
                        <div className="border p-2">{formData?.email || "N/A"}</div>
                    </div>

                    {/* Phone */}
                    <div className="flex flex-col space-y-2">
                        <label className="text-sm font-semibold">Phone</label>
                        <div className="border p-2">{formData?.phone || "N/A"}</div>
                    </div>

                    {/* Portfolio */}
                    <div className="flex flex-col space-y-2">
                        <label className="text-sm font-semibold">Portfolio</label>
                        <a href={formData?.portfolio} target="_blank" className="border hover:underline text-green-500 text-sm p-2">
                            {formData?.portfolio || "No Portfolio Link Submitted"}
                        </a>
                    </div>

                    {/* Github */}
                    <div className="flex flex-col space-y-2">
                        <label className="text-sm font-semibold">GitHub</label>
                        {formData?.github ? (
                            <a href={formData?.github} target="_blank" className="border hover:underline text-gray-300 text-sm p-2">
                                {formData?.github}
                            </a>
                        ) : (
                            <span className="text-red-400 text-sm">No GitHub Link Found</span>
                        )}
                    </div>

                    {/* LinkedIn */}
                    <div className="flex flex-col space-y-2">
                        <label className="text-sm font-semibold">LinkedIn</label>
                        {formData?.linkedin ? (
                            <a href={formData?.linkedin} target="_blank" className="border hover:underline text-gray-300 text-sm p-2">
                                {formData?.linkedin}
                            </a>
                        ) : (
                            <span className="text-red-400 text-sm">No LinkedIn Link Found</span>
                        )}
                    </div>

                    {/* Resume */}
                    <div className="flex flex-col space-y-2 col-span-2">
                        <label className="text-sm font-semibold">Resume</label>
                        <div className="border p-2">
                            {formData?.resume ? (
                                <div>
                                    <h3 className="font-semibold mb-2">Resume Preview</h3>
                                    <img
                                        src={formData?.resume}
                                        alt="Resume"
                                        className="w-full h-64 object-cover mb-4"
                                    />
                                    <a
                                        href={formData?.resume}
                                        download={`${formData?.firstName}-${formData.lastName}_resume.pdf`}
                                        target='_blank'
                                        className="block text-red-500 underline"
                                    >
                                        <Download/>
                                    </a>
                                </div>
                            ) : (
                                <span>No Resume Available</span>
                            )}
                        </div>
                    </div>

                    {/* Action Buttons */}
                    <div className="mt-4 flex justify-end col-span-2 space-x-4 w-full">
                        <button
                            onClick={()=> setDeletingJobAppId(jobApplication._id)}
                            className="bg-red-600 text-white py-2 px-6 rounded-lg w-full"
                        >
                            Delete Application
                        </button>
                    </div>
                </div>
                <div className="mt-4 flex justify-end col-span-2 space-x-4 absolute top-0 right-0">
                    <button
                        onClick={onClose}
                        className="text-black py-2 px-6 rounded-lg"
                    >
                        <X/>
                    </button>
                </div>
                
            </div>
            <ConfirmDeletePopup
                isOpen={deletingJobAppId !== null}
                onCancel={() => setDeletingJobAppId(null)}
                onConfirm={deleteJobApplication}
            />
        </div>


    );
};

export default JobApplications