import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  status: '',
  isLoading: true,
  data: {
    users: [],
    totalUserCount: 0,
    currentPage: 0,
    pageSize: 1,
    totalPages: 0,
    reportpendingcount:0,
    searchUser:[]
  },
  error: null,
  selectedUserId: null,
  pagecount:null
};

const userSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    fetchRequest(state,action) {
      console.log(action.payload,"action.payload")
      state.status = 'loading';
      state.isLoading = true;
      state.pageSize=action.payload
      state.error = null;
    },
    fetchSuccess(state, action) {
      state.data = action.payload;
      state.status = 'succeeded';
      state.isLoading = false;
    },
    fetchFailure(state, action) {
      state.error = action.payload;
      state.status = 'failed';
      state.isLoading = false;
    },

    updateRequest(state, action) {
      state.status = 'loading';
      state.isLoading = true;
      state.error = null;
  },

  updateSuccess(state, action) {
      const updatedUser = action.payload;
      state.data.users = state.data.users.map(user =>
          user._id === updatedUser._id ? updatedUser : user
      );
      state.status = 'succeeded';
      state.isLoading = false;
  },

  updateFailure(state, action) {
      state.error = action.payload;
      state.status = 'failed';
      state.isLoading = false;
  },
  resetState(state) {
      return initialState;
  },

  

    setSelectedUserId(state, action) { 
      state.selectedUserId = action.payload;
    },
    setPagecount(state, action) { 
      state.pagecount = action.payload;
    },
  },
});

export const {
  fetchRequest,
  fetchSuccess,
  fetchFailure,
  updateRequest,
  updateSuccess,
  updateFailure,
  resetState,
  setSelectedUserId,
  setPagecount,

} = userSlice.actions;

export default userSlice.reducer;
