import { useEffect, useState } from 'react';
import Sidebar from '../Frame/Sidebar';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRequest, setSelectedUserId } from '../../Redux/slices/userSlice.js';
import { useUser } from '../../Constants/context.js';
import { selectUsers } from '../../Redux/Selector/userSelector.js';
import SendPushNotification from './SendPushNotification.jsx';
import DynamicFilterForm from '../Report/Filter.jsx';
import axios from 'axios';
import { BASE_URL } from '../../Constants/constant.js';


export const PushNotification = () => {
    const dispatch = useDispatch();
    const users = useSelector(selectUsers);
    const status = useSelector(state => state.data.status);
    const error = useSelector(state => state.data.error);
    const [selectedUserId, setSelectedUserIdLocal] = useState(null);
    const [activeFilter, setActiveFilter] = useState('all');
    const { Admin, Sales, Telecaller, Verification, Market } = useUser();
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [fcmTokens, setFcmTokens] = useState([]);
    const [allSelected, setAllSelected] = useState(false); 
    const [showNotificationForm, setShowNotificationForm] = useState(false);
    const handleSendNotification  = () => setShowNotificationForm(true);
    const handleCloseSendNotification  = () => setShowNotificationForm(false);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const token = localStorage.getItem('token');
    
    useEffect(() => {
        dispatch(fetchRequest());
    }, [dispatch]);

    const handleViewClick = (userId) => {
        setSelectedUserIdLocal(userId);
        dispatch(setSelectedUserId(userId));
    };

    useEffect(() => {
        const tokens = users
          .filter(user => selectedUsers.includes(user._id))
          .map(user => ({
            token: user.fcmTokens[0]
          }));
        
        console.log(tokens);
        setFcmTokens(tokens);
      }, [selectedUsers, users]);


    const handleCheckboxChange = (userId) => {
        setSelectedUsers(prevSelected => {
            if (prevSelected.includes(userId)) {
                return prevSelected.filter(id => id !== userId);
              
            } else {
                return [...prevSelected, userId];
            }
        });
    };

    const handleSelectAll = () => {
        if (allSelected) {
            setSelectedUsers([]);
        } else {
            const allUserIds = filteredUsers.map(user => user._id);
            setSelectedUsers(allUserIds);
        }
        setAllSelected(prevState => !prevState); 
    };

    const handleFiltersApplied = async (filters) => {
        try {
            const response = await axios.post(`${BASE_URL}/api/v-1/user/filter`, filters,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                      },
                });
            setFilteredUsers(response.data);
            setSelectedUsers([]);
            setAllSelected(false)
        } catch (error) {
            console.error('Error fetching filtered users:', error);
        }
    };

    return (
        <Sidebar>
            <div className={`p-4 xl:ml-80 flex flex-col`}>
           
                    <div>
                       <div className='flex w-screen'>
                       <div className='me-5 '> 
                        <DynamicFilterForm className='relative' onFiltersApplied={handleFiltersApplied} />
                        </div>
                        <div className=' '>
                        <button
                            type="button"
                            className={`text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 ${activeFilter === 'all' ? 'bg-blue-500' : ''}`}
                            onClick={handleSendNotification}
                        >
                            Send Notification
                        </button>
                
                        </div>
                      
                       </div>
                        
                        {showNotificationForm && (
                                <div className="fixed inset-0 flex items-center justify-center z-50">
                                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                                    <div className="bg-white rounded-lg p-8 z-10">
                                        <button
                                            className="absolute top-0 right-0 p-3"
                                            onClick={handleCloseSendNotification}
                                        >
                                            Close
                                        </button>
                                        <SendPushNotification onClose={handleCloseSendNotification} notificactiontokens={fcmTokens}/>
                                    </div>
                                </div>
                            )}
                    </div>
            
                <div className="-my-2 mt-10 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <div className="table-container-button overflow-x-auto ">
                                <table className="table table-bordered w-full">
                                    <thead className="bg-gray-100 sticky top-0">
                                        <tr>
                                            <th
                                                type="button"
                                              className='cursor-pointer'
                                                onClick={handleSelectAll}
                                            >
                                              {allSelected ? 'Deselect All' : 'Select All'}
                                            </th>
                                            <th className="px-6 py-3 text-left">Sl.No</th>
                                            <th className="px-6 py-3 text-left">Profile</th>
                                            <th className="px-6 py-3 text-left">Name</th>
                                            <th className="px-6 py-3 text-left">Phone Number</th>
                                            {Verification && (
                                                <th className="px-6 py-3 text-left">Aadhar</th>
                                            )}
                                            {Verification && (
                                                <th className="px-6 py-3 text-left">Pan</th>
                                            )}
                                            {Verification && (
                                                <th className="px-6 py-3 text-left">Bankdetails</th>
                                            )}
                                            {Verification && (
                                                <th className="px-6 py-3 text-left">Final</th>
                                            )}


                                            <th className="px-6 py-3 text-left">Registered Date</th>
                                            {(Admin || Telecaller) && (
                                                <th className="px-6 py-3 text-left">Action</th>
                                            )}
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                        {status === 'loading' ? (
                                            <tr>
                                                <td colSpan="12" className="px-6 py-4 text-center">Loading...</td>
                                            </tr>
                                        ) : status === 'failed' ? (
                                            <tr>
                                                <td colSpan="12" className="px-6 py-4 text-center text-red-500">{error}</td>
                                            </tr>
                                        ) : (
                                            filteredUsers.map((user, index) => (
                                                <tr key={user._id} className={index % 2 === 0 ? "bg-white" : "bg-gray-100"}>
                                                    <td className="px-6 py-4">
                                                        <input
                                                            type="checkbox"
                                                            checked={selectedUsers.includes(user._id)}
                                                            onChange={() => handleCheckboxChange(user._id)}
                                                        />
                                                    </td>
                                                    <td className="px-6 py-4">{index + 1}</td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="flex items-center">
                                                            <div className="flex-shrink-0 h-10 w-10">
                                                                <img className="h-10 w-10 rounded-full" src={user.image || '/default-image.png'} alt="" />
                                                            </div>

                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className="text-sm font-medium text-gray-900">{user.name || 'No Name'}</div>
                                                    </td>

                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="text-sm text-gray-500">{user.phoneNumber || 'No phoneNumber'}</div>
                                                    </td>
                                                    {Verification && (
                                                        <td className="px-6 py-4 whitespace-nowrap ">
                                                            <span className={`px-2 mr-2 inline-flex text-xs leading-5 font-semibold rounded-full ${user.verificationstatusaadharcard ? 'bg-red-100 text-red-800' : 'bg-green-100 text-green-800'}`}>
                                                                {user.verificationstatusaadharcard ? 'Not Verified' : 'Verified'}
                                                            </span>
                                                        </td>
                                                    )}
                                                    {Verification && (
                                                        <td className="px-6 py-4 whitespace-nowrap ">
                                                            <span className={`px-2 mr-2 inline-flex text-xs leading-5 font-semibold rounded-full ${user.verificationstatuspancard ? 'bg-red-100 text-red-800' : 'bg-green-100 text-green-800'}`}>
                                                                {user.verificationstatuspancard ? 'Not Verified' : 'Verified'}
                                                            </span>
                                                        </td>
                                                    )}
                                                    {Verification && (
                                                        <td className="px-6 py-4 whitespace-nowrap ">
                                                            <span className={`px-2 mr-2 inline-flex text-xs leading-5 font-semibold rounded-full ${user.verificationstatusbank ? 'bg-red-100 text-red-800' : 'bg-green-100 text-green-800'}`}>
                                                                {user.verificationstatusbank ? 'Not Verified' : 'Verified'}
                                                            </span>
                                                        </td>
                                                    )}
                                                    {Verification && (
                                                        <td className="px-6 py-4 whitespace-nowrap ">
                                                            <span className={`px-2 mr-2 inline-flex text-xs leading-5 font-semibold rounded-full ${user.verificationstatus ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                                                                {user.verificationstatus ? 'Verified' : 'Not Verified'}
                                                            </span>
                                                        </td>
                                                    )}



                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="text-sm text-gray-500">{user.createdAt.split('T')[0]}</div>
                                                    </td>
                                                    {(Admin || Telecaller || Verification) && (
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                                                            {(Admin || Telecaller) && (
                                                                <Link
                                                                    to={`/view_user_details/${user._id}`}
                                                                    onClick={() => handleViewClick(user._id)}
                                                                    className="cursor-pointer text-green-600 hover:text-green-900"
                                                                >
                                                                    View
                                                                </Link>
                                                            )}
                                                            {Verification && (
                                                                <Link
                                                                    to={`/verification_details/${user._id}`}
                                                                    // onClick={() => handleViewClick(user._id)}
                                                                    className="cursor-pointer text-blue-600 hover:text-blue-900 ms-4"
                                                                >
                                                                    Verify
                                                                </Link>
                                                            )}
                                                        </td>
                                                    )}
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Sidebar>
    );
};


