import React, { createContext, useState, useContext, useEffect } from 'react';
import Cookies from 'js-cookie';

// UserContext
const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [userRoles, setUserRoles] = useState(() => {
        try {
            const roles = Cookies.get('userRoles');
            return roles ? JSON.parse(roles) : []; // Handle undefined or invalid JSON
        } catch (error) {
            console.error("Error parsing userRoles from cookies:", error);
            return [];
        }
    });

    useEffect(() => {
        Cookies.set('userRoles', JSON.stringify(userRoles));
    }, [userRoles]);

    // Define role-based flags
    const Admin = userRoles.includes("Admin") || userRoles.includes("Telecaller Team") || userRoles.includes("Verification Team") || userRoles.includes("Market Team");
    const Telecaller = userRoles.includes("Telecaller Team");
    const Verification = userRoles.includes("Verification Team");
    const Market = userRoles.includes("Market Team");

    return (
        <UserContext.Provider value={{ userRoles, setUserRoles, Admin, Telecaller, Verification, Market }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => {
    return useContext(UserContext);
};

// AuthContext
const AuthContext = createContext();

export const useAuthContext = () => {
    return useContext(AuthContext);
};

export const AuthContextProvider = ({ children }) => {
    const [authUser, setAuthUser] = useState(() => {
        const token = Cookies.get("token");
        return token ? { token } : null;
    });

    useEffect(() => {
        if (authUser) {
            Cookies.set("token", authUser.token);
        } else {
            Cookies.remove("token"); // Fix typo (remtem -> remove)
        }
    }, [authUser]);

    return (
        <AuthContext.Provider value={{ authUser, setAuthUser }}>
            {children}
        </AuthContext.Provider>
    );
};

export const CombinedProvider = ({ children }) => {
    return (
        <AuthContextProvider>
            <UserProvider>
                {children}
            </UserProvider>
        </AuthContextProvider>
    );
};



// import React, { createContext, useState, useContext, useEffect } from 'react';
// import Cookies from "js-cookie";

// // UserContext
// const UserContext = createContext();

// export const UserProvider = ({ children }) => {
//     const [userRoles, setUserRoles] = useState(() => {
//         try {
//             const userInfoString = Cookies.get('userInfo');
//             const userInfo = userInfoString ? JSON.parse(userInfoString) : null;
//             return userInfo?.access || []; 
//         } catch (error) {
//             console.error("Error parsing userRoles from cookies:", error);
//             return [];
//         }
//     });

//     const Admin = userRoles.includes("Admin");
//     const Telecaller = userRoles.includes("Telecaller Team");
//     const Verification = userRoles.includes("Verification Team");
//     const Market = userRoles.includes("Market Team");

//     return (
//         <UserContext.Provider value={{ userRoles, setUserRoles, Admin, Telecaller, Verification, Market }}>
//             {children}
//         </UserContext.Provider>
//     );
// };

// export const useUser = () => {
//     return useContext(UserContext);
// };

// // AuthContext
// const AuthContext = createContext();

// export const useAuthContext = () => {
//     return useContext(AuthContext);
// };

// export const AuthContextProvider = ({ children }) => {
//         const [authUser, setAuthUser] = useState(() => {
//             const token = Cookies.get("token");
//             return token ? { token } : null;
//         });
    
//         useEffect(() => {
//             if (authUser) {
//                 Cookies.set("token", authUser.token);
//             } else {
//                 Cookies.remove("token"); 
//             }
//         }, [authUser]);
    
//         return (
//             <AuthContext.Provider value={{ authUser, setAuthUser }}>
//                 {children}
//             </AuthContext.Provider>
//         );
//     };
    

// export const CombinedProvider = ({ children }) => {
//     return (
//         <AuthContextProvider>
//             <UserProvider>
//                 {children}
//             </UserProvider>
//         </AuthContextProvider>
//     );
// };
