import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllFAQSWebsite, removeFAQSWebsite, setFAQSWebsite } from '../../../Redux/slices/websiteSlice';
import toast from 'react-hot-toast';

const FaqManagementComponents = () => {
    const{faqsWebsite} = useSelector(state => state.website);
	const dispatch = useDispatch();
	// const [faqs, setFaqs] = useState([]);
	const [question, setQuestion] = useState('');
	const [answer, setAnswer] = useState('');
    const handleAction = async (action,params)=> {
        if(params){
            await dispatch(action(params));
        }else{
            await dispatch(action());
        }
        dispatch(fetchAllFAQSWebsite());
    }

	// Handle form submission to add FAQ
	const handleSubmit = async (e) => {
		e.preventDefault();
		if (question && answer) {
            handleAction(setFAQSWebsite,{question,answer});
			toast.success('FAQ added successfully.');
			setQuestion('');
			setAnswer('');
		} else {
			toast.error('Please provide both question and answer.');
		}
	};
	const removeFAQ = async (id)=>{
        handleAction(removeFAQSWebsite,{faqId:id});
        // await dispatch(removeFAQSWebsite({faqId:id}));
        // dispatch(fetchAllFAQSWebsite());
        toast.success('FAQ removed successfully.');
	}
	useEffect(()=>{
		dispatch(fetchAllFAQSWebsite());
	},[dispatch])
	console.log("FAQ Webstis: ",faqsWebsite);

	return (
		<div className="w-full mx-auto p-1 bg-white shadow-lg rounded-lg">
            <h2 className="text-2xl font-semibold mb-4">FAQ Admin Dashboard</h2>

            {/* FAQ Form */}
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label htmlFor="question" className="block text-sm font-medium text-gray-700">
                        Question
                    </label>
                    <input
                        type="text"
                        id="question"
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                        placeholder="Enter your question"
                        className="w-full mt-1 p-2 border rounded-md shadow-sm focus:ring-gray-500 focus:border-gray-500"
                    />
                </div>

                <div>
                    <label htmlFor="answer" className="block text-sm font-medium text-gray-700">
                        Answer
                    </label>
                    <textarea
                        id="answer"
                        value={answer}
                        onChange={(e) => setAnswer(e.target.value)}
                        placeholder="Enter the answer"
                        rows="4"
                        className="w-full mt-1 p-2 border rounded-md shadow-sm focus:ring-gray-500 focus:border-gray-500"
                    />
                </div>

                <button
                    type="submit"
                    className="w-full mt-4 bg-gray-600 text-white py-2 px-4 rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500"
                >
                Add FAQ
                </button>
            </form>

            {faqsWebsite && faqsWebsite.length > 0 && (
                <div className="mt-8 py-8">
                    <h3 className="text-xl font-semibold mb-4">FAQ List</h3>
                    <div className="space-y-4">
                        {faqsWebsite.map((faq, index) => (
                            <div
                                key={index}
                                className="bg-gray-100 p-4 rounded-lg shadow-md relative"
                            >
                                <button
                                    onClick={() => removeFAQ(faq?._id)}
                                    className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                </button>
                                <p className="font-semibold text-lg">{faq.question}</p>
                                <p className="text-gray-700">{faq.answer}</p>
                            </div>
                        ))}
                    </div>
                </div>
            )}
		</div>
	);
}

export default FaqManagementComponents