// import { useEffect, useState } from "react";
// import toast from "react-hot-toast";
// import { BASE_URL } from "../../../Constants/constant.js";
// import { useSocketContext } from "../../../Constants/SocketContext.jsx";

// const useGetConversations = () => {
// 	const [loading, setLoading] = useState(false);
// 	const [conversations, setConversations] = useState([]);
// 	const { fetchdata } = useSocketContext();

	
// 		const getConversations = async () => {
// 			setLoading(true);
// 			try {
// 				console.log("ca;llleetrwea");
// 				const token = localStorage.getItem('token');
// 				const res = await fetch(`${BASE_URL}/api/v-1/user/chatbot`, {
// 					headers: {
// 						'Authorization': `Bearer ${token}`,
// 					},
// 				});
// 				const data = await res.json();
// 				console.log(data,"data112233")
// 				if (data.error) {
// 					throw new Error(data.error);
// 				}
// 				setConversations(data);
// 			} catch (error) {
// 				toast.error(error.message);
// 			} finally {
// 				setLoading(false);
// 			}
// 		};

// 		useEffect(() => {
// 		getConversations();
// 	}, [fetchdata]);

// 	return { loading, conversations };
// };
// export default useGetConversations;



import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { BASE_URL } from "../../../Constants/constant.js";
import { useSocketContext } from "../../../Constants/SocketContext.jsx";

const useGetConversations = () => {
	const [loading, setLoading] = useState(false);
	const [conversations, setConversations] = useState([]);
	const { fetchdata } = useSocketContext();

	const getConversations = async () => {
		setLoading(true);
		try {
			const token = localStorage.getItem('token');
			
			const res = await fetch(`${BASE_URL}/api/v-1/user/chatbot`, {
				headers: {
					'Authorization': `Bearer ${token}`,
				},
			});
			const data = await res.json();
			if (data.error) {
				throw new Error(data.error);
			}
			setConversations(data);
		} catch (error) {
			toast.error(error.message);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getConversations(); // Call getConversations when the component mounts or fetchdata changes
	}, [fetchdata]); // Dependency array includes fetchdata to re-run the effect on change

	return { loading, conversations };
};

export default useGetConversations;
