import React, { useEffect } from 'react';
import axios from 'axios';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { BASE_URL } from '../../Constants/constant.js';
import { addValidationSchema } from '../Validation/AddValidationSchema.js';


const AddBanner = ({ add,onClose }) => {
  const formik = useFormik({
    initialValues: {
      name: '',
      link: '',
      priority: '',
      add: null, 
    },
    validationSchema: addValidationSchema,
    onSubmit: async (values) => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post(`${BASE_URL}/api/v-1/add/uploadadd`, values, {
          headers: {
            'Content-Type': 'multipart/form-data', 
            'Authorization': `Bearer ${token}`,
          },
        });
        add()
        toast.success(response.data.message);
        formik.resetForm();
        onClose();
      } catch (error) {
        if (error.response && error.response.data) {
          toast.error(error.response.data.error || 'An unexpected error occurred');
        } else {
          toast.error('Network error or server not reachable');
        }
      }
    },
  });

  useEffect(() => {
    if (formik.errors) {
      Object.values(formik.errors).forEach(error => toast.error(error));
    }
  }, [formik.errors]);

  const handleFileChange = (event) => {
    formik.setFieldValue('add', event.currentTarget.files[0]);
  };

  return (
    <form className="mx-auto px-8 mb-4" onSubmit={formik.handleSubmit}>
      <div className="text-center mb-4">
        <h1 className="text-xl font-bold">Enter Banner Details</h1>
      </div>
      <div className="grid md:grid-cols-2 md:gap-6">
        <div className="relative z-0 w-full mb-5 group">
          <input
            type="text"
            name="name"
            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:border-blue-600 peer"
            placeholder=" "
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            required
          />
          <label className="absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
            Banner name
          </label>
        </div>
        <div className="relative z-0 w-full mb-5 group">
          <input
            type="text"
            name="link"
            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:border-blue-600 peer"
            placeholder=" "
            value={formik.values.link}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            required
          />
          <label className="absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
            Banner Link
          </label>
        </div>
        <div className="relative z-0 w-full mb-5 group">
          <input
            type="file"
            name="add"
            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:border-blue-600 peer"
            onChange={handleFileChange}
            onBlur={formik.handleBlur}
            required
          />
          <label className="absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
            Banner Image
          </label>
        </div>
        <div className="relative z-0 w-full mb-5 group">
          <input
            type="text"
            name="priority"
            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:border-blue-600 peer"
            placeholder=" "
            value={formik.values.priority}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            required
          />
          <label className="absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
            Banner Priority
          </label>
        </div>
      </div>
      <div className='flex justify-center'>
        <div className='mr-4'>
          <button
            type="submit"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Register
          </button>
        </div>
        <div>
          <button
            onClick={onClose}
            className="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Close
          </button>
        </div>
      </div>
    </form>
  );
};

export default AddBanner;
