import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Sidebar from '../Frame/Sidebar';
import { BASE_URL } from '../../Constants/constant';
import Viewfaq from './Viewfaq.jsx';
import Viewsubfaq from './Viewsubfaq.jsx';
import Addcategory from './Addcategory.jsx';
import Addsubcategory from './Addsubcategory.jsx';
import Updatecategory from './Updatecategory.jsx';
import Updatesubcategory from './Updatesubcategory.jsx';
import Cookies from 'js-cookie';
const Tickets = () => {
    const [tickets, setTickets] = useState([]);
    const [popupfaqview, setPopupfaqview] = useState(false);
    const [popupaddsubCategory, setPopupaddsubCategory] = useState(false);
    const [popupaddCategory, setPopupaddCategory] = useState(false);
    const [popupsubfaqview, setPopupsubfaqview] = useState(false);
    const [selectedFaq, setSelectedFaq] = useState(null);
    const [selectedFaq1, setSelectedFaq1] = useState(null);
    const [selectedsubFaq, setSelectedsubFaq] = useState(null);
    const [deletePopupVisible, setDeletePopupVisible] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [deleteSubId, setDeletesubId] = useState(null);
    const [popupupdateCategory, setPopupupdateCategory] = useState(false)
    const [popupupdatesubCategory, setPopupupdatesubCategory] = useState(false)

    const token = Cookies.get('token');
 
    const fetchAdds = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/v-1/faq/getcategory`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setTickets(response.data || []);
        } catch (error) {
            console.error('Error fetching tickets:', error);
            setTickets([]);
        }
    };

    useEffect(() => {
        fetchAdds();
    }, [selectedFaq]);

    const handleFaqClick = (categoryId) => {
        console.log(categoryId,"categoryId")
        const selected = tickets.find(ticket => ticket._id === categoryId);
        console.log(selected,"selected")
        setSelectedFaq(selected);
    };

    const handlesubFaqClick = (subCategoryId) => {
        const parentTicket = tickets.find(ticket =>
            ticket.subcategory?.some(sub => sub._id === subCategoryId)
        );
        const selectedSubCategory = parentTicket?.subcategory?.find(sub => sub._id === subCategoryId);
        setSelectedFaq1(parentTicket);
        setSelectedFaq(parentTicket)
        setSelectedsubFaq(selectedSubCategory);
    };

    const handleDeleteConfirmation = (id, subid) => {
        setDeleteId(id);
        setDeletesubId(subid)
        setDeletePopupVisible(true);
    };

    const handleDeleteCategory = async () => {
        try {
            if (deleteSubId) {
                // Deleting subcategory
                await axios.delete(`${BASE_URL}/api/v-1/faq/deletesubcategory/${deleteId}`, {
                    data: { subCategoryId: deleteSubId },
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
            } else {
                // Deleting category
                await axios.delete(`${BASE_URL}/api/v-1/faq/deletecategory/${deleteId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
            }
            fetchAdds();
        } catch (error) {
            console.error('Error deleting:', error);
        } finally {
            setDeletePopupVisible(false);
        }
    };

    const handleClosePopup = () => {
        setPopupfaqview(false);
        setPopupsubfaqview(false);
        setPopupaddCategory(false);
        setPopupaddsubCategory(false);
        setPopupupdatesubCategory(false);
        setSelectedFaq(null);
        setSelectedsubFaq(null);
        setDeletePopupVisible(false);
        setPopupupdateCategory(false)
    };

    return (
        <Sidebar>
            <div className="p-4 xl:ml-80 flex flex-col">
                <div>
                    <button
                        type="button"
                        onClick={() => setPopupaddCategory(true)}
                        className="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                    >
                        Add Category
                    </button>
                </div>
                <div className="table-container overflow-x-auto">
                    <table className="table table-bordered w-full">
                        <thead className="bg-gray-200 text-gray-700">
                            <tr>
                                <th className="border border-gray-300 px-4 py-2 text-left">Sl. No</th>
                                <th className="border border-gray-300 px-4 py-2 text-left">Category</th>
                                <th className="border border-gray-300 px-4 py-2 text-left">Subcategories</th>
                                <th className="border border-gray-300 px-4 py-2 text-left">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tickets.length > 0 ? (
                                tickets.map((ticket, index) => (
                                    <React.Fragment key={ticket._id || index}>
                                        <tr
                                            className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'} hover:bg-gray-100`}
                                        >
                                            <td className="border border-gray-300 px-4 py-2">{index + 1}</td>
                                            <td className="border border-gray-300 px-4 py-2">
                                                <div className="flex items-center justify-between">
                                                    <span>{ticket.name}</span>
                                                    <div className="space-x-2">
                                                        <button   
                                                        onClick={() => {
                                                                handleFaqClick(ticket._id);
                                                                setPopupupdateCategory(true);
                                                            }} 
                                                            className="text-sm px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600">
                                                            Edit
                                                        </button>
                                                        <button
                                                            onClick={() => {
                                                                handleFaqClick(ticket._id);
                                                                setPopupfaqview(true);
                                                            }}
                                                            className="text-sm px-3 py-1 bg-green-500 text-white rounded hover:bg-green-600"
                                                        >
                                                            View
                                                        </button>
                                                        <button
                                                            onClick={() => {
                                                                setPopupaddsubCategory(true);
                                                                handleFaqClick(ticket._id);
                                                            }}
                                                            className="text-sm px-3 py-1 bg-indigo-500 text-white rounded hover:bg-indigo-600"
                                                        >
                                                            Add Subcategory
                                                        </button>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="border border-gray-300 px-4 py-2">
                                                {ticket.subcategory.length > 0 ? (
                                                    <ul className="space-y-2">
                                                        {ticket.subcategory.map((sub, subIndex) => (
                                                            <li
                                                                key={sub._id || subIndex}
                                                                className="flex items-center justify-between bg-gray-50 p-2 rounded-md hover:bg-gray-100"
                                                            >
                                                                <span>{sub.name}</span>
                                                                <div className="space-x-2">
                                                                    <button   onClick={() => { handlesubFaqClick(sub._id);setPopupupdatesubCategory(true) }} className="text-sm px-2 py-1 bg-blue-500 text-white rounded hover:bg-blue-600">
                                                                        Edit
                                                                    </button>
                                                                    <button
                                                                        onClick={() => { handlesubFaqClick(sub._id); setDeletesubId(sub._id);setPopupsubfaqview(true); }}
                                                                        className="text-sm px-3 py-1 bg-green-500 text-white rounded hover:bg-green-600"
                                                                    >
                                                                        View
                                                                    </button>
                                                                    <button
                                                                        onClick={() => handleDeleteConfirmation(ticket._id, sub._id)}
                                                                        className="text-sm px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600"
                                                                    >
                                                                        Delete
                                                                    </button>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                ) : (
                                                    <span className="text-gray-500">No Subcategories</span>
                                                )}
                                            </td>
                                            <td>
                                                <button
                                                    onClick={() => handleDeleteConfirmation(ticket._id)}
                                                    className="text-sm px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600"
                                                >
                                                    Delete
                                                </button>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))
                            ) : (
                                <tr>
                                    <td
                                        colSpan="4"
                                        className="text-center border border-gray-300 px-4 py-4 text-gray-600"
                                    >
                                        No Data Found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Popups */}
            {popupfaqview && <Viewfaq fetchAdds={fetchAdds} selectedFaq={selectedFaq} onClose={handleClosePopup} />}
            {popupupdateCategory && <Updatecategory fetchAdds={fetchAdds}  selectedFaq={selectedFaq} onClose={handleClosePopup} />}
            {popupupdatesubCategory && <Updatesubcategory selectedsubFaq={selectedsubFaq} fetchAdds={fetchAdds}  selectedFaq={selectedFaq} onClose={handleClosePopup} />}
            {popupsubfaqview && (
                <Viewsubfaq
                    selectedFaq1={selectedFaq1}
                    selectedFaq={selectedFaq}
                    selectedsubFaq={selectedsubFaq}
                    onClose={handleClosePopup}
                />
            )}
            {popupaddCategory && <Addcategory fetchAdds={fetchAdds} onClose={handleClosePopup} />}
            {popupaddsubCategory && <Addsubcategory selectedFaq={selectedFaq} fetchAdds={fetchAdds} onClose={handleClosePopup} />}

            {/* Confirmation Modal */}

            {deletePopupVisible && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50 z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <h2 className="text-lg font-semibold">Confirm</h2>
                        <p className="mt-2">
                            Are you sure you want to delete {deleteSubId ? 'Subcategory' : 'Category'} permanently?
                        </p>
                        <div className="mt-4 flex justify-end">
                            <button
                                onClick={handleDeleteCategory}
                                className="bg-blue-500 text-white px-4 py-2 rounded-lg mr-2"
                            >
                                Yes
                            </button>
                            <button
                                onClick={handleClosePopup}
                                className="bg-gray-500 text-white px-4 py-2 rounded-lg"
                            >
                                No
                            </button>
                        </div>
                    </div>
                </div>
            )}

          



        </Sidebar>
    );
};

export default Tickets;
