import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";
import { BASE_URL } from "../../Constants/constant";
import Cookies from "js-cookie";

const SPIN_DEPOSIT_OPTIONS = ["CashBack", "WithDrawal", "GameBalance", "BonusBalance"];

const SpinWheelTable = () => {
  const [bonus, setBonus] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    PayPerSpin: "",
    active: false,
    freeSpinDeposit: "",
    name: "",
    payedSpinDeposite: "",
    totalSpins: "",
    totalUsers: "",
  });

  useEffect(() => {
    fetchBonusData();
  }, []);

  const token = Cookies.get("token");

  const fetchBonusData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/v-1/spinWheel/spin-config`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data && response.data) {
        setBonus(response.data);
        setFormData(response.data);
      } else {
        toast.error("Invalid bonus data received.");
      }
    } catch (error) {
      toast.error("Failed to load bonus data.");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdate = async () => {
    try {
      await axios.put(`${BASE_URL}/api/v-1/spinWheel/updatedashboard`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      });

      toast.success("Bonus data updated successfully.");
      setIsEditing(false);
      fetchBonusData();
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to update bonus data.");
    }
  };

  if (!bonus) return <p>Loading...</p>;

  return (
    
    <div className="table-container overflow-x-auto p-4">
      <div>
        <p className="text-center pt-2 pb-2 text-2xl font-bold">SpinWheel Details</p>
        <table className="table table-bordered w-full border border-gray-300">
          <thead className="bg-gray-100 sticky top-0 border border-gray-300 rounded-lg font-bold">
            <tr>
              <th>Sl.No</th>
              <th>Data Name</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 text-lg">
            {[
              { name: "Pay Per Spin", key: "PayPerSpin", type: "number" },
              { name: "Active Status", key: "active", type: "boolean" },
              { name: "Free Spin Deposit", key: "freeSpinDeposite", type: "enum" },
              { name: "Name", key: "name", type: "text" },
              { name: "Payed Spin Deposit", key: "payedSpinDeposite", type: "enum" },
            //   { name: "Total Spins", key: "totalSpins", type: "number" },
            //   { name: "Total Users", key: "totalUsers", type: "number" },
            ].map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.name}</td>
                <td>
                  {isEditing ? (
                    item.type === "boolean" ? (
                      <select
                        name={item.key}
                        value={formData[item.key]}
                        onChange={(e) => setFormData((prev) => ({ ...prev, [item.key]: e.target.value === "true" }))}
                        className="border p-1 w-32"
                      >
                        <option value="true">True</option>
                        <option value="false">False</option>
                      </select>
                    ) : item.type === "enum" ? (
                      <select
                        name={item.key}
                        value={formData[item.key]}
                        onChange={handleInputChange}
                        className="border p-1 w-40"
                      >
                        {SPIN_DEPOSIT_OPTIONS.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <input
                        type={item.type}
                        name={item.key}
                        value={formData[item.key]}
                        onChange={handleInputChange}
                        className="border p-1 w-20"
                      />
                    )
                ) : (
                    item.type === "boolean"
                      ? formData[item.key]
                        ? "Active"
                        : "Inactive"
                      : item.type === "enum"
                      ? formData[item.key]
                      : item.key === "name"
                      ? formData[item.key]
                      : item.key === "PayPerSpin"
                      ? `₹${bonus[item.key] || 0}.00`
                      : formData[item.key] ?? `${bonus[item.key] || 0}`
                  )
                  }
                </td>
              </tr>
            ))}
          </tbody>
          
        </table>
      </div>
      <div className="mt-4 flex justify-center">
          {isEditing ? (
            <div>
              <button
                onClick={handleUpdate}
                className="bg-green-500 text-white px-4 py-2 rounded-lg mr-5"
              >
                Save Changes
              </button>
              <button
                onClick={() => setIsEditing(false)}
                className="bg-red-500 text-white px-4 py-2 rounded-lg"
              >
                Cancel
              </button>
            </div>
          ) : (
            <button
              onClick={() => setIsEditing(true)}
              className="bg-blue-500 text-white px-4 py-2 rounded-lg"
            >
              Update Details
            </button>
          )}
        </div>
    </div>
  );
};

export default SpinWheelTable;
