import React, { useState } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../Constants/constant';
import Cookies from 'js-cookie';
const Updatecategory = ({ selectedFaq,fetchAdds, onClose }) => {
  const [category, setCategory] = useState(selectedFaq.name);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const token = Cookies.get('token');
      const response = await axios.put(
        `${BASE_URL}/api/v-1/faq/updateCategory/${selectedFaq._id}`,
        { name: category },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setSuccess(response.data.message);
        setCategory('');
        fetchAdds();
        onClose();
      }
    } catch (error) {
      setError(error.response?.data?.message || 'Something went wrong');
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-4">
      <div className="bg-white rounded-lg shadow-lg p-8 max-w-lg w-full relative overflow-hidden flex flex-col space-y-6 transform transition-all duration-300 ease-in-out scale-100 hover:scale-105">
        <h2 className="text-2xl font-bold mb-4 text-gray-800 text-center">
          Update Main Category
          <button
            className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 transition-colors"
            onClick={onClose}
            aria-label="Close"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </h2>

        <form onSubmit={handleSubmit} className="space-y-6">
        {error && <div className="text-red-500 text-sm text-center">{error}</div>}
        {success && <div className="text-green-500 text-sm text-center">{success}</div>}
          <div>
            <input
              type="text"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              placeholder="Enter category name"
              className="w-full p-4 text-lg border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="px-6 py-3 bg-yellow-600 text-white rounded-lg shadow-lg hover:bg-yellow-700 transition duration-200"
            >
              Update Category
            </button>

          </div>
        </form>
      </div>
    </div>
  );
};

export default Updatecategory;
