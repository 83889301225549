import { useEffect, useState } from "react";
import Sidebar from "../Frame/Sidebar";
import { BASE_URL } from "../../Constants/constant";
import axios from "axios";
import ReactPaginate from "react-paginate";
import Cookies from 'js-cookie';
export const AllDeposites = () => {
  const [deposite, setDeposite] = useState([]);
  const [activeFilter, setActiveFilter] = useState("all");
  const token = Cookies.get("token");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(1);
const [totalPages, setTotalPages] = useState(1);
const [totalCount, setTotalCount] = useState(1);

  const fetchDeposits = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/v-1/subpaisapayment/getalldeposits?page=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTotalPages(response.data.totalPages)
      setPageSize(response.data.pageSize);
      setCurrentPage (response.data.currentPage)
      setTotalCount(response.data.totalCount)

      console.log(response.data, "sabpaisa");
      setDeposite(response.data.alldeposites);
    } catch (error) {
      console.error("Error fetching alldeposites:", error);
    }
  };


  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected + 1);
  };
  

  useEffect(() => {
    fetchDeposits();
  }, [currentPage]);
  

  const filteredDeposits = () => {
    switch (activeFilter) {
      case "SUCCESS":
        return deposite.filter((w) => w.status === "SUCCESS");
      case "FAILED":
        return deposite.filter((w) => w.status === "FAILED");
      case "PENDING":
        return deposite.filter((w) => w.status === "PENDING");
      case "Not Initaiated":
        return deposite.filter((w) => w.status === "Not Initaiated");
      case "all":
      default:
        return deposite;
    }
  };

  const calculateSerialNumber = (index) => {
    return (currentPage - 1) * pageSize + index + 1;
};

  return (
    <Sidebar>
      <div className="p-4 xl:ml-80 flex flex-col">
        <div>
          <button
            type="button"
            className={`text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 ${
              activeFilter === "all" ? "bg-blue-500" : ""
            }`}
            onClick={() => setActiveFilter("all")}
          >
            All alldeposites
          </button>
          <button
            type="button"
            className={`text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 ${
              activeFilter === "Pending" ? "bg-blue-500" : ""
            }`}
            onClick={() => setActiveFilter("PENDING")}
          >
            deposite Pending
          </button>
          <button
            type="button"
            className={`text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 ${
              activeFilter === "Success" ? "bg-blue-500" : ""
            }`}
            onClick={() => setActiveFilter("SUCCESS")}
          >
            deposite Success
          </button>
          <button
            type="button"
            className={`text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 ${
              activeFilter === "Success" ? "bg-blue-500" : ""
            }`}
            onClick={() => setActiveFilter("FAILED")}
          >
            deposite Failed
          </button>
          <button
            type="button"
            className={`text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 ${
              activeFilter === "Success" ? "bg-blue-500" : ""
            }`}
            onClick={() => setActiveFilter("Not Initaiated")}
          >
            deposite Not Initaiated
          </button>
        </div>
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <div className="table-container-button overflow-x-auto">
                <table className="table table-bordered w-full">
                  <thead className="bg-gray-100 sticky top-0">
                    <tr>
                      <th className="px-6 py-3 text-left">Sl.No</th>
                      <th className="px-6 py-3 text-left">userId</th>
                      <th className="px-6 py-3 text-left">Amount Paid</th>
                      <th className="px-6 py-3 text-left">Status</th>
                      <th className="px-6 py-3 text-left">Payer Name</th>
                      <th className="px-6 py-3 text-left">Payer Email</th>
                      <th className="px-6 py-3 text-left">Payer Mobile</th>
                      <th className="px-6 py-3 text-left">Sabpaisa TxnId</th>
                      <th className="px-6 py-3 text-left">Bank TxnId</th>
                      <th className="px-6 py-3 text-left">Payment Mode</th>
                      <th className="px-6 py-3 text-left">Trans Date</th>
                      <th className="px-6 py-3 text-left">client TxnId</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {filteredDeposits().length === 0 ? (
                      <tr>
                        <td colSpan="13" className="px-6 py-4 text-center">
                          No deposite Requests
                        </td>
                      </tr>
                    ) : (
                      filteredDeposits().map((user, index) => (
                        <tr
                          key={user._id}
                          className={
                            index % 2 === 0 ? "bg-white" : "bg-gray-100"
                          }
                        >
                          <td className="px-6 py-4">{calculateSerialNumber(index)}</td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm font-xs text-gray-900">
                              {user.userId || "No userId"}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm font-xs text-gray-900">
                              {user.amount || "No amount"}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span
                              className={`px-2 py-1 mr-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                user.status === "SUCCESS"
                                  ? "bg-green-100 text-green-800"
                                  : user.status === "Not Initaiated"
                                  ? "bg-yellow-100 text-yellow-950"
                                  : "bg-red-100 text-red-800"
                              }`}
                            >
                              {user.status === "SUCCESS"
                                ? "SUCCESS"
                                : user.status === "PENDING"
                                ? "PENDING"
                                : user.status === "FAILED"
                                ? "FAILED"
                                : "Not Initaiated"}
                            </span>
                          </td>

                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-500">
                              {user.payerName || "No payerName"}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-500">
                              {user.payerEmail || "No payerEmail"}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-500">
                              {user.payerMobile || "No payerMobile"}
                            </div>
                          </td>

                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-500">
                              {user.sabpaisaTxnId || "No sabpaisaTxnId"}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-500">
                              {user.bankTxnId || "No bankTxnId"}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-500">
                              {user.paymentMode || "No paymentMode"}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-500">
                              {user.createdAt
                                ? `${user.transDate.split("G")[0]}`
                                : "No createdAt"}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-500">
                              {user.clientTxnId || "No clientTxnId"}
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>

                <div className="fixed bottom-12 right-0 text-blue-500 mr-12  text-xl text-md  ">
                  <div className="left-0 flex items-center justify-between ">
                    <p>
                      Showing page {currentPage} of {totalPages} &ensp;
                      {currentPage * pageSize - pageSize} to{" "}
                      {Math.min(currentPage * pageSize, totalCount)} of{" "}
                      {totalCount} entries
                    </p>
                    <div className="text-blue-500  `{active? text-red-500 }` pagination">
                      <ReactPaginate
                        previousLabel={""}
                        nextLabel={"."}
                        breakLabel={"...."}
                        pageCount={totalPages}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                        className="flex"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Sidebar>
  );
};
