import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";
import { BASE_URL } from "../../Constants/constant";
import Cookies from "js-cookie";

const FreeSpin = () => {
  const [bonus, setBonus] = useState([]);
  const [amount, setAmount] = useState("");
  const [editIndex, setEditIndex] = useState(null);
  const [updatedAmount, setUpdatedAmount] = useState("");
  const token = Cookies.get("token");

  useEffect(() => {
    fetchBonusData();
  }, []);

  const fetchBonusData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/v-1/spinWheel/spin-config`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data?.freeSpinAmount) {
        setBonus(response.data.freeSpinAmount);
      } else {
        setBonus([]);
      }
    } catch (error) {
      toast.error("Failed to load spin data.");
    }
  };

  // Function to Add, Delete, or Update Free Spin Amount
  const updateFreeSpin = async (index, amount, action) => {
    if (action === "add" && (!amount || isNaN(amount))) {
      return toast.error("Enter a valid amount to add.");
    }

    if (action === "update" && (!updatedAmount || isNaN(updatedAmount))) {
      return toast.error("Enter a valid amount to update.");
    }

    try {
      const response = await axios.put(
        `${BASE_URL}/api/v-1/spinWheel/updatefreespin`,
        { 
          spinId: index, 
          amount: action === "update" ? Number(updatedAmount) : Number(amount),
          action, 
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      toast.success(response.data.message);
      fetchBonusData();
      setEditIndex(null);
      setUpdatedAmount("");
      if (action === "add") setAmount(""); // Clear input after adding
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to update spin.");
    }
  };

  return (
    <div className="table-container overflow-x-auto p-4 ">
      <p className="text-center pt-2 pb-2 text-2xl font-bold">SpinWheel Data</p>

      {/* Add New Amount */}
      <div className="flex items-center space-x-2 mb-4">
        <input
          type="number"
          placeholder="Enter amount"
          className="border p-2 rounded w-1/3"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded"
          onClick={() => updateFreeSpin(null, amount, "add")}
        >
          Add Amount
        </button>
      </div>

      {/* Free Spin Table */}
      <table className="table-auto w-full border border-gray-300">
        <thead className="bg-gray-100 font-bold">
          <tr>
            <th>Sl.No</th>
            <th>Amount</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {bonus.length > 0 ? (
            bonus.map((amt, index) => (
              <tr key={index}>
                <td className="text-center">{index + 1}</td>

                {/* Editable Field for Update */}
                <td className="text-center">
                  {editIndex === index ? (
                    <input
                      type="number"
                      className="border p-1 rounded"
                      value={updatedAmount}
                      onChange={(e) => setUpdatedAmount(e.target.value)}
                    />
                  ) : (
                    amt
                  )}
                </td>

                <td className="text-center space-x-2">
                  {/* Update / Save Button */}
                  {editIndex === index ? (
                    <button
                      className="bg-green-500 text-white px-3 py-1 rounded"
                      onClick={() => updateFreeSpin(index, amt, "update")}
                    >
                      Save
                    </button>
                  ) : (
                    <button
                      className="bg-yellow-500 text-white px-3 py-1 rounded"
                      onClick={() => {
                        setEditIndex(index);
                        setUpdatedAmount(amt);
                      }}
                    >
                      Edit
                    </button>
                  )}

                  {/* Delete Button */}
                  <button
                    className="bg-red-500 text-white px-3 py-1 rounded"
                    onClick={() => updateFreeSpin(index, amt, "delete")}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3" className="text-center text-gray-500 py-2">
                No free spins available.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default FreeSpin;
