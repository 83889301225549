import React, { useEffect, useState } from "react";
import { deleteOurGame, fetchOurGamesData, setOurGamesData, UpdateOurGamesById } from "../../../Redux/slices/websiteSlice";
import { useDispatch, useSelector } from "react-redux";
import FileUploadComponent from "./FileUploadComponent";
import { Edit, Eye, EyeClosed, X } from "lucide-react";
import { FaGamepad } from "react-icons/fa";
import toast from "react-hot-toast";
import ConfirmDeletePopup from "../ConfirmDeletePopup";

const GameForm = () => {
    const dispatch = useDispatch();
    const { OurGames } = useSelector(state => state.website);
    const [rest,setReset] = useState({
        iconImage: false,
        largeImage: false,
        pcViewIcon: false,
    })
    const [isLoading, setIsLoading] = useState({
        iconImage: false,
        largeImage: false,
        pcViewIcon: false,
    });

    // Initial form state
    const [formData, setFormData] = useState({
        title: "",
        subtitle: "",
        description: "",
        isActiveGame: false,
        iconImage: "",
        largeImage: "",
        pcViewIcon: ""
    });

    // Modal visibility state
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Handle form changes
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === "checkbox" ? checked : value
        });
    };

    // Handle file uploads
    const handleFileUpload = (key, files) => {
        setFormData({
            ...formData,
            [key]: files[0].url
        });
    };

    // Submit form handler
    const submitForm = async (e) => {
        e.preventDefault();
        try {
            console.log("Form Data: ", formData);
            await dispatch(setOurGamesData(formData));
            setIsModalOpen(false); // Close the modal after form submission
            setReset({
                iconImage: true,
                largeImage: true,
                pcViewIcon: true,
            })
            setTimeout(() => {
                setReset({
                    iconImage: false,
                    largeImage: false,
                    pcViewIcon: false,
                })  
            }, 100);
        } catch (error) {
            console.error("Error Submitting the form: ", error);
        }
    };

    useEffect(() => {
        if(OurGames || OurGames.length === 0) {
            dispatch(fetchOurGamesData())
            toast.success("Games Fetched Successfully");

        }
    }, [dispatch]);

    // Function to open the modal
    const openModal = () => setIsModalOpen(true);

    // Function to close the modal
    const closeModal = () => setIsModalOpen(false);

    return (
        <div className="w-full mx-auto p-6 bg-white rounded-xl shadow-lg">
            <button
                onClick={openModal}
                className="py-2 px-4 bg-red-500 flex flex-row justify-center items-center space-x-3 text-white rounded-md hover:bg-red-600 focus:outline-none"
            >
                <FaGamepad/>
                <span>Add Game</span>
            </button>

            {/* Modal for Game Form */}
            {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    
                    <div className="bg-white p-6 rounded-lg w-3/4 sm:w-1/2 max-h-[700px] overflow-y-auto">
                        <div className="w-full justify-between flex items-center">
                            <h2 className="text-2xl font-bold mb-4">Game Details Preview</h2>
                            <button
                                onClick={closeModal}
                                className="cursor-pointer w-fit h-fit z-20 bg-black text-white rounded-md p-1"
                            >
                                <X/>
                            </button>
                        </div>
                        <form onSubmit={submitForm}>
                            {/* Title */}
                            <InputField
                                label="Title"
                                name="title"
                                value={formData.title}
                                onChange={handleChange}
                                required
                            />
                
                            {/* Subtitle */}
                            <InputField
                                label="Subtitle"
                                name="subtitle"
                                value={formData.subtitle}
                                onChange={handleChange}
                                required
                            />
                
                            {/* Description */}
                            <TextAreaField
                                label="Description"
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                                required
                            />
                
                            {/* Is Active Game */}
                            <div className="mb-4 flex items-center">
                                <label htmlFor="isActiveGame" className="mr-2 text-gray-700">Is Active Game</label>
                                <input
                                    type="checkbox"
                                    id="isActiveGame"
                                    name="isActiveGame"
                                    checked={formData.isActiveGame}
                                    onChange={handleChange}
                                    className="h-5 w-5 text-blue-500"
                                />
                            </div>
                
                            {/* File Uploads */}
                            <FileUploadInput
                                label="Icon Image URL"
                                keyName="iconImage"
                                onSetImageUrls={handleFileUpload}
                                isLoading={isLoading.iconImage}
                                setIsLoading={(status) => setIsLoading({ ...isLoading, iconImage: status })}
                                reset={rest.iconImage}
                            />
                            <FileUploadInput
                                label="Large Image URL"
                                keyName="largeImage"
                                onSetImageUrls={handleFileUpload}
                                isLoading={isLoading.largeImage}
                                setIsLoading={(status) => setIsLoading({ ...isLoading, largeImage: status })}
                                reset={rest.largeImage}
                            />
                            <FileUploadInput
                                label="PC View Icon URL"
                                keyName="pcViewIcon"
                                onSetImageUrls={handleFileUpload}
                                isLoading={isLoading.pcViewIcon}
                                setIsLoading={(status) => setIsLoading({ ...isLoading, pcViewIcon: status })}
                                reset={rest.pcViewIcon}
                            />
                
                            {/* Submit Button */}
                            <div className="mb-4">
                                <button
                                    type="submit"
                                    className="w-full py-2 bg-red-500 text-white rounded-md hover:bg-red-600 focus:outline-none"
                                >
                                    Submit
                                </button>
                            </div>
                        </form>                        
                    </div>
                </div>
            
            )}
            {OurGames && OurGames.length > 0 && <OurGamesTableView OurGames={OurGames}/>}
        </div>
    );
};

// Reusable Input Field Component
const InputField = ({ label, name, value, onChange, required }) => (
    <div className="mb-4">
        <label htmlFor={name} className="block text-gray-700">{label}</label>
        <input
            type="text"
            id={name}
            name={name}
            value={value}
            onChange={onChange}
            required={required}
            className="w-full px-4 py-2 mt-1 border rounded-md focus:ring-2 focus:ring-blue-400"
        />
    </div>
);

// Reusable Text Area Component
const TextAreaField = ({ label, name, value, onChange, required }) => (
    <div className="mb-4">
        <label htmlFor={name} className="block text-gray-700">{label}</label>
        <textarea
            id={name}
            name={name}
            value={value}
            onChange={onChange}
            required={required}
            className="w-full px-4 py-2 mt-1 border rounded-md focus:ring-2 focus:ring-blue-400"
            rows="4"
        />
    </div>
);

// Reusable File Upload Input
const FileUploadInput = ({ label, keyName, onSetImageUrls, isLoading, setIsLoading,reset }) => (
    <div className="mb-4">
        <label htmlFor={keyName} className="block text-gray-700">{label}</label>
        <FileUploadComponent
            maxFiles={1}
            tag={`ourGames_${keyName}`}
            onSetImageUrls={(files) => onSetImageUrls(keyName, files)}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            onReset={reset}
        />
    </div>
);

const OurGamesTableView = ({OurGames}) => {
    const dispatch = useDispatch();
    const [selectedGame, setSelectedGame] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editedGame, setEditedGame] = useState(null);
    const [deletingGame, setDeletingGame] = useState(null);
    const [rest,setReset] = useState({
        iconImage: false,
        largeImage: false,
        pcViewIcon: false,
    })
    const [isLoading, setIsLoading] = useState({
        iconImage: false,
        largeImage: false,
        pcViewIcon: false,
    });

    // Open the modal with game details
    const handleOpenModal = (game) => {
        setSelectedGame(game);
        setEditedGame(game); // Create a copy for editing
    };
    const deleteGame = async (gameId)=>{
        console.log("Deleting game with ID:", gameId);
        await dispatch(deleteOurGame({id:gameId}));
        dispatch(fetchOurGamesData());
        setDeletingGame(null);
        toast.success("Game deleted successfully!");
    }

    // Close the modal
    const handleCloseModal = () => {
        setSelectedGame(null);
        setIsEditing(false); // Reset the editing mode
        dispatch(fetchOurGamesData());
    };

    // Handle changes in editable fields
    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setEditedGame({
            ...editedGame,
            [name]: type === "checkbox" ? checked : value,
        });
    };
    
    const handleFileUpload = (key, files) => {
        setEditedGame({
            ...editedGame,
            [key]: files[0].url
        });
        // console.log("Uploading file: ", key, files);
    };

    // Save changes
    const handleSaveChanges = async () => {
        // Dispatch update or handle the save
        console.log("Saved Game:", editedGame);
        const response = await dispatch(UpdateOurGamesById({gameId: editedGame._id, data: editedGame}));
        console.log("Game updated successfully",response.payload);
        if(response?.payload?.success){
            setReset({
                iconImage: true,
                largeImage: true,
                pcViewIcon: true,
            })
            setTimeout(() => {
                setReset({
                    iconImage: false,
                    largeImage: false,
                    pcViewIcon: false,
                })
                handleCloseModal();
            }, 100);
            toast.success("Game updated successfully!");
        }
        dispatch(fetchOurGamesData());
    };

    return (
        <div>
            <h2 className="text-2xl font-bold mb-4">Game List</h2>
            <div className="overflow-x-auto">
                {/* Table Header */}
                <div className="grid grid-cols-8 gap-4 p-4 font-semibold justify-center items-center bg-gray-200 border-b">
                    <div className="px-4 py-2 text-center border-r border-gray-800 h-full w-full">Title</div>
                    <div className="px-4 py-2 text-center border-r border-gray-800 h-full w-full">Subtitle</div>
                    <div className="px-4 py-2 text-center border-r border-gray-800 h-full w-full">Description</div>
                    <div className="px-4 py-2 text-center border-r border-gray-800 h-full w-full">Large Image</div>
                    <div className="px-4 py-2 text-center border-r border-gray-800 h-full w-full">Icon</div>
                    <div className="px-4 py-2 text-center border-r border-gray-800 h-full w-full">Large Icon</div>
                    <div className="px-4 py-2 text-center border-r border-gray-800 h-full w-full">Active</div>
                    <div className="px-4 py-2 text-center border-r border-gray-800 h-full w-full">Actions</div>
                </div>

                {/* Table Rows */}
                {OurGames.map((game) => (
                    <div
                        key={game._id}
                        className="grid grid-cols-8 gap-4 p-4 border-b cursor-pointer hover:bg-gray-50"
                        
                    >
                        <div className="px-4 py-2 text-left">{game.title}</div>
                        <div className="px-4 py-2 text-left">{game.subtitle}</div>
                        <div className="px-4 py-2 text-left">{game.description.slice(0, 30)}</div>
                        <div className="px-4 py-2 text-left">
                            <img src={game.largeImage} alt={`${game.title}_large_Image`} className="h-16 w-16 object-contain" />
                        </div>
                        <div className="px-4 py-2 text-left">
                            <img src={game.iconImage} alt={`${game.title}_icon_image`} className="h-16 w-16 object-contain" />
                        </div>
                        <div className="px-4 py-2 text-left">
                            <img src={game.pcViewIcon} alt={`${game.title}_pc_view_icon`} className="h-16 w-16 object-contain" />
                        </div>
                        <div className="px-4 py-2 text-center justify-center flex items-center">
                            <label className={`${game.isActiveGame ? "bg-black text-white":"bg-white border border-gray-800 text-black"} px-4 py-2 rounded-full`}>
                                {game.isActiveGame ? "Active" : "Inactive"}
                            </label>
                        </div>
                        <div className="px-4 py-2 gap-2 text-center justify-center flex items-center">
                            <button onClick={() => handleOpenModal(game)} className={`bg-green-500 text-white px-4 py-2 rounded-lg`}>
                                <Edit/>
                            </button>
                            <button onClick={() => {
                                setDeletingGame(game?._id);
                            }} className={`bg-red-600 text-white px-4 py-2 rounded-lg`}>
                                <X/>
                            </button>
                        </div>
                    </div>
                ))}
            </div>

            {/* Game Details Modal */}
            {selectedGame && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg w-full sm:w-3/4 md:w-1/2 lg:w-1/3 max-h-[90vh] overflow-y-auto">
                        <div className="w-full flex justify-between items-center">
                            <h3 className="text-2xl font-bold mb-4">{selectedGame?.title}</h3>
                            <button
                                onClick={handleCloseModal}
                                className="cursor-pointer w-fit h-fit text-gray-500 hover:text-gray-700"
                            >
                                <X/>
                            </button>
                        </div>

                        {/* Toggle for Editing Mode */}
                        <button
                            onClick={() => setIsEditing(!isEditing)}
                            className="mb-4 py-2 px-4 bg-red-500 text-white rounded-md hover:bg-red-600 focus:outline-none"
                        >
                            {isEditing ? <EyeClosed/>:<Eye/>}
                        </button>
                        {/* Editable or Normal View */}
                        {isEditing ? (
                            <div>
                                {/* Editable fields */}
                                <div className="mb-4">
                                    <label htmlFor="title" className="block text-gray-700">Title</label>
                                    <input
                                        type="text"
                                        name="title"
                                        value={editedGame?.title}
                                        onChange={handleInputChange}
                                        className="w-full px-4 py-2 mt-1 border rounded-md focus:ring-2 focus:ring-blue-400"
                                    />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="subtitle" className="block text-gray-700">Subtitle</label>
                                    <input
                                        type="text"
                                        name="subtitle"
                                        value={editedGame?.subtitle}
                                        onChange={handleInputChange}
                                        className="w-full px-4 py-2 mt-1 border rounded-md focus:ring-2 focus:ring-blue-400"
                                    />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="description" className="block text-gray-700">Description</label>
                                    <textarea
                                        name="description"
                                        value={editedGame?.description}
                                        onChange={handleInputChange}
                                        className="w-full px-4 py-2 mt-1 border rounded-md focus:ring-2 focus:ring-blue-400"
                                        rows="4"
                                    />
                                </div>
                                <div className="mb-4 flex items-center">
                                    <label htmlFor="isActiveGame" className="mr-2 text-gray-700">Is Active Game</label>
                                    <input
                                        type="checkbox"
                                        id="isActiveGame"
                                        name="isActiveGame"
                                        checked={editedGame?.isActiveGame}
                                        onChange={handleInputChange}
                                        className="h-5 w-5 text-blue-500"
                                    />
                                </div>

                                {/* File Upload Inputs */}
                                <div className="mb-4">
                                    <label htmlFor="largeImage" className="block text-gray-700">Thumbnail Image</label>
                                    <img
                                        src={editedGame?.largeImage}
                                        alt="Large Image"
                                        className="w-full h-[100px] object-contain mb-2"
                                        
                                    />
                                    <FileUploadInput
                                        label="Large Image URL"
                                        keyName="largeImage"
                                        onSetImageUrls={handleFileUpload}
                                        isLoading={isLoading?.largeImage}
                                        setIsLoading={(status) => setIsLoading({ ...isLoading, largeImage: status })}
                                        reset={rest?.largeImage}
                                    />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="iconImage" className="block text-gray-700">Icon Image</label>
                                    <img
                                        src={editedGame?.iconImage}
                                        alt="Icon Image"
                                        className="w-full h-[100px] object-contain mb-2"
                                    />
                                    <FileUploadInput
                                        label="Icon Image URL"
                                        keyName="iconImage"
                                        onSetImageUrls={handleFileUpload}
                                        isLoading={isLoading.iconImage}
                                        setIsLoading={(status) => setIsLoading({ ...isLoading, iconImage: status })}
                                        reset={rest?.iconImage}
                                    />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="mobileViewIcon" className="block text-gray-700">Mobile View Icon</label>
                                    <img
                                        src={editedGame?.pcViewIcon}
                                        alt="Pc View Icons"
                                        className="w-full h-[100px] object-contain mb-2"
                                        
                                    />
                                    <FileUploadInput
                                        label="PC View Icon URL"
                                        keyName="pcViewIcon"
                                        onSetImageUrls={handleFileUpload}
                                        isLoading={isLoading.pcViewIcon}
                                        setIsLoading={(status) => setIsLoading({ ...isLoading, pcViewIcon: status })}
                                        reset={rest?.pcViewIcon}
                                    />
                                </div>

                                {/* Save Changes */}
                                <button
                                    onClick={handleSaveChanges}
                                    className="w-full py-2 bg-red-500 text-white rounded-md hover:bg-red-600 focus:outline-none"
                                >
                                    Save Changes
                                </button>
                            </div>
                        ) : (
                            <div>
                                {/* Normal View */}
                                <p className="font-semibold text-lg animate-pulse"><strong>Active Status:</strong> {selectedGame.isActiveGame ? "Active" : "Inactive"}</p>
                                <p><strong>Subtitle:</strong> {selectedGame.subtitle}</p>
                                <p><strong>Description:</strong> {selectedGame.description}</p>
                                <div className="flex flex-wrap justify-between mt-4">
                                    <img src={selectedGame?.largeImage} alt="Large" className="h-24 w-24 object-cover mb-2 sm:mb-0" />
                                    <img src={selectedGame?.iconImage} alt="Icon" className="h-24 w-24 object-cover mb-2 sm:mb-0" />
                                    <img src={selectedGame?.pcViewIcon} alt="pc_View_Icon" className="h-24 w-24 object-cover" />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
            <ConfirmDeletePopup
                isOpen={deletingGame !== null}
                onConfirm={()=>{
                    deleteGame(deletingGame)
                }}
                onCancel={() => setDeletingGame(null)}
            />
        </div>
    );
};

export default GameForm;
