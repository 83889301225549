// import React, { useState, useEffect } from "react";
// import { useSocketContext } from "../../Constants/SocketContext";
// import Sidebar from "../Frame/Sidebar";
// import { FaUsers, FaMoneyBillWave, FaClock } from 'react-icons/fa'; 

// export const Getpoolsdata = () => {
//     const { poolsData } = useSocketContext();
//     const [poolArray, setPoolArray] = useState([]);
//     console.log(poolsData,"sadfasfd")


//     useEffect(() => {
//         if (poolsData?.data) {
//             const parsedData = JSON.parse(poolsData.data);
//             setPoolArray((prev) => {
//                 const updatedArray = [
//                     ...prev.filter((item) => item.uniqueId !== parsedData.uniqueId),
//                     parsedData,
//                 ];
//                 return updatedArray.sort((a, b) => a.seconds - b.seconds);
//             });
//         }
//     }, [poolsData]);


//     const shouldHighlightRed = (data) => {
//         const isSecondsLessThan10 = data.seconds < 10;
    
//         if (!isSecondsLessThan10) return false;
    
//         if (data.nuOfPlayer === 2 && data.totalPlayers % 2 !== 0) {
//             return true;
//         }
    
//         if (data.nuOfPlayer === 4 && data.totalPlayers % 4 === 0) {
//             return true;
//         }
    
//         return false;
//     };
    

//     return (
//         <Sidebar>
//             <div className="xl:ml-80 text-center px-4 py-5 sm:px-6 relative flex flex-col items-center">
//                 <h2 className="text-3xl font-extrabold mb-8 text-gray-700">Pools Data</h2>
//                 <div className="poolbar-container flex flex-col gap-6 w-full max-w-8xl">
//                     {poolArray.length > 0 ? (
//                         poolArray.map((data) => (
//                             <button
//                             key={data.uniqueId}
//                             className={`w-full p-6 rounded-2xl shadow-lg transition-all duration-500 transform hover:scale-102 hover:shadow-2xl ${
//                                 data.seconds < 10
//                                     ? "bg-gradient-to-r from-red-100 to-red-300 border-red-500 text-red-700"
//                                     : "bg-gradient-to-r from-green-100 to-green-300 border-green-500 text-green-700"
//                             } border-2`}
//                         >
//                                 <div className="flex flex-col md:flex-row justify-between items-start space-y-4 md:space-y-0 md:space-x-6">
//                                     <div className="flex flex-col space-y-2 text-left">
//                                         <div className="flex items-center gap-2">
//                                             <FaUsers className="text-lg text-gray-600" />
//                                             <p className="text-sm text-gray-500">
//                                                 <strong>Mode:</strong> {data.ModeType}
//                                             </p>
//                                         </div>
//                                         <div className="flex items-center gap-2">
//                                             <FaClock className="text-lg text-gray-600" />
//                                             <p className="text-xs text-gray-400">ID: {data.uniqueId}</p>
//                                         </div>
//                                     </div>
//                                     <div className="flex flex-col items-center space-y-4 md:space-y-2">
//                                         <p className="font-bold flex items-center gap-2">
//                                             <FaClock className="text-2xl text-gray-600" />
//                                             <strong>Seconds:</strong>{" "}
//                                             <span
//                                                 className={
//                                                     data.seconds < 10 ? "text-red-500 font-bold text-2xl" : "font-bold text-2xl"
//                                                 }
//                                             >
//                                                 {data.seconds}s
//                                             </span>
//                                         </p>
                                    
//                                             <p className="flex ">  
//                                             <FaUsers className="text-2xl mt-1 me-2 text-gray-600" />
//                                                 <strong className="me-2 mt-1">Total Players:</strong>{" "}
//                                                 <span
//                                                     className={data.totalPlayers % 2 !== 0 ? "text-red-500 font-bold text-2xl":"font:bold text-2xl "}
//                                                 >
//                                                     {data.totalPlayers}
//                                                 </span>
//                                             </p>
//                                         </div>
//                                     <div className="flex flex-col items-end space-y-2">
                                      
//                                         <div className="flex items-center gap-2">
                                     
//                                             <p>
//                                                 <strong> Room Type:</strong> {data.roomType}
//                                             </p> 
//                                         </div>   
//                                         <div className="flex items-center gap-2">
//                                             <FaMoneyBillWave className="text-lg text-gray-600" />
//                                             <p>
//                                                 <strong>Winning Amount:</strong> {data.winingAmount}
//                                             </p> 
//                                         </div>   
                                                       
                                        
//                                     </div>
//                                 </div>
//                             </button>
//                         ))
//                     ) : (
//                         <p className="text-gray-500">No pools data available.</p>
//                     )}
//                 </div>
//             </div>
//         </Sidebar>
//     );
// };


import React, { useState, useEffect } from "react";
import { useSocketContext } from "../../Constants/SocketContext";
import Sidebar from "../Frame/Sidebar";
import { FaUsers, FaMoneyBillWave, FaClock } from 'react-icons/fa'; 

export const Getpoolsdata = () => {
    const { poolsData } = useSocketContext();
    const [poolArray, setPoolArray] = useState([]);
    console.log(poolsData, "Incoming data");

    useEffect(() => {
        if (poolsData?.data) {
            const parsedData = JSON.parse(poolsData.data);
            setPoolArray((prev) => {
                const updatedArray = [
                    ...prev.filter((item) => item.uniqueId !== parsedData.uniqueId),
                    parsedData,
                ].filter((item) => item.seconds > 0); // Exclude items with seconds === 0
                return updatedArray.sort((a, b) => a.seconds - b.seconds);
            });
        }
    }, [poolsData]);

    useEffect(() => {
        const interval = setInterval(() => {
            setPoolArray((prev) =>
                prev
                    .map((data) => ({
                        ...data,
                        seconds: data.seconds > 0 ? data.seconds - 1 : 0,
                    }))
                    .filter((data) => data.seconds > 0) // Exclude items with seconds === 0
            );
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const shouldHighlightRed = (data) => {
        const isSecondsLessThan10 = data.seconds < 10;

        if (!isSecondsLessThan10) return false;

        if (data.nuOfPlayer === 2 && data.totalPlayers % 2 !== 0) {
            return true;
        }

        if (data.nuOfPlayer === 4 && data.totalPlayers % 4 === 0) {
            return true;
        }

        return false;
    };

    return (
        <Sidebar>
            <div className="xl:ml-80 text-center px-4 py-5 sm:px-6 relative flex flex-col items-center">
                <h2 className="text-3xl font-extrabold mb-8 text-gray-700">Pools Data</h2>
                <div className="poolbar-container flex flex-col gap-6 w-full max-w-8xl">
                    {poolArray.length > 0 ? (
                        poolArray.map((data) => (
                            <button
                                key={data.uniqueId}
                                className={`w-full p-6 rounded-2xl shadow-lg transition-all duration-500 transform hover:scale-102 hover:shadow-2xl ${
                                    data.seconds < 10
                                        ? "bg-gradient-to-r from-red-100 to-red-300 border-red-500 text-red-700"
                                        : "bg-gradient-to-r from-green-100 to-green-300 border-green-500 text-green-700"
                                } border-2`}
                            >
                                <div className="flex flex-col md:flex-row justify-between items-start space-y-4 md:space-y-0 md:space-x-6">
                                    <div className="flex flex-col space-y-2 text-left">
                                        <div className="flex items-center gap-2">
                                            <FaUsers className="text-lg text-gray-600" />
                                            <p className="text-sm text-gray-500">
                                                <strong>Mode:</strong> {data.ModeType}
                                            </p>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <FaClock className="text-lg text-gray-600" />
                                            <p className="text-xs text-gray-400">ID: {data.uniqueId}</p>
                                        </div>
                                    </div>
                                    <div className="flex flex-col items-center space-y-4 md:space-y-2">
                                        <p className="font-bold flex items-center gap-2">
                                            <FaClock className="text-2xl text-gray-600" />
                                            <strong>Seconds:</strong>{" "}
                                            <span
                                                className={
                                                    data.seconds < 10 ? "text-red-500 font-bold text-2xl" : "font-bold text-2xl"
                                                }
                                            >
                                                {data.seconds}s
                                            </span>
                                        </p>

                                        <p className="flex ">
                                            <FaUsers className="text-2xl mt-1 me-2 text-gray-600" />
                                            <strong className="me-2 mt-1">Total Players:</strong>{" "}
                                            <span
                                                className={
                                                    data.totalPlayers % 2 !== 0
                                                        ? "text-red-500 font-bold text-2xl"
                                                        : "font-bold text-2xl"
                                                }
                                            >
                                                {data.totalPlayers}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="flex flex-col items-end space-y-2">
                                        <div className="flex items-center gap-2">
                                            <p>
                                                <strong> Room Type:</strong> {data.roomType}
                                            </p>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <FaMoneyBillWave className="text-lg text-gray-600" />
                                            <p>
                                                <strong>Winning Amount:</strong> {data.winingAmount}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </button>
                        ))
                    ) : (
                        <p className="text-gray-500">No pools data available.</p>
                    )}
                </div>
            </div>
        </Sidebar>
    );
};
