import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.js';
import reportWebVitals from './reportWebVitals.js';
import { CombinedProvider } from './Constants/context.js'; 
import { Provider } from 'react-redux';
import store from './Redux/store.js';
import { SocketContextProvider } from './Constants/SocketContext.jsx';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <CombinedProvider>
      <SocketContextProvider>
        <Provider store={store}>
          <App />
        </Provider>
      </SocketContextProvider>
    </CombinedProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
