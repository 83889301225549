import axios from 'axios';
import { BASE_URL } from '../../Constants/constant.js';

export const getApi = async (url, config) => {
  try {
    const response = await axios.get(`${BASE_URL}/${url}`, config);
    return response;
  } catch (error) {
    return error.response || error;
  }
};

export const postApi = async (url, data, config) => {
  try {
    const response = await axios.post(`${BASE_URL}/${url}`, data, config);
    return response;
  } catch (error) {
    return error.response || error;
  }
};


export const putApi = async (url, data, config) => {
  try {
    const response = await axios.put(`${BASE_URL}/${url}`, data, config);
    return response;
  } catch (error) {
    return error.response || error;
  }
};
