import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { delBenefit, editBenefit, fetchBenefitsData, setBenefitsData } from '../../../Redux/slices/ludoWebsiteSlice';
import FileUploadComponent from '../WebComponents/FileUploadComponent';
import { Edit, X } from 'lucide-react';
import ConfirmDeletePopup from '../ConfirmDeletePopup';

const defaultData = {
    icon:'',
    header:'',
    subHeader:''
}
const BenefitsDataUpload = () => {
    const[removingBenefit,setRemovingBenefit] = useState(null);
    const [editingBenefit,setEditingBenefit] = useState(null);
    const { benefitsData } = useSelector((state) => state.ludoWebsite);
    const [isLoading, setIsLoading] = useState(false);
    const [reset, setIsReset] = useState(false);
    const [formData, setFormData] = useState(defaultData);
    const dispatch = useDispatch();
    const homeActions = async (actions, params) => {
        if (params) {
            await dispatch(actions(params));
        } else {
            await dispatch(actions());
        }
        dispatch(fetchBenefitsData());
        setIsReset(true);
        setFormData(defaultData);
        setTimeout(() => {
            setIsReset(false);
        }, 100);
    };
    const removeBenefits = async (benefitId) => {
        await homeActions(delBenefit,benefitId)
    };
    const editBenefits = async (benefit) => {
        await homeActions(editBenefit,{id:editingBenefit?._id,data:benefit})
    };
    const handleUpdateBenefits = async () => {
        await homeActions(setBenefitsData, formData);
        setEditingBenefit(null);
    };
    useEffect(()=>{
        dispatch(fetchBenefitsData());
    },[dispatch])
    // console.log("Benefits Data: ",benefitsData);
    return (
        <div className='w-full h-full justify-start items-center flex flex-col space-y-4 pb-8'>
            <div className='w-full justify-start items-start flex flex-row flex-wrap gap-2  p-2'>
                {
                    benefitsData && benefitsData.length > 0 && benefitsData.map((item,index)=>(
                        <div key={index} className='w-full border max-h-52 min-h-52 max-w-52 relative border-gray-700 p-4 md:w-1/2 lg:w-1/3 xl:w-1/4 justify-start items-center flex flex-col space-y-2'>
                            <h1 className='text-xl font-bold'>{item?.header}</h1>
                            <p className='text-gray-500'>{item?.subHeader}</p>
                            <img src={item?.icon} alt={`benefit-icon-${index}`} className='w-10 h-10 object-cover bg-gray-50'/>
                            <button onClick={(e)=>{
                                // removeBenefits(item?._id)
                                setRemovingBenefit(item?._id);
                            }} className='bg-black text-white absolute top-0 right-2 rounded-md'>
                                <X/>
                            </button>
                            <button onClick={(e)=>{
                                // removeBenefits(item?._id)
                                setEditingBenefit(item);
                            }} className='bg-black text-white absolute top-0 left-2 rounded-md'>
                                <Edit/>
                            </button>
                        </div>
                    ))
                }
            </div>
            <h1>Upload New Benefit Data</h1>
            <input
                type="text"
                placeholder="Enter header"
                className="w-full p-2 border border-gray-300 rounded-md"
                value={formData?.header}
                onChange={(e) => {
                    setFormData({...formData,header: e.target.value})
                }}
            />
            <input
                type="text"
                placeholder="Enter Subheader"
                className="w-full p-2 border border-gray-300 rounded-md"
                value={formData?.subHeader}
                onChange={(e) => {
                    setFormData({...formData,subHeader: e.target.value})
                }}
            />
            <FileUploadComponent
                maxFiles={1}
                tag="icon"
                onSetImageUrls={(files) => {
                    setFormData({ ...formData, icon: files[0]?.url });
                }}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                onReset={reset}
            />
            
            <button
                className="bg-black px-4 py-2 rounded-lg text-white"
                onClick={handleUpdateBenefits}
            >
                Add / Update Benefits Data
            </button>
            {
                editingBenefit && <EditBenefitPopUp
                    editingBenefit={editingBenefit}
                    onClose={() => setEditingBenefit(null)}
                    onSave={(updatedData) => {
                        // Handle saving the updated data
                        editBenefits(updatedData);
                    }}
                />
            }
            {
                removingBenefit && <ConfirmDeletePopup
                    isOpen={removingBenefit !== null}
                    onClose={() => setRemovingBenefit(null)}
                    onCancel={() => setRemovingBenefit(null)}
                    onConfirm={() => {
                        removeBenefits(removingBenefit);
                        setRemovingBenefit(null);
                    }}
                />
            }
        </div>
    )
}
const EditBenefitPopUp = ({ editingBenefit, onClose, onSave }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [reset, setIsReset] = useState(false);
    const [formData, setFormData] = useState({ ...editingBenefit });

    const handleSave = () => {
        onSave(formData); // Save the updated data
        onClose(); // Close the modal
        setTimeout(() => {
            setIsReset(false);
        }, 100);
    };
    console.log("Form Data: ",formData);

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg w-3/4 sm:w-1/2 max-h-[700px] relative overflow-y-auto">
                <h2 className="text-2xl font-semibold mb-4 text-center">Preview</h2>
                <button onClick={onClose} className='bg-black text-white absolute top-2 right-2 rounded-md'>
                    <X/>
                </button>
                <div className="grid grid-cols-1 gap-6">
                    <h1>Update Benefit</h1>
                    <img
                        src={formData?.icon}
                        alt="Uploaded Image"
                        className="w-20 h-20 rounded-lg"

                    />
                    <FileUploadComponent
                        maxFiles={1}
                        tag="icon"
                        onSetImageUrls={(files) => {
                            setFormData({ ...formData, icon: files[0]?.url });
                        }}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        onReset={reset}
                    />
                    <input
                        type="text"
                        placeholder="Enter header"
                        className="w-full p-2 border border-gray-300 rounded-md"
                        value={formData?.header}
                        onChange={(e) => {
                            setFormData({...formData,header: e.target.value})
                        }}
                    />
                    <input
                        type="text"
                        placeholder="Enter Subheader"
                        className="w-full p-2 border border-gray-300 rounded-md"
                        value={formData?.subHeader}
                        onChange={(e) => {
                            setFormData({...formData,subHeader: e.target.value})
                        }}
                    />
                    <button
                        className="bg-black px-4 py-2 rounded-lg text-white"
                        onClick={handleSave}
                    >
                        Add / Update Benefits Data
                    </button>
                </div>
            </div>
        </div>


    );
};


export default BenefitsDataUpload