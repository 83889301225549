import { Plus, X, XIcon } from 'lucide-react';
import React, { useEffect, useState } from 'react';

const BulletPointForm = ({ tag, onChange, defaultData = [] }) => {
    const [bulletPoints, setBulletPoints] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const handleInputChange = (index, field, value) => {
        // Create a new array with the updated point, without mutating the existing state
        const updatedPoints = bulletPoints.map((point, i) =>
            i === index ? { ...point, [field]: value } : point
        );

        setBulletPoints(updatedPoints);
        // Filter out empty fields before passing to onChange
        const filteredData = updatedPoints.filter(b => b.PointBody !== '' && b.PointHeader !== '');
        if (onChange) {
            onChange(filteredData);
        }
        setIsEditing(true);
    };

    const addBulletPoint = () => {
        // Add a new bullet point with empty fields
        setBulletPoints([...bulletPoints, { PointHeader: '', PointBody: '' }]);
        const filteredData = bulletPoints.filter(b => b.PointHeader !== '' && b.PointBody !== '');
        if (onChange) {
            onChange(filteredData);
        }
        setIsEditing(true);
    };

    const removeBulletPoint = (index) => {
        // Remove the bullet point by filtering it out from the array
        const updatedPoints = bulletPoints.filter((_, i) => i !== index);
        setBulletPoints(updatedPoints);
        const filteredData = updatedPoints.filter(b => b.PointBody !== '' && b.PointHeader !== '');
        if (onChange) {
            onChange(filteredData);
        }
        setIsEditing(true);
    };

    useEffect(() => {
        if (!isEditing && defaultData && defaultData.length > 0) {
            setBulletPoints(defaultData);
        }
    }, [defaultData,isEditing]);

    return (
        <form className="w-full h-auto justify-start items-center flex flex-col gap-y-9 rounded-md">
        {bulletPoints && bulletPoints.length > 0 &&
            bulletPoints.map((point, index) => (
            <div key={index} style={{ marginBottom: "10px" }} className="justify-center p-4 space-y-4 w-full items-center flex flex-col space-x-2 relative border border-gray-700">
                <div className="justify-center w-full items-center h-auto">
                    <div className="flex gap-5 flex-col justify-between items-start">
                        <h1 className="text-center text-gray-700 font-bold">
                            PointHeader:
                            {point?.PointHeader === "" && (
                                <label className="text-red-500 text-xl">*</label> // Display star if header is empty
                            )}
                        </h1>
                        <div className="flex items-center w-full space-x-2">
                            <input
                                id={tag}
                                type="text"
                                value={point?.PointHeader}
                                onChange={(e) =>
                                    handleInputChange(index, "PointHeader", e.target.value)
                                }
                                placeholder="Enter header"
                                required
                                className="border focus:border-gray-900 border-black p-2 rounded-md"
                            />
                        </div>
                    </div>
                </div>
                <div className="justify-center w-full items-center max-w-full h-auto">
                    <div className="flex gap-5 flex-col justify-between items-start">
                        <h1 className="text-center font-bold">
                            PointBody:
                        </h1>
                        <textarea
                            id={tag}
                            value={point?.PointBody}
                            onChange={(e) =>
                                handleInputChange(index, "PointBody", e.target.value)
                            }
                            placeholder="Enter body"
                            rows="6"
                            required
                            className="border p-2 focus:border-gray-900 w-full border-black rounded-md"
                        />
                    </div>
                </div>
                <button
                    className="w-4 h-5 bg-black text-white absolute top-0 right-4 justify-center items-center flex-row flex"
                    onClick={() => removeBulletPoint(index)}
                >
                    <X size={30} />
                </button>
            </div>
            ))
        }
        <button
            type="button"
            className="bg-slate-800 font-bold justify-center text-white items-center w-full space-x-8 h-auto p-2 flex-row flex rounded-lg"
            onClick={addBulletPoint}
        >
            <span>Add New Bullet Point</span> <Plus strokeWidth={5} />
        </button>
        </form>
    );
};

export default BulletPointForm;
