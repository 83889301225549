import React, { useEffect, useState } from 'react';
import Sidebar from '../Frame/Sidebar';
import { useParams } from 'react-router-dom';
import { Footer } from '../../Constants/Footer';
import { setSelectedUserId } from '../../Redux/slices/userSlice';
import { useDispatch } from 'react-redux';
import { exportToExcel, exportToPDF, PDFIcon, ExcelIcon } from '../Utils/Exportutils.jsx';
import { BASE_URL } from '../../Constants/constant.js';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import Cookies from 'js-cookie';

export const WalletTtransaction = () => {
    const { userId } = useParams();
    const dispatch = useDispatch();
    const [wallet, setWallet] = useState([]);
    const [totalCount, setTotalCount] = useState(null);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const token = Cookies.get('token');
    const fetchReports = async (page = 1) => {
        setLoading(true);
        try {
            const response = await axios.get(`${BASE_URL}/api/v-1/wallet_history/wallet/${userId}?page=${page}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            setWallet(response.data.history || []);
            setTotalCount(response.data.totalCount);
            setTotalPages(response.data.totalPages);
            setCurrentPage(page);
            setPageSize(response.data.pageSize);
        } catch (error) {
            console.error('Error fetching wallet history:', error);
            setError('Failed to fetch wallet transactions.');
            setWallet([]);
        } finally {
            setLoading(false);
        }
    };

    const handlePageClick = ({ selected }) => {
        const newPageNumber = selected + 1;
        fetchReports(newPageNumber);
    };

    useEffect(() => {
        if (userId) {
            dispatch(setSelectedUserId(userId));
        }
        fetchReports();
    }, [dispatch, userId]);

    const getAmountStyle = (type) => {
        switch (type) {
            case 'joining_bonus':
            case 'DEPOSITE':
            case 'refferal_bonus':
            case 'REPORTDEPOSITE':
            case 'Ludo_game_win_amount':
            case 'ENCOURAGE_BONUS':
                return 'text-green-500';
            case 'withdrawal':
            case 'Ludo_game_joining_amount':
            case 'FAILED':
                return 'text-red-600';
            default:
                return '';
        }
    };

    const formatAmount = (amount, type) => {
        const style = getAmountStyle(type);
        const sign = style === 'text-red-600' ? '-' : style === 'text-green-500' ? '+' : '';

        return (
            <span className={style}>
                {sign} {amount}
            </span>
        );
    };



    const calculateSerialNumber = (index) => {
        return (currentPage - 1) * pageSize + index + 1;
    };

    return (
        <Sidebar>
            <div className="xl:ml-80 text-center px-4 py-5 sm:px-6">
                <h3 className="text-2xl leading-6 font-medium text-gray-900">
                    User Wallet Transaction Details
                </h3>
                <div className="mt-4 flex justify-end space-x-4">
                    <span
                        onClick={() => exportToPDF('wallet_transactionTable', 'wallet_transactionDetails.pdf')}
                        className="cursor-pointer"
                        title="Export to PDF"
                    >
                        <PDFIcon width={50} height={50} />
                    </span>

                    <span
                        onClick={() => exportToExcel('wallet_transactionTable', 'wallet_transactionDetails.xlsx')}
                        className="cursor-pointer"
                        title="Export to Excel"
                    >
                        <ExcelIcon width={50} height={50} />
                    </span>
                </div>
            </div>
            <div className="xl:ml-80 flex flex-col table-container overflow-x-auto">
                <table id="wallet_transactionTable" className="table table-bordered">
                    <thead className="bg-gray-100 sticky top-0">
                        <tr>
                            <th>Serial No</th>
                            <th>Transaction Id</th>
                            <th>Type</th>
                            <th>Amount</th>
                            <th>Time</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                        {Array.isArray(wallet) && wallet.length > 0 ? (
                            wallet.map((wallets, index) => (
                                <tr key={wallets.transactionId || index}>
                                    <td>{calculateSerialNumber(index)}</td>
                                    <td>{wallets.transactionId}</td>
                                    <td>{wallets.type}</td>
                                    <td className={`${wallets.type === 'withdrawal' || wallets.type === 'Ludo_game_joining_amount' || wallets.type === 'FAILED' || wallets.type === "SPINNING_THE_WHEEL" ? 'text-red-500' : 'text-green-500'}`}>
                                        <span className={getAmountStyle(wallets.type)}>
                                            {wallets.type === 'withdrawal' || wallets.type === 'Ludo_game_joining_amount' || wallets.type === 'FAILED' || wallets.type === "SPINNING_THE_WHEEL" ? '-' : '+'}
                                            {wallets.amount}
                                        </span>
                                        &nbsp;({wallets.status})
                                    </td>
                                    <td>{new Date(wallets.createdAt).toLocaleString()}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="12" className="text-center">No transactions available</td>
                            </tr>
                        )}
                    </tbody>
                </table>

                <div className="fixed bottom-20 right-0 text-blue-500 mr-12  text-xl text-md  ">
                    <div className="left-0 flex items-center justify-between px-4 py-2">
                        <p>
                            Showing page {currentPage} of {totalPages} &ensp;
                            {currentPage * pageSize - pageSize} to {Math.min(currentPage * pageSize, totalCount)} of {totalCount} entries
                        </p>
                        <div className="text-blue-500  `{active? text-red-500 }` pagination">
                            <ReactPaginate
                                previousLabel={""}
                                nextLabel={"."}
                                breakLabel={"...."}
                                pageCount={totalPages}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                                className="flex"
                            />
                        </div>
                    </div>
                </div>
            </div>




            <Footer />
        </Sidebar>
    );
};
