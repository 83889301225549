import { useEffect, useRef, useState } from "react";
import { BsSend, BsImage } from "react-icons/bs";
import useSendMessage from "../hooks/useSendMessage.js";

const MessageInput = () => {
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState([]);
  const { loading, sendMessage } = useSendMessage();

  const handleFileChange = (e) => {
    setFiles(Array.from(e.target.files));
  };

  const textareaRef = useRef(null);

  useEffect(() => {
    textareaRef.current.style.height = 'auto';
    textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
  }, [message]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!message.trim() && files.length === 0) return; 
    
    try {
      await sendMessage(message, files); 
    } finally {
      setMessage("");
      setFiles([]);  
    }
  };

  return (
    <form className="px-4 my-3" onSubmit={handleSubmit} style={{marginLeft:"280px"}}>
      <div className="relative flex items-center">
        <textarea
      ref={textareaRef}
      className="border flex text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 text-white"
      style={{
        // resize: 'none', 
        overflow: 'hidden', 
        paddingRight:'100px'
      }}
      placeholder="Send a message"
      value={message}
      onChange={(e) => setMessage(e.target.value)}
      rows={1} 
    />


        
        <input
          id="file-upload"
          type="file"
          multiple
          onChange={handleFileChange}
          className="absolute inset-y-0 w-[12px]  end-0 opacity-0 "
          aria-label="Upload files"
        />
        
        <label htmlFor="file-upload" className="absolute inset-y-0 end-12 flex items-center px-3 cursor-pointer">
          <BsImage size={20} className="text-white hover:text-blue-500 " />
        </label>

        <button type="submit" className="absolute  inset-y-0 end-3 flex items-center px-3">
          {loading ? (
            <div class="w-6 h-6 border-4 border-t-4 border-white-500 border-solid border-blue-700 rounded-full animate-spin"></div>
          ) : (
            <BsSend className="text-white hover:text-blue-500 text-xl font-bold" />
          )}
        </button>
      </div>
      
      <div className="pt-1 flex flex-wrap gap-2 bg-gray-300 rounded-lg">
        {files.map((file, index) => (
          <div key={index} className="relative">
            <img
              src={URL.createObjectURL(file)}
              alt={`preview-${index}`}
              className="w-20 h-20 object-cover rounded-lg border border-gray-600"
            />
            <button
              type="button"
              onClick={() => setFiles(files.filter((_, i) => i !== index))}
              className="absolute top-1 right-1 bg-red-600 text-white p-1 rounded-full text-sm"
            >
              &times;
            </button>
          </div>
        ))}
      </div>
    </form>
  );
};

export default MessageInput;
