import React, { useEffect, useState } from 'react';
import Sidebar from '../Frame/Sidebar';
import { Footer } from '../../Constants/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { BASE_URL } from '../../Constants/constant';
import axios from 'axios';
import RoomDetailsPopup from './Reportpopup.jsx';
import { fetchRequest, setSelectedUserId } from '../../Redux/slices/userSlice.js';
import { exportToExcel, exportToPDF, PDFIcon, ExcelIcon } from '../Utils/Exportutils.jsx';

export const ReportDetails = () => {
    const { userId } = useParams();
    const dispatch = useDispatch();
    const { users, loading, error } = useSelector(state => state.data.data);
    const user = Array.isArray(users) ? users.find(user => user._id === userId) : null;
    
    const [room, setRoom] = useState([]);
    const [popup, setPopup] = useState(false);
    const [selectedRoom, setSelectedRoom] = useState(null);
    const token = localStorage.getItem('token');
    const fetchReports = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/v-1/room/getallrooms`,{
                headers:{
                    'Authorization': `Bearer ${token}`,
                }
            });
            setRoom(response.data.rooms || []);
        } catch (error) {
            console.error('Error fetching rooms:', error);
            setRoom([]);
        }
    };

    useEffect(() => {
        if (userId) {
            dispatch(setSelectedUserId(userId));
            dispatch(fetchRequest());
        }
        fetchReports();
    }, [dispatch, userId]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error loading data: {error.message}</div>;

    const handleRoomClick = (roomId) => {
        const selected = room.find(room => room.roomId === roomId);
        setSelectedRoom(selected);
        setPopup(true);
    };

    const handleClosePopup = () => {
        setPopup(false);
        setSelectedRoom(null);
    };

    return (
        <Sidebar>
            <div className="xl:ml-80 text-center px-4 py-5 sm:px-6">
                <h3 className="text-2xl leading-6 font-medium text-gray-900">
                    User Report Details
                </h3>
                <div className="mt-4 flex justify-end space-x-4">
                    <span
                        onClick={() => exportToPDF('reportTable', 'reportTableDetails.pdf')}
                        className="cursor-pointer"
                        title="Export to PDF"
                    >
                        <PDFIcon width={50} height={50} />
                    </span>
                    <span
                        onClick={() => exportToExcel('reportTable', 'reportTable.xlsx')}
                        className="cursor-pointer"
                        title="Export to Excel"
                    >
                        <ExcelIcon width={50} height={50} />
                    </span>
                </div>
            </div>
            <div className="xl:ml-80 flex flex-col table-container overflow-x-auto">
                <table id="reportTable" className="table table-bordered">
                    <thead className="bg-gray-100 sticky top-0">
                        <tr>
                            <th className="px-6 py-3 text-left">Serial No</th>
                            <th className="px-6 py-3 text-left">Amount</th>
                            <th className="px-6 py-3 text-left">Number of Players</th>
                            <th className="px-6 py-3 text-left">Mode</th>
                            <th className="px-6 py-3 text-left">RoomId</th>
                            <th className="px-6 py-3 text-left">Status</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                        {Array.isArray(user?.Report) && user.Report.length === 0 ? (
                            <tr>
                                <td colSpan="12" className="text-center py-4">No Reports Registered by user</td>
                            </tr>
                        ) : (
                            user?.Report?.map((usergame, index) => {
                                const roomData = room.find(room => room.roomId === usergame.roomId);
                                const gameAmount = roomData?.joining_amount || 'N/A';
                                const no_of_players = roomData?.no_of_players || 'N/A';
                                const mode_type = roomData?.mode_type || 'N/A';
                                return (
                                    <tr key={usergame._id} className={index % 2 === 0 ? "bg-white" : "bg-gray-100"}>
                                        <td className='px-6 py-4'>{index + 1}</td>
                                        <td className='px-6 py-4'>{gameAmount}</td>
                                        <td className='px-6 py-4'>{no_of_players}</td>
                                        <td className='px-6 py-4'>{mode_type}</td>
                                        <td className='px-6 py-4 text-blue-500'>
                                            <button onClick={() => handleRoomClick(usergame.roomId)} className="underline">
                                                {usergame.roomId}
                                            </button>
                                        </td>
                                        <td className={`px-6 py-4 ${usergame.status === 'pending' ? 'text-red-500' :
                                            usergame.status === 'resolved' ? 'text-green-500' : ''
                                            }`}>
                                            {usergame.status}
                                        </td>
                                    </tr>
                                );
                            })
                        )}
                    </tbody>
                </table>
            </div>
            <Footer />
            <RoomDetailsPopup room={selectedRoom} user={user} onClose={handleClosePopup} />
        </Sidebar>
    );
};
