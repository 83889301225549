import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { MAIN_URL } from "../../Constants/constant.js";


const initialState = {
	isLoading:true,
	homeData:null,
    benefitsData:[],
    ErrorMessage:''
    
}
const ludoWebsiteReducer = createSlice({
	name:'ludoWebsite',
    initialState,
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(fetchLudoHomeData.pending,(state)=>{
            state.isLoading = true;
        }).addCase(fetchLudoHomeData.fulfilled,(state,action)=>{
            state.isLoading = false;
            state.homeData = action?.payload?.result || [];
        }).addCase(fetchLudoHomeData.rejected,(state,action)=>{
            state.isLoading = false;
            state.homeData = []
            state.ErrorMessage = action?.payload?.error?.message || "Failed to fetch Hero Carousal";
        }).addCase(fetchBenefitsData.pending,(state)=>{
            state.isLoading = true;
        }).addCase(fetchBenefitsData.fulfilled,(state,action)=>{
            state.isLoading = false;
            state.benefitsData = action?.payload?.result || [];
        }).addCase(fetchBenefitsData.rejected,(state,action)=>{
            state.isLoading = false;
            state.benefitsData = []
            state.ErrorMessage = action?.payload?.error?.message || "Failed to fetch Benefits Carousal";
        })
    }
})


export const setLudoHomeData = createAsyncThunk('/myPrideWebsite/addHomeData',async(data)=>{
    try {
        const response = await axios.post(`${MAIN_URL}/api/ludoMyPrideWebsite/ludoHome/add`,data)
        // console.log("Set Carousal Image",response.data);
        return response.data;
    } catch (error) {
        console.error("Error setting carousal image: ",error);
        return error?.response?.data || { message: "Unknown error occurred" };
    }
})
export const fetchLudoHomeData = createAsyncThunk('/myPrideWebsite/addHomeData',async()=>{
    try {
        const response = await axios.get(`${MAIN_URL}/api/ludoMyPrideWebsite/ludoHome/all`)
        // console.log("Set Carousal Image",response.data);
        return response.data;
    } catch (error) {
        console.error("Error setting carousal image: ",error);
        return error?.response?.data || { message: "Unknown error occurred" };
    }
})


export const setBenefitsData = createAsyncThunk('/myPrideWebsite/addBenefitsData',async(data)=>{
    try {
        const response = await axios.post(`${MAIN_URL}/api/ludoMyPrideWebsite/ludoBenefits/add`,data)
        return response.data;
    } catch (error) {
        console.error("Error setting carousal image: ",error);
        return error?.response?.data || { message: "Unknown error occurred" };
    }
})

export const fetchBenefitsData = createAsyncThunk('/myPrideWebsite/addBenefitsData',async()=>{
    try {
        const response = await axios.get(`${MAIN_URL}/api/ludoMyPrideWebsite/ludoBenefits/all`)
        return response.data;
    } catch (error) {
        console.error("Error setting carousal image: ",error);
        return error?.response?.data || { message: "Unknown error occurred" };
    }
})
export const delBenefit = createAsyncThunk('/myPrideWebsite/deleteBenefitsData',async(id)=>{
    try {
        const response = await axios.delete(`${MAIN_URL}/api/ludoMyPrideWebsite/ludoBenefits/delete/${id}`)
        return response.data;
    } catch (error) {
        console.error("Error Removing Benefits Data: ",error);
        return error?.response?.data || { message: "Unknown error occurred" };
    }
})
export const editBenefit = createAsyncThunk('/myPrideWebsite/editBenefit',async({id,data})=>{
    try {
        const response = await axios.patch(`${MAIN_URL}/api/ludoMyPrideWebsite/ludoBenefits/update/${id}`,data)
        return response.data;
    } catch (error) {
        console.error("Error Removing Benefits Data: ",error);
        return error?.response?.data || { message: "Unknown error occurred" };
    }
})


export default ludoWebsiteReducer.reducer;