import React, { useEffect, useState } from 'react';
import Sidebar from '../Frame/Sidebar';
import { Footer } from '../../Constants/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchRequest, setSelectedUserId } from '../../Redux/slices/userSlice';
import { exportToExcel, exportToPDF, PDFIcon, ExcelIcon } from '../Utils/Exportutils.jsx';
import { BASE_URL } from '../../Constants/constant.js';
import axios from 'axios';
import Cookies from 'js-cookie';

export const ResultData = () => {
    const { userId } = useParams();
    const [rooms, setRooms] = useState([]);
    const dispatch = useDispatch();
    const users = useSelector(state => state.data.data.users) || [];
    const user = Array.isArray(users) ? users.find(user => user._id === userId) : null;
    const token = Cookies.get('token');
    const fetchReports = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/v-1/room/getallrooms`,{
                headers:{
                    'Authorization': `Bearer ${token}`,
                }
            });
            setRooms(response.data.rooms || []);
        } catch (error) {
            console.error('Error fetching rooms:', error);
            setRooms([]);
        }
    };

    const particulardata = rooms
    .filter(room => room.players.some(player => player.clientId === userId))
    .map(room => ({
        _id: room._id,
        joining_amount: room.joining_amount,
        no_of_players: room.no_of_players,
        mode_type: room.mode_type,
        roomId: room.roomId,
        result: room.players.find(player => player.clientId === userId),
        date:room.date
    }))
    // .slice(0, 20);

    useEffect(() => {
        fetchReports();
    }, []);

    useEffect(() => {
        if (userId) {
            dispatch(setSelectedUserId(userId));
            dispatch(fetchRequest());
        }
    }, [dispatch, userId]);

    if (!user) {
        return (
            <Sidebar>
                <div className="xl:ml-80 text-center px-4 py-5 sm:px-6">
                    <h3 className="text-2xl leading-6 font-medium text-gray-900">
                        User Not Found
                    </h3>
                </div>
                <Footer />
            </Sidebar>
        );
    }

    return (
        <Sidebar>
            <div className="xl:ml-80 text-center px-4 py-5 sm:px-6">
                <h3 className="text-2xl leading-6 font-medium text-gray-900">
                    User Game Result Details
                </h3>
                <div className="mt-4 flex justify-end space-x-4">
                    <span
                        onClick={() => exportToPDF('resultTable', 'ResultDataDetails.pdf')}
                        className="cursor-pointer"
                        title="Export to PDF"
                    >
                        <PDFIcon width={50} height={50} />
                    </span>

                    <span
                        onClick={() => exportToExcel('resultTable', 'ResultDataDetails.xlsx')}
                        className="cursor-pointer"
                        title="Export to Excel"
                    >
                        <ExcelIcon width={50} height={50} />
                    </span>
                </div>
            </div>
            <div className="xl:ml-80 flex flex-col table-container overflow-x-auto">
                <table id="resultTable" className="table table-bordered">
                    <thead className="bg-gray-100 sticky top-0">
                        <tr>
                            <th className="px-6 py-3 text-left">Serial No</th>
                            <th className="px-6 py-3 text-left">Amount</th>
                            <th className="px-6 py-3 text-left">Number of Players</th>
                            <th className="px-6 py-3 text-left">Mode</th>
                            <th className="px-6 py-3 text-left">RoomId</th>
                            <th className="px-6 py-3 text-left">Result</th>
                            <th className="px-6 py-3 text-left">Date</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                        {particulardata.length > 0 ? (
                            particulardata.map((usergame, index) => (
                                <tr key={usergame._id} className={index % 2 === 0 ? "bg-white" : "bg-gray-100"}>
                                    <td className='px-6 py-4'>{index + 1}</td>
                                    <td className='px-6 py-4'>{usergame.joining_amount}</td>
                                    <td className='px-6 py-4'>{usergame.no_of_players}</td>
                                    <td className='px-6 py-4'>{usergame.mode_type}</td>
                                    <td className='px-6 py-4'>{usergame.roomId}</td>
                                    <td className='px-6 py-4'>
                                        {usergame.result ? (
                                            <span className={usergame.result.winorloss === "Win"   ? "text-green-500" : "text-red-500"}>
                                                {usergame.result.winorloss === "Win"  ? `+${usergame.result.winamount}` : `-${usergame.joining_amount}`}
                                            </span>
                                        ) : 'No result'}
                                    </td>
                                    <td className='px-6 py-4'>{usergame.date.split('T')[0]} &ensp; Time =&ensp;{usergame.date.split('T')[1].split(".")[0]} </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="12" className="px-6 py-4 text-center">No game results found.</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <Footer />
        </Sidebar>
    );
};
