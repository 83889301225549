import React, { useEffect, useState } from 'react';
import Sidebar from '../Frame/Sidebar';
import { useParams } from 'react-router-dom';
import { Footer } from '../../Constants/Footer';
import { useSelector, useDispatch } from 'react-redux';
import { fetchRequest, setSelectedUserId } from '../../Redux/slices/userSlice.js';
import PaymentForm from '../Subpaisa/Subpaisa.jsx';

export const ViewDetails = () => {
    const { userId } = useParams();
    const dispatch = useDispatch();
    const [user, setUser] = useState(null);

    const userState = useSelector(state => state.data || {});
    const { users } = userState.data || {};

    useEffect(() => {
        if (userId) {
            dispatch(setSelectedUserId(userId));
            dispatch(fetchRequest());
        }
    }, [dispatch, userId]);

    useEffect(() => {
        if (users.length > 0) {
            const foundUser = users.find(user => user._id === userId);
            setUser(foundUser);
        }
    }, [users, userId]);

    if (!user) {
        return <div>Loading...</div>;
    }

    return (
        <Sidebar>
            <div>
                <div className='xl:ml-80 flex flex-col '>
                    <div class="bg-white shadow sm:rounded-lg">
                        <div class="px-4 text-center py-5 sm:px-6">
                            <h3 class="text-2xl leading-6 font-medium text-gray-900">
                                User Details
                            </h3>
                        </div>
                        <div class="border-t border-gray-200">
                            <div className='flex '>
                                <div className='w-3/4'>
                                    <dl>
                                        <div class="bg-gray-50 px-4 py-5 xl:grid xl:grid-cols-3 xl:gap-4 xl:px-6">
                                            <dt class="text-sm font-medium text-gray-900">
                                                Unique Id
                                            </dt>
                                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                {user._id}
                                            </dd>
                                        </div>
                                        <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt class="text-sm font-medium text-gray-900">
                                                Name
                                            </dt>
                                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                {user.name}
                                            </dd>
                                        </div>
                                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt class="text-sm font-medium text-gray-900">
                                                Phone Number
                                            </dt>
                                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                {user.phoneNumber}
                                            </dd>
                                        </div>
                                        <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt class="text-sm font-medium text-gray-900">
                                                DOB
                                            </dt>
                                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                {user.dob}
                                            </dd>
                                        </div>
                                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt class="text-sm font-medium text-gray-900">
                                                State
                                            </dt>
                                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                {user.state}
                                            </dd>
                                        </div>
                                        <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt class="text-sm font-medium text-gray-900">
                                                RefferalCode
                                            </dt>
                                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                {user.RefferalCode}
                                            </dd>
                                        </div>
                                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt class="text-sm font-medium text-gray-900">
                                                Last Activity
                                            </dt>
                                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                <p className='top-2'>
                                                    {user.IsActive === true ? (
                                                        <span className="inline-flex items-center bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">
                                                            <span className="w-2 h-2 me-1 bg-green-500 rounded-full" style={{ animation: 'blink 1s infinite' }}></span>
                                                            Active
                                                        </span>
                                                    ) : (
                                                        <span className="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                                                            <span className="w-2 h-2 me-1 bg-red-500 rounded-full" style={{ animation: 'blink 1s infinite' }}></span>
                                                            NotActive
                                                        </span>
                                                    )}
                                                    <style jsx>{` @keyframes blink { 0% { opacity: 1; } 50% { opacity: 0; }100% { opacity: 1; } } `}</style>

                                                </p>
                                            </dd>
                                        </div>
                                        <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt class="text-sm font-medium text-gray-900">
                                                Bank Verification
                                            </dt>
                                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                <p className='top-2'>
                                                    {user.verificationstatusbank === "Not-Uploaded" ? (
                                                        <span className="inline-flex items-center bg-stone-100 text-stone-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-stone-900 dark:text-stone-300">
                                                            <span className="w-2 h-2 me-1 bg-stone-500 rounded-full" style={{ animation: 'blink 1s infinite' }}></span>
                                                            Not-Uploaded
                                                        </span>
                                                    ) : user.verificationstatusbank === "Pending" ? (
                                                        <span className="inline-flex items-center bg-yellow-100 text-yellow-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-yellow-900 dark:text-yellow-300">
                                                            <span className="w-2 h-2 me-1 bg-yellow-500 rounded-full" style={{ animation: 'blink 1s infinite' }}></span>
                                                            Pending
                                                        </span>
                                                    ) : user.verificationstatusbank === "Not-Verified" ? (
                                                        <span className="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                                                            <span className="w-2 h-2 me-1 bg-red-500 rounded-full" style={{ animation: 'blink 1s infinite' }}></span>
                                                            Not-Verified
                                                        </span>
                                                    ) : user.verificationstatusbank === "Verified" ? (
                                                        <span className="inline-flex items-center bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">
                                                            <span className="w-2 h-2 me-1 bg-green-500 rounded-full" style={{ animation: 'blink 1s infinite' }}></span>
                                                            Verified
                                                        </span>
                                                    ) : null}
                                                    <style jsx>{` @keyframes blink { 0% { opacity: 1; } 50% { opacity: 0; }100% { opacity: 1; } } `}</style>

                                                </p>
                                            </dd>
                                        </div>
                                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt class="text-sm font-medium text-gray-00">
                                                Withdrawal Balance
                                            </dt>
                                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                {/* {Number(user.withdrawal_balance)} */}
                                                {user.withdrawal_balance["$numberDecimal"]}
                                            </dd>
                                        </div>

                                    </dl>
                                </div>
                                <div className='w-1/2'>
                                    <dl>
                                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt class="text-sm font-medium text-gray-900">
                                                Game Name
                                            </dt>
                                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                {user.game_name}
                                            </dd>
                                        </div>
                                    
                                        <div class=" bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt class="text-sm font-medium text-gray-900">
                                                Country
                                            </dt>
                                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                {user.country}
                                            </dd>
                                        </div>
                                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt class="text-sm font-medium text-gray-900">
                                                Account Status
                                            </dt>
                                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                <p className='top-2'>
                                                    {user.verificationstatus === true ? (
                                                        <span className="inline-flex items-center bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">
                                                            <span className="w-2 h-2 me-1 bg-green-500 rounded-full" style={{ animation: 'blink 1s infinite' }}></span>
                                                            Verified
                                                        </span>
                                                    ) : (
                                                        <span className="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                                                            <span className="w-2 h-2 me-1 bg-red-500 rounded-full" style={{ animation: 'blink 1s infinite' }}></span>
                                                            NotVerified
                                                        </span>
                                                    )}
                                                    <style jsx>{` @keyframes blink { 0% { opacity: 1; } 50% { opacity: 0; }100% { opacity: 1; } } `}</style>

                                                </p>

                                            </dd>
                                        </div>
                                        <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt class="text-sm font-medium text-gray-900">
                                                Pan Verification
                                            </dt>
                                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                <p className='top-2'>
                                                    {user.verificationstatuspancard === "Not-Uploaded" ? (
                                                        <span className="inline-flex items-center bg-stone-100 text-stone-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-stone-900 dark:text-stone-300">
                                                            <span className="w-2 h-2 me-1 bg-stone-500 rounded-full" style={{ animation: 'blink 1s infinite' }}></span>
                                                            Not-Uploaded
                                                        </span>
                                                    ) : user.verificationstatuspancard === "Pending" ? (
                                                        <span className="inline-flex items-center bg-yellow-100 text-yellow-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-yellow-900 dark:text-yellow-300">
                                                            <span className="w-2 h-2 me-1 bg-yellow-500 rounded-full" style={{ animation: 'blink 1s infinite' }}></span>
                                                            Pending
                                                        </span>
                                                    ) : user.verificationstatuspancard === "Not-Verified" ? (
                                                        <span className="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                                                            <span className="w-2 h-2 me-1 bg-red-500 rounded-full" style={{ animation: 'blink 1s infinite' }}></span>
                                                            Not-Verified
                                                        </span>
                                                    ) : user.verificationstatuspancard === "Verified" ? (
                                                        <span className="inline-flex items-center bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">
                                                            <span className="w-2 h-2 me-1 bg-green-500 rounded-full" style={{ animation: 'blink 1s infinite' }}></span>
                                                            Verified
                                                        </span>
                                                    ) : null}
                                                    <style jsx>{` @keyframes blink { 0% { opacity: 1; } 50% { opacity: 0; }100% { opacity: 1; } } `}</style>

                                                </p>
                                            </dd>
                                        </div>
                                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt class="text-sm font-medium text-gray-900">
                                                Aadhar Verification
                                            </dt>
                                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                <p className='top-2'>
                                                    {user.verificationstatusaadharcard === "Not-Uploaded" ? (
                                                        <span className="inline-flex items-center bg-stone-100 text-stone-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-stone-900 dark:text-stone-300">
                                                            <span className="w-2 h-2 me-1 bg-stone-500 rounded-full" style={{ animation: 'blink 1s infinite' }}></span>
                                                            Not-Uploaded
                                                        </span>
                                                    ) : user.verificationstatusaadharcard === "Pending" ? (
                                                        <span className="inline-flex items-center bg-yellow-100 text-yellow-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-yellow-900 dark:text-yellow-300">
                                                            <span className="w-2 h-2 me-1 bg-yellow-500 rounded-full" style={{ animation: 'blink 1s infinite' }}></span>
                                                            Pending
                                                        </span>
                                                    ) : user.verificationstatusaadharcard === "Not-Verified" ? (
                                                        <span className="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                                                            <span className="w-2 h-2 me-1 bg-red-500 rounded-full" style={{ animation: 'blink 1s infinite' }}></span>
                                                            Not-Verified
                                                        </span>
                                                    ) : user.verificationstatusaadharcard === "Verified" ? (
                                                        <span className="inline-flex items-center bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">
                                                            <span className="w-2 h-2 me-1 bg-green-500 rounded-full" style={{ animation: 'blink 1s infinite' }}></span>
                                                            Verified
                                                        </span>
                                                    ) : null}
                                                    <style jsx>{` @keyframes blink { 0% { opacity: 1; } 50% { opacity: 0; }100% { opacity: 1; } } `}</style>

                                                </p>

                                            </dd>
                                        </div>
                                        <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt class="text-sm font-medium text-gray-900">
                                                Device Id
                                            </dt>
                                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                {user.deviceId}
                                            </dd>
                                        </div>
                                        <div class=" bg-gray-50  px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt class="text-sm font-medium text-gray-900">
                                                Game Balance
                                            </dt>
                                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                {/* {Number(user.game_balance)} */}
                                                {user.game_balance["$numberDecimal"]}
                                            </dd>
                                            

                                        </div>
                                        <div class=" bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt class="text-sm font-medium text-gray-900">
                                                Bonus Balance
                                            </dt>
                                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                {user.bonus_balance["$numberDecimal"]}
                                            </dd>
                                        </div>

                                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt class="text-sm font-medium text-gray-900">
                                                Cashback Name
                                            </dt>
                                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                               {user.cashback_balance["$numberDecimal"]}
                                            </dd>
                                        </div>
                                    </dl>
                                </div>
                            </div>
                        </div>
                        <div class="px-4 py-5 sm:px-6 mt-2">
                            <h3 class="text-lg leading-6 font-medium text-gray-900">
                                Game Details
                            </h3>
                        </div>
                        <div className="table-container ">
                            <table className="table table-bordered">
                                <thead className="bg-gray-100 sticky top-0">
                                    <tr>
                                        <th>Serial No</th>
                                        <th>Gmae Type</th>
                                        <th>Played Count</th>
                                        <th>Total Win</th>
                                        <th>Total Loss</th>
                                        <th>Win_Loose Ratio</th>

                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                    <tr >
                                        <td>1</td>
                                        <td>Ludo</td>
                                        <td>{user.game_wincount + user.game_losscount}</td>
                                        <td>{user.game_wincount}</td>
                                        <td>{user.game_losscount}</td>
                                        <td>{user.game_wincount}/{user.game_losscount}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </Sidebar>
    );
}






