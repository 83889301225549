import React, { useState } from "react";
import { BASE_URL } from "../../Constants/constant";
import toast from "react-hot-toast";
import moment from "moment";
import Cookies from 'js-cookie';

const Updatemaintainance = ({ add, onClose, contact }) => {
  const [formData, setFormData] = useState({
    serverName: "LUDO_SERVER",
    status: contact.status || "",
    nextMaintenance: contact.nextMaintenance
      ? moment(contact.nextMaintenance).format("YYYY-MM-DDTHH:mm")
      : "",
  });

  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const token = Cookies.get("token");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]:
        name === "nextMaintenance"
          ? moment(value).format("YYYY-MM-DDTHH:mm") // Format correctly for datetime-local
          : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!token) {
      toast.error("Authentication token is missing. Please log in again.");
      return;
    }

    try {
      const payload = {
        ...formData,
        nextMaintenance: moment(formData.nextMaintenance).format(
          "YYYY-MM-DD HH:mm:ss"
        ), // Convert to API format
      };

      const res = await fetch(`${BASE_URL}/api/v-1/serverMaintenace/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }

      const result = await res.json();
      setResponse(result);
      setError(null);
      add();
      toast.success("Maintenance details updated successfully!");
      onClose();
    } catch (err) {
      setError(err.message);
      setResponse(null);
      toast.error("Failed to update maintenance details.");
    }
  };

  return (
    <div style={{ width: "50vw" }}>
      {response && (
        <div className="mt-6 p-4 bg-green-50 border border-green-200 text-green-800 rounded-lg shadow-md">
          <h3 className="text-lg text-center font-semibold">Updated Successfully!</h3>
        </div>
      )}
      {error && (
        <div className="mt-6 p-4 bg-red-50 border border-red-200 text-red-800 rounded-lg shadow-md">
          <h3 className="text-lg font-semibold">Error in Updating</h3>
        </div>
      )}
      <h2 className="text-2xl font-semibold text-gray-800 mb-6 text-center">
        Update Maintenance Details
      </h2>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="flex flex-col-2">
          <label className="block text-sm font-medium text-gray-700 py-2.5 mr-5">Status:</label>
          <select
            name="status"
            value={formData.status}
            onChange={handleChange}
            className="block py-2.5 px-0 text-center w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          >
            <option value="">Select Status</option>
            <option value="active">Active</option>
            <option value="maintenance">Maintenance</option>
          </select>
        </div>
        <div className="flex flex-col-2">
          <label className="block text-sm font-medium text-gray-700 py-2.5 mr-5">
            Next Maintenance:
          </label>
          <input
            type="datetime-local"
            name="nextMaintenance"
            value={formData.nextMaintenance}
            onChange={handleChange}
            className="block py-2.5 px-0 text-center w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          />
        </div>
        <div className="flex space-between">
          <button
            type="submit"
            className="w-full bg-indigo-600 text-white py-3 px-1 rounded-lg shadow-md hover:bg-indigo-700 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Submit
          </button>
          <button
            onClick={onClose}
            className="w-full bg-red-600 text-white py-3 px-1 ml-5 rounded-lg shadow-md hover:bg-red-700 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Close
          </button>
        </div>
      </form>
    </div>
  );
};

export default Updatemaintainance;
