import useGetConversations from "../hooks/useGetConversations";
import Conversation from "./Conversation";

const Conversations = () => {
    const { loading, conversations } = useGetConversations();

    return (
        <div className='py-2 flex flex-col table-container-button overflow-x-auto' style={{ height: "72vh" }}>
            {conversations.length > 0 ? (
                conversations.map((conversation, idx) => (
                    <Conversation
                        key={conversation._id}
                        conversation={conversation}
                        lastIdx={idx === conversations.length - 1}
                    />
                ))
            ) : (
                <p>No conversations available</p>
            )}
            {loading && <span className='loading loading-spinner mx-auto'></span>}
        </div>
    );
};

export default Conversations;
