import { createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';

const initialState = {
  status: '',
  isLoading: true,
  employees: {
    employees: [], 
    totalEmployeeCount: 0,
    currentPage: 0,
    totalPages: 0,
  },
  error: null,
  selectedEmployeeId: null,
};

const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {
    addRequest(state) {
      state.status = 'loading';
      state.isLoading = true;
      state.error = null;
  },
  addSuccess(state, action) {
      state.status = 'succeeded';
      state.isLoading = false;
      state.employees.totalEmployeeCount += 1;
      if (action.payload) {
          state.employees.employees.push(action.payload);
          toast.success('Employee added successfully');
          console.log(action.payload, "success");
      }
  },
  addFailure(state, action) {
      state.error = action.payload;
      state.status = 'failed';
      state.isLoading = false;
      toast.error(action.payload);
      console.log(action.payload, "failure");
  },
  
    // Update password actions
    updatePasswordRequest(state,action) {
      state.status = 'loading';
      state.isLoading = true;
      state.error = null;
      const updatedEmployee = action.payload;
      state.employees.employees = state.employees.employees.map(employee=>
        employee._id === updatedEmployee._id ? updatedEmployee : employee    
        );
    },
    updatePasswordSuccess(state, action) {
      state.status = 'succeeded';
      state.isLoading = false;
    },
    updatePasswordFailure(state, action) {
      state.error = action.payload;
      state.status = 'failed';
      state.isLoading = false;
    },

    // Fetch actions
    fetchRequest(state) {
      state.status = 'loading';
      state.isLoading = true;
      state.error = null;
    },
    fetchSuccess(state, action) {
      state.employees = action.payload;
      state.status = 'succeeded';
      state.isLoading = false;
    },
    fetchFailure(state, action) {
      state.error = action.payload;
      state.status = 'failed';
      state.isLoading = false;
    },

    // Login actions
    loginRequest(state) {
      state.status = 'loading';
      state.isLoading = true;
      state.error = null;
    },
    loginSuccess(state, action) {
      // Handle login success logic if needed
      state.status = 'succeeded';
      state.isLoading = false;
    },
    loginFailure(state, action) {
      state.error = action.payload;
      state.status = 'failed';
      state.isLoading = false;
    },

    updateRequest: (state, action) => {
      state.status = 'loading';
     state.isLoading = true;
     state.error = null;
     state.employees.employees = state.employees.employees.map(emp =>
       emp._id === action.payload.id ? { ...emp, ...action.payload } : emp
     );
     state.status = 'loading';
   },
   updateSuccess: (state, action) => {
     state.status = 'succeeded';
     state.isLoading = false;
     state.employees.employees = state.employees.employees.map(emp =>
       emp._id === action.payload._id ? action.payload : emp
     );
     state.status = 'succeeded';
   },
   updateFailure: (state, action) => {
     state.error = action.payload;
     state.status = 'failed';
     state.error = action.payload;
   },
 

// Redux slice
deleteRequest(state, action) {
  state.status = 'loading';
  state.isLoading = true;
  state.error = null;
  const deleteId = action.payload;
  state.employees.employees = state.employees.employees.map(emp =>
    emp._id === deleteId ? { ...emp, deleting: true } : emp
  );

},

deleteSuccess(state, action) {
  state.status = 'succeeded';
  state.isLoading = false;
  toast.success(action.payload);
  state.employees.employees = state.employees.employees.filter(emp =>
    emp._id !== action.payload._id
    
  );
},

deleteFailure(state, action) {
  state.status = 'failed';
  state.error = action.payload;
  toast.error(action.payload);
  state.isLoading = false;
  
  const originalEmployee = action.meta.arg.originalEmployee;
  
  // Rollback the optimistic update by resetting the deleting state
  state.employees.employees = state.employees.employees.map(emp =>
    emp._id === originalEmployee._id ? { ...originalEmployee, deleting: false } : emp
  );
},


    // Reset state
    resetState(state) {
      return initialState;
    },

    // Selected User ID
    setSelectedEmployeeId(state, action) {
      state.selectedEmployeeId = action.payload;
    },
  },
});

export const {
  loginRequest,
  loginSuccess,
  loginFailure,
  updatePasswordRequest,
  updatePasswordSuccess,
  updatePasswordFailure,
  fetchRequest,
  fetchSuccess,
  fetchFailure,
  updateRequest,
  updateSuccess,
  updateFailure,
  deleteRequest,
  deleteSuccess,
  deleteFailure,
  addRequest,
  addSuccess,
  addFailure,
  resetState,
  setSelectedEmployeeId,
} = employeeSlice.actions;

export default employeeSlice.reducer;
