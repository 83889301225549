import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import BulletPointForm from "./BulletPointForm";
import { getTermsAndConditions, updateTermsAndConditions } from "../../../Redux/slices/websiteSlice";
import {ChevronUp } from 'lucide-react';
const TermsAndCondition = () => {
	const{termsAndCondition} = useSelector(state => state.website);
    const[toggleBulletPoints, setToggleBulletPoints] = useState([{index:0, toggle:false}]);
    const [formData, setFormData] = useState(termsAndCondition);

    const dispatch = useDispatch();
	

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
			...prevState,
			[name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(!formData.MainData.every((item)=>item.Header !== '' && item.Body.length > 0)){
            toast.error("Please fill in all the fields");
            return;
        }
		await dispatch(updateTermsAndConditions(formData))
		toast.success("Your Terms and Conditions has been updated");
    };
    const addNewMainData = (e) => {
        e.preventDefault();
        setFormData((prevState) => ({
            ...prevState,
            MainData: [...prevState.MainData, { Header: '', Body: [{ PointHeader: '', PointBody: '' }] }]
        }));

        setToggleBulletPoints([...toggleBulletPoints,{index:formData.MainData.length,toggle:false}]);
    };
    const updateMainData = (e, index) => {
        const{name,value} = e.target;
        setFormData((prevState) => {
            const updatedMainData = [...prevState.MainData];
            updatedMainData[index] = { ...updatedMainData[index], [name]: value };
            return { ...prevState, MainData: updatedMainData };
        });
    }
    const removeMainData = (e,index) => {
        e.preventDefault();
        setFormData((prevState) => {
            const updatedMainData = [...prevState.MainData];
            updatedMainData.splice(index, 1);
            return { ...prevState, MainData: updatedMainData };
        });

        setToggleBulletPoints(toggleBulletPoints.filter((item)=>item.index !== index));
    }
    const handleToggleBulletPoints = (index) => {
        
        setToggleBulletPoints(toggleBulletPoints.map((item)=>{
            if(item.index === index){
                return {...item, toggle:!item.toggle}
            }
            return item;
        }));
    }

    useEffect(() => {
		dispatch(getTermsAndConditions());
    }, [dispatch]);
	useEffect(()=>{
		// Check if Terms And Conditions has been updated and display a notification if so
        if(termsAndCondition && !formData){
            toast.success("Your Terms And Conditions has been Loaded");
			setFormData(termsAndCondition);
            if(termsAndCondition.MainData.length){
                const temp = []
                Array(termsAndCondition.MainData.length).fill(0).map((_,index)=>{
                    temp.push({index,toggle:false});
                })
                setToggleBulletPoints(temp);
            }
        }
	},[termsAndCondition])
    console.log("Toggle Bullet Points: ",toggleBulletPoints);
	
    return (
        <div className="w-full mx-auto px-6 py-12">
            <h1 className="text-3xl font-extrabold text-gray-900 mb-8">
                Admin Privacy Policy Editor
            </h1>
            <form onSubmit={handleSubmit} className="space-y-8 px-3">
                {/* Effective Date */}
                <div className="space-y-2">
                    <label className="text-lg font-medium text-gray-800" htmlFor="effectiveDate">
                        Effective Date
                    </label>
                    <input
                        type="date"
                        id="effectiveDate"
                        name="effectiveDate"
                        placeholder='Enter Effective Date'
                        value={formData?.effectiveDate}
                        onChange={handleChange}
                        className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-red-500"
                        required
                    />
                </div>
                <div className="space-y-2">
                    <label className="text-lg font-medium text-gray-800" htmlFor="introduction">
                        Introduction
                    </label>
                    <textarea
                        type="text"
                        id="introduction"
                        name="introduction"
                        rows={'6'}
                        placeholder='Enter introduction'
                        value={formData?.introduction}
                        onChange={handleChange}
                        className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-red-500"
                        required
                    />
                </div>

                {/* Introduction */}
                <div className="space-y-2">
                <label className="text-lg font-medium text-gray-800" htmlFor="introduction">
                    Privacy And Policy Data
                </label>
                <button onClick={addNewMainData} className="w-full py-3 px-6 bg-red-600 text-white text-lg font-semibold rounded-md shadow-sm hover:bg-red-700 focus:ring-2 focus:ring-red-500">
                    Create New Section
                </button>
                <h1 className='font-extrabold text-gray-800 text-2xl'>Section Header:</h1>
                <div className='grid-cols-2 grid gap-4'>
                    {formData?.MainData?.map((item, index) => (
                        <div key={index} className="flex items-center flex-col space-y-4">
                            <div className='w-full justify-between flex flex-row px-3 space-x-3 items-center'>
                                <input
                                    type="text"
                                    name="Header"
                                    placeholder='Enter Header'
                                    value={item.Header}
                                    onChange={(event) => {
                                        event.preventDefault();
                                        updateMainData(event,index)
                                    }}
                                    className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-gray-500"
                                />
                                <button
                                    onClick={(e) => removeMainData(e,index)}
                                    className="font-bold bg-gray-600 text-white p-2 rounded-md shadow-sm text-xs hover:bg-gray-700 focus:ring-2 focus:ring-gray-500"
                                >
                                    Delete Section {index + 1}
                                </button>

                            </div>
                            <button onClick={(event)=> {
                                event.preventDefault();
                                handleToggleBulletPoints(index)
                            }} className='bg-red-600 justify-center items-center w-full flex text-white flex-row space-x-4 rounded-lg py-2'>
                                <ChevronUp size={24} className={`transition-all transform ease-in-out ${toggleBulletPoints[index]?.toggle ? "rotate-180":""}`}/> <span className='text-center'>{toggleBulletPoints[index]?.toggle ? "Hide":"Show"} All Points</span>
                            </button>
                            <div className={`w-full ${toggleBulletPoints[index]?.toggle ? "":"hidden"} flex flex-col space-y-4`}>
                                <BulletPointForm tag ={`bt-${index}`} onChange={(data)=>{
                                    updateMainData({target:{name:'Body',value:data}},index);
                                }} defaultData={item?.Body || []}/>
                            </div>
                        </div>
                    ))}
                </div>
                <button
                    type="submit"
                    className="w-full py-3 px-6 bg-red-600 text-white text-lg font-semibold rounded-md shadow-sm hover:bg-red-700 focus:ring-2 focus:ring-red-500"
                >
                    Save Terms and Conditions
                </button>
                </div>
            </form>
        </div>
    );
};

export default TermsAndCondition