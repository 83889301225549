import React, { useState } from 'react';


 const ViewEmployee = ({employee, onClose}) => {

    return (
        <>
            <h6
                class="block mb-2 font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900 uppercase">
               {employee.name}
            </h6>
            <div class="relative flex bg-clip-border rounded-xl bg-white text-gray-700 shadow-md w-full max-w-[48rem] flex-row">
                <div
                    class="relative w-2/5 m-0 overflow-hidden text-gray-700 bg-white rounded-r-none bg-clip-border rounded-xl shrink-0">
                    <img
                        src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1471&amp;q=80"
                        alt="card-image" class="object-cover w-full h-full" />
                </div>
                <div class="pl-6 top-0">
                    <h6
                        class="block mb-2 font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900 uppercase">
                       Employee Id : {employee.employeeId}
                    </h6>
                    <h4 class="block mb-2 font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-gray-700">
                        Designation: {employee.role}
                    </h4>
                    <h4 class="block mb-2 font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-gray-700">
                        Aasdhar_no:  {employee.aadharno}
                    </h4>
                    <h4 class="block mb-2 font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-gray-700">
                    <span className="font-semibold">Email:</span>
                    <span className="ml-4">{employee.email}</span> <br/>
                    <span className="font-semibold">Accesss:</span>
                    <span className="ml-4">{employee.access.join(',')}</span> <br/>
                    <span className="font-semibold">Contact:</span>
                    <span className="ml-4">{employee.phone}</span> <br/>
                      
                    </h4>
                </div>
            </div>
            <div className='text-center my-3 bottom-0'>
                <button
                 onClick={onClose}
                    className="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                >
                    Close
                </button>
            </div>
        </>
    );
};

export default ViewEmployee

