import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectTotalUserCount, selectUsers } from '../../Redux/Selector/userSelector';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { format, startOfWeek, endOfWeek, startOfMonth, endOfMonth, startOfYear, endOfYear } from 'date-fns';

// Register Chart.js components and plugins
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ChartDataLabels);

const UserGraph = () => {
    const users = useSelector(selectUsers);
    const totalUserCount = useSelector(selectTotalUserCount);
    const [filter, setFilter] = useState('day');

    const getFilteredData = () => {
        const counts = {};
        users.forEach(user => {
            const date = new Date(user.createdAt.split('T')[0]);
            let key;
            switch (filter) {
                case 'week':
                    key = `${format(startOfWeek(date), 'yyyy-MM-dd')} to ${format(endOfWeek(date), 'yyyy-MM-dd')}`;
                    break;
                case 'month':
                    key = format(date, 'yyyy-MM');
                    break;
                case 'year':
                    key = format(date, 'yyyy');
                    break;
                default:
                    key = format(date, 'yyyy-MM-dd');
                    break;
            }
            counts[key] = (counts[key] || 0) + 1;
        });

        // Sort keys in descending order
        const sortedKeys = Object.keys(counts).sort((a, b) => new Date(b) - new Date(a));
        const sortedCounts = sortedKeys.reduce((acc, key) => {
            acc[key] = counts[key];
            return acc;
        }, {});

        return sortedCounts;
    };

    const filteredData = useMemo(() => getFilteredData(), [users, filter]);

    const data = {
        labels: Object.keys(filteredData),
        datasets: [
            {
                label: 'User Count',
                data: Object.values(filteredData),
                backgroundColor: 'rgba(0, 0, 139, 0.8)',
                borderColor: 'rgba(0, 0, 139, 1)',
                borderWidth: 1,
                datalabels: {
                    color: '#fff',
                    anchor: 'end',
                    align: 'top',
                    formatter: (value) => value,
                }
            }
        ]
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            tooltip: {
                callbacks: {
                    label: (context) => `${context.dataset.label}: ${context.raw}`,
                },
            },
            datalabels: {
                color: '#fff',
                anchor: 'end',
                align: 'top',
                formatter: (value) => value,
                display: true,
            },
        },
        scales: {
            x: {
                beginAtZero: true,
                ticks: {
                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0,
                    maxTicksLimit: 0,
                },
                grid: {
                    display: false,
                },
            },
            y: {
                beginAtZero: true,
                ticks: {
                    stepSize: 1,
                },
                suggestedMax: Math.ceil(Math.max(...Object.values(filteredData)) / 10) * 10,
            },
        },
    };

    return (
        <div className="p-4 ">
            <div className=" flex">
                <button
                    onClick={() => setFilter('day')}
                    className="bg-blue-700 text-white ms-2 p-1 rounded hover:bg-blue-900 transition"
                >
                    Day
                </button>
                <button
                    onClick={() => setFilter('week')}
                    className="bg-blue-700 text-white ms-2 p-1 rounded hover:bg-blue-900 transition"
                >
                    Week
                </button>
                <button
                    onClick={() => setFilter('month')}
                    className="bg-blue-700 text-white ms-2 p-1 rounded hover:bg-blue-900 transition"
                >
                    Month
                </button>
                <button
                    onClick={() => setFilter('year')}
                    className="bg-blue-700 text-white ms-2 p-1 rounded hover:bg-blue-900 transition"
                >
                    Year
                </button>
               
            </div>
            <div style={{ maxHeight: '15vh', maxWidth: '35vw' }}>
                <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                <p  className='relative  text-center  text-lg font-semibold'>Total User Count: {totalUserCount}</p>
                    <div style={{minWidth: '100%' }}>
            
                        <Bar data={data} options={options} />
                    </div>
                </div>
            </div>

        </div>
    );
};

export default UserGraph;
