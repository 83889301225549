import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Sidebar from '../Frame/Sidebar';
import { BASE_URL } from '../../Constants/constant';
import { Link } from 'react-router-dom';
import AddBanner from './AddBanner';
import toast from 'react-hot-toast';
import { z } from 'zod';
import Cookies from 'js-cookie';

const onlyLetters = /^[A-Za-z]+$/;
const nameSchema = z
  .string()
  .min(3, 'Name length should be greater than 3')
  .regex(onlyLetters, 'Name should contain only alphabets (A-Z, a-z) without spaces or special characters');

export const addValidationSchema = z.object({
  name: nameSchema,
  link: z.string().url('Invalid URL').min(1, 'Link is required'),
  priority: z.number().int().refine(value => value > 0 && value < 6, {
    message: 'Priority must be a number between 1 and 5',
  }),
});

export const updatePrioritiesSchema = z.object({
    newPriorityOrder: z.array(
      z.number().int().refine(value => value > 0 && value < 6, {
        message: 'Each priority must be a number between 1 and 5',
      })
    ).min(1, 'Priority order must not be empty'),
  });

const Banner = () => {
    const [adds, setAdds] = useState([]);
    const [showBannerForm, setShowBannerForm] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [addToDelete, setAddToDelete] = useState(null);
    const token = Cookies.get('token');
    const fetchAdds = async () => {
        try {

            const response = await axios.get(`${BASE_URL}/api/v-1/add/getalladds`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            setAdds(response.data.adds);
        } catch (error) {
            console.error('Error fetching Banner:', error);
            setAdds([]);
        }
    };

    useEffect(() => {
        fetchAdds();
    }, []);

    const handlePriorityChange = async (index, newPriority) => {
        try {
            const updatedAdds = [...adds];
            updatedAdds[index].priority = newPriority;
            setAdds(updatedAdds);

            await axios.put(`${BASE_URL}/api/v-1/add/update`, {

                newPriorityOrder: updatedAdds.map(add => add.priority)
            },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
        } catch (error) {
            console.error('Error updating priority:', error);
        }
    };
    const handleAddBanner = () => setShowBannerForm(true);
    const handleCloseBanner = () => setShowBannerForm(false);

    const handleDeleteConfirmation = (employee) => {
        setAddToDelete(employee);
        setConfirmDialogOpen(true);
    };
    const handleCloseConfirmDialog = () => {
        setConfirmDialogOpen(false);
        setAddToDelete(null);
    };


    const handleDelete = async () => {
        if (addToDelete) {
            try {
                await axios.delete(`${BASE_URL}/api/v-1/add/delete/${addToDelete._id}`,
                    {
                        headers: {
                            "Authorization": `Bearer ${token}`
                        }

                    });
                setAdds(adds.filter(add => add._id !== addToDelete._id));
                await fetchAdds()
                toast.success("Banner Deleted Successfully");
            } catch (error) {
                console.error('Error deleting Banner:', error);
                toast.error("Error Deleting Banner ");
            } finally {
                setConfirmDialogOpen(false);
                setAddToDelete(null);
            }
        }
    };

    return (
        <Sidebar>
            <div className="p-4 xl:ml-80 flex flex-col">
                <div>
                    <button
                        type="button"
                        onClick={handleAddBanner}
                        className=" text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                    >
                        Add Banner
                    </button>
                    {showBannerForm && (
                        <div className="fixed inset-0 flex items-center justify-center z-50">
                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                            <div className="bg-white rounded-lg p-8 z-10">
                                <button
                                    className="absolute top-0 right-0 p-3"
                                    onClick={handleCloseBanner}
                                >
                                    Close
                                </button>
                                <AddBanner add={() => fetchAdds()} onClose={handleCloseBanner} />
                            </div>
                        </div>
                    )}
                </div>
                <div className="table-container overflow-x-auto">
                    <table className="table table-bordered">
                        <thead className="bg-gray-100 sticky top-0">
                            <tr>
                                <th>Sl.No</th>
                                <th>Name</th>
                                <th>Add</th>
                                <th>Link</th>
                                <th>Priority</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody className="divide-y  divide-gray-200">
                            {adds.map((add, index) => (
                                <tr key={index} className={index % 2 === 0 ? "bg-white" : "bg-gray-100"}>
                                    <td>{index + 1}</td>
                                    <td>{add.name}</td>
                                    <td>
                                        <img className='w-[12rem]' src={add.add} alt="" />
                                    </td>
                                    <td>{add.link}</td>
                                    <td>
                                        <input
                                            type="number"
                                            value={add.priority}
                                            min="1"
                                            max="5"
                                            onChange={(e) => {
                                                const newValue = parseInt(e.target.value);
                                                if (newValue >= 1 && newValue <= 5) {
                                                    handlePriorityChange(index, newValue);
                                                }
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <Link className="cursor-pointer text-red-600 hover:text-red-900" onClick={() => handleDeleteConfirmation(add)}>
                                            Delete
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {confirmDialogOpen && addToDelete && (
                    <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50 z-50">
                        <div className="bg-white p-6 rounded-lg shadow-lg">
                            <h2 className="text-lg font-semibold">Confirm</h2>
                            <p className="mt-2">Are you sure you want to delete {addToDelete.name} permanently?</p>
                            <div className="mt-4 flex justify-end">
                                <button
                                    onClick={handleDelete}
                                    className="bg-blue-500 text-white px-4 py-2 rounded-lg mr-2"
                                >
                                    Yes
                                </button>
                                <button
                                    onClick={handleCloseConfirmDialog}
                                    className="bg-gray-500 text-white px-4 py-2 rounded-lg"
                                >
                                    No
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Sidebar>
    );
};

export default Banner;

