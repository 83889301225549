import React, { useState } from 'react';
import { z } from 'zod';
import { BASE_URL } from '../../Constants/constant';
import toast from 'react-hot-toast';
import Cookies from 'js-cookie';

export const contactValidationSchema = z.object({
  PHONE_NUMBER: z
    .string()
    .min(10, 'Phone number must be at least 10 digits')
    .max(10, 'Phone number must be at most 10 digits')
    .regex(/^[0-9]+$/, 'Invalid phone number, only digits allowed'),

  EMAIL: z.string().email('Invalid email address'),

  FACE_BOOK: z.string().url('Invalid Facebook URL'),

  WHATS_UP: z
    .string()
    .min(10, 'Phone number must be at least 10 digits')
    .max(10, 'Phone number must be at most 10 digits')
    .regex(/^[0-9]+$/, 'Invalid phone number, only digits allowed'),

  INSTAGRAM: z.string().url('Invalid Instagram URL'),

  TWEETER: z.string().url('Invalid Twitter URL'),

  LINKED_IN: z.string().url('Invalid LinkedIn URL'),

  TELEGRAM: z.string().url('Invalid Telegram URL'),

  APP_LINK: z.string().url('Invalid App link URL'),
});

const AddContactdetails = ({ add, onClose, contact }) => {
  const [formData, setFormData] = useState({
    LINKED_IN: contact?.LINKED_IN || '',
    WHATS_UP: contact?.WHATS_UP || '',
    TWEETER: contact?.TWEETER || '',
    INSTAGRAM: contact?.INSTAGRAM || '',
    TELEGRAM: contact?.TELEGRAM || '',
    FACE_BOOK: contact?.FACE_BOOK || '',
    PHONE_NUMBER: contact?.PHONE_NUMBER || '',
    EMAIL: contact?.EMAIL || '',
    APP_LINK: contact?.APP_LINK || ''
  });

  const token = Cookies.get('token');
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'WHATS_UP' || name === 'PHONE_NUMBER') {
      if (/^\d{0,10}$/.test(value)) {
        setFormData({ ...formData, [name]: value });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setResponse(null);

    try {
      contactValidationSchema.parse(formData);
      const res = await fetch(`${BASE_URL}/api/v-1/contactdetail/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(formData)
      });

      if (!res.ok) {
        const errorData = await res.json();
        throw new Error(errorData.message || `HTTP error! status: ${res.status}`);
      }

      const result = await res.json();
      setResponse(result);
      toast.success('Added successfully...');
      add();
      onClose();
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div style={{ width: '50vw' }}>
      {response && (
        <div className="mt-6 p-4 bg-green-50 border border-green-200 text-green-800 rounded-lg shadow-md">
          <h3 className="text-lg text-center font-semibold">Uploaded Successfully!</h3>
        </div>
      )}
      {error && (
        <div className="mt-6 p-4 bg-red-50 border border-red-200 text-red-800 rounded-lg shadow-md">
          <h3 className="text-lg font-semibold">{error}</h3>
        </div>
      )}
      <h2 className="text-2xl font-semibold text-gray-800 mb-6 text-center">Add Contact Details</h2>
      <form onSubmit={handleSubmit} className="space-y-6">
        {[ 
          { label: 'LINKED_IN', type: 'text', name: 'LINKED_IN' },
          { label: 'WHATS_UP', type: 'text', name: 'WHATS_UP' },
          { label: 'TWEETER', type: 'text', name: 'TWEETER' },
          { label: 'INSTAGRAM', type: 'text', name: 'INSTAGRAM' },
          { label: 'TELEGRAM', type: 'text', name: 'TELEGRAM' },
          { label: 'FACE_BOOK', type: 'text', name: 'FACE_BOOK' },
          { label: 'PHONE_NUMBER', type: 'number', name: 'PHONE_NUMBER' },
          { label: 'EMAIL', type: 'email', name: 'EMAIL' },
          { label: 'APP_LINK', type: 'text', name: 'APP_LINK' },
        ].map(({ label, type, name }) => (
          <div key={name} className="flex items-center">
            <label className="block text-sm font-medium text-gray-700 py-2.5 mr-5">{label}:</label>
            <input
              type={type}
              name={name}
              value={formData[name]}
              onChange={handleChange}
              maxLength={name === 'WHATS_UP' || name === 'PHONE_NUMBER' ? 10 : undefined}
              className="block py-2.5 px-0 text-center w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            />
          </div>
        ))}
        <div className="flex justify-between">
          <button
            type="submit"
            className="w-full bg-indigo-600 text-white py-3 px-1 rounded-lg shadow-md hover:bg-indigo-700 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Submit
          </button>
          <button
            onClick={onClose}
            type="button"
            className="w-full bg-red-600 text-white py-3 px-1 ml-5 rounded-lg shadow-md hover:bg-red-700 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Close
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddContactdetails;
