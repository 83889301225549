// src/components/Employee.jsx
import { useEffect, useState } from 'react';
import Sidebar from '../Frame/Sidebar';
import { EditEmployee } from './EditEmployee';
import ViewEmployee from './ViewEmployee';
import { AddEmployee } from './AddEmployee';
import { useUser } from '../../Constants/context';
import { fetchRequest, setSelectedEmployeeId, deleteRequest } from '../../Redux/slices/employeeSlice.js';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentPage, selectEmployees, selectPageSize, selectTotalPages, selectTotalUserCount } from '../../Redux/Selector/employeeSelector.js';
import toast, { Toaster } from 'react-hot-toast';
import ReactPaginate from 'react-paginate';


export const Employee = () => {
    const dispatch = useDispatch();
    const employees = useSelector(selectEmployees);
    const pageSize = useSelector(selectPageSize);
    const totalPages = useSelector(selectTotalPages);
    const currentPage = useSelector(selectCurrentPage);
    const totalCount = useSelector(selectTotalUserCount);
    const [selectedEmployeeId, setSelectedEmployeeIdLocal] = useState(null);
    const [showRegisterForm, setShowRegisterForm] = useState(false);
    const [showUpdateForm, setShowUpdateForm] = useState(false);
    const [showViewForm, setShowViewForm] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [employeeToDelete, setEmployeeToDelete] = useState(null);
    useEffect(() => {
        dispatch(fetchRequest());
    }, [dispatch]);

    const handleRegisterUser = () => setShowRegisterForm(true);
    const handleCloseRegisterForm = () => setShowRegisterForm(false);

   const handlePageClick = ({ selected }) => {
        const pageSize = selected + 1;
        dispatch(fetchRequest(pageSize));
    };
    
    

    const handleUpdateUser = (employee) => {
        setSelectedEmployeeIdLocal(employee);
        dispatch(setSelectedEmployeeId(employee));
        setShowUpdateForm(true);
    };
    const handleCloseUpdateForm = () => {
        setShowUpdateForm(false);
        setSelectedEmployeeIdLocal(null);
    };

    const handleViewUser = (employee) => {
        setSelectedEmployeeIdLocal(employee);
        setShowViewForm(true);
        dispatch(setSelectedEmployeeId(employee));
    };
    const handleCloseViewForm = () => {
        setShowViewForm(false);
        setSelectedEmployeeIdLocal(null);

    };

    const handleDeleteConfirmation = (employee) => {
        setEmployeeToDelete(employee);
        setConfirmDialogOpen(true);
        dispatch(setSelectedEmployeeId(employee));
    };

    const handleDelete = async () => {
        if (employeeToDelete) {
            try {
                const resultAction = await dispatch(deleteRequest(employeeToDelete._id));
                if (resultAction.payload?.status === 200) {
                    dispatch(fetchRequest());
                    toast.success(resultAction.payload.message || "Employee deleted successfully");
                }
            } catch (error) {
                toast.error("An error occurred while deleting the employee");
                console.error(error);
            }
        }
        handleCloseConfirmDialog();
    };

    const calculateSerialNumber = (index) => {
        return (currentPage - 1) * pageSize + index + 1;
    };


    const handleCloseConfirmDialog = () => {
        setConfirmDialogOpen(false);
        setEmployeeToDelete(null);
    };
    const { Admin, Sales, Telecaller, Verification, Market } = useUser();
    return (

        <Sidebar>

            <div className="p-4 xl:ml-80 flex flex-col">

                {Admin ?
                    (
                        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                    <button
                                        type="button"
                                        onClick={handleRegisterUser}
                                        className="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                                    >
                                        Add Employee
                                    </button>

                                    {showRegisterForm && (
                                        <div className="fixed inset-0 flex items-center justify-center z-50">
                                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                                            <div className="bg-white rounded-lg p-8 z-10">
                                                <button
                                                    className="absolute top-0 right-0 p-3"
                                                    onClick={handleCloseRegisterForm}
                                                >
                                                    Close
                                                </button>
                                                <AddEmployee onClose={handleCloseRegisterForm} />
                                            </div>
                                        </div>
                                    )}

                                    {showUpdateForm && selectedEmployeeId && (
                                        <div className="fixed inset-0 flex items-center justify-center z-50">
                                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                                            <div className="bg-white rounded-lg p-8 z-10">
                                                <button
                                                    className="absolute top-0 right-0 p-3"
                                                    onClick={handleCloseUpdateForm}
                                                >
                                                    Close
                                                </button>
                                                <EditEmployee
                                                    employee={selectedEmployeeId}
                                                    onClose={handleCloseUpdateForm}
                                                />
                                            </div>
                                        </div>
                                    )}

                                    {showViewForm && selectedEmployeeId && (
                                        <div className="fixed inset-0 flex items-center justify-center z-50">
                                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                                            <div className="bg-white rounded-lg p-8 z-10">
                                                <button
                                                    className="absolute top-0 right-0 p-3"
                                                    onClick={handleCloseViewForm}
                                                >
                                                    Close
                                                </button>
                                                <ViewEmployee employee={selectedEmployeeId} onClose={handleCloseViewForm} />
                                            </div>
                                        </div>
                                    )}
                                    <div className="table-container-button overflow-x-auto ">
                                        <table className="table table-bordered ">
                                            <thead className="bg-gray-100 sticky top-0">
                                                <tr>
                                                    <th className="">Sl no</th>
                                                    <th className="">Name</th>
                                                    <th className="">Email</th>
                                                    <th className="">EmployeeId</th>
                                                    <th className="">Designation</th>
                                                    <th className="">Status</th>
                                                    <th className="">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200 ">
                                                {employees.map((person, index) => (
                                                    <tr key={index} className={index % 2 === 0 ? "bg-white" : "bg-gray-100"}> {/* Ensure unique key */}
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="text-sm text-gray-500">{calculateSerialNumber(index)}</div>
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="flex items-center">
                                                                <div className="text-sm font-medium text-gray-900">{person.name || 'No Name'}</div>
                                                            </div>
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="text-sm text-gray-500">{person.email || 'No Email'}</div>
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="text-sm text-gray-500">{person.employeeId || 'No EmployeeId'}</div>
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                            {person.role || 'No Role'}
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <span
                                                                className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${person.block
                                                                    ? 'bg-red-100 text-red-800'
                                                                    : 'bg-green-100 text-green-800'
                                                                    }`}
                                                            >
                                                                {person.block ? 'Blocked' : 'Active'}
                                                            </span>
                                                        </td>
                                                        <td className="px-6 py-4 text-sm font-medium">
                                                            <a className="cursor-pointer text-indigo-600 hover:text-indigo-900" onClick={() => handleUpdateUser(person)}>
                                                                Edit
                                                            </a><br />
                                                            <a className="cursor-pointer text-green-600 hover:text-green-900" onClick={() => handleViewUser(person)}>
                                                                View
                                                            </a><br />
                                                            <a className="cursor-pointer text-red-600 hover:text-red-900" onClick={() => handleDeleteConfirmation(person)}>
                                                                Delete
                                                            </a>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                        <div className="fixed bottom-12 right-0 text-blue-500 mr-12  text-xl text-md  ">
                                            <div className="left-0 flex items-center justify-between ">
                                                <p>
                                                    Showing page {currentPage} of {totalPages} &ensp;
                                                    {currentPage * pageSize - pageSize} to {Math.min(currentPage * pageSize, totalCount)} of {totalCount} entries
                                                </p>
                                                <div className="text-blue-500  `{active? text-red-500 }` pagination">
                                                    <ReactPaginate
                                                        previousLabel={""}
                                                        nextLabel={"."}
                                                        breakLabel={"...."}
                                                        pageCount={totalPages}
                                                        marginPagesDisplayed={1}
                                                        pageRangeDisplayed={5}
                                                        onPageChange={handlePageClick}
                                                        containerClassName={"pagination"}
                                                        activeClassName={"active"}
                                                        className="flex"
                                                    />
                                                </div>
                                            </div>
                                        </div>




                                    </div>

                                    {confirmDialogOpen && employeeToDelete && (
                                        <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50 z-50">
                                            <div className="bg-white p-6 rounded-lg shadow-lg">
                                                <h2 className="text-lg font-semibold">Confirm</h2>
                                                <p className="mt-2">Are you sure you want to delete {employeeToDelete.name} permanently?</p>
                                                <div className="mt-4 flex justify-end">
                                                    <button
                                                        onClick={handleDelete}
                                                        className="bg-blue-500 text-white px-4 py-2 rounded-lg mr-2"
                                                    >
                                                        Yes
                                                    </button>
                                                    <button
                                                        onClick={handleCloseConfirmDialog}
                                                        className="bg-gray-500 text-white px-4 py-2 rounded-lg"
                                                    >
                                                        No
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='flex items-center justify-center h-80'>
                            <p className='mb-5 text-3xl text-red-500'>Access Not Permitted...!</p>
                        </div>
                    )}

            </div>
            <Toaster />
        </Sidebar>

    );
};








