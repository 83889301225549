import { useEffect } from 'react';
import useConversation from "../zustand/useConversation";
import { useSocketContext } from '../../../Constants/SocketContext.jsx';

const Conversation = ({ conversation, lastIdx }) => {
    const { selectedConversation, setSelectedConversation } = useConversation();
    const { onlineUsers } = useSocketContext();

    const isSelected = selectedConversation?._id === conversation._id;
    const isOnline = onlineUsers.includes(conversation._id);
    
    const unreadMessagesCount = conversation.unreadmessage || 0;

    useEffect(() => {
        if (conversation) {
            setSelectedConversation(conversation);
        }
    }, [conversation, setSelectedConversation]);

    return (
        <>
            <div
                className={`flex items-center gap-3 p-2 py-1 rounded cursor-pointer transition-colors duration-300 
                            ${isSelected ? "bg-sky-700" : "hover:bg-sky-600"} 
                            ${isOnline ? "border-green-400" : "border-gray-300"}`}
                onClick={() => setSelectedConversation(conversation)}
                aria-selected={isSelected}
            >
                <div className={`relative w-14 h-14 rounded-full bg-gradient-to-r from-blue-400 to-gray-500 shadow-lg border-2 
                                ${isOnline ? "border-green-400" : "border-gray-300"}`}>
                    <div className='flex items-center justify-center w-full h-full'>
                        <span className='text-white text-2xl font-extrabold'>{conversation?.name?.charAt(0)?.toUpperCase()}</span>
                    </div>
                    {unreadMessagesCount > 0 && (
                        <div className='absolute top-0 right-0 w-5 h-5 flex items-center justify-center bg-red-500 text-white text-xs font-bold rounded-full border-2 border-white'>
                            {unreadMessagesCount}
                        </div>
                    )}
                </div>
                <div className='flex flex-col flex-1'>
                    <p className='font-bold text-gray-500'>{conversation.name}</p>
                    <p className='font-semibold text-gray-500'>{conversation.game_name}</p>
                </div>
            </div>
            {!lastIdx && <div className='my-1 py-0 h-1 bg-gray-200' />}
        </>
    );
};

export default Conversation;
