import React, { useEffect, useState } from 'react';
import Sidebar from '../Frame/Sidebar';
import { Footer } from '../../Constants/Footer';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { BASE_URL } from '../../Constants/constant';
import RoomDetailsPopup1 from './Reportcomplete';
import { selectReportpendingcount } from '../../Redux/Selector/userSelector';

export const Report = () => {
    const [room, setRoom] = useState([]);
    const [report, setReport] = useState([]);
    const [popup, setPopup] = useState(false);
    const [selectedRoom, setSelectedRoom] = useState(null);
    const [selectedReport, setSelectedReport] = useState(null);
    const token = localStorage.getItem('token');
    const Reportpendingcount = useSelector(selectReportpendingcount);

    const fetchRooms = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/v-1/room/getallrooms`,{
                headers:{
                    'Authorization': `Bearer ${token}`,
                }
            });
            setRoom(response.data.rooms || []);
        } catch (error) {
            console.error('Error fetching rooms:', error);
            setRoom([]);
        }
    };

    const fetchReports = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/v-1/report/getallreport`,{
                headers:{
                    'Authorization': `Bearer ${token}`,
                }
            }
            );
            setReport(response.data.report || []);
        } catch (error) {
            console.error('Error fetching reports:', error);
            setReport([]);
        }
    };

    useEffect(() => {
        fetchRooms();
        fetchReports();
    }, []);

    const handleRoomClick = (roomId) => {
        const selected = room.find(room => room.roomId === roomId);
        const selectedReport = report.find(report => report.roomId === roomId);
        setSelectedRoom(selected);
        setSelectedReport(selectedReport);
        setPopup(true);
    };

    const handleClosePopup = () => {
        setPopup(false);
        setSelectedRoom(null);
        setSelectedReport(null);
    };

    return (
        <Sidebar>
            <div className="xl:ml-80 text-center px-4 py-5 sm:px-6 relative">
                <h3 className="text-2xl leading-6 font-medium text-gray-900">
                    User Report Details
                   

                    {Reportpendingcount > 0 && (
                        <span
                            className="relative   ml-3  transform translate-x-1/2 -translate-y-1/2 bg-red-500 text-white text-sm font-bold px-3 py-2 rounded-full animate-blink"
                        >
                            {Reportpendingcount}
                        </span>
                    )}
                </h3>
                <style jsx>{`
                    @keyframes blink {
                        0% { opacity: 1; }
                        50% { opacity: 0.5; }
                        100% { opacity: 1; }
                    }
                    .animate-blink {
                        animation: blink 2s infinite;
                    }
                `}</style>
            </div>
            <div className="xl:ml-80 flex flex-col table-container overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-100 sticky top-0">
                        <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Serial No</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Number of Players</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Mode</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">RoomId</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {report.length ===0 ? (
                            <tr>
                            <td colSpan={12} className="px-6 py-4 text-center">
                                <p className='text-sm  text-red-500'>No Report Details...</p>
                            </td>
                        </tr>

                        ):(
                            report.map((usergame, index) => {
                                const roomDetails = room.find(r => r.roomId === usergame.roomId);
                                const gameAmount = roomDetails?.joining_amount || 'N/A';
                                const no_of_players = roomDetails?.no_of_players || 'N/A';
                                const mode_type = roomDetails?.mode_type || 'N/A';
                                return (
                                    <tr key={usergame._id} className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}>
                                        <td className='px-6 py-4 whitespace-nowrap'>{index + 1}</td>
                                        <td className='px-6 py-4 whitespace-nowrap'>{gameAmount}</td>
                                        <td className='px-6 py-4 whitespace-nowrap'>{no_of_players}</td>
                                        <td className='px-6 py-4 whitespace-nowrap'>{mode_type}</td>
                                        <td className='px-6 py-4 whitespace-nowrap text-blue-500'>
                                            <button onClick={() => handleRoomClick(usergame.roomId)} className="underline">
                                                {usergame.roomId}
                                            </button>
                                        </td>
                                        <td className={`px-6 py-4 whitespace-nowrap ${usergame.status === 'pending' ? 'text-red-500' : usergame.status === 'resolved' ? 'text-green-500' : ''}`}>
                                            {usergame.status}
                                        </td>
                                    </tr>
                                );
                            })



                        )

                        }
                
                    </tbody>
                </table>
            </div>
            <Footer />
            {popup && (
                <RoomDetailsPopup1
                update={()=>fetchReports()}
                    room={selectedRoom}
                    user={selectedReport}
                    onClose={handleClosePopup}
                />
            )}
        </Sidebar>
    );
};
