import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";
import { BASE_URL } from "../../Constants/constant";
import Cookies from "js-cookie";

const SpinData = () => {
  const [bonus, setBonus] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    fetchBonusData();
  }, []);

  const token = Cookies.get("token");

  const fetchBonusData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/v-1/spinWheel/spin-config`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.data?.spins) {
        setBonus(response.data.spins);
        setFormData(
          response.data.spins.reduce((acc, item) => {
            acc[item.SpinId] = { ...item };
            return acc;
          }, {})
        );
      }
    } catch (error) {
      toast.error("Failed to load spin data.");
    }
  };

  const handleInputChange = (e, spinId) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [spinId]: {
        ...prev[spinId],
        [name]: name === "utilizedUsers" ? Number(value) : value, // Ensure number conversion
      },
    }));
  };
  

  const handleUpdate = async () => {
    console.log("Updated Form Data:", formData); // Debugging log
  
    const totalPercentage = Object.values(formData).reduce(
      (acc, item) => acc + Number(item.SpinPercentage || 0),
      0
    );
  
    if (totalPercentage > 100) {
      toast.error("Spin percentage should not be greater than 100.");
      return;
    }
  
    try {
      const updatePromises = Object.keys(formData).map(async (spinId) => {
        const { SpinLabel, SpinAmount, SpinPercentage, utilizedUsers } = formData[spinId];
  
        return axios.put(
          `${BASE_URL}/api/v-1/spinWheel/spin-config/update`,
          {
            SpinId: spinId,
            SpinLabel,
            SpinAmount,
            newPercentage: SpinPercentage,
            utilizedUsers, // Ensure this is correctly passed
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      });
  
      await Promise.all(updatePromises);
      toast.success("Spin data updated successfully.");
      setIsEditing(false);
      fetchBonusData();
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to update spin data.");
    }
  };
  
  

  if (!bonus.length) return <p>Loading...</p>;

  return (
    
    <div className="table-container overflow-x-auto p-4">
      <div>
        <p className="text-center pt-2 pb-2 text-2xl font-bold">SpinWheel Data</p>
        <table className="table-auto table table-bordered w-full border border-gray-300">
          <thead className="bg-gray-100 sticky top-0 border border-gray-300 font-bold">
            <tr>
              <th>Sl.No</th>
              <th>SpinLabel</th>
              <th>SpinAmount</th>
              <th>Spin Percentage</th>
              <th>Utilized Users</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 text-lg ">
            {bonus.map((item, index) => (
              <tr key={item.SpinId}>
                <td>{index + 1}</td>
                <td>
                  {isEditing ? (
                    <input
                      type="text"
                      name="SpinLabel"
                      value={formData[item.SpinId]?.SpinLabel || "0"}
                      onChange={(e) => handleInputChange(e, item.SpinId)}
                      className="border p-1 w-32"
                    />
                  ) : (
                    item.SpinLabel
                  )}
                </td>
                <td>
                  {isEditing ? (
                    <input
                      type="number"
                      name="SpinAmount"
                      value={formData[item.SpinId]?.SpinAmount || "0"}
                      onChange={(e) => handleInputChange(e, item.SpinId)}
                      className="border p-1 w-32"
                    />
                  ) : (
                    `₹${item.SpinAmount}`
                  )}
                </td>
                <td>
                  {isEditing ? (
                    <input
                      type="number"
                      name="SpinPercentage"
                      value={formData[item.SpinId]?.SpinPercentage || "0"}
                      onChange={(e) => handleInputChange(e, item.SpinId)}
                      className="border p-1 w-32"
                    />
                  ) : (
                    `${item.SpinPercentage}%`
                  )}
                </td>
                <td>
                  {isEditing ? (
                    <input
                      type="number"
                      name="utilizedUsers"
                      value={formData[item.SpinId]?.utilizedUsers || "0"}
                      onChange={(e) => handleInputChange(e, item.SpinId)}
                      className="border p-1 w-32"
                      disabled
                    />
                  ) : (
                    item.utilizedUsers
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mt-4 flex justify-center">
        {isEditing ? (
          <div>
            <button
              onClick={handleUpdate}
              className="bg-green-500 text-white px-4 py-2 rounded-lg mr-5"
            >
              Save Changes
            </button>
            <button
              onClick={() => setIsEditing(false)}
              className="bg-red-500 text-white px-4 py-2 rounded-lg"
            >
              Cancel
            </button>
          </div>
        ) : (
          <button
            onClick={() => setIsEditing(true)}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg"
          >
            Update Details
          </button>
        )}
      </div>
    </div>
  );
};

export default SpinData;
