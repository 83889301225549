import React, { useState } from "react";
import SpinData from "./SpinsData";
import SpinWheelTable from "./SpinwheelDetails";
import FreeSpin from "./FreeSpin";
import Sidebar from "../Frame/Sidebar";

function SpinMain() {
  const [activeTab, setActiveTab] = useState("spinData");

  return (
    <Sidebar>
    <div className="p-4 xl:ml-80 flex flex-col">
      {/* Tab Buttons */}
      <div className="flex space-x-4 mb-4">
        <button
          className={`px-4 py-2 rounded-lg ${
            activeTab === "spinData" ? "bg-blue-600 text-white" : "bg-gray-200"
          }`}
          onClick={() => setActiveTab("spinData")}
        >
          Spin Data
        </button>
        <button
          className={`px-4 py-2 rounded-lg ${
            activeTab === "spinWheel" ? "bg-blue-600 text-white" : "bg-gray-200"
          }`}
          onClick={() => setActiveTab("spinWheel")}
        >
          Spin Wheel Table
        </button>

        <button
          className={`px-4 py-2 rounded-lg ${
            activeTab === "FreespinWheel" ? "bg-blue-600 text-white" : "bg-gray-200"
          }`}
          onClick={() => setActiveTab("FreespinWheel")}
        >
          Free Spin Wheel Table
        </button>
      </div>

      {/* Render the selected component */}
      {activeTab === "spinData" && <SpinData />}
      {activeTab === "spinWheel" && <SpinWheelTable />}
      {activeTab === "FreespinWheel" && <FreeSpin />}
    </div>
    </Sidebar>
  );
}

export default SpinMain;
