import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./Componens/ChatBot/pages/home/Home.jsx";
import AdminDashboard from "./Componens/Dashboard/AdminDashboard.jsx";
import Login from "./Componens/Login/Login.jsx";
import { Employee } from "./Componens/Employee/Employees.jsx";
import './App.css';
import { Toaster } from "react-hot-toast";
import Banner from "./Componens/Banner/Banner.jsx";
import { UserMain } from "./Componens/Users/UserMain.jsx";
import { ViewDetails } from "./Componens/Users/ViewDetails.jsx";
import { DepositDetils } from "./Componens/Users/DepositDetils.jsx";
import { WalletTtransaction } from "./Componens/Users/Wallettransaction.jsx";
import { JoinedLegue } from "./Componens/Users/JoinedLegue.jsx";
import { ResultData } from "./Componens/Users/ResultData.jsx";
import { WithdrawalStatus } from "./Componens/Users/Withdrawal.jsx";
import { VerificationUser } from "./Componens/Users/VerifyUser.jsx";
import { RefferalDetails } from "./Componens/Users/RefferalDetails.jsx";
import { ReportDetails } from "./Componens/Users/ReportDetails.jsx";
import { Report } from "./Componens/Report/Report.jsx";
import Winner_Timer from "./Componens/Gametemplates/Winnercash_timerdata.jsx";
import { PushNotification } from "./Componens/PushNotification/PushNotification.jsx";
import { useAuthContext } from "./Constants/context.js";
import PaymentForm from "./Componens/Cashfree/payments.jsx";
import PayoutForm from "./Componens/Cashfree/payouts.jsx";
import { AllWithdrawals } from "./Componens/Cashfree/Withdrawal.jsx";

function App() {
  const { authUser } = useAuthContext(); 
  return (
    <div className="App">
   {/* <Toaster /> */}
      <BrowserRouter>
        <Routes>
    
        {/* <Route path="/Dashboard" element={authUser ? <PaymentForm /> : <Navigate to={"/"} />} />    */}
        <Route path="/Dashboard" element={authUser ? <AdminDashboard /> : <Navigate to={"/"} />} />     
        <Route path="/" element={authUser ? <Navigate to='/Dashboard' /> : <Login />}  />    
          <Route path="/user_details" element={authUser ?<UserMain/>: <Navigate to={"/"} />} />
          <Route path="/report" element={authUser ?<Report/>: <Navigate to={"/"} />} />
          <Route path="/Employee" element={authUser ?<Employee/>: <Navigate to={"/"} />} />
          <Route path="/Banner" element={authUser ?<Banner/>: <Navigate to={"/"} />} />
          <Route path="/VerificationList" element={authUser ?<UserMain/>: <Navigate to={"/"} />} />
     
          <Route path="/deposite_details/:userId" element={authUser ?<DepositDetils/>: <Navigate to={"/"} />} />
          <Route path="/wallet_transaction/:userId" element={authUser ?<WalletTtransaction/>: <Navigate to={"/"} />} />
          <Route path="/joined_games/:userId" element={authUser ?<JoinedLegue/>: <Navigate to={"/"} />} />
          <Route path="/result_data/:userId" element={authUser ?<ResultData/>: <Navigate to={"/"} />} />
          <Route path="/withdrawal_details/:userId" element={authUser ?<WithdrawalStatus/>: <Navigate to={"/"} />} />
          <Route path="/verification_details/:userId" element={authUser ?<VerificationUser/>: <Navigate to={"/"} />} />
          <Route path="/refferal_details/:userId" element={authUser ?<RefferalDetails/>: <Navigate to={"/"} />} />
          <Route path="/report_details/:userId" element={authUser ?<ReportDetails/>: <Navigate to={"/"} />} />
          <Route path="/game_template" element={authUser ?<Winner_Timer/>: <Navigate to={"/"} />} />
          <Route path="/dashboard/notification" element={authUser ?<PushNotification/>: <Navigate to={"/"} />} />
          <Route path="/view_user_details/:userId" element={authUser ?<ViewDetails/>: <Navigate to={"/"} />} />
          <Route path="/dashboard/chatbox" element={authUser ?<Home/>: <Navigate to={"/"} />} /> 
          <Route path="/Withdraw" element={authUser ?<AllWithdrawals/>: <Navigate to={"/"} />} /> 
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
