import { createContext, useState, useEffect, useContext } from "react";
import io from "socket.io-client";
import { useAuthContext } from "./context";
import { SOCKET_URL } from "./constant";
import Cookies from 'js-cookie';

const SocketContext = createContext();

export const useSocketContext = () => {
    return useContext(SocketContext);
};

export const SocketContextProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);
    const [messages, setMessages] = useState([]);
    const [onlineUsers, setOnlineUsers] = useState([]);
    const [ticketCount, setTicketCount] = useState(0);
    const [reportCount, setReportCount] = useState(0);
    const [data, setData] = useState([]);
    const [poolsData, setPoolsData] = useState([]);
    const [withdrawaldata, setWithdrawaldata] = useState([]);
    const [fetchdata, setFetchdata] = useState([]);
    
    const { authUser } = useAuthContext();

    useEffect(() => {
        if (!authUser || !authUser.token) {
            if (socket) {
                socket.disconnect();
                setSocket(null);
                console.log("Socket disconnected due to missing authUser or token");
            }
            return;
        }

        const id = Cookies.get('id');
        if (!id) {
            console.error("No user ID found in cookies");
            return;
        }

        // Ensure previous socket instance is disconnected before creating a new one
        if (socket) {
            socket.disconnect();
        }

        const socketInstance = io(SOCKET_URL, {
            query: { userId: id },
            transports: ["websocket"],
        });

        socketInstance.on("connect", () => {
            console.log("Socket connected with ID:", socketInstance.id);
            socketInstance.emit("MessageFromReact", { data: "Connected" });
        });

        socketInstance.on("connect_error", (error) => {
            console.error("Socket connection error:", error);
        });

        socketInstance.on("disconnect", () => {
            console.log("Socket disconnected");
        });

        socketInstance.on("getOnlineUsers", (users) => {
            console.log("Received online users:", users);
            setOnlineUsers(users);
        });

 

        socketInstance.on("PoolsData", (pools) => {
            setPoolsData(pools);
        });

        socketInstance.on("ticketCountUpdated", (count) => {
            setTicketCount(count);
        });

        socketInstance.on("reportCountUpdated", (count) => {
            setReportCount(count);
        });

        socketInstance.on("Dashboard", (dashboardData) => {
            console.log("Received Dashboard Data:", dashboardData);
            setData(dashboardData);
        });


        socketInstance.on("Withdrawal", (WithdrawalData) => {
            console.log("Received Withdrawal Data:", WithdrawalData);
            setWithdrawaldata(WithdrawalData);
        });

        socketInstance.on("Fetchmessage", (messages) => {
            console.log("Received messages:", messages);
            setFetchdata(messages);
        });

        socketInstance.on("newMessage", (message) => {
            console.log("New message received:", message);
            setMessages((prev) => [...prev, message]);
        });

        setSocket(socketInstance);

        return () => {
            socketInstance.disconnect();
            console.log("Socket disconnected on cleanup");
        };
    }, [authUser]);

    const sendMessage = (message) => {
        if (socket) {
            socket.emit("sendMessage", message);
        }
    };

    return (
        <SocketContext.Provider value={{ 
            socket, onlineUsers, messages, sendMessage, 
            ticketCount, reportCount, poolsData, fetchdata, data ,withdrawaldata
        }}>
            {children}
        </SocketContext.Provider>
    );
};
