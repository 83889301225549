// import React, { useEffect, useState } from 'react';
// import Sidebar from '../Frame/Sidebar';
// import { useParams } from 'react-router-dom';
// import { Footer } from '../../Constants/Footer';
// import { setSelectedUserId } from '../../Redux/slices/userSlice';
// import { useDispatch } from 'react-redux';
// import { exportToExcel, exportToPDF, PDFIcon, ExcelIcon } from '../Utils/Exportutils.jsx';
// import { BASE_URL } from '../../Constants/constant.js';
// import axios from 'axios';

// export const RefferalDetails = () => {
//     const { userId } = useParams();
//     const dispatch = useDispatch();
//     const [refferal, setRefferal] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const token = Cookies.get('token');
//     const fetchReports = async () => {
//         setLoading(true);
//         try {
//             const response = await axios.get(`${BASE_URL}/api/v-1/refferal/getrefferal/${userId}`, {
//                 headers: {
//                     'Authorization': `Bearer ${token}`,
//                 },
//             });
//             setRefferal(response.data.refferals || []);
//             console.log(response)
//         } catch (error) {
//             console.error('Error fetching refferal history:', error);
//             setError('Failed to fetch refferal transactions.');
//             setRefferal([]);
//         } finally {
//             setLoading(false);
//         }
//     };

//     useEffect(() => {
//         if (userId) {
//             dispatch(setSelectedUserId(userId));
//         }
//         fetchReports();
//     }, [dispatch, userId]);


//     return (
//         <Sidebar>
//             <div className="xl:ml-80 text-center px-4 py-5 sm:px-6">
//                 <h3 className="text-2xl leading-6 font-medium text-gray-900">
//                     User Refferal Details
//                 </h3>
//                 <div className="mt-4 flex justify-end space-x-4">
//                     <span
//                         onClick={() => exportToPDF('wallet_transactionTable', 'wallet_transactionDetails.pdf')}
//                         className="cursor-pointer"
//                         title="Export to PDF"
//                     >
//                         <PDFIcon width={50} height={50} />
//                     </span>

//                     <span
//                         onClick={() => exportToExcel('wallet_transactionTable', 'wallet_transactionDetails.xlsx')}
//                         className="cursor-pointer"
//                         title="Export to Excel"
//                     >
//                         <ExcelIcon width={50} height={50} />
//                     </span>
//                 </div>
//             </div>
//             <div className="xl:ml-80 flex flex-col table-container overflow-x-auto">
//                 {loading && <p>Loading...</p>}  {/* Added loading indicator */}
//                 {error && <p className="text-red-500">{error}</p>}  {/* Display error message */}
//                 {!loading && !error && (
//                     <table id="wallet_transactionTable" className="table table-bordered">
//                         <thead className="bg-gray-100 sticky top-0">
//                             <tr>
//                                 <th>Serial No</th>
//                                 <th>Referred From</th>
//                                 <th>Referred To</th>

//                                 <th>Type</th>
//                                 <th>Amount</th>
//                                 <th>Time</th>
//                             </tr>
//                         </thead>
//                         <tbody className="divide-y divide-gray-200">
//                             {Array.isArray(refferal) && refferal.length > 0 ? (
//                                 refferal.map((wallets, index) => (
//                                     <tr key={wallets.transactionId || index}>
//                                         <td>{index + 1}</td>
//                                         <td>{wallets.userId}</td>
//                                         <td>{wallets.ReferredUser}</td>

//                                         <td>{wallets.Type}</td>
//                                         <td>{wallets.Amount}</td>
//                                         <td>{new Date(wallets.createdAt).toLocaleString()}</td>
//                                     </tr>
//                                 ))
//                             ) : (
//                                 <tr>
//                                     <td colSpan="12" className="text-center">No transactions available</td>
//                                 </tr>
//                             )}
//                         </tbody>
//                     </table>
//                 )}
//             </div>
//             <Footer />
//         </Sidebar>
//     );
// };


import React, { useEffect, useState, useCallback } from 'react';
import Sidebar from '../Frame/Sidebar';
import { useParams } from 'react-router-dom';
import { Footer } from '../../Constants/Footer';
import { setSelectedUserId } from '../../Redux/slices/userSlice';
import { useDispatch } from 'react-redux';
import { exportToExcel, exportToPDF, PDFIcon, ExcelIcon } from '../Utils/Exportutils.jsx';
import { BASE_URL } from '../../Constants/constant.js';
import axios from 'axios';
import Cookies from 'js-cookie';

export const RefferalDetails = () => {
    const { userId } = useParams();
    const dispatch = useDispatch();
    const [refferal, setRefferal] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const token = Cookies.get('token');

    const fetchReports = useCallback(async () => {
        setLoading(true);
        setError(null);  // Reset error before fetching
        try {
            const response = await axios.get(`${BASE_URL}/api/v-1/refferal/getrefferal/${userId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            setRefferal(response.data.referrals || []);
        } catch (error) {
            console.error('Error fetching refferal history:', error);
            setError('Failed to fetch refferal transactions.');
        } finally {
            setLoading(false);
        }
    }, [userId, token]);

    useEffect(() => {
        if (userId) {
            dispatch(setSelectedUserId(userId));
        }
        fetchReports();
    }, [dispatch, userId, fetchReports]);

    const renderTableBody = () => {
        if (loading) return <tr><td colSpan="6" className="text-center">Loading...</td></tr>;
        if (error) return <tr><td colSpan="6" className="text-center text-red-500">{error}</td></tr>;
        if (refferal.length === 0) return <tr><td colSpan="6" className="text-center">No data available</td></tr>;  {/* Updated text */}

        return refferal.map((wallets, index) => (
            <tr key={wallets.transactionId || index}>
                <td>{index + 1}</td>
                <td>{wallets.userId}</td>
                <td>{wallets.ReferredUser}</td>
                <td>{wallets.Type}</td>
                <td>{wallets.Amount}</td>
                <td>{new Date(wallets.createdAt).toLocaleString()}</td>
            </tr>
        ));
    };

    return (
        <Sidebar>
            <div className="xl:ml-80 text-center px-4 py-5 sm:px-6">
                <h3 className="text-2xl leading-6 font-medium text-gray-900">User Refferal Details</h3>
                <div className="mt-4 flex justify-end space-x-4">
                    <span
                        onClick={() => exportToPDF('wallet_transactionTable', 'wallet_transactionDetails.pdf')}
                        className="cursor-pointer"
                        title="Export to PDF"
                    >
                        <PDFIcon width={50} height={50} />
                    </span>
                    <span
                        onClick={() => exportToExcel('wallet_transactionTable', 'wallet_transactionDetails.xlsx')}
                        className="cursor-pointer"
                        title="Export to Excel"
                    >
                        <ExcelIcon width={50} height={50} />
                    </span>
                </div>
            </div>
            <div className="xl:ml-80 flex flex-col table-container overflow-x-auto">
                <table id="wallet_transactionTable" className="table table-bordered">
                    <thead className="bg-gray-100 sticky top-0">
                        <tr>
                            <th>Serial No</th>
                            <th>Referred From</th>
                            <th>Referred To</th>
                            <th>Type</th>
                            <th>Amount</th>
                            <th>Time</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                        {renderTableBody()}
                    </tbody>
                </table>
            </div>
            <Footer />
        </Sidebar>
    );
};
