import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { BriefcaseBusiness, Edit, Eye, EyeClosed, X } from 'lucide-react';
import toast from 'react-hot-toast';
import { createJob, fetchAllJobs, removeJob, updateCurrentJob } from '../../../Redux/slices/websiteSlice';
import ConfirmDeletePopup from '../ConfirmDeletePopup';

const initialForm = {
    location: '',
    jobType: [],
    title: '',
    description: '',
    roleOverview: '',
    keyResponsibilities: [],
    skills: [],
    qualifications: [],
    salary: '',
    experience: '',
    contactInfo: ''
};

const JobPostingForm = () => {
    const { AllJobs } = useSelector(state => state.website);
    const [formData, setFormData] = useState(initialForm);
    const [file, setFile] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false); // State to handle modal visibility
    const dispatch = useDispatch();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleFileChange = (e) => {
        const uploadedFile = e.target.files[0];
        if (uploadedFile) {
            setFile(uploadedFile);
            parseFile(uploadedFile);
        }
    };

    const parseFile = (uploadedFile) => {
        const fileExtension = uploadedFile.name.split('.').pop().toLowerCase();

        if (fileExtension === 'csv') {
            Papa.parse(uploadedFile, {
                complete: (result) => {
                    const data = result.data[1];
                    mapDataToForm(data);
                },
                header: true,
            });
        } else if (fileExtension === 'xlsx') {
            const reader = new FileReader();
            reader.onload = (e) => {
                const binaryString = e.target.result;
                const wb = XLSX.read(binaryString, { type: 'binary' });
                const ws = wb.Sheets[wb.SheetNames[0]];
                const data = XLSX.utils.sheet_to_json(ws);
                mapDataToForm(data[0]);
            };
            reader.readAsBinaryString(uploadedFile);
        } else {
            toast.error('Invalid file type. Only CSV and XLSX are supported.');
        }
    };

    const mapDataToForm = (data) => {
        setFormData({
            ...formData,
            location: data.location || '',
            jobType: data.jobType ? data.jobType.split(',') : [],
            title: data.title || '',
            description: data.description || '',
            roleOverview: data.roleOverview || '',
            keyResponsibilities: data.keyResponsibilities ? data.keyResponsibilities.split(',') : [''],
            skills: data.skills ? data.skills.split(',') : [''],
            qualifications: data.qualifications ? data.qualifications.split(',') : [''],
            salary: data.salary || '',
            experience: data.experience || '',
            contactInfo: data.contactInfo || ''
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await dispatch(createJob(formData));
        toast.success("Job posted successfully");
        setFormData(initialForm);
        setIsModalOpen(false); // Close the modal after submission
    };

    useEffect(() => {
        dispatch(fetchAllJobs());
    }, [dispatch]);

    return (
        <div className="relative px-6 bg-white rounded-md shadow-lg">
            {/* Trigger Button to Open Modal */}
            <button
                onClick={() => setIsModalOpen(true)}
                className="bg-red-600 flex flex-row justify-center items-center font-semibold space-x-3 text-white p-3 rounded-md hover:bg-red-700"
            >
                <BriefcaseBusiness />
                <span>
                    Post Job
                </span>
            </button>

            {/* Modal Background */}
            {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
                    <div className="bg-white relative rounded-lg shadow-md w-full max-w-2xl px-8 py-3 flex flex-col max-h-svh overflow-y-auto">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-6">Job Posting Form</h2>
                        <button
                            onClick={() => setIsModalOpen(false)} // Close modal on click
                            className="absolute top-3 right-3 text-black p-3 rounded-md"
                        >
                            <X/>
                        </button>
                        <form onSubmit={handleSubmit}>
                            <TextInput
                                label="Location"
                                name="location"
                                value={formData.location}
                                onChange={handleInputChange}
                                required
                            />
                            <Checkboxes
                                label="Job Type"
                                options={['Full Time', 'Part Time', 'Contract','Freelance']}
                                selectedOptions={formData.jobType}
                                onChange={(selected) => setFormData({ ...formData, jobType: selected })}
                            />
                            <TextInput
                                label="Title"
                                name="title"
                                value={formData.title}
                                onChange={handleInputChange}
                                required
                            />
                            <TextArea
                                label="Description"
                                name="description"
                                value={formData.description}
                                onChange={handleInputChange}
                                required
                            />
                            <TextArea
                                label="Role Overview"
                                name="roleOverview"
                                value={formData.roleOverview}
                                onChange={handleInputChange}
                                required
                            />
                            <TagsInput
                                label="Key Responsibilities"
                                values={formData.keyResponsibilities}
                                onChange={(newValues) => setFormData({ ...formData, keyResponsibilities: newValues })}
                            />
                            <TagsInput
                                label="Skills"
                                values={formData.skills}
                                onChange={(newValues) => setFormData({ ...formData, skills: newValues })}
                            />
                            <TagsInput
                                label="Qualifications"
                                values={formData.qualifications}
                                onChange={(newValues) => setFormData({ ...formData, qualifications: newValues })}
                            />
                            <TextInput
                                label="Salary"
                                name="salary"
                                type="number"
                                value={formData.salary}
                                onChange={handleInputChange}
                                required
                            />
                            <TextInput
                                label="Experience (in years)"
                                name="experience"
                                type="number"
                                value={formData.experience}
                                onChange={handleInputChange}
                                required
                            />
                            <TextInput
                                label="Contact"
                                name="contactInfo"
                                value={formData.contactInfo}
                                onChange={handleInputChange}
                                required
                            />
                            <button className="mt-6 flex justify-center items-center transition-all duration-300 hover:scale-105 ease-in-out w-full bg-red-600 text-white p-3 rounded-md hover:bg-red-700 text-center">
                                <span>Submit</span>
                            </button>
                        </form>
                    </div>
                </div>
            )}

            {/* Job Table View */}
            {AllJobs && AllJobs.length > 0 && <ShowJobsTableView AllJobs={AllJobs} />}
        </div>
    );
};
const TableHeader = () => {
    return (
        <div className={`grid grid-cols-7 gap-2 p-4 border font-semibold text-black shadow-md bg-gray-200 rounded-t-lg`}>
            <div className="px-4 py-2 text-base text-center">Title</div>
            <div className="px-4 py-2 text-base text-center">Description</div>
            <div className="px-4 py-2 text-base text-center">Location</div>
            <div className="px-4 py-2 text-base text-center">Salary</div>
            <div className="px-4 py-2 text-base text-center">Status</div>
            <div className="px-4 py-2 text-base text-center">Action</div>
        </div>
    );
};

const TableRow = ({ job,editJobApplication ,removeJobApplication}) => {
    return (
        <div className={`grid grid-cols-7 gap-2 p-4 border-b hover:bg-gray-50 border justify-center items-center`}>
            <div className="px-4 py-2 text-center text-sm">{job?.title}</div>
            <div className="px-4 py-2 text-center text-sm">{job?.description}</div>
            <div className="px-4 py-2 text-center text-sm">{job?.location}</div>
            <div className="px-4 py-2 text-center text-sm">{job?.salary}</div>
            <div className="px-4 py-2 rounded-full space-x-3 p-2 w-fit font-bold bg-black text-white text-center text-sm justify-center flex items-center">
                {job?.isActiveJob ? <Eye/> : <EyeClosed/>}
                <label className={``}>
                    {job?.isActiveJob ? "Active" : "Inactive"}
                </label>
            </div>
            <div className="px-4 py-2 text-center text-sm space-x-2 justify-center items-center flex">
                <button onClick={editJobApplication} className='bg-green-600 p-2 rounded-md text-white transition-all duration-300 hover:bg-green-700 ease-ease-in-out-expo hover:scale-105'>
                    <Edit/>
                </button>
                <button onClick={removeJobApplication} className='bg-red-600 p-2 rounded-md text-white transition-all duration-300 hover:bg-red-700 ease-ease-in-out-expo hover:scale-105'>
                    <X/>
                </button>
            </div>
        </div>
    );
};
const InputField = ({ label, name, value, onChange, type = 'text', rows, checked }) => {
    if (type === 'checkbox') {
        return (
            <div>
                <label className="block text-sm font-semibold mb-2">{label}</label>
                <input
                    type="checkbox"
                    name={name}
                    checked={checked}
                    onChange={onChange}
                    className="border p-2 w-6 h-6"
                />
            </div>
        );
    }

    if (type === 'textarea') {
        return (
            <div>
                <label className="block text-sm font-semibold mb-2">{label}</label>
                <textarea
                    name={name}
                    value={value}
                    onChange={onChange}
                    rows={rows}
                    className="border p-2 w-full"
                />
            </div>
        );
    }

    return (
        <div>
            <label className="block text-sm font-semibold mb-2">{label}</label>
            <input
                type={type}
                name={name}
                value={value}
                onChange={onChange}
                className="border p-2 w-full"
            />
        </div>
    );
};

const EditJobModal = ({ job, onClose, onSave }) => {
    const [formData, setFormData] = useState({ ...job });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: type === 'checkbox' ? checked : value }));
    };

    const handleSave = () => {
        onSave(formData); // Save the updated data
        onClose(); // Close the modal
    };

    if (!job) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg w-1/2 max-h-[700px] overflow-y-auto">
                <div className='w-full justify-center items-center p-3 flex bg-gray-500 mb-4'>
                    <h2 className="text-xl text-white text-center font-semibold">Edit Job</h2>
                </div>
                <div className="space-y-4">
                    <InputField
                        label="Job Active"
                        name="isActiveJob"
                        type="checkbox"
                        checked={formData?.isActiveJob}
                        onChange={handleChange}
                    />
                    <InputField
                        label="Title"
                        name="title"
                        value={formData?.title}
                        onChange={handleChange}
                    />
                    <InputField
                        label="Location"
                        name="location"
                        value={formData?.location}
                        onChange={handleChange}
                    />
                    <InputField
                        label="Description"
                        name="description"
                        value={formData?.description}
                        onChange={handleChange}
                        rows={6}
                        type="textarea"
                    />
                    <InputField
                        label="Role Overview"
                        name="roleOverview"
                        value={formData?.roleOverview}
                        onChange={handleChange}
                        rows={6}
                        type="textarea"
                    />
                    <InputField
                        label="Experience"
                        name="experience"
                        value={formData?.experience}
                        onChange={handleChange}
                    />
                    <TagsInput
                        label="Key Responsibilities"
                        values={formData?.keyResponsibilities}
                        onChange={(newValues) => setFormData({ ...formData, keyResponsibilities: newValues })}
                    />
                    <TagsInput
                        label="Skills"
                        values={formData?.skills}
                        onChange={(newValues) => setFormData({ ...formData, skills: newValues })}
                    />
                    <TagsInput
                        label="Qualifications"
                        values={formData?.qualifications}
                        onChange={(newValues) => setFormData({ ...formData, qualifications: newValues })}
                    />
                    <InputField
                        label="Expected Salary"
                        name="salary"
                        value={formData?.salary}
                        onChange={handleChange}
                    />
                    <InputField
                        label="Contact Info"
                        name="contactInfo"
                        value={formData.contactInfo}
                        onChange={handleChange}
                    />
                    <div className="mt-4 flex justify-end">
                        <button onClick={handleSave} className="bg-red-600 text-white py-2 px-4 rounded-lg">
                            Save Changes
                        </button>
                        <button onClick={onClose} className="ml-2 bg-gray-300 py-2 px-4 rounded-lg">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};


const ShowJobsTableView = ({ AllJobs }) => {
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedJob, setSelectedJob] = useState(null);
    const[deletingJobId,setDeletingJobId] = useState(null);

    const openModal = (job) => {
        setSelectedJob(job);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedJob(null);
    };

    const handleSave = async (updatedJob) => {
        if(updatedJob !== null){
            await dispatch(updateCurrentJob({jobId:updatedJob._id,data:updatedJob}));
            dispatch(fetchAllJobs());
            toast.success("Job Updated Successfully")
        }
    };
    const removeJobApplication = async (jobId) => {
        console.log("Job Id:",jobId)
        const response =  await dispatch(removeJob({jobId}));
        if(response?.payload?.success){
            toast.success("Job Deleted Successfully")
            dispatch(fetchAllJobs());
        }else{
            toast.error("Failed: Something went wrong!")
        }
        setDeletingJobId(null);
    };

    return (
        <div className="overflow-x-auto bg-white mt-3 ">
            <TableHeader />
            {/* Table Rows */}
            {AllJobs.map((job, index) => (
                <div key={job._id || index} className="cursor-pointer hover:bg-gray-100">
                    <TableRow job={job}
                        editJobApplication={()=>{
                            openModal(job);
                        }}
                        removeJobApplication={(e)=>{
                            // removeJobApplication(job._id)
                            setDeletingJobId(job._id)
                        }} 
                    />
                </div>
            ))}
            {/* Modal for editing job */}
            {
                isModalOpen && <EditJobModal job={selectedJob} onClose={closeModal} onSave={handleSave}/>
            }
            <ConfirmDeletePopup isOpen={deletingJobId !== null} onCancel={() => setDeletingJobId(null)} onConfirm={() => removeJobApplication(deletingJobId)}/>
        </div>
    );
};



const FileUpload = ({ onFileChange }) => {
    return (
        <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Upload CSV/XLSX File</label>
            <input
                type="file"
                onChange={onFileChange}
                accept=".csv,.xlsx"
                className="mt-2 p-2 w-full border rounded-md"
            />
        </div>
    );
};

const TextInput = ({ label, name, value, onChange, required, type = 'text' }) => {
    return (
        <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">{label}</label>
            <input
                type={type}
                name={name}
                value={value}
                onChange={onChange}
                required={required}
                className="mt-2 p-2 w-full border rounded-md"
            />
        </div>
    );
};


const TextArea = ({ label, name, value, onChange, required }) => {
    return (
        <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">{label}</label>
            <textarea
                name={name}
                value={value}
                onChange={onChange}
                required={required}
                className="mt-2 p-2 w-full border rounded-md"
                rows="4"
            />
        </div>
    );
};

const Checkboxes = ({ label, options, selectedOptions, onChange }) => {
    const handleChange = (e) => {
        const { value } = e.target;
        const updatedOptions = selectedOptions.includes(value)
            ? selectedOptions.filter((option) => option !== value)
            : [...selectedOptions, value];
        onChange(updatedOptions);
    };

    return (
        <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">{label}</label>
            <div className="flex space-x-4 mt-2">
                {options.map((option) => (
                    <label key={option} className="inline-flex items-center">
                        <input
                            type="checkbox"
                            value={option}
                            checked={selectedOptions.includes(option)}
                            onChange={handleChange}
                            className="form-checkbox h-4 w-4 text-red-600"
                        />
                        <span className="ml-2 text-gray-700">{option}</span>
                    </label>
                ))}
            </div>
        </div>
    );
};

const TagsInput = ({ label, values, onChange }) => {
    const handleAddTag = (e) => {
        if (e.key === 'Enter' && e.target.value.trim()) {
            e.preventDefault();
            const updatedTags = [...values, e.target.value.trim()];
            onChange(updatedTags);
            e.target.value = '';
        }
    };

    const handleRemoveTag = (index) => {
        const updatedTags = values.filter((_, idx) => idx !== index);
        onChange(updatedTags);
    };

    return (
        <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">{label}</label>
            <div className="mt-2">
                {values.map((value, index) => (
                    <span key={index} className="inline-flex items-center bg-black text-white rounded-full py-1 px-3 mr-2 mb-2">
                        {value}
                        <button
                            type="button"
                            onClick={() => handleRemoveTag(index)}
                            className="ml-2"
                        >
                            <X />
                        </button>
                    </span>
                ))}
            </div>
            <input
                type="text"
                onKeyDown={handleAddTag}
                placeholder="Press Enter to add tag"
                className="mt-2 p-2 w-full border rounded-md"
            />
        </div>
    );
};

export default JobPostingForm;
