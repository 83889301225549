import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  searchTerm: '',
  searchResults: [],
  status: '',
  isLoading: false,
  error: null,
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchTerm(state, action) {
      state.searchTerm = action.payload;
    },
    fetchRequest(state) {
      state.status = 'loading';
      state.isLoading = true;
      state.error = null;
    },
    fetchSuccess(state, action) {
      state.searchResults = action.payload.results;
      state.status = 'succeeded';
      state.isLoading = false;
    },
    fetchFailure(state, action) {
      state.error = action.payload;
      state.status = 'failed';
      state.isLoading = false;
    },
    clearResults(state) {
      state.searchResults = [];
    }
  },
});

export const {
  setSearchTerm,
  fetchRequest,
  fetchSuccess,
  fetchFailure,
  clearResults
} = searchSlice.actions;

// Selectors
export const selectSearchTerm = (state) => state.search.searchTerm;
export const selectSearchResults = (state) => state.search.searchResults;
export const selectSearchStatus = (state) => state.search.status;
export const selectSearchLoading = (state) => state.search.isLoading;
export const selectSearchError = (state) => state.search.error;

export default searchSlice.reducer;
