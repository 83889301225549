import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../Constants/constant';
import { fetchRequest } from '../../Redux/slices/userSlice';
import { useDispatch } from 'react-redux';
import { PayBonus } from './Payamount';
import Cookies from 'js-cookie';
const RoomDetailsPopup1 = ({ update, room, onClose, user, clientId }) => {

    const [showConfirmPopup, setShowConfirmPopup] = useState(false);
    const [paypopup, setPaypopup] = useState(false)
    const [selectedReport, setSelectedReport] = useState(null);
    const [resolveMessage, setResolveMessage] = useState('');
    const dispatch = useDispatch();
    const token = Cookies.get('token');
    const handleUpdateStatus = async () => {
        if (!selectedReport) return;

        try {
            await axios.put(`${BASE_URL}/api/v-1/report/updatereportbyid/${selectedReport._id}`, {
                status: 'resolved',
                resolvemessage: resolveMessage
            },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
            setShowConfirmPopup(false);
            update()
            dispatch(fetchRequest());
            alert('Report updated successfully.');
            onClose();
        } catch (error) {
            console.error('Error updating report:', error);
            alert('Failed to update the report.');
        }
    };

    const openConfirmPopup = (report) => {
        setSelectedReport(report);
        setShowConfirmPopup(true);
    };

    if (!room) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-70 z-50">
            <div className="bg-white xl:ml-40 mr-16  p-6 rounded-xl shadow-xl ">
                <h2 className="text-2xl font-bold text-gray-800 mb-6">
                    Room Details: <span className="text-blue-600">#{room.roomId}</span><br />
                    Repoted clientID : <span className="text-blue-600">#{clientId}</span><br />
                    <button
                        onClick={() => setPaypopup(true)}
                        className=" px-4 py-2 mt-4 bg-gradient-to-l from-red-400 to-red-600 text-white font-semibold rounded-lg shadow-lg hover:from-red-500 hover:to-red-700 transform hover:scale-105 transition-all duration-300"
                    >
                        Amount Related Issue
                    </button>

                    {paypopup && (
                        <PayBonus userId={clientId} roomId={room.roomId} onClose={()=>setPaypopup(false)} />
                    )}



                </h2>
                <table className="w-full border border-gray-300">
                    <thead className="bg-gray-100">
                        <tr>
                            <th className="border px-4 py-2 text-left text-gray-700">Amount</th>
                            <th className="border px-4 py-2 text-left text-gray-700">Number of Players</th>
                            <th className="border px-4 py-2 text-left text-gray-700">Mode Type</th>
                            <th className="border px-4 py-2 text-left text-gray-700">Players</th>
                            <th className="border px-4 py-2 text-left text-gray-700">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="border-t">
                            <td className="border px-4 py-3 text-gray-800">{room.joining_amount}</td>
                            <td className="border px-4 py-3 text-gray-800">{room.no_of_players}</td>
                            <td className="border px-4 py-3 text-gray-800">{room.mode_type}</td>
                            <td className="border px-4 py-3 text-gray-800">
                                {room.players.map((player, index) => (
                                    <div key={index} className="mb-4">
                                        <table className="w-full border border-gray-300 mb-4">
                                            <thead className="bg-gray-200">
                                                <tr>
                                                    <th className="border px-2 py-1 text-left text-gray-700">Game Name</th>
                                                    <th className="border px-2 py-1 text-left text-gray-700">Client ID</th>
                                                    <th className="border px-2 py-1 text-left text-gray-700">Win/Loss Status</th>
                                                    <th className="border px-2 py-1 text-left text-gray-700">Win/Loss Amount</th>
                                                    <th className="border px-2 py-1 text-left text-gray-700">Pieces</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="border px-2 py-1 font-semibold">{player.game_name}</td>
                                                    <td className="border px-2 py-1 font-semibold">{player.clientId}</td>
                                                    <td className="border px-2 py-1 font-semibold">{player.winorloss}</td>
                                                    <td className="border px-2 py-1 font-semibold">{player.winamount}</td>
                                                    <td className="border px-2 py-1 font-semibold">
                                                        {player.pieces.map((piece, pieceIndex) => (
                                                            <div key={pieceIndex} className="text-sm text-gray-600 mb-2">
                                                                P1: {piece.p1}  |
                                                                P2: {piece.p2}   |
                                                                P3: {piece.p3}   |
                                                                P4: {piece.p4}  |
                                                                <hr />
                                                                <div className="font-semibold">Total Points: {piece.totalpoints}</div>
                                                            </div>
                                                        ))}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                ))}
                            </td>
                            <td className="border px-4 py-3 text-gray-800">
                                <div>
                                    <span className={`px-6 py-4 ${user.status === 'pending' ? 'text-red-500' :
                                        user.status === 'resolved' ? 'text-green-500' : ''
                                        }`}>
                                        {user.status}
                                    </span>
                                    {user.status === 'pending' && (
                                        <button
                                            onClick={() => openConfirmPopup(user)}
                                            className="ml-2 px-4 py-2 bg-gradient-to-r from-blue-400 to-blue-600 text-white font-semibold rounded-lg shadow-lg hover:from-blue-500 hover:to-blue-700 transform hover:scale-105 transition-all duration-300"
                                        >
                                            Resolve
                                        </button>
                                    )}
                                </div>

                            </td>
                        </tr>
                    </tbody>
                </table>
                <h1 className='text-2xl font-bold text-gray-800 mb-6'>Reported Details</h1>
                <div className='flex'>
                    <div className='flex text-gray-800 font-semibold mr-10' >
                        <div className='mr-10'>
                            Reported Message:
                            <br />
                            <textarea
                                className='text-red-500 text-justify'
                                cols={100}
                                rows={6}
                                value={user.message}
                                readOnly
                            />
                        </div>

                        <div className='text-1xl text-gray-800 mb-2 font-semibold'>
                            Resolve Message:
                            <br />
                            <textarea
                                className='text-green-500 text-justify border'
                                placeholder={user.resolvemessage ? `${user.resolvemessage}` : 'Please Enter Proper response'}
                                cols={100}
                                rows={6}
                                value={resolveMessage}
                                onChange={(e) => setResolveMessage(e.target.value)}
                            />
                        </div>
                    </div>
                </div>

                <div className="mt-6 text-right">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 mt-4 bg-gradient-to-l from-blue-400 to-blue-600 text-white font-semibold rounded-lg shadow-lg hover:from-blue-500 hover:to-blue-700 transform hover:scale-105 transition-all duration-300"
                    >
                        Close
                    </button>
                </div>
            </div>

            {showConfirmPopup && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-70 z-50">
                    <div className="bg-white p-6 rounded-xl shadow-xl">
                        <h2 className="text-xl font-bold text-gray-800 mb-4">
                            Confirm Status Update
                        </h2>
                        <p className="text-gray-600 mb-6">
                            Are you sure you want to resolve this report?
                        </p>
                        <div className="flex justify-end">
                            <button
                                onClick={() => setShowConfirmPopup(false)}
                                className="px-4 py-2 bg-gray-300 text-gray-800 rounded-lg mr-4 hover:bg-gray-400 transition duration-200"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleUpdateStatus}
                                className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition duration-200"
                            >
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
};

export default RoomDetailsPopup1;
